import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import "@polymer/iron-flex-layout/iron-flex-layout.js";
import "@polymer/iron-flex-layout/iron-flex-layout-classes.js";
import { OECommonMixin } from "oe-mixins/oe-common-mixin.js";
import {AnimateMixin} from "../mixins/animate-mixin.js";
import "@polymer/paper-card/paper-card.js";
import "../../styles/app-theme.js";
import "../../styles/oe-animation.js";

/**
 * ### getting-started-page
 * `getting-started-page`
 *
 *
 * @customElement
 * @polymer
 */
class GettingStartedPage extends AnimateMixin(OECommonMixin(PolymerElement)) {
	static get is() {
		return "getting-started-page";
	}

	static get template() {
		return html `
            <style include="iron-flex iron-flex-alignment iron-flex-factors oe-animation">

                #container{
                    padding: 32px 0px;
                    background:var(--secondary-background-color);
                }

                #content{
                    @apply --canvas;
                }
                
                h1{
                    color:var(--primary-dark-color);
                    margin-top: 0;
                    margin-bottom: 0.5rem;
                    font-weight: 500;
                    line-height: 1.2;
                    font-size:2.5rem;
                }

                .main-section{
                    background:var(--primary-background-color);
                }

                .main-content{
                    padding: 0px 32px;
                    letter-spacing: 0.7px;
                    line-height: 30px;
                    background: var(--primary-background-color);
                    box-sizing: border-box;
                    align-items: center;
                    align-content: center;
                    display: flex;
                }

                .image-container{
                    height: 312px;
                    width: 590px;
                }

                .card-panel{
                    margin: 32px 0px;
                }

                .card-panel > .secondary-card{
                    width: 48%;
                    padding: 16px;
                    box-sizing: border-box;
                    background: var(--primary-background-color);
                    border-radius : 24px;
                    border-bottom-left-radius:0px;
                    box-shadow: 3px 4px 1px rgb(0 0 0 / 10%);
                }

                .card-header{
                    font-size:24px;
                    color:var(--primary-dark-color);
                    display: flex;
                    align-items: center;
                }
                
                .card-content{
                    padding:16px 0px;
                    line-height: 1.5rem;
                }

            </style>
            <div id="container">
                <div id="content">
                    <h1 oe-animate="fade-up">
                        <oe-i18n-msg msgid="Glimpse of our APIs">Glimpse of our APIs</oe-i18n-msg>
                    </h1>
                    <div class="layout horizontal main-section">
                        <div class="flex main-content">
                            <p oe-animate="fade-up" oe-animate-delay="500">
                                <oe-i18n-msg msgid="getting_started_glimpse_desc">Blend a comprehensive, well-designed suite of 300+ Core APIs to your digital offering. A well-defined Open API solution, a new way for your Corporate Customers and FinTech Partners to interact.</oe-i18n-msg>    
                            </p>
                        </div>
                        <iron-image class="image-container" oe-animate="zoom-in" oe-animate-delay="500" src="img/getting-started-visual.png" sizing="contain"></iron-image>
                    </div>
                    <div class="layout horizontal justified card-panel">
                        <div class="secondary-card" oe-animate="fade-up" oe-animate-delay="500">
                            <label class="card-header">
                                <oe-i18n-msg msgid="Result">Result</oe-i18n-msg>
                                <iron-icon icon="chevron-right"></iron-icon>
                            </label>
                            <div class="card-content">
                                <p>
                                    <oe-i18n-msg msgid="getting_started_before_register">Before you register, explore our API ecosystem, see what you can do and read our Getting Started Guide</oe-i18n-msg>
                                </p>
                            </div>
                        </div>
                        <div class="secondary-card" oe-animate="fade-up" oe-animate-delay="1000">
                            <label class="card-header">
                                <oe-i18n-msg msgid="Build">Build</oe-i18n-msg>
                                <iron-icon icon="chevron-right"></iron-icon>
                            </label>
                            <div class="card-content">
                                <p>
                                    <strong><oe-i18n-msg msgid="Ready to get started ?">Ready to get started ?</oe-i18n-msg></strong>
                                    <a href$=[[__getPath("/register")]]><oe-i18n-msg msgid="Request your access">Request your access</oe-i18n-msg></a>
                                </p>
                                <p>
                                    <oe-i18n-msg msgid="getting_started_request_access">Once you've registered, generate your secure certificate for our Sandbox and you'll be able to access our APIs. After that, it's up to your imagination!</oe-i18n-msg>    
                                </p>
                                <tpp-button oe-animate="fade-up" oe-animate-delay="1000" primary animate-on-hover message="Register" icon="arrow-forward" on-tap="_gotoRegister"></tpp-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        `;
	}

	__getPath(path) {
		return window.OEUtils.subPath + path;
	}

	_gotoRegister() {
		window.oe_navigate_to("/register");
	}
}
window.customElements.define(GettingStartedPage.is, GettingStartedPage);
