import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import "@polymer/iron-flex-layout/iron-flex-layout.js";
import "@polymer/iron-flex-layout/iron-flex-layout-classes.js";
import { PromiseMixin } from "../mixins/promise-mixin.js";
import "../../styles/app-theme.js";
import "oe-ui-forms/validators/oe-block-validator.js";
import {AnimateMixin} from "../mixins/animate-mixin.js";
import "../../styles/oe-animation.js";
import "../components/tpp-button.js";

/**
 * ### reset-password-page
 * `reset-password-page`
 *
 *
 * @customElement
 * @polymer
 */
class ResetPasswordPage extends AnimateMixin(PromiseMixin(PolymerElement)) {
	static get is() {
		return "reset-password-page";
	}

	static get template() {
		return html `
   <style include="iron-flex iron-flex-alignment app-theme oe-animation iron-flex-factors">

		.banner-section{
			margin: 0 100px;
    		padding: 0px 16px 60px 16px;
            width: auto;
            min-height: calc(100vh - 120px);
            box-sizing: border-box;
        }

        
        .welcome-message{
            padding: 40px 0px;
        }

         .hero h1 {
			font-size: 42px;
    		font-weight: 300;
            color: var(--primary-dark-color);
        }
        
        .hero h2 {
            color: var(--primary-grey-color);
        }

		.form-field > [field-id]{
			width:310px;
			margin-top:22px;
		}

		.float-right{
			width: fit-content;
			margin-top:30px;
		}

    </style>
	 <main id="container">
		<section class="hero banner-section layout horizontal justified">
            <div class="welcome-message layout vertical flex-2" oe-animate="fade-down">
                <h1>Sign up to get access to our <strong>API<br>sandboxes</strong> and <strong>documentation</strong></h1>
                <h2>Already have an account? Sign in here.</h2>
				<div class="form-field">
					<h1>Reset Password</h1>
					<oe-block-validator id="validator">
						<tpp-input type="password" hide-password-toggle field-id="new" required label="New Password" value={{password.new}}></tpp-input>
						<tpp-input type="password" hide-password-info field-id="confirm" required label="Confirm New Password" value={{password.confirm}}></tpp-input>
						<oe-eq-validator error="New Password should match confirm Password" fields='["new","confirm"]' model={{password}}></oe-eq-validator>
					</oe-block-validator>
				</div>
				<tpp-button class="float-right" primary animate-on-hover message="Reset" icon="arrow-forward" on-tap="_resetPassword"></tpp-button>
            </div>
            <img oe-animate="zoom-in" class="banner-img flex-1" src="img/reset-password-visual.png"/>
		</section>
	 </main>
    `;
	}

	constructor() {
		super();
		this.set("password", {});
	}

	_resetPassword() {
		this.$.validator.validateForm().then(result => {
			if (!result.valid) {
				return;
			}
			this._changePassword();
		});
	}

	async _changePassword() {
		let payload = {
			password: this.password.new
		};
		let token = (new URLSearchParams(location.search)).get("access_token");
		let url = `@restApiRoot/Appusers/updatePassword?access_token=${token}`;
		let [err] = await this._makeAjaxPromise(url, "post", payload);
		if (err) {
			this.fire("oe-show-error",  window.devportalError(err));
			return;
		}
		this.set("password", {});
		this.fire("oe-show-confirm", {
			message: "Password changed successfully.",
			ok: this._login,
			okLabel: "Login"
		});
		// this.fire("oe-show-success", "Password changed successfully.");
		// this._login();
	}

	_login() {
		window.location.pathname = window.OEUtils.subPath + "/authlogin";
	}
}
window.customElements.define(ResetPasswordPage.is, ResetPasswordPage);
