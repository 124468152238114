import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import "@polymer/iron-flex-layout/iron-flex-layout.js";
import "@polymer/iron-flex-layout/iron-flex-layout-classes.js";
import "../../tpp-button.js";
import "../../api-bundle-filter.js";
import "./manage-app/manage-app-api-mapping.js";
import "./manage-app/manage-app-credentials.js";
import "./promote-application.js";
import { PromiseMixin } from "../../../mixins/promise-mixin.js";

/**
 * ### manage-application
 * `manage-application`
 *
 *
 * @customElement
 * @polymer
 */
class ManageApplication extends PromiseMixin(PolymerElement) {
	static get is() {
		return "manage-application";
	}

	static get template() {
		return html`
            <style include="iron-flex iron-flex-alignment iron-flex-factors input-styles">
                .app-form{
                    gap: 4px 32px;
                    @apply --layout-horizontal;
                    @apply --layout-wrap;
                }

                .app-form label{
                    display:block;
                    margin-bottom:8px;
                }

                .app-form > *:not(.full-width){
                    width:calc(50% - 16px);
                }

                .app-form > .full-width{
                    width:calc(100% - 8px);
                    margin:8px 0px;
                }

                .primary-text{
                    color: var(--primary-dark-color);
                }

                .login-methods > paper-checkbox{
                    display:block;
                    margin-bottom: 8px;
                }

                paper-radio-button{
                    --paper-radio-group-item-padding:12px 24px 12px 0px;
                }

                .description{
                    font-size: 14px;
                    border-left: 2px solid var(--accent-color);
                    padding-left: 8px;
                    margin: 0px;
                }

                .credential-container{
                    margin: 8px 0px;
                }

                .credential-container > oe-info{
                    width:45%;
                }

                .credential-container > oe-info::part(value){
                    overflow-wrap: break-word;
                    padding-right: 5px;
                }


                oe-data-grid{
                    padding:2px;
                }

                manage-app-api-mapping{
                    padding:4px;
                    --filter-text-size:12px;
                }

                .button-container{
                    margin-top:16px;
                }

                tpp-button + tpp-button{
					margin-left:16px;
				}
                
                [hidden]{
                    display:none !important;
                }
            </style>
            <div>
                <div class="application-heading layout horizontal center justified">
                    <h2 class="app-name flex primary-text">
						<oe-i18n-msg msgid="App Details">App Details</oe-i18n-msg>
					</h2>
                    <tpp-button mini secondary message="Cancel" on-tap="_goBackToList"></tpp-button>
                </div>
                <div class="api-listing">
                    <div class="layout vertical">
                        ${this.__editAppTemplate}
                        <manage-app-api-mapping application=[[application]] all-apis={{allApis}} existing-maps={{existingAppApiMaps}} read-only-mode=[[readOnlyMode]]></manage-app-api-mapping>
                    </div>
                    <div class="layout horizontal end-justified button-container">
                        <div class="flex">
                            <tpp-button hidden=[[readOnlyMode]] class="self-start" mini primary message="Delete" on-tap="_deleteApp"></tpp-button>
                            <tpp-button hidden=[[__or(readOnlyMode,disablePublish)]] disabled=[[_isPromoteDisabled(pendingProdApp)]] class="self-start" mini primary message="Publish" on-tap="_initPublishApp"></tpp-button>
                        </div>
                        <tpp-button data-test-id="submit req" hidden=[[readOnlyMode]] mini primary message="Submit" on-tap="_saveChanges"></tpp-button>
                        <tpp-button mini secondary message="Cancel" on-tap="_goBackToList"></tpp-button>
                    </div>
                </div>
                <promote-application id="promoteDlg"></promote-application>
            </div>
        `;
	}

	static get __editAppTemplate() {
		return html`
        <div class="app-form">
            <tpp-input readonly=[[readOnlyMode]] label="Name" value={{application.name}}></tpp-input>
            <tpp-input readonly=[[readOnlyMode]] label="Description" value={{application.description}}></tpp-input>
            <tpp-input readonly=[[readOnlyMode]] pattern=[[absoluteRegex]] label="Callback URL" maxlength="250" value={{application.callbackUrl}}></tpp-input>
            <tpp-input readonly=[[readOnlyMode]] label="IP Whitelist" value={{application.ipWhiteList}}></tpp-input>
            <tpp-input readonly=[[readOnlyMode]] value={{application.certificate.authority}} label="SSL Certificate authority"></tpp-input>
            <oe-textarea always-float-label readonly$=[[readOnlyMode]]  class="rounded-input" value={{application.certificate.caCert}}  label="SSL Public certificate"></oe-textarea>

            <manage-app-credentials data-test-id="locate-auth" class="full-width" application={{application}} login-creds=[[loginCredentials]] read-only-mode=[[readOnlyMode]]></manage-app-credentials>
            <h2 class="full-width primary-text">
				<oe-i18n-msg msgid="Webhook Details">Webhook Details</oe-i18n-msg>
			</h2>
            <p class="description full-width">
				<oe-i18n-msg msgid="Please provide details for webhook end point you want us to configure, if its different than the call back URL you provided while registration of this APP.">Please provide details for webhook end point you want us to configure, if its different than the call back URL you provided while registration of this APP.</oe-i18n-msg>	
			</p>
            <tpp-input readonly=[[readOnlyMode]] auto-validate pattern=[[absoluteRegex]] label="Callback Endpoint" maxlength="250" value={{application.credentials.event.callbackUrl}}></tpp-input>
            <div>
                <label>&nbsp;</label>
                <paper-checkbox disabled=[[readOnlyMode]] checked={{application.credentials.event.allowCommonCallback}}>
					<oe-i18n-msg msgid="Common Callback Path">Common Callback Path</oe-i18n-msg>
				</paper-checkbox>
            </div>
            <h2 class="full-width primary-text">
				<oe-i18n-msg msgid="Webhook Authentication">Webhook Authentication</oe-i18n-msg>	
			</h2>
            <p class="description full-width">
				<oe-i18n-msg msgid="Please provide webhook authentication details, expected by your systems.">Please provide webhook authentication details, expected by your systems.</oe-i18n-msg>	
			</p>
            
            <oe-radio-group disabled$=[[readOnlyMode]] value="{{application.credentials.event.method}}" label="Authentication Type" 
                class="layout-radio-button" listdata="[[authTypeList]]" valueproperty="c" displayproperty="d"></oe-radio-group>            
            
            <tpp-input readonly=[[readOnlyMode]] auto-validate pattern=[[absoluteRegex]] label="Authentication Endpoint" maxlength="250" value={{application.credentials.event.tokenURL}}></tpp-input>

            <dom-if if=[[__isEqual(application.credentials.event.method,'apikey')]]>
                <template>
                    <div>
                        <tpp-input readonly=[[readOnlyMode]] label="API Key" maxlength="128" value={{application.credentials.event.apiKey}}></tpp-input>
                    </div>
                    <div></div>
                </template>
            </dom-if>

            <dom-if if=[[__isEqual(application.credentials.event.method,'client')]]>
                <template>
                    <div>
                        <tpp-input readonly=[[readOnlyMode]] label="Client ID" maxlength="128" user-error-message='{"patternMismatch":"Credentials should not contain special characters."}' pattern="[a-zA-Z0-9_\\.\\+\\-]+" value={{application.credentials.event.clientId}}></tpp-input>
                    </div>
                    <div>
                        <tpp-input readonly=[[readOnlyMode]] label="Client secret" maxlength="128" user-error-message='{"patternMismatch":"Credentials should not contain special characters."}' pattern="[a-zA-Z0-9_\\.\\+\\-]+" value={{application.credentials.event.clientSecret}}></tpp-input>
                    </div>
                </template>
            </dom-if>
        </div>
        `;
	}

	static get properties() {
		return {
			applicationId: {
				type: Object,
				observer: "_applicationIdChanged"
			},
			disablePublish: {
				type: Boolean,
				value: false
			},
			readOnlyMode: {
				type: Boolean,
				value: false
			},
			absoluteRegex: {
				type: String
			}
		};
	}

	__or(a, b) {
		return a || b;
	}

	constructor() {
		super();
		this.set("authTypeList", [
			{
				c: "apikey",
				d: "API Key"
			},
			{
				c: "client",
				d: "Client credentials"
			}
		]);
	}

	async _applicationIdChanged() {
		if (this.applicationId) {
			let url = "@restApiRoot/Applications/" + this.applicationId;
			let [err, application] = await this._makeAjaxPromise(url, "GET", null);

			let [err2, pendingProdApp] = await this._makeAjaxPromise(`${url}/getProductionData`, "GET", null);

			if (err || err2) {
				this.fire("oe-show-error", window.devportalError(err || err2));
				return;
			}


			if (pendingProdApp && pendingProdApp.result) {
				this.set("pendingProdApp", pendingProdApp.result);
			} else {
				this.set("pendingProdApp", null);
			}

			this.absoluteRegex = "^http(s?)\:\/\/[0-9a-zA-Z]*(.*)";

			if (!application.credentials) {
				application.credentials = {};
			}

			if (!application.credentials.login) {
				application.credentials.login = [];
			} else {
				this.set("loginCredentials", application.credentials.login.map(r => {
					return {
						...r,
						showSecret: false
					};
				}));
			}

			if (!application.credentials.event) {
				application.credentials.event = {};
			}

			if (!application.certificate) {
				application.certificate = {};
			}

			this.__backupApp = JSON.parse(JSON.stringify(application));
			this.set("application", application);
		}
	}

	/** SAVE / UPDATE LOGIC */

	__validate() {
		let isValid = true;
		let errorField = null;
		let needsUpdate = false;
		let needsLinkUpdate = false;
		let selectedApis = [];

		// Validate application data change
		let keys = ["name", "callbackUrl", "credentials"];
		for (let i = 0, kl = keys.length; i < kl; i++) {
			let k = keys[i];
			let oldValue = this.__backupApp[k];
			let newValue = this.application[k];

			if (!newValue) {
				isValid = false;
				errorField = k;
				break;
			}
			if (typeof newValue === "string" && typeof oldValue === "string") {
				if (newValue !== oldValue) {
					needsUpdate = true;
				}
			} else if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
				needsUpdate = true;
			}
		}

		if (!isValid) {
			return {
				isValid,
				errorField,
				needsUpdate,
				needsLinkUpdate,
				selectedApis
			};
		}

		let {
			loginMethod,
			clientConsent
		} = this.application;

		if (loginMethod === "client_credentials" && !(clientConsent && clientConsent.expiresAt)) {
			return {
				isValid: false,
				errorField: "Account Authorization",
				needsUpdate,
				needsLinkUpdate,
				selectedApis
			};
		}

		// validate app-api link change
		let existingMaps = this.existingAppApiMaps;
		selectedApis = this.allApis.filter(a => !!a._info.selected);

		if (selectedApis.length !== existingMaps.length) {
			needsLinkUpdate = true;
		} else {
			selectedApis.sort((a, b) => a.id > b.id ? -1 : 0);
			existingMaps.sort((a, b) => a.apiId > b.apiId ? -1 : 0);

			for (let i = 0, l = selectedApis.length; i < l; i++) {
				if (selectedApis[i].id !== existingMaps[i].apiId) {
					needsLinkUpdate = true;
					break;
				}
			}
		}

		return {
			isValid,
			errorField,
			needsUpdate,
			needsLinkUpdate,
			selectedApis
		};
	}

	_deleteApp() {
		this.fire("oe-show-confirm", {
			message: `Confirm deleting Application "${this.application.name}" ?`,
			ok: () => {
				let url = `@restApiRoot/Applications/${this.application.id}`;
				this.makeAjaxCall(url, "delete", null, null, null, null, (err, resp) => {
					if (err) {
						this.fire("oe-show-error", window.devportalError(err));
						return;
					}
					this.__finishUpdate("Application deleted successfully.");
				});
			},
			okLabel: "Delete"
		});
	}

	_saveChanges() {
		let {
			isValid,
			errorField,
			needsUpdate,
			needsLinkUpdate,
			selectedApis
		} = this.__validate();

		if (!isValid) {
			this.fire("oe-show-error", `Error in ${errorField} value`);
			return;
		}

		if (needsUpdate) {
			this.application.certificate.__row_status = "modified";

			let url = `@restApiRoot/AppUsers/${this.application.appUserId}/application/${this.application.id}`;
			this.makeAjaxCall(url, "PUT", this.application, null, null, null, (err) => {
				if (err) {
					this.fire("oe-show-error", window.devportalError(err));
					return;
				}
				if (!needsLinkUpdate) {
					this.__finishUpdate("Application updated successfully.");
				} else {
					this.__updateAppApiLinks(selectedApis);
				}
			});
		} else if (needsLinkUpdate) {
			this.__updateAppApiLinks(selectedApis);
		} else {
			this.fire("oe-show-message", "No modifications made.");
		}
	}

	__updateAppApiLinks(selectedApis) {
		let payload = {
			appId: this.application.id,
			apiIdList: selectedApis.map(api => {
				let result = {
					endpoint: this.application.credentials.event,
					...api
				};
				delete result._info;

				return result;
			}),
			endpoint: this.application.credentials.event
		};

		this.makeAjaxCall("@restApiRoot/AppApiMaps/updateMappedApis", "post", payload, null, null, null, (err, resp) => {
			if (err) {
				this.fire("oe-show-error", window.devportalError(err));
				return;
			}
			this.__finishUpdate("Your application request has been sent to admin to validate and approve.\nYou will receive email confirmation as soon as admin approves it.");
		});
	}

	__finishUpdate(message) {
		this.fire("oe-show-success", message);
		this.fire("update-app-list");
		this.set("applicationId", null);
	}

	/** PROMOTE APP */

	_isPromoteDisabled(promotedApp) {
		return promotedApp ? true : false;
	}

	_initPublishApp() {
		let config = {
			baseAppId: this.applicationId,
			apis: this.existingAppApiMaps.filter(a => a.approved)
		};

		if (this.apporvedProdApp) {
			config.application = this.apporvedProdApp;
		} else {
			let {
				callbackUrl,
				loginMethod,
				credentials,
				clientConsent,
				ipWhiteList
			} = this.application;

			config.application = {
				callbackUrl,
				loginMethod,
				credentials,
				clientConsent,
				ipWhiteList,
				certificate: "",
				apis: []
			};
		}

		if (config.apis.length === 0) {
			this.fire("oe-show-error", "There are no approved APIs for this application.");
			return;
		}

		this.$.promoteDlg.launch(config, () => {
			this._applicationIdChanged();
		});
	}

	/** UTILITY FUNCTIONS */

	__isEqual(a, b) {
		return a === b;
	}

	_goBackToList() {
		this.fire("show-list-view");
		this.set("applicationId", null);
	}
}
window.customElements.define(ManageApplication.is, ManageApplication);
