window.polymerSkipLoadingFontRoboto = true;
import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import "oe-utils/oe-utils.js";

const I18CONFIG = {
	lang: null,
	url: "locales",
	locales: {},
	defaults: {
		"valueMissing": { message: "$label$ is mandatory" },
		"rangeOverflow": { message: "$label$ is more than $max$" },
		"rangeUnderflow": { message: "$label$ is less than $min$" },
		"tooShort": { message: "Lengthen the value. min length is $minlength$" },
		"tooLong": { message: "Shorten the value. max length is $maxlength$" },
		"patternMismatch": { message: "Value does not match prescribed pattern" },
		"typeMismatch": { message: "Value does not match type $type$" },
		"numberFormat": { message: "Please enter a valid number" },
		"dateFormat": { message: "Please enter a valid date" },
		"record-created": { message: "Saved successfully" },
		"record-deleted": { message: "Deleted successfully" },
		"record-updated": { message: "Saved successfully" }
	}
};

if (window.OEi18nMsg) {
	window.OEi18nMsg.defaults = I18CONFIG.defaults;
} else {
	window.OEi18nMsg = I18CONFIG;
}

window.OEi18nMsg.url = "api/Literals/render";

window.OEUtils = window.OEUtils || {};
window.OEUtils.componentDefaults = window.OEUtils.componentDefaults || {};
window.OEUtils.componentDefaults["oe-combo"] = {
	itemTemplate: html`
		<span hidden=[[multi]]>[[_getItemDisplay(item)]]</span>
		<oe-checkbox hidden=[[!multi]] value=[[__isItemSelected(item,selectedItems.length)]] label="[[_getItemDisplay(item)]]"></oe-checkbox>
	`
};

window.devportalError = (err) => {
	let errResponse = err.detail.request.response;

	if (typeof errResponse === "string") {
		return errResponse;
	}

	let errorObj = window.OEUtils.extractErrorMessage(err);
	return errorObj.message || errorObj.code;
};

((dev)=>{
	let createTemplateString = (str) => {
		let t = [str];
		t.raw = [str];
		return html(t);
	};

	Object.keys(dev.customizedContent).forEach(c => {
		dev.customize[c] = createTemplateString(dev.customizedContent[c]);
	});
})(window.devportal);

import { setPassiveTouchGestures } from "@polymer/polymer/lib/utils/settings";
setPassiveTouchGestures(true);


import "@polymer/app-layout/app-header/app-header.js";
import "@polymer/app-layout/app-scroll-effects/app-scroll-effects.js";
import "@polymer/app-layout/app-toolbar/app-toolbar.js";
import "@polymer/iron-pages/iron-pages.js";
import "@polymer/app-route/app-location.js";

import "@polymer/app-layout/app-layout.js";
import "oe-message-handler/oe-message-handler.js";
import "oe-i18n-msg/oe-i18n-msg.js";
import { OEAjaxMixin } from "oe-mixins/oe-ajax-mixin.js";
import { ReduxMixin } from "./redux/redux-mixin.js";
import { updateUserInfo, updateOrgInfo, updateInvitesInfo } from "./redux/actions.js";

import "./layout/header-section.js";
import "./layout/footer-section.js";
import "./components/tpp-message-handler.js";
import "./components/loading-spinner.js";
import "./components/tpp-input.js";
import "./components/api-bundle-add-dialog.js";

import "./extended-components.js";
import "./overlay-fixer.js";
import "./pages/register-user-page.js";
import "./pages/login-user-page.js";
import "./components/session-handler.js";

/**
 * @customElement
 * @polymer
 */
class AppShell extends ReduxMixin(OEAjaxMixin(PolymerElement)) {
	static get is() {
		return "app-shell";
	}

	static get template() {
		return html`
    <style>

		:host{
			color : var(--primary-text-color);
			background-color:var(--primary-background-color);
			--canvas-width:94vw;

			--canvas:{
				width:100%;
				max-width: 1400px;
				margin : 0 auto;}
		}

		app-header {
			--app-header-shadow: {
				box-shadow: 0px 2px 20px rgb(1 41 112 / 30%);
			};
			z-index:22;
		}

		app-header::before{
			bottom:0px;
		}

		.scroll-to-top{
			position: fixed;
			visibility: hidden;
			opacity: 0;
			right: 15px;
			bottom: 15px;
			background: var(--primary-dark-color);
			width: 40px;
			height: 40px;
			border-radius: 4px;
			transition: all 0.4s;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor:pointer;
			color:var(--primary-contrast-color);
			--iron-icon-width:20px;
			--iron-icon-height:20px;
		}

		.scroll-to-top.show-true{
			visibility:visible;
			opacity:1;
		}

		[route-target]{
			min-height:calc(100vh - 120px);
		}

		@media(min-width:1200px) and (max-width:1399px){
			:host{
				--canvas-width:1140px;
			}
		}
		
		@media(min-width:992px) and (max-width:1199px){
			:host{
				--canvas-width:960px;
			}
		}

		@media(min-width:768px) and (max-width:991px){
			:host{
				--canvas-width:720px;
			}
		}

    </style>
	<tpp-header-layout>
        <app-header slot="header" fixed effects="waterfall">
          <header-section></header-section>
        </app-header>
        <div id="maincontent" class="content">
          <app-location route="{{route}}"></app-location>
          <tpp-custom-router route="{{route}}">
			<iron-pages route-target></iron-pages>
			<span class$="scroll-to-top show-[[showScrollToTop]]" on-tap="_scrollToTop">
				<iron-icon icon="arrow-upward"></iron-icon>
			</span>
          </tpp-custom-router>
          <footer-section></footer-section>
        </div>
    </tpp-header-layout>
	<session-handler></session-handler>
    <tpp-message-handler></tpp-message-handler>
	<loading-spinner></loading-spinner>
	<api-bundle-add-dialog>
		<slot name="yaml"></slot>
	</api-bundle-add-dialog>
    `;
	}

	constructor() {
		super();
		this.__computeScrollToTop();
		window.addEventListener("scroll", this.__computeScrollToTop.bind(this));
		window.addEventListener("location-changed", () => {
			document.scrollingElement.scrollTop = 0;
		});
		window.addEventListener("refetch-user-info", this._getUserInfo.bind(this));
		this._getUserInfo();
	}


	__computeScrollToTop() {
		this.set("showScrollToTop", window.scrollY > 100);
	}

	_scrollToTop() {
		window.scrollTo({
			top: 0,
			behavior: "smooth"
		});
	}

	_getUserInfo() {
		this.makeAjaxCall("@restApiRoot/AppUsers/aboutMe", "GET", null, null, null, null, (err, resp) => {
			if (err) {
				// window.OEi18nMsg.lang = window.devportal.defaultLanguage || navigator.language;
				return;
			}
			if (resp && typeof resp.userId === "string") {
				// window.OEi18nMsg.lang = resp.language || navigator.language;
				this.dispatch(updateUserInfo(resp));
				this._getUserOrganization(resp.email);
			}
		});
	}

	_getUserOrganization(email) {
		this.makeAjaxCall("@restApiRoot/AppUsers/myOrganization", "GET", null, null, null, null, (err, resp) => {
			if (err) {
				return;
			}
			if (resp && typeof resp.id === "string") {
				this.dispatch(updateOrgInfo(resp));
			} else {
				this._getUserInvites(email);
			}
		});
	}

	_getUserInvites(email) {
		this.makeAjaxCall("@restApiRoot/Invites/", "GET", null, null, { filter: { where: { email, status: "pending" } } }, null, (err, resp)=>{
			if (err) {
				return;
			}
			this.dispatch(updateInvitesInfo(resp));
		});
	}

	static get properties() {
		return {
			route: {
				type: Object
			}
		};
	}
}

window.customElements.define("app-shell", AppShell);
