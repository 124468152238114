import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import "@polymer/iron-flex-layout/iron-flex-layout.js";
import "@polymer/iron-flex-layout/iron-flex-layout-classes.js";
import { OEAjaxMixin } from "oe-mixins/oe-ajax-mixin.js";
import "../../styles/app-theme.js";
import "oe-input/oe-input.js";
import "oe-ui-forms/validators/oe-block-validator.js";
import {AnimateMixin} from "../mixins/animate-mixin.js";
import "../../styles/oe-animation.js";
import "@polymer/iron-image/iron-image.js";
import "@polymer/paper-dialog/paper-dialog.js";
import "../components/tpp-button.js";

/**
 * ### forgot-password-page
 * `forgot-password-page`
 *
 *
 * @customElement
 * @polymer
 */
class ForgotPasswordPage extends AnimateMixin(OEAjaxMixin(PolymerElement)) {
	static get is() {
		return "forgot-password-page";
	}

	static get template() {
		return html `
		<style include="iron-flex iron-flex-alignment app-theme oe-animation iron-flex-factors">

				.banner-section{
					margin: 0 100px;
					padding: 0px 16px 60px 16px;
					width: auto;
					min-height: calc(100vh - 120px);
					box-sizing: border-box;
				}

				
				.welcome-message{
					padding: 16px 0px;
				}

				.hero h1 {
					font-size: 42px;
					font-weight: 300;
					color: var(--primary-dark-color);
				}
				
				.hero h2 {
					color: var(--primary-grey-color);
				}

				.form-field{
					margin:20px 0px;
					width:374px;
				}

				.form-field [field-id]{
					margin-top:22px;
				}

				.float-right{
					float:right;
					margin-top:30px;
				}

				.banner-img{
					width:450px;
					height:315px;
				}

				.dialog-content{
					padding:16px;
					line-height:30px;
					width:45vw;
				}

			</style>
			<main id="container">
				<section class="hero banner-section layout horizontal justified">
					<div class="welcome-message layout vertical flex" oe-animate="fade-down">
					<h1><oe-i18n-msg msgid="Forgot Password">Forgot Password</oe-i18n-msg></h1>
					<h2>
					<oe-i18n-msg msgid="Enter the email address associated with your account and we'll send you a link to reset your password. ">Enter the email address associated with your account and we'll send you a link to reset your password.</oe-i18n-msg>
					</h2>
						<div class="form-field">
                            <input aria-hidden="true" label="User Id" hidden id="userId"/>
							<oe-block-validator id="validator">
								<tpp-input required type="email" label="Email ID" field-id="email" value={{userInfo.email}}></tpp-input>
							</oe-block-validator>
							<tpp-button class="float-right" on-tap="_forgotPassword" primary animate-on-hover message="Reset Password" icon="arrow-forward"></tpp-button>
						</div>
					</div>
					<iron-image sizing="contain" oe-animate="zoom-in" class="banner-img" src="img/forgot-password-visual.png"></iron-image>
				</section>
			</main>
			<paper-dialog id="dialog" modal>
				<div class="dialog-content">
					<oe-i18n-msg msgid="If you have provided a valid registered email address, you should expect a verification email shortly.">If you have provided a valid registered email address, you should expect a verification email shortly.</oe-i18n-msg> <br />
					<oe-i18n-msg msgid="If it's been a few minutes and you haven't received it yet, here's what you can do:">If it's been a few minutes and you haven't received it yet, here's what you can do:</oe-i18n-msg> <br />
					<oe-i18n-msg msgid="Check your spam folder to make sure it did not accidentally get filtered there.">- Check your spam folder to make sure it did not accidentally get filtered there.</oe-i18n-msg> <br />
					<oe-i18n-msg msgid="If you're not sure you have an account with us, you can always create a new one by signing up.">- If you're not sure you have an account with us, you can always create a new one by signing up.</oe-i18n-msg> <br />
					<oe-i18n-msg msgid="If you still need help, please contact us.">- If you still need help, please contact us.</oe-i18n-msg>
				</div>
				<div class="dialog-footer layout horizontal end-justified">
					<tpp-button on-tap="_loginPage" message="Close" primary mini></tpp-button>
				</div>
			</paper-dialog>
    	`;
	}

	connectedCallback() {
		super.connectedCallback();
		this.set("userInfo", {});
		this.addEventListener("oe-route-change", ()=>{
			this.fire("oe-captcha-init", {
				name: "register",
				onValid: (response) => {
					this.__forgotPassword(response);
				},
				onError: (error) => {
					this.fire("oe-show-error", error);
				},
				onRegister: captcha => {
					this._captcha = captcha;
				}
			});
		});
	}

	_forgotPassword() {
		this.$.validator.validateForm().then(result => {
			if (!result.valid) {
				this.fire("oe-show-error", "Error in form validation.");
				return;
			}
			this._captcha.validate(this.$.userId);
		});
	}

	__forgotPassword(captchaToken) {
		let {
			email
		} = this.userInfo;

		let payload = {
			email,
			captchaToken
		};
		payload.userId = this.$.userId.value;
		this.set("userInfo", {});
		this.makeAjaxCall("@restApiRoot/AppUsers/reset", "POST", payload, null, null, null, (err, resp) => {
			if (err) {
				this.fire("oe-show-error", window.devportalError(err));
				return;
			}
			this.$.dialog.open();
		});
	}

	_loginPage() {
		this.$.dialog.close();
		window.location.pathname = window.OEUtils.subPath + "/authlogin";
	}
}
window.customElements.define(ForgotPasswordPage.is, ForgotPasswordPage);
