import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import { PromiseMixin } from "../mixins/promise-mixin.js";
import "./tpp-dialog.js";
import "oe-radio-group/oe-radio-group.js";

class AppsListDialog extends PromiseMixin(PolymerElement) {
	static get is() {
		return "apps-list-dialog";
	}

	static get properties() {
		return {
			applicationsList: {
				type: Array,
				value: () => []
			}
		};
	}

	static get template() {
		return html`
            <style include="iron-flex iron-flex-alignment app-theme iron-flex-factors">

                #dialog::part(dialog){
                    min-width:300px;
                    width:500px;
                    margin:0px;
                }   

                .client-id{
                    --oe-radio-item:{
                        white-space: nowrap;
                        width: 190px;
                        display: block;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }

                .client-id::part(input-container){
                    padding:0px;
                }

                .client-id::part(radio-button){
                    padding:8px;
                    width: calc(50% - 20px);
                    overflow:hidden;
                }

            </style>
            <tpp-dialog id="dialog" entry-animation="none">
                <div slot="body" class="layout vertical justified list-body">
                    <tpp-dropdown disable-text-input label="Application" value={{authSetting.app}} list=[[applicationsList]] disp="__disp"></tpp-dropdown>
                    <oe-radio-group class="flex client-id" label="Client Id" value={{authSetting.credential}} listdata=[[credentialsList]] displayproperty="clientId"></oe-radio-group>
                </div>
                <tpp-button slot="footer" mini primary message="Copy" on-tap="_applySelectedAuth"></tpp-button>
                <tpp-button slot="footer" mini message="Cancel" on-tap="_close"></tpp-button>
            </tpp-dialog>
        `;
	}

	static get observers() {
		return [
			"_authAppChanged(authSetting.app)"
		];
	}

	async _launch(target) {
		this.set("authSetting", {
			app: this.applicationsList[0]
		});
		this.$.dialog.open({
			target,
			alignment: {
				vertical: "top",
				horizontal: "right"
			},
			offset: {
				vertical: 5
			}
		});
	}

	_close() {
		this.$.dialog.close();
	}

	_authAppChanged() {
		let creds = [];
		if (this.authSetting.app) {
			creds = this.authSetting.app.credentials.login;
		}
		this.set("credentialsList", [
			...creds
		]);
		if (creds.length > 0) {
			this.set("authSetting.credential", creds[0]);
		}
	}

	_applySelectedAuth() {
		let {
			credential
		} = this.authSetting;

		if (credential) {
			this.fire("apply-credential", credential);
		}
	}
}
window.customElements.define(AppsListDialog.is, AppsListDialog);
