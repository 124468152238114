import { html } from "@polymer/polymer/polymer-element.js";
import ProfileBaseView from "./profile-base-view.js";
import "@polymer/iron-icons/editor-icons.js";
import "../shuttle-list.js";

class ManageCatalogView extends ProfileBaseView {
	static get is() {
		return "manage-catalog-view";
	}

	static get contentTemplate() {
		return html`
		    <oe-data-grid disable-selection disable-config-editor disable-full-screen-mode disable-add
                id="grid" items={{catalogResponse}} min-column-width="100"
                on-oe-data-table-row-action="__handleRowAction"
                on-oe-data-table-action-refresh="_getCatalogs"
                on-oe-data-table-action-add="__launchAddDialog"
                toolbar-actions=[[grid.tableActions]]
                columns=[[_gridColumnConverter(grid.columns)]] row-actions=[[grid.rowActions]]></oe-data-grid>
            ${this.addCatalogTemplate}
        `;
	}

	_gridColumnConverter(cols) {
		return cols.map(c => {
			return {
				label: window.OEUtils.camelCaseToLabel(c.key),

				...c
			};
		});
	}

	connectedCallback() {
		super.connectedCallback();
		this._getCatalogs();
		this.set("viewTitle", "Catalog List");
		let gridConfig = {
			columns: [
				{
					key: "name"
				},
				{
					key: "description",
					disableFilter: true,
					disableSort: true
				},
				{
					key: "grpCount",
					label: "Group count",
					type: "number",
					disableFilter: true,
					disableSort: true
				}
			],
			rowActions: [
				{ action: "edit", title: "Edit", icon: "create", isHiddenFunction: r => r.type === "master"},
				{ action: "delete", title: "Delete", icon: "delete", isHiddenFunction: r => r.type !== "custom" }
			],
			tableActions: [
				{ action: "add", title: "Add catalog", icon: "add", isButton: true },
				{ action: "refresh", title: "Refresh list", icon: "refresh", isButton: true }
			]
		};
		this.set("grid", gridConfig);
	}

	async _getOrgCatalogMapping() {
		let [err, resp] = await this._makeAjaxPromise("@restApiRoot/OrgCatalogMappings", "GET");
		if (err) {
			this.fire("oe-show-error", window.devportalError(err));
			return;
		}
		this.set("orgCatalogMappings", resp);
	}

	async _getApiGroups() {
		let [apiGrpErr, apiGrpRes] = await this._makeAjaxPromise("@restApiRoot/Apigroups", "GET");
		if (apiGrpErr) {
			this.fire("oe-show-error", window.devportalError(apiGrpErr));
			return;
		}
		this.set("apiGroups", apiGrpRes);
	}

	async _getCatalogApiGroupMapping() {
		let [mappingErr, mappingResp] = await this._makeAjaxPromise("@restApiRoot/CatalogApiGroupMappings", "GET");
		if (mappingErr) {
			this.fire("oe-show-error", window.devportalError(mappingErr));
			return;
		}
		this.set("mappings", mappingResp);
	}

	async _getCatalogs() {
		await this._getApiGroups();
		await this._getCatalogApiGroupMapping();
		await this._getOrgCatalogMapping();
		let [catalogErr, catalogResp] = await this._makeAjaxPromise("@restApiRoot/Catalogs", "GET");
		if (!catalogErr) {
			for (let catalog of catalogResp) {
				let catalogId = catalog.id;
				let matchingMappings = this.mappings.filter(mapping => mapping.catalogId === catalogId);
				let groupIds = matchingMappings.map(mapping => mapping.apiGroupKey);
				catalog.groups = groupIds;
				// Set the _grpCount property on the catalog object
				catalog.grpCount = groupIds.length;
			}
			this.set("catalogResponse", catalogResp);
		}
	}

	static get addCatalogTemplate() {
		return html`
        <style include="input-styles"></style>
            <tpp-dialog id="add" modal>
                <label class="group-name" slot="header">
					<oe-i18n-msg msgid="[[dialogTitle]]"></oe-i18n-msg>
				</label>
                <div slot="body">
                    <tpp-input required label="Name" value="{{newCatalog.name}}"></tpp-input>
                    <oe-textarea rows="2" class="full-width rounded-input" always-float-label label="Description" value="{{newCatalog.description}}"></oe-textarea>
                    <h3>API Groups ([[newCatalog.apiGroups.length]])</h3>
                    <shuttle-list listdata=[[apiGroups]] selected={{newCatalog.apiGroups}} displayproperty="name" keyproperty="key"></shuttle-list>
                </div>
                <tpp-button primary mini slot="footer" on-tap="_saveCatalog" message="Save"></tpp-button>
                <tpp-button mini slot="footer" message="Close" on-tap="__closeAddDialog"></tpp-button>
            </tpp-dialog>
        `;
	}

	__launchAddDialog(catalog) {
		let title = "Edit catalog";
		if (!(catalog && catalog.id)) {
			title = "Add new catalog";
			catalog = {
				name: "",
				description: "",
				groups: [],
				apiGroups: [],
				type: "custom"
			};
		} else {
			catalog.apiGroups = catalog.groups.map(key => this.apiGroups.find(a => a.key === key));
		}
		this.set("newCatalog", catalog);
		this.set("dialogTitle", title);
		this.$.add.open();
	}

	__closeAddDialog() {
		this.$.add.close();
	}

	//  row-action
	__handleRowAction(event) {
		let {
			action,
			row
		} = event.detail;
		let isAlreadyMapped;
		switch (action.action) {
		case "edit":
			this.__launchAddDialog(row);
			break;
		case "delete":
			isAlreadyMapped = this.orgCatalogMappings.some(m => m.catalogId === row.id);
			if (isAlreadyMapped) {
				this.fire("oe-show-error", "Catalog is currently mapped to organization(s) and cannot be deleted.");
				return;
			}
			this.fire("oe-show-confirm", {
				message: "Confirm deleting the Catalog ?",
				ok: () => this._deleteCatalog(row.id)
			});
			break;
		default: break;
		}
	}

	async _deleteCatalog(id) {
		let [err] = await this._makeAjaxPromise("@restApiRoot/Catalogs/" + id, "DELETE");
		if (err) {
			return this.fire("oe-show-error", window.devportalError(err));
		}
		this.fire("oe-show-success", "Catalog deleted successfully.");
		this._getCatalogs();
	}

	async _saveCatalog() {
		if (!this.newCatalog) {
			return;
		}

		if (!this.newCatalog.name) {
			return this.fire("oe-show-error", "Catalog name is mandatory");
		}

		if (this.newCatalog.apiGroups.length === 0) {
			return this.fire("oe-show-error", "API Groups cannot be empty");
		}

		let payload = this.newCatalog;
		payload.groups = payload.apiGroups.map(g => g.key);
		delete payload.apiGroups;
		let method = payload.id ? "PUT" : "POST";
		let successMsg = payload.id ? "Catalog updated successfully." : "Catalog created successfully.";
		let [err, resp] = await this._makeAjaxPromise("@restApiRoot/Catalogs", method, payload);
		if (err) {
			return this.fire("oe-show-error", window.devportalError(err));
		}
		let mappings = payload.groups;
		let mappingPayload = { catalogId: resp.id, groupKeys: mappings };
		await this._makeAjaxPromise("@restApiRoot/CatalogApiGroupMappings/updateMappedApiGroups", "POST", mappingPayload);
		this.fire("oe-show-success", successMsg);
		this.__closeAddDialog();
		this._getCatalogs();
	}
}
window.customElements.define(ManageCatalogView.is, ManageCatalogView);
