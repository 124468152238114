import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import "oe-input/oe-input.js";
import { OECommonMixin } from "oe-mixins/oe-common-mixin.js";
import "../../styles/input-styles.js";


const DEFAULTPROPS = {
	minlength: 4,
	maxlength: 50,
	alwaysFloatLabel: false
};

class TppInput extends OECommonMixin(PolymerElement) {
	static get is() {
		return "tpp-input";
	}

	static get template() {
		return html`
            <style include="input-styles">

                iron-icon{
                    padding: 4px;
                }

				.tooltip-sufix{
                    position:relative;
                    cursor:help;
                }

                .toggle-suffix{
                    position:relative;
					cursor:pointer;
                }

                .tooltip-content{
                    width: 170px;
                    line-height: 14px;
                    letter-spacing: 0.7px;
                }

                .tooltip-content ul {
                    list-style: disc;
                    margin-inline-start: 16px;
                    padding:0px;
                }
                
                .rounded-input.password-true::part(iron-input){
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px;
                }

                :host(.has-prefix) .rounded-input::part(iron-input){
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                }

                .rounded-input.no-errors-true::part(paper-input-container){
                    margin-bottom:0px;
                }
                
                .suffix{
                    display:flex;
                    border: 1px solid var(--border-color);
                    border-radius: 3px;
                    height: 39px;
                    align-items: center;
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                    box-sizing: border-box;
                    border-left-width: 0px;
                    padding:0 8px;
                }
            </style>
            <oe-input id="input" class$="rounded-input password-[[_isPassword(type)]] no-errors-[[noErrors]]" auto-validate$=[[autoValidate]] on-blur="_trimOnBlur" user-error-message=[[userErrorMessage]]
            readonly$=[[readonly]] required=[[required]] field-id=[[fieldId]] type="[[_computedType(type,_showPassword)]]" value={{value}} always-float-label=[[_computeFloatLabel(label,alwaysFloatLabel)]] no-label-float$=[[noLabelFloat]] label=[[label]] placeholder=[[placeholder]] maxlength=[[maxlength]] minlength=[[minlength]] pattern=[[pattern]]>
                <slot name="prefix" slot="prefix"></slot>
                <template is="dom-if" if=[[_isPassword(type)]]>
                    <div slot="suffix" class="suffix">
						<div  class="toggle-suffix" hidden=[[!_showPasswordToggle(type,hidePasswordToggle)]]>
                        	<iron-icon id="toggle-password" on-tap="_toggleVisibility" icon=[[_computePasswordIcon(_showPassword)]]></iron-icon>
						</div>
                        <div  class="tooltip-sufix" hidden=[[!_showPasswordInfo(type,hidePasswordInfo)]]>
                            <iron-icon icon="info"></iron-icon>
                            <paper-tooltip position="left">
                                <div class="tooltip-content">
                                    <label><oe-i18n-msg msgid="Password complexity">Password complexity</oe-i18n-msg></label>
                                    <ul>
                                        <li><oe-i18n-msg msgid="1 small letter">1 small letter</oe-i18n-msg></li>
                                        <li><oe-i18n-msg msgid="1 capital letter">1 capital letter</oe-i18n-msg></li>
                                        <li><oe-i18n-msg msgid="1 number">1 number</oe-i18n-msg></li>
                                        <li><oe-i18n-msg msgid="1 special character @ # $ _">1 special character @ # $ _</oe-i18n-msg></li>
                                        <li><oe-i18n-msg msgid="8 to 12 letters long">8 to 12 letters long</oe-i18n-msg></li>
                                    </ul>
                                </div>
                            </paper-tooltip>
                        </div>
                    </div>
                </template>
            </oe-input>
        `;
	}

	static get properties() {
		return {
			value: {
				type: String,
				notify: true
			},
			type: {
				type: String,
				value: "text"
			},
			label: String,
			placeholder: String,
			required: {
				type: Boolean,
				value: false
			},
			skipDefaults: {
				type: Boolean,
				value: false
			},
			noErrors: Boolean,
			readonly: {
				type: Boolean,
				value: false
			},
			autoValidate: {
				type: Boolean,
				value: false
			},
			alwaysFloatLabel: {
				type: Boolean
			},
			noLabelFloat: {
				type: Boolean
			},
			pattern: String,
			fieldId: String,
			maxlength: Number,
			hidePasswordInfo: {
				type: Boolean,
				value: false
			},
			hidePasswordToggle: {
				type: Boolean,
				value: false
			},
			userErrorMessage: Object,
			minlength: Number
		};
	}

	connectedCallback() {
		super.connectedCallback();

		let delta = {
			_showPassword: false
		};

		Object.keys(DEFAULTPROPS).forEach(key => {
			if (typeof this[key] === "undefined") {
				delta[key] = DEFAULTPROPS[key];
			}
		});

		if (this.type !== "text") {
			if (this.type === "password") {
				delta.pattern = "(?=.*[\\d])(?=.*[a-z])(?=.*[A-Z])(?=.*[@_#$])[0-9a-zA-Z@_#$]{8,12}";
				delta.minlength = 8;
				delta.maxlength = 12;
			}
		} else {
			delta.pattern = (typeof this.pattern === "undefined") ? "[a-zA-Z0-9\\.\\s\\-]+" : this.pattern;
		}
		if (!this.skipDefaults) {
			this.setProperties(delta);
		}
	}

	_isPassword(type) {
		return type === "password";
	}

	_showPasswordToggle(type, hideToggle) {
		return type === "password" && !hideToggle;
	}

	_showPasswordInfo(type, hideInfo) {
		return type === "password" && !hideInfo;
	}

	_computePasswordIcon(showPassword) {
		return showPassword ? "visibility-off" : "visibility";
	}

	_computeFloatLabel(label, alwaysFloatLabel) {
		return alwaysFloatLabel || label;
	}

	_computedType(type, showPassword) {
		if (type === "password") {
			return showPassword ? "text" : "password";
		}
		return type;
	}

	_toggleVisibility() {
		this.set("_showPassword", !this._showPassword);
	}

	_trimOnBlur() {
		if (this.value && this.type !== "password") {
			this.set("value", this.value.trim());
		}
	}
}

window.customElements.define(TppInput.is, TppInput);
