import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import "@polymer/iron-flex-layout/iron-flex-layout.js";
import "@polymer/iron-flex-layout/iron-flex-layout-classes.js";
import { OECommonMixin } from "oe-mixins/oe-common-mixin.js";
import "../../styles/app-theme.js";

/**
 * ### footer-section
 * `footer-section`
 *
 *
 * @customElement
 * @polymer
 */
class FooterSection extends OECommonMixin(PolymerElement) {
	static get is() {
		return "footer-section";
	}

	static get template() {
		return html `
            <style include="iron-flex iron-flex-alignment iron-flex-factors">
                
                #container{
                    background: var(--primary-grey-color);
                    color:var(--primary-contrast-color);
                    padding: 0 0 5px 0;
                    font-size: 14px;
                }

                .main-content{
                    background: var(--primary-dark-color);
                    padding: 32px 0px;
                }

                .link-content{
                    @apply --canvas;
                }

                .footer-img{
                    margin-bottom: 40px;
                    height: 40px;
                    width: 70px;
                }

                .copyright-content{
                    padding: 20px 0px;
                    line-height: 22px;
                    text-align: center;
                }

                .copyright-content p{
                    @apply --canvas;
                }

                .main-content a{
                    display:block;
                    color : white;
                    margin-bottom:8px;
                }
        
                ul{
                    list-style:none;
                    margin:0px;
                    padding:0px;
                }

                a{
                    text-decoration:none;
                }

            </style>
            <footer id="container">
                <div class="main-content">
                    <div class="layout vertical link-content">
                        <img class="footer-img" src="img/footer-logo.png"/>
                        <div class="layout horizontal">
                            <ul class="flex-1">             
                                <li><a href="#"><oe-i18n-msg msgid="About Us">About Us</oe-i18n-msg></a></li>
                                <li><a href="#"><oe-i18n-msg msgid="Discover APIs">Discover APIs</oe-i18n-msg></a></li>
                                <li><a href="#"><oe-i18n-msg msgid="How It Works">How It Works</oe-i18n-msg></a></li>
                            </ul>
                            <ul class="flex-2">
                                <li><a href="#"><oe-i18n-msg msgid="Terms of service">Terms of service</oe-i18n-msg></a></li>
                                <li><a href="#"><oe-i18n-msg msgid="Developer Terms">Developer Terms</oe-i18n-msg></a></li>
                                <li><a href="#"><oe-i18n-msg msgid="Privacy Policy for Developers">Privacy Policy for Developers</oe-i18n-msg></a></li>
                            </ul>
                        </div>
                        ${this.copyrightTemplate}
                    </div>
                </div>
            </footer>
        `;
	}

	static get copyrightTemplate() {
		let cust = window.devportal.customize;
		return cust["footer-copyright"] ? cust["footer-copyright"] : html`<!-- <span>Footer copyright not loaded</span> -->`;
	}
}
window.customElements.define(FooterSection.is, FooterSection);
