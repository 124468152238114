import "@polymer/polymer/lib/elements/dom-module.js";
const styleElement = document.createElement("dom-module");
styleElement.innerHTML =
    `<template>
        <style>
            .rounded-input{
                --border-color:var(--tpp-input-default-border);
                --background-color:var(--tpp-input-default-background);
                --paper-input-container-underline: {
                    display: none;
                };
        
                --paper-input-container-underline-focus: {
                    display: none;
                };

                --oe-input-error:{
                    font-size:12px;
                    padding-left: 8px;
                }
            }

            .rounded-input[focused]{
                --border-color:var(--tpp-input-focused-border);
            }

            .rounded-input[invalid]{
                --border-color: var(--tpp-input-error-border);
            }

            .rounded-input[disabled],
            .rounded-input[readonly]{
                --border-color:var(--tpp-input-disabled-border);
                --background-color:var(--tpp-input-disabled-background);
            }

            .rounded-input::part(paper-input-container){
                padding: 0px;
                box-sizing: border-box;
                margin-bottom:16px;
            }

            .rounded-input::part(label){
                width: auto;
                line-height:30px;
                margin-left:8px;
                z-index: 1;
            }

            .rounded-input[no-label-float]::part(label){
                line-height:39px;
                opacity: 0.6;
            }
        
            .rounded-input::part(input) {
                border: 0px;
                box-sizing:border-box;
                color: var(--tpp-input-text);
            }
        
            .rounded-input::part(iron-input){
                height: 39px;
            }

            .rounded-input::part(iron-input), 
            .rounded-input::part(textarea){
                padding: 8px 8px 6px 8px;
                border: 1px solid var(--border-color);
                border-radius: 3px;
                box-sizing: border-box;
                background:var(--background-color); 
            }

            oe-textarea.rounded-input{
                --iron-autogrow-textarea:{
                    padding: 8px 8px 6px 8px;
                }
            }
            
        </style>
    </template>`;
styleElement.register("input-styles");