import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import "@polymer/iron-flex-layout/iron-flex-layout.js";
import "@polymer/iron-flex-layout/iron-flex-layout-classes.js";
import { PromiseMixin } from "../../../../mixins/promise-mixin.js";

import "../../../tpp-dialog.js";
import "../../../tpp-button.js";
import "../../../tpp-dropdown.js";

import "@polymer/iron-pages/iron-pages.js";
import "oe-ui-accordion/oe-ui-accordion.js";
import "oe-ui-accordion/oe-accordion-item.js";
import "oe-checkbox/oe-checkbox.js";
import "oe-checkbox/oe-checkbox-group.js";


class AddConsentDialog extends PromiseMixin(PolymerElement) {
	static get is() {
		return "add-consent-dialog";
	}

	static get properties() {
		return {
			isProduction: {
				type: Boolean,
				value: false
			}
		};
	}

	static get template() {
		return html`
        <style include="iron-flex iron-flex-alignment iron-flex-factors">
            .view-page{
                min-width:50vw;
                min-height:400px;
            }

            .dialog-header{
                flex:1;
            }

            .view-title{
                font-weight:400;
            }

            .access-title{
                padding: 8px 0px;
            }

            .access-description{
                padding: 16px 0px;
                box-shadow: 0px 0px 3px 1px rgb(0 0 0 / 20%);
            }

            .access-description ul {
                list-style:disc;
                margin-left: 32px;
                padding:0px;
                margin-top: 0px;
                margin-bottom: 0px;
            }

            .access-description li{
                padding:8px;
            }

            .accounts-list{
                display:flex;
                flex-direction:column;
            }

            .accounts-list oe-checkbox{
                margin:16px;
            }

            tpp-dropdown{
                flex:1;
                margin-right: 16px;
            }

            tpp-button + tpp-button{
                margin-left:16px;
            }
        </style>
        <tpp-dialog id="dialog" modal show-close-icon>
            <label slot="header" class="dialog-header">
				<oe-i18n-msg msgid="Authorize Accounts">Authorize Accounts</oe-i18n-msg>
			</label>
            <div slot="body">
                <div view="access" class="view-page">
                    <div class="layout horizontal center">
                        <tpp-dropdown multi required label="Accounts" value={{ui.selectedAccounts}} disp="maskedAccountNo" list=[[ui.accounts]]></tpp-dropdown>
                        <tpp-button message="Select All" mini secondary on-tap="_selectAllAccounts"></tpp-button>
                    </div>
                    <oe-ui-accordion>
                        <dom-repeat items={{ui.accessList}} as="access">
                            <template>
                                <oe-accordion-item>
                                    <div slot="title" class="access-title">
                                        <oe-checkbox label="[[access.label]]" value={{access.selected}}></oe-checkbox>
                                    </div>
                                    <div slot="content" class="access-description">
                                        <ul>
                                            <dom-repeat items={{access.list}}>
                                                <template>
                                                    <li>
														<oe-i18n-msg msgid=[[item]]></oe-i18n-msg>	
													</li>
                                                </template>
                                            </dom-repeat>
                                        </ul>
                                    </div>
                                </oe-accordion-item>
                            </template>
                        </dom-repeat>
                    </oe-ui-accordion>
                </div>
            </div>
            <tpp-button slot="footer" primary mini message="Submit" on-tap="_handlePrimaryAction"></tpp-button>
            <tpp-button slot="footer" mini message="Cancel" on-tap="_close"></tpp-button>
        </tpp-dialog>
        `;
	}

	_resetDialog() {
		this.set("ui", {
			accessList: [
				{
					key: "account_details_basic",
					selected: false,
					label: "Your Account's Basic Details",
					list: [
						"Your Accout Name, Number and currency",
						"Any other name you refer to this account"
					]
				},
				{
					key: "account_transactions",
					selected: false,
					label: "Your Account Transactions for last 12 months",
					list: [
						"Details of all your incoming transactions",
						"Details of all your outgoing transactions",
						"Amount & date of each transaction"
					]
				},
				{
					key: "account_details_advance",
					selected: false,
					label: "Your Account Features & Benefits",
					list: [
						"The type of account you have",
						"The fees, charges & interest you pay",
						"The benefits, services, rewards, & interest you account offers"
					]
				},
				{
					key: "account_balance",
					selected: false,
					label: "Your Account Balance",
					list: [
						"The type of account you have",
						"Balance of your accounts"
					]
				}
			],
			application: {},
			accounts: [],
			selectedAccounts: []
		});
	}

	async launch(application, closeCb) {
		this._resetDialog();
		this._closeCb = closeCb;
		this.set("ui.application", application);
		let [err, resp] = await this._makeAjaxPromise(`${this._appURLBase}/fetchAccounts`, "GET");

		if (err) {
			this.fire("oe-show-error", window.devportalError(err));
			return;
		}

		this.set("ui.accounts", resp.accounts);
		this.$.dialog.open();
	}

	_selectAllAccounts() {
		this.set("ui.selectedAccounts", this.ui.accounts);
	}

	get _appURLBase() {
		return `@restApiRoot/${this.isProduction ? "ProductionApplications" : "Applications"}/${this.ui.application.id}`;
	}

	async _handlePrimaryAction() {
		let selectedAccess = this.ui.accessList.filter(a => a.selected);
		let selectedAccounts = this.ui.selectedAccounts;

		if (selectedAccess.length === 0) {
			this.fire("oe-show-error", "Atleast one access must be provided to proceed.");
			return;
		}

		if (selectedAccounts.length === 0) {
			this.fire("oe-show-error", "Atleast one account must be provided.");
			return;
		}

		let payload = {
			permissions: selectedAccess.map(a => a.key),
			tokenizedAccounts: selectedAccounts.map(a => a.tokenizedAccountNo)
		};
		let [err, resp] = await this._makeAjaxPromise(`${this._appURLBase}/addConsent`, "POST", payload);
		if (err) {
			this.fire("oe-show-error", window.devportalError(err));
			return;
		}

		if (typeof this._closeCb === "function") {
			this._closeCb(resp.consent);
			this._close();
		}
	}

	_close() {
		this.$.dialog.close();
	}
}

window.customElements.define(AddConsentDialog.is, AddConsentDialog);
