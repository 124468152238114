let createAction = (type) => {
	return detail => {
		return {
			detail,
			type
		};
	};
};

export const UPDATE_USER_INFO = "UPDATE_USER_INFO";
export const UPDATE_ORGANIZATION_INFO = "UPDATE_ORGANIZATION_INFO";
export const UPDATE_INVITES_INFO = "UPDATE_INVITES_INFO";

export const updateUserInfo = createAction(UPDATE_USER_INFO);
export const updateOrgInfo = createAction(UPDATE_ORGANIZATION_INFO);
export const updateInvitesInfo = createAction(UPDATE_INVITES_INFO);
