import { html } from "@polymer/polymer/polymer-element.js";
import ProfileBaseView from "./profile-base-view.js";
import "@polymer/iron-icons/editor-icons.js";
import "oe-input/oe-json-input.js";
import { getYamlTestInfo } from "../../utils/api-utils.js";

class ManageApiBundle extends ProfileBaseView {
	static get is() {
		return "manage-api-bundle";
	}

	static get contentTemplate() {
		return html`
		    <oe-data-grid 
                    disable-selection disable-config-editor disable-full-screen-mode disable-add
                    id="grid" items={{apiBundleList}} min-column-width="100"
                    on-oe-data-table-row-action="__handleRowAction"
                    on-oe-data-table-action-refresh="_getApiBundles"
                    on-oe-data-table-action-add="__launchAddDialog"
                    on-oe-data-table-action-apihub="__launchApiHubDialog"
                    toolbar-actions=[[grid.tableActions]]
                    columns=[[_gridColumnConverter(grid.columns)]] row-actions=[[grid.rowActions]]></oe-data-grid>
            ${this.testDlgTemplate}
		`;
	}

	_gridColumnConverter(cols) {
		return cols.map(c => {
			return {
				label: window.OEUtils.camelCaseToLabel(c.key),
				...c
			};
		});
	}

	connectedCallback() {
		super.connectedCallback();
		this._getApiBundles();
		this.set("viewTitle", "API Bundles List");
		let gridConfig = {
			columns: [
				{
					key: "name"
				},
				{
					key: "type",
					disableFilter: true,
					disableSort: true
				},
				{
					key: "apiCount",
					label: "API Count",
					type: "number",
					disableFilter: true,
					disableSort: true
				},
				{
					key: "published",
					disableFilter: true,
					disableSort: true
				}
			],
			rowActions: [
				{ action: "unpublish", title: "Unpublish", icon: "icons:cloud-download", isHiddenFunction: r => !r.published },
				{ action: "publish", title: "Publish", icon: "icons:cloud-upload", isHiddenFunction: r => r.published },
				{ action: "view", title: "View", icon: "visibility" },
				{ action: "view-test", title: "View & Edit Test cases", icon: "build" },
				{ action: "edit", title: "Edit", icon: "create" },
				{ action: "delete", title: "Delete", icon: "delete", isHiddenFunction: r => r.published }
			],
			tableActions: [
				{ action: "add", title: "Add Bundle", icon: "add", isButton: true },
				{ action: "refresh", title: "Refresh list", icon: "refresh", isButton: true }
			]
		};
		if (!(this.devportalConfig && this.devportalConfig.DISABLE_PULL_FROM_APIHUB)) {
			gridConfig.tableActions.push({
				action: "apihub",
				title: "Pull from APIHub",
				icon: "cloud-download",
				isMenu: true
			});
		}
		this.set("grid", gridConfig);
	}
	// action-add
	__launchAddDialog() {
		this.fire("launch-add-api-bundle", {
			cb: () => {
				this.fire("oe-show-success", "API Bundle created successfully.");
				this._getApiBundles();
			}
		});
	}
	// action-apihub
	__launchApiHubDialog() {
		this.fire("oe-show-confirm", {
			message: "Select action on duplicate YAML records from APIHub",
			okLabel: "Overwrite",
			ok: () => {
				this._pullFromApiHub("overwrite");
			},
			cancelLabel: "Skip",
			cancel: () => {
				this._pullFromApiHub("skip");
			}
		});
	}

	_pullFromApiHub(action) {
		let payload = { action };
		this.makeAjaxCall("@restApiRoot/ApiBundles/migrate", "POST", payload, null, null, null, (err, resp) => {
			if (err) {
				this.fire("oe-show-error", window.devportalError(err));
				return;
			}
			this._getApiBundles();
		});
	}
	// action-refresh
	_getApiBundles() {
		let ajaxFilter = {
			filter: { "fields": { "yamlContent": false } }
		};
		this.makeAjaxCall("@restApiRoot/ApiBundles", "GET", null, null, ajaxFilter, null, (err, resp) => {
			if (err) {
				this.fire("oe-show-error", window.devportalError(err));
				return;
			}
			this.set("apiBundleList", resp);
		});
	}

	_deleteApiBundle(id) {
		this.makeAjaxCall("@restApiRoot/ApiBundles/" + id + "/deleteApiBundle", "POST", null, null, null, null, (err, resp) => {
			if (err) {
				this.fire("oe-show-error", window.devportalError(err));
				return;
			}
			this.fire("oe-show-success", "API Bundle deleted successfully.");
			this._getApiBundles();
		});
	}

	_publishYaml(id) {
		this.makeAjaxCall("@restApiRoot/ApiBundles/" + id + "/publish", "POST", null, null, null, null, (err, resp) => {
			if (err) {
				this.fire("oe-show-error", window.devportalError(err));
				return;
			}
			this.fire("oe-show-success", "API Bundle published successfully.");
			this._getApiBundles();
		});
	}

	_unpublishYaml(id) {
		this.makeAjaxCall("@restApiRoot/ApiBundles/" + id + "/unpublish", "POST", null, null, null, null, (err, resp) => {
			if (err) {
				this.fire("oe-show-error", window.devportalError(err));
				return;
			}
			this.fire("oe-show-success", "Unpublish in progress, please check status after sometime.");
			this._getApiBundles();
		});
	}
	// row-action
	__handleRowAction(event) {
		let {
			action,
			row
		} = event.detail;

		switch (action.action) {
		case "publish":
			this._publishYaml(row.id);
			break;
		case "unpublish":
			this._unpublishYaml(row.id);
			break;
		case "edit":
			this.fire("launch-add-api-bundle", {
				id: row.id,
				mode: "edit",
				cb: () => {
					this.fire("oe-show-success", "API Bundle updated successfully.");
					this._getApiBundles();
				}
			});
			break;
		case "view":
			this.fire("launch-add-api-bundle", {
				id: row.id,
				mode: "view"
			});
			break;
		case "delete":
			this.fire("oe-show-confirm", {
				message: "Confirm deleting the API Bundle ?",
				ok: () => {
					this._deleteApiBundle(row.id);
				}
			});
			break;
		case "view-test":
			this._viewTest(row.id);
			break;
		default: break;
		}
	}

	static get testDlgTemplate() {
		return html`
            <style include="input-styles">
                .dlg-body{
                    gap:16px;
                    min-width:60vw;
                    min-height:70vh;
                }

                oe-json-input{
                    --iron-autogrow-textarea : {
                        max-height:60vh;
                        overflow:auto;
                        padding: 16px;
                        box-sizing :border-box;
                    }
                }
            </style>
            <tpp-dialog id="dialog" entry-animation="none" modal>
                <label class="group-name" slot="header">Edit Test cases</label>
                <div slot="body" class="layout horizontal center dlg-body">
                    <oe-json-input label="Test Cases" always-float-label value={{testRecord.testCases}} required class="rounded-input flex" rows="16"></oe-json-input>
                    <oe-file-input value={{testRecord.testCases}} value-type="text" accept="application/json">
                        <template custom-upload>
                            <tpp-button message="Upload" secondary mini on-tap="_selectFile"></tpp-button>
                        </template>
                    </oe-file-input>
                </div>
                <tpp-button slot="footer" mini primary message="Update" on-tap="_submitTestCases"></tpp-button>
                <tpp-button slot="footer" mini message="Cancel" on-tap="_closeTest"></tpp-button>
            </tpp-dialog>
        `;
	}

	async _viewTest(apiBundleId) {
		let [err, matched] = await this._makeAjaxPromise("@restApiRoot/APIBundleTests", "get", null, null, {filter: {where: { apiBundleId }}});
		if (err) {
			return this.fire("oe-show-error", this.resolveError(err));
		}

		let [err2, resp] = await this._makeAjaxPromise(`@restApiRoot/APIBundles/${apiBundleId}`, "get", null, null, {filter: {fields: { yamlContent: true}}});
		if (err2) {
			return this.fire("oe-show-error", this.resolveError(err2));
		}
		let testRecord;

		if (matched.length === 1) {
			testRecord = {
				...matched[0]
			};
		} else {
			let {
				testCases
			} = getYamlTestInfo(resp.yamlContent);

			testRecord = {
				testCases,
				apiBundleId
			};
		}
		this.set("testFile", null);
		this.set("testRecord", testRecord);
		this.$.dialog.open();
	}

	async _submitTestCases() {
		if (this.testRecord.testCases) {
			let url = "@restApiRoot/ApiBundleTests";
			let method = this.testRecord.id ? "PUT" : "POST";
			let [err] = await this._makeAjaxPromise(url, method, {...this.testRecord});
			if (err) {
				return this.fire("oe-show-error", this.resolveError(err));
			}
			this.fire("oe-show-success", "Testcases updated successfully for APIBundle");
			this.set("testRecord", {
				testCases: {}
			});
			this._closeTest();
		}
	}

	_closeTest() {
		this.$.dialog.close();
	}
}

window.customElements.define(ManageApiBundle.is, ManageApiBundle);
