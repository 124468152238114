import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import "@polymer/iron-flex-layout/iron-flex-layout.js";
import "@polymer/iron-flex-layout/iron-flex-layout-classes.js";
import "../../styles/input-styles.js";
import { PromiseMixin } from "../mixins/promise-mixin.js";

/**
 * ### view-organization-details
 * `view-organization-details`
 *
 *
 * @customElement
 * @polymer
 */
class ViewOrganizationDetails extends PromiseMixin(PolymerElement) {
	static get is() {
		return "view-organization-details";
	}

	static get template() {
		return html`
            <style include="iron-flex iron-flex-alignment iron-flex-factors input-styles">

                h2{
                    font-size : 20px;
                    margin: 16px 0px;
                    color: var(--primary-dark-color);
                }

                .flex-form{
                    padding: 16px 2px;
                }

                .flex-form .full-width{
                    width:100%;
                    display : block;
                }

                .flex-form > *:not(.full-width){
                    width : 45%;
                }

                .service-list{
                    margin-bottom:16px;
                }

                .service-list > paper-checkbox{
                    width : calc(25% - 16px);
                }

                .uploaded-file-item{
                    font-size: 13px;
                    border: 1px solid;
                    border-radius: 4px;
                    margin: 8px 0px;
                    padding: 2px 16px;
                    display: flex;
                    align-items: center;
                }
                .uploaded-file-item > label{
                    flex:1;
                }

                .uploaded-file-item .download-icon{
                    cursor:pointer;
                }

                .margin-b-16{
                    display:block;
                    margin-bottom:16px;
                }

                [hidden]{
                    display:none !important;
                }
            </style>
            <div class="flex-form layout horizontal justified wrap flex">
                <tpp-input readonly field-id="name" required label="Company Legal Name" value=[[companyInfo.name]]></tpp-input>
                <tpp-input readonly field-id="url" type="url" required label="Company Website URL" value=[[companyInfo.url]]></tpp-input>
                <oe-textarea rows="4" class="full-width rounded-input" always-float-label readonly label="Company Address" value=[[companyInfo.address.line]]></oe-textarea>
                <tpp-input readonly label="City (Optional)" value=[[companyInfo.address.city]]></tpp-input>
                <tpp-input readonly label="State (Optional)" value=[[companyInfo.address.state]]></tpp-input>
                <tpp-input readonly label="Postal code (Optional)" value=[[companyInfo.address.postalCode]]></tpp-input>
                <tpp-input readonly label="Market" value=[[companyInfo.market]]></tpp-input>
                <tpp-input readonly label="CIF ID" field-id="cifId" value=[[companyInfo.cifId]]></tpp-input>
                <tpp-input readonly label="Your Full Name" value=[[companyInfo.ownerName]]></tpp-input>
                <tpp-input readonly label="Your role (optional)" value=[[companyInfo.ownerRole]]></tpp-input>
                <tpp-input readonly label="Your corporate email Id" type="email" value=[[companyInfo.email]]></tpp-input>
                <tpp-input readonly label="Phone No" value=[[companyInfo.phone]]></tpp-input>
                <oe-textarea rows="4" class="full-width rounded-input" always-float-label readonly field-id="businessCase" required label="Describe your business case"  value=[[companyInfo.businessCase]]></oe-textarea>
                <div class="full-width layout horizontal service-list">
                    <label class="full-width margin-b-16">
                        <oe-i18n-msg msgid="Type of services organization is authorised for: "></oe-i18n-msg>    
                    </label>
                    <paper-checkbox disabled checked=[[companyInfo.serviceType.pisp]]>
                        <oe-i18n-msg msgid="PISP"></oe-i18n-msg>    
                    </paper-checkbox>
                    <paper-checkbox disabled checked=[[companyInfo.serviceType.neobanks]]>
                        <oe-i18n-msg msgid="Neobanks"></oe-i18n-msg>    
                    </paper-checkbox>
                    <paper-checkbox disabled checked=[[companyInfo.serviceType.aggregator]]>
                        <oe-i18n-msg msgid="Aggregrator"></oe-i18n-msg>    
                    </paper-checkbox>
                    <paper-checkbox disabled checked=[[companyInfo.serviceType.erp]]>
                        <oe-i18n-msg msgid="ERP OEMs"</oe-i18n-msg>    
                    </paper-checkbox>
                </div>
                <div class="margin-b-16" hidden=[[!companyInfo.documents.length]]>
                    <label class="margin-b-16 primary-text">
                        <oe-i18n-msg msgid="Board Resolution documents"></oe-i18n-msg> ([[companyInfo.documents.length]])
                    </label>
                    <dom-repeat items=[[companyInfo.documents]]>
                        <template>
                            <div class="uploaded-file-item">
                                <label>[[item]]</label>
                                <iron-icon icon="file-download" class="download-icon" on-tap="_downloadDocument"></iron-icon>
                            </div>
                        </template>
                    </dom-repeat>
                </div>
                <oe-textarea rows="4" class="full-width rounded-input"  always-float-label readonly label="CA Certificate"  value=[[companyInfo.cacert]]></oe-textarea>
            </div>
            <oe-data-grid disable-selection disable-config-editor disable-full-screen-mode disable-add
                label="Invites sent by [[companyInfo.email]]" items=[[companyInfo.invites]] columns=[[inviteColumns]] ></oe-data-grid>
        `;
	}

	constructor() {
		super();
		this.set("inviteColumns", [
            {
                key: "email",
                label: "Recepient Email"
            },
            {
                key: "roleId",
                label: "Role",
                formatter: val => val === "developer" ? "API Developer" : "Viewer"
            },
            {
                key: "userRegistered",
                label: "Registered",
                type: "boolean",
                formatter: val => val ? "True" : "False"
            },
            {
                key: "status",
                label: "Status"
            }
        ]);
	}

	async showOrganization(orgId) {
		let filter = {
			include: ["certificate", "invites"]
		};
        let [err,resp] = await this._makeAjaxPromise(`@restApiRoot/Organizations/${orgId}`, "GET", null, null, { filter });
		if (err) {
            this.fire("oe-show-error", window.devportalError(err));
            return;
        }
        resp.documents = resp.documents || [];
        this.set("companyInfo", resp);
	}

	_downloadDocument(event) {
		let fileName = event.model.item;
		let url = window.OEUtils.geturl(`@restApiRoot/Documents/${this.companyInfo.id}/download/${fileName}`);
		let a = document.createElement("a");
		a.setAttribute("href", url);
		a.setAttribute("download", fileName);
		a.setAttribute("target", "blank");
		a.click();
	}
}
window.customElements.define(ViewOrganizationDetails.is, ViewOrganizationDetails);
