import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import "@polymer/iron-flex-layout/iron-flex-layout.js";
import "@polymer/iron-flex-layout/iron-flex-layout-classes.js";
import { PromiseMixin } from "../../../mixins/promise-mixin.js";

import "../../../../styles/input-styles.js";
import "../../tpp-dialog.js";
import "../../tpp-button.js";


class PromoteApplication extends PromiseMixin(PolymerElement) {
	static get is() {
		return "promote-application";
	}

	static get template() {
		return html`
        <style include="input-styles iron-flex iron-flex-alignment iron-flex-factors">
            .view-page{
                min-width:50vw;
                min-height:400px;
            }

            .dialog-header{
                flex:1;
            }

            .grid-2{
                display:grid;
                gap: 0px 24px;
                padding:0 4px;
                grid-template-columns: repeat(2,1fr);
                align-items: center;
            }

            tpp-button + tpp-button{
                margin-left:16px;
            }
        </style>
        <tpp-dialog id="dialog" modal show-close-icon>
            <label slot="header" class="dialog-header">
				<oe-i18n-msg msgid="Push to production">Push to production</oe-i18n-msg>
			</label>
            <div slot="body">
                <tpp-input required readonly=[[readOnlyMode]] pattern=[[absoluteRegex]] label="Callback URL" maxlength="100" value={{application.callbackUrl}}></tpp-input>
                <h2><oe-i18n-msg msgid="Webhooks">Webhooks</oe-i18n-msg></h2>
                <div class="grid-2">
                    <tpp-input readonly=[[readOnlyMode]] pattern=[[absoluteRegex]] label="Callback Endpoint" required value={{application.credentials.event.callbackUrl}}></tpp-input>
                    <tpp-input readonly=[[readOnlyMode]] label="Authentication Endpoint" value={{application.credentials.event.tokenURL}}></tpp-input>
                    
                    <oe-radio-group disabled$=[[readOnlyMode]] value="{{application.credentials.event.method}}" label="Authentication Type" 
                    class="layout-radio-button" listdata="[[authTypeList]]" valueproperty="c" displayproperty="d"></oe-radio-group>            
                    <paper-checkbox disabled=[[readOnlyMode]] checked={{application.credentials.event.allowCommonCallback}}>
						<oe-i18n-msg msgid="Common Callback Path">Common Callback Path</oe-i18n-msg>
					</paper-checkbox>
                    
                </div>
                
                <dom-if if=[[__isEqual(application.credentials.event.method,'apikey')]]>
                    <template>
                        <div class="grid-2">
                            <tpp-input readonly=[[readOnlyMode]] label="API Key" value={{application.credentials.event.apiKey}}></tpp-input>
                        </div>
                    </template>
                </dom-if>

                <dom-if if=[[__isEqual(application.credentials.event.method,'client')]]>
                    <template>
                        <div class="grid-2">
                            <tpp-input readonly=[[readOnlyMode]] label="Client ID" value={{application.credentials.event.clientId}}></tpp-input>
                            <tpp-input readonly=[[readOnlyMode]] label="Client secret" value={{application.credentials.event.clientSecret}}></tpp-input>
                        </div>
                    </template>
                </dom-if>

                <oe-textarea always-float-label readonly=[[readOnlyMode]] label="Certificate" class="rounded-input" value={{application.certificate}}></oe-textarea>
                <oe-data-grid id="apigrid" label="APIs" multi-selection disable-config-editor disable-full-screen-mode disable-add disable-edit disable-delete 
                columns=[[grid.columns]] items=[[grid.items]] selected-items={{application.apis}}></oe-data-grid>
            </div>
            <tpp-button slot="footer" primary mini message="Submit" on-tap="_handlePrimaryAction"></tpp-button>
            <tpp-button slot="footer" mini message="Cancel" on-tap="_close"></tpp-button>
        </tpp-dialog>
        `;
	}

	_resetDialog() {
		let columns = [
			{
				key: "type",
				label: "Type"
			},
			{
				key: "path",
				label: "Path"
			},
			{
				key: "method",
				label: "Method"
			},
			{
				key: "operationId",
				label: "Operation Id"
			}
		];

		columns.forEach(col => {
			col.disableSort = true;
			col.disableFilter = true;
		});

		this.set("grid", {
			columns: columns,
			items: []
		});
		this.set("absoluteRegex", "^http(s?)\:\/\/[0-9a-zA-Z]*(.*)");
		this.set("authTypeList", [
			{
				c: "apikey",
				d: "API Key"
			},
			{
				c: "client",
				d: "Client credentials"
			}
		]);
	}

	launch({ application, apis, baseAppId, readonly }, closeCb) {
		this._resetDialog();
		this._closeCb = closeCb;
		this.baseAppId = baseAppId;
		let clone = JSON.parse(JSON.stringify(application));
		this.set("grid.items", apis);
		this.set("application", clone);
		this.set("readonly", Boolean(readonly));
		this.set("readOnlyMode", Boolean(application.id));
		let publishedApis = application.apis || [];
		let selectedItems = apis.filter(a => publishedApis.find(p => p.id === a.id));
		// let selectedItems = application.apis.map(appApi => apis.find(api => api.apiId === appApi.apiId));
		this.$.apigrid.__selectItems(selectedItems);
		this.$.dialog.open();
	}

	async _handlePrimaryAction() {
		let payload = this.application;

		let {
			callbackUrl,
			credentials,
			apis
		} = payload;

		if (!callbackUrl) {
			this.fire("oe-show-error", "Callback URL is mandatory.");
			return;
		}

		if (!credentials.event.callbackUrl) {
			this.fire("oe-show-error", "Callback Endpoint is mandatory.");
			return;
		}

		if (!Array.isArray(apis) || apis.length === 0) {
			this.fire("oe-show-error", "Minimum 1 API is needed to push to production.");
			return;
		}

		// let method = payload.id ? "PUT" : "POST";
		let [err, resp] = await this._makeAjaxPromise(`@restApiRoot/Applications/${this.baseAppId}/publishToProduction`, "POST", payload);

		if (err) {
			this.fire("oe-show-error", window.devportalError(err));
			return;
		}

		this.fire("oe-show-success", "Application submitted for production.");
		if (typeof this._closeCb === "function") {
			this._closeCb(resp);
		}
		this._close();
	}

	_close() {
		this.$.dialog.close();
	}

	/** UTILITY FUNCTIONS */

	__isEqual(a, b) {
		return a === b;
	}
}

window.customElements.define(PromoteApplication.is, PromoteApplication);
