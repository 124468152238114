import { html } from "@polymer/polymer/polymer-element.js";
import ProfileBaseView from "./profile-base-view.js";
import "oe-ui-forms/validators/oe-eq-validator.js";
import "oe-ui-forms/validators/oe-ne-validator.js";

class ChangePasswordView extends ProfileBaseView {
	static get is() {
		return "change-password-view";
	}

	static get stylesTemplate() {
		return html`
            ${super.stylesTemplate}
            <style>
                .tooltip-sufix{
                    position:relative;
                    padding-right:4px;
                }

                .tooltip-content{
                    width: 170px;
                    line-height: 14px;
                    letter-spacing: 0.7px;
                }

                .tooltip-content ul {
                    list-style: disc;
                    margin-inline-start: 16px;
                }

                #validator{
                    margin-bottom:16px;
                    display:block;
                }

                .button-container{
                    margin-top:16px;
                }

            </style>
        `;
	}

	static get contentTemplate() {
		return html`
            <oe-block-validator id="validator">
                <tpp-input type="password" hide-password-info field-id="old" required label="Current Password" value={{password.old}}></tpp-input>
                <tpp-input type="password" hide-password-toggle field-id="new" required label="New Password" value={{password.new}}></tpp-input>
                <tpp-input type="password" hide-password-info field-id="confirm" required label="Confirm New Password" value={{password.confirm}}></tpp-input>
                <oe-eq-validator error="New Password should match confirm Password" fields='["new","confirm"]' model={{password}}></oe-eq-validator>
                <oe-ne-validator error="New Password should not be same as old Password" fields='["new","old"]' model={{password}}></oe-ne-validator>
                <div class="layout horizontal center justified button-container">
                    <tpp-button message="Change Password" primary mini on-tap="_validateChangePassword"></tpp-button>
                    <tpp-button message="Cancel" secondary mini on-tap="_cancel"></tpp-button>
                </div>
            </oe-block-validator>
        `;
	}

	constructor() {
		super();
		this.set("passwordPattern", "(?=.*[\\d])(?=.*[a-z])(?=.*[A-Z])(?=.*[@_#$])[0-9a-zA-Z@_#$]{6,10}");
		this.set("password", {});
		this.set("viewTitle", "Change Password");
	}

	_validateChangePassword() {
		this.$.validator.validateForm().then(result => {
			if (!result.valid) {
				return;
			}
			this._changePassword();
		});
	}

	_changePassword() {
		let payload = {
			oldPassword: this.password.old,
			newPassword: this.password.new
		};
		this.makeAjaxCall("@restApiRoot/AppUsers/change-password", "POST", payload, null, null, null, (err, resp)=>{
			if (err) {
				this.fire("oe-show-error",  window.devportalError(err));
				return;
			}
			this.set("password", {});
			this.fire("oe-show-success", "Password changed successfully.");
		});
	}

	_cancel() {
		this.set("password", {});
	}
}

window.customElements.define(ChangePasswordView.is, ChangePasswordView);
