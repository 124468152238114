import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import "@polymer/iron-flex-layout/iron-flex-layout.js";
import "@polymer/iron-flex-layout/iron-flex-layout-classes.js";
import { PromiseMixin } from "../../../mixins/promise-mixin.js";
import "../../tpp-button.js";
import "../../tpp-input.js";
import "../../../../styles/input-styles.js";

/**
 * ### manage-published-app
 * `manage-published-app`
 *
 *
 * @customElement
 * @polymer
 */
class ManagePublishedApp extends PromiseMixin(PolymerElement) {
	static get is() {
		return "manage-published-app";
	}

	static get template() {
		return html`
            <style include="input-styles ">

                .application-heading,
                .footer-view{
                    display : flex;
                    margin-bottom: 16px;
                    justify-content: space-between;
                    align-items: center;
                }

                .footer-view{
                    flex-direction:row-reverse;
                    margin-top: 32px;
                    padding-top: 16px;
                    border-top: 1px solid var(--divider-color);
                }

                .app-name{
                    flex:1;
                    margin: 0px;
                }

                .primary-text{
                    color: var(--primary-dark-color);
                }

                .non-editable,
                .editable{
                    margin-bottom:16px;
                }

                .grid-2{
                    display:grid;
                    gap: 0px 24px;
                    padding:0 4px;
                    grid-template-columns: repeat(2,1fr);
                    align-items: center;
                }

                [hidden]{
                    display:none !important;
                }
            </style>
            <div>
                <div class="application-heading">
                    <h2 class="app-name flex primary-text">
						<oe-i18n-msg msgid="Production App details">Production App details</oe-i18n-msg>
					</h2>
                    <tpp-button mini secondary message="Cancel" on-tap="_goBackToList"></tpp-button>
                </div>
                ${this.nonEditable}
                ${this.editable}
                <div class="footer-view">
                    <tpp-button mini secondary message="Cancel" on-tap="_goBackToList"></tpp-button>
                    <tpp-button mini primary hidden=[[readOnlyMode]] message="Update" on-tap="patchPublishedApp"></tpp-button>
                </div>
            </div>
        `;
	}

	static get properties() {
		return {
			application: {
				type: Object
			},
			readOnlyMode: {
				type: Boolean,
				value: false
			}
		};
	}

	constructor() {
		super();
		let columns = [
			{
				key: "type",
				label: "Type"
			},
			{
				key: "path",
				label: "Path"
			},
			{
				key: "method",
				label: "Method"
			},
			{
				key: "operationId",
				label: "Operation Id"
			}
		];

		columns.forEach(col => {
			col.disableSort = true;
			col.disableFilter = true;
		});

		this.set("ui", {
			urlRegex: "^http(s?)\:\/\/[0-9a-zA-Z]*(.*)",
			loginMethods: [
				{ d: "Client Credentials", v: "client_credentials" },
				{ d: "Authorization Code", v: "authorization_code" }
			],
			authTypeList: [
				{
					c: "apikey",
					d: "API Key"
				},
				{
					c: "client",
					d: "Client credentials"
				}
			],
			apiColumns: columns
		});
	}

	static get nonEditable() {
		return html`
        <div class="non-editable">
            <tpp-input readonly label="Name" value=[[application.name]]></tpp-input>
            <oe-data-grid label="Subscribed API" disable-selection disable-config-editor disable-full-screen-mode disable-add disable-edit disable-delete 
                columns=[[ui.apiColumns]] items=[[application.apis]]></oe-data-grid>
        </div>
        `;
	}

	static get editable() {
		return html`
        <div class="editable">
            <tpp-input readonly=[[readOnlyMode]] required pattern=[[ui.urlRegex]] label="Callback URL" maxlength="100" value={{application.callbackUrl}}></tpp-input>
            <h2 class="primary-text">
				<oe-i18n-msg msgid="Webhooks"></oe-i18-msg>
			</h2>
            <div class="grid-2">
                <tpp-input readonly=[[readOnlyMode]] required auto-validate pattern=[[ui.urlRegex]] label="Callback Endpoint" value={{application.credentials.event.callbackUrl}}></tpp-input>
                <tpp-input readonly=[[readOnlyMode]] label="Authentication Endpoint" value={{application.credentials.event.tokenURL}}></tpp-input>
                
                <oe-radio-group disabled$=[[readOnlyMode]] value="{{application.credentials.event.method}}" label="Authentication Type" 
                class="layout-radio-button" listdata="[[ui.authTypeList]]" valueproperty="c" displayproperty="d"></oe-radio-group>            
                <paper-checkbox disabled=[[readOnlyMode]] checked={{application.credentials.event.allowCommonCallback}}>
					<oe-i18n-msg msgid="Common Callback Path">Common Callback Path</oe-i18n-msg>
				</paper-checkbox>
                
            </div>
            
            <dom-if if=[[__isEqual(application.credentials.event.method,'apikey')]]>
                <template>
                    <div class="grid-2">
                        <tpp-input readonly=[[readOnlyMode]] label="API Key" value={{application.credentials.event.apiKey}}></tpp-input>
                    </div>
                </template>
            </dom-if>

            <dom-if if=[[__isEqual(application.credentials.event.method,'client')]]>
                <template>
                    <div class="grid-2">
                        <tpp-input readonly=[[readOnlyMode]] label="Client ID" value={{application.credentials.event.clientId}}></tpp-input>
                        <tpp-input readonly=[[readOnlyMode]] label="Client secret" value={{application.credentials.event.clientSecret}}></tpp-input>
                    </div>
                </template>
            </dom-if>

            <oe-textarea readonly$=[[readOnlyMode]] always-float-label label="Certificate" class="rounded-input" value={{application.promoteCertificate}}></oe-textarea>
            <manage-app-credentials is-production class="full-width" application={{application}} login-creds=[[loginCredentials]]></manage-app-credentials>
        </div>
        `;
	}

	__getLoginCreds(credentials) {
		if (!credentials || !credentials.login) {
			return [];
		}
		return credentials.login.map(r => {
			return {
				...r,
				showSecret: false
			};
		});
	}

	_goBackToList() {
		this.fire("show-list-view");
		this.set("application", null);
	}

	async patchPublishedApp() {
		let payload = this.application;

		let {
			callbackUrl,
			credentials
		} = payload;

		if (!callbackUrl) {
			this.fire("oe-show-error", "Callback URL is mandatory.");
			return;
		}

		if (!credentials.event.callbackUrl) {
			this.fire("oe-show-error", "Callback Endpoint is mandatory.");
			return;
		}

		let [err] = await this._makeAjaxPromise(`@restApiRoot/Applications/${this.baseAppId}/publishToProduction`, "PATCH", payload);
		if (err) {
			this.fire("oe-show-error", window.devportalError(err));
		} else {
			this.fire("oe-show-success", "Production Application patched.");
		}
	}
}

window.customElements.define(ManagePublishedApp.is, ManagePublishedApp);
