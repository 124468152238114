import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import "@polymer/iron-flex-layout/iron-flex-layout.js";
import "@polymer/iron-flex-layout/iron-flex-layout-classes.js";
import { OECommonMixin } from "oe-mixins/oe-common-mixin.js";
import "@polymer/iron-image/iron-image.js";
import "./rating-view.js";

const LOGOLIST = [
	"default",
	"collaterals",
	"consent-APIs",
	"corporate-current-accounts",
	"corporate-deposit-accounts",
	"corporate-overdraft-accounts",
	"credit-limit",
	"crm-customer-data-hub",
	"current-accounts",
	"data-privacy",
	"deposit-accounts",
	"liquidity-management",
	"loan-accounts-secured",
	"loan-accounts-unsecured",
	"loan",
	"open-banking-APIs",
	"overdraft-accounts",
	"payments",
	"savings-accounts",
	"trade-finance",
	"transactions",
	"treasury",
	"virtual-accounts",
	"wealth-management"
];
/**
 * ### api-group-card
 * `api-group-card`
 *
 *
 * @customElement
 * @polymer
 */

class ApiGroupCard extends OECommonMixin(PolymerElement) {
	static get is() {
		return "api-group-card";
	}

	static get template() {
		return html`
        <style  include="iron-flex iron-flex-alignment">
            .group-container{
                width:100%;
                height : 220px;
                float:left;
                padding: 10px 5px;
                background: var(--primary-background-color);
                text-align: center;
                box-shadow: 0px 0 4px 4px rgb(1 41 112 / 8%);
                border-radius: 4px;
                position: relative;
                overflow: hidden;
                box-sizing: border-box;
                transition: 0.3s;
            }

            .group-container:hover{
                transform:scale(1.05);
                box-shadow: 0px 0 15px 6px rgb(1 41 112 / 10%);
            }

            .group-name{
                font-weight: 700;
                font-size: 18px;
                margin-bottom: 5px;
                overflow: hidden;
                text-overflow: ellipsis;
                text-transform:capitalize;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
            }
            .img-container{
                position:relative;
                --iron-image-width:75%;
            }

            .img-container > iron-image{
                width:95px;
                height:95px;
                align-self: center;
                object-fit: contain;
            }

            .group-category{
                     margin-top: 0px;
                     margin-bottom:0px;
               }

            .overview-label{
                padding-right: 5px;
                align-self: flex-end;
                cursor:pointer;
            }

            .overview-label:hover{
                text-decoration:underline;
            }
            .doc-review > label{
                margin-right:auto;
            }
        </style>
        <div class="group-container layout vertical secondary-card" on-tap="_viewGroup">
            <label class="group-name" title$="[[group.name]]">[[group.name]]</label>
            <div hidden=[[hideApiCount]]>
                <p class="group-category" hidden=[[!group.hasApi]]> <label hidden=[[!group.api.category]]>[[group.api.category]]  / </label>[[group.api.apiCount]] <oe-i18n-msg msgid="APIs">APIs</oe-i18n-msg></p>
                <p class="group-category" hidden=[[!group.hasEvent]]> <label hidden=[[!group.event.category]]>[[group.event.category]] / </label>[[group.event.apiCount]] <oe-i18n-msg msgid="Webhooks">Webhooks</oe-i18n-msg></p>
            </div>
            <div class="layout vertical flex center-center img-container">
            <iron-image sizing="contain" src$="[[__getImg(group.img)]]"></iron-image>
            </div>
            <div class="doc-review layout horizontal justified">
                <rating-view score=[[group.rating]]></rating-view>
                <div class="overview-label" on-tap="_launchOverview">
                    <oe-i18n-msg msgid="Overview">Overview</oe-i18n-msg>
                </div>
            </div>
        </div>
        `;
	}

	static get properties() {
		return {
			group: {
				type: Object
			},
			allowEdit: {
				type: Boolean,
				value: false
			},
            hideApiCount:{
                type:Boolean,
                value : Boolean(window.devportal.HIDE_API_CARD_COUNT)
            }
		};
	}

	_viewGroup() {
		this.fire("view-group-details", this.group);
	}

	_launchOverview(event) {
		event.stopPropagation();
		event.stopImmediatePropagation();
		this.fire("show-group-overview", this.group);
	}

	_editImageClicked(event) {
		event.stopPropagation();
		event.stopImmediatePropagation();
		this.fire("edit-group-image", this.group);
	}
	__getImg(selected) {
		if (LOGOLIST.indexOf(selected) === -1) {
			selected = "default";
		}
		return `${this.rootPath}./img/proxy-logo/${selected}.png`;
	}
}
window.customElements.define(ApiGroupCard.is, ApiGroupCard);
