import { html } from "@polymer/polymer/polymer-element.js";
import ProfileBaseView from "../profile-base-view.js";
import "@polymer/iron-pages/iron-pages.js";
import "@polymer/iron-icon/iron-icon.js";
import "oe-radio-group/oe-radio-group.js";
import "../../tpp-dialog.js";
import "../../tpp-button.js";
import "./manage-application.js";
import "./manage-published-app.js";
import "@polymer/paper-menu-button/paper-menu-button.js";
import "@polymer/paper-listbox/paper-listbox.js";
import "@polymer/paper-icon-button/paper-icon-button.js";
import "@polymer/paper-item/paper-item.js";

class ManageAppView extends ProfileBaseView {
	static get is() {
		return "manage-app-view";
	}

	static get stylesTemplate() {
		return html`
			${super.stylesTemplate}
			<style>		
				.app-list{
					display: grid;
					grid-template-columns: repeat(3,1fr);
					grid-auto-rows: 220px;
					gap: 20px;
					padding: 0 8px 16px 8px;
					margin-top:16px;
				}
		
				.application-item{
					background: var(--primary-background-color);
					text-align: center;
					box-shadow: 0px 0 10px 4px rgb(0 0 0/ 8%);
					border-radius: 4px;
					display: flex;
					justify-content: center;
					align-items: center;
					cursor:pointer;
					flex-direction:column;
					min-width: 259px;
					position:relative;
				}
		
				.app-name{
					margin:16px 0px;
					display:block;
					font-size:20px;
				}
		
				.add-application label{
					margin:16px 0px;
					display:block;
				}
		
				.add-application iron-icon{
					--iron-icon-width:80px;
					--iron-icon-height:80px;
					transition:color 300ms ease;
				}
		
				.add-application:hover iron-icon{
					color : var(--primary-dark-color);
				}
		
				.body-container{
					margin:16px 0px;
				}
		
				.add-table{
					width:100%;
				}
		
				.label-cell{
					width:30%;
					vertical-align:top;
					padding-top:20px;
				}
		
				.add-table oe-radio-group::part(radio-group){
					margin-top:8px;
					display:flex;
					flex-direction: column;
				}
		
				.add-table oe-radio-group::part(radio-button){
					margin-bottom: 8px;
				}
		
				oe-data-grid{
					padding: 2px;
					margin-bottom:16px;
					box-sizing: border-box;
				}
		
				.required-cell:after{
					content:"*";
					color:red;
				}
		
				.app-more-menu{
					padding: 0px;
					position: absolute;
					top: 0px;
					right: 0px;
				}
				
				.app-more-menu paper-item{
					min-width: 120px;
					padding: 0 8px;
					--paper-item-min-height: 32px;
				}
		
				[hidden]{
					display:none !important;
				}
		
				tpp-button + tpp-button{
					margin-left:16px;
				}
			</style>
		`;
	}

	static get contentTemplate() {
		return html`
			<iron-pages selected=[[selectedView]] attr-for-selected="view"  on-show-list-view="_showListView">
                ${this.applicationListTemplate}
                ${this.manageAppTemplate}
                ${this.prodAppTemplate}
            </iron-pages>
			${this.addApplicationTemplate}
		`;
	}

	connectedCallback() {
		super.connectedCallback();
		this.set("teamMembers", []);
		this.__getMyApplications();
		this.__getMyOrgMemebers();
		this.__getMyOrgApplications();
		this.set("hideViewTitle", true);
		this.set("orgappList", []);
		this.set("publishedAppsList", []);
		this.set("_loginMethodsList", [
			{
				d: "Authorization Code (B2B2C)",
				v: "authorization_code"
			}, {
				d: "Client Credentials (B2B)",
				v: "client_credentials"
			}
		]);

		this.set("orgappConfig", {
			columns: [
				{
					key: "name",
					label: "App Name"
				},
				{
					key: "description",
					label: "Description"
				},
				{
					key: "callbackUrl",
					label: "Callback Url"
				},
				{
					key: "appUser.email",
					label: "User Email"
				},
				{
					key: "status",
					label: "Status"
				}
			],
			rowActions: [
				{
					action: "view",
					title: "View App",
					icon: "visibility"
				},
				{
					action: "ownership",
					title: "Change ownership",
					icon: "face"
				}
			],
			publishedRowActions: [
				{
					action: "view",
					title: "View App",
					icon: "visibility"
				},
				{
					action: "approve",
					title: "Approve",
					icon: "done",
					isHiddenFunction: app => app.status !== "Pending with organization Admin"
				},
				{
					action: "unpublish",
					title: "Un Publish",
					icon: "cloud-off",
					isHiddenFunction: app => app.status !== "published"
				},
				{
					action: "reject",
					title: "Reject",
					icon: "close",
					isHiddenFunction: app => app.status !== "Pending with organization Admin"
				}
			]
		});
	}

	async __getMyApplications() {
		let url = `@restApiRoot/AppUsers/${this.userInfo.userId}/application`;
		let filter = {
			include: [
				"certificate",
				"productionApplication"
			]
		};
		let [err, resp] = await this._makeAjaxPromise(url, "GET", null, null, { filter });
		if (err) {
			this.fire("oe-show-error", window.devportalError(err));
			return;
		}
		this.set("applicationsList", resp);

		if (this.userInfo.roles[0] === "developer") {
			let publishedApps = resp.filter(app => app.productionApplication).map(app => {
				let prodApp = app.productionApplication;
				prodApp.name = app.name;
				prodApp.description = app.description;
				prodApp.status = "Approved";
				return prodApp;
			});
			this.set("publishedAppsList", publishedApps);
		}
		this.set("selectedView", "app-list");
	}

	async __getMyOrgMemebers() {
		if (!this.userInfo.roles.includes("organization-admin")) {
			return;
		}
		let filter = {
			include: ["application"]
		};
		let url = `@restApiRoot/Organizations/${this.userInfo.organizationId}/user`;
		let [err, resp] = await this._makeAjaxPromise(url, "GET", null, null, { filter });
		if (err) {
			this.fire("oe-show-error", window.devportalError(err));
			return;
		}
		this.set("teamMembers", resp);
	}

	async __getMyOrgApplications() {
		if (this.userInfo.roles[0] !== "organization-admin") {
			return;
		}
		let url = `@restApiRoot/Organizations/${this.userInfo.organizationId}/application`;
		let filter = {
			include: [
				"certificate",
				"productionApplication",
				"appUser"
			]
		};
		let [err, allApps] = await this._makeAjaxPromise(url, "GET", null, null, { filter });

		if (err) {
			this.fire("oe-show-error", window.devportalError(err));
			return;
		}

		// this.set("orgappList", allApps.filter(app => app.appUserId !== this.userInfo.userId));
		this.set("orgappList", allApps);

		let publishedApps = allApps.filter(app => app.productionApplication).map(app => {
			let prodApp = app.productionApplication;
			prodApp.name = app.name;
			prodApp.description = app.description;
			prodApp.status = "Approved";
			return prodApp;
		});

		let [err2, resp2] = await this._makeAjaxPromise("@restApiRoot/Tasks", "GET", null, null, {
			filter: {
				where: {
					name: {
						inq: ["publish-orgadmin-approval", "publish-admin-approval"]
					},
					status: "pending"
				}
			}
		});

		if (err2) {
			this.fire("oe-show-error", window.devportalError(err));
			return;
		}

		let pendingPublishApps = resp2.map(task => {
			let publishData = task.formVariables._modelInstance;
			let linkedApp = allApps.find(app => app.id === task.formVariables.correlationId) || {};

			return {
				...publishData,
				name: linkedApp.name,
				description: linkedApp.description,
				status: task.name === "publish-orgadmin-approval" ? "Pending with organization Admin" : "Pending with Admin",
				_task: task
			};
		});

		this.set("publishedAppsList", [
			...publishedApps, ...pendingPublishApps
		]);
	}

	_loadView(isList, app, readonly) {
		this.setProperties({
			selectedView: isList ? "app-list" : "manage-app",
			selectedApp: JSON.parse(JSON.stringify(app)),
			readOnlyApp: readonly
		});
		document.scrollingElement.scrollIntoView({ behavior: "smooth" });
	}

	/* APPLICATION LIST LOGIC */

	static get applicationListTemplate() {
		return html`
            <div view="app-list">
                <h2 class="section-title">
					<oe-i18n-msg msgid="Manage Applications">Manage Applications</oe-i18n-msg>	
				</h2>
                <div class="app-list">
                    <dom-repeat items=[[applicationsList]] as="application">
                        <template>
                            <div class="application-item">
                                <paper-menu-button hidden=[[devportalConfig.DISABLE_PUBLISH_APP]] class="app-more-menu" no-animations horizontal-align="right" vertical-offset="32">
                                    <paper-icon-button icon="menu" slot="dropdown-trigger"></paper-icon-button>
                                    <paper-listbox slot="dropdown-content">
                                        <paper-item on-tap="_showProdApp">
											<oe-i18n-msg msgid="View Production">View Production</oe-i18n-msg>		
										</paper-item>
                                    </paper-listbox>
                                </paper-menu-button>
                                <label class="app-name">[[application.name]]</label>
                                <tpp-button message="Manage" primary on-tap="_configApplication"></tpp-button>
                            </div>
                        </template>
                    </dom-repeat>
                    <div class="application-item add-application" on-tap="_lanchAddDialog">
                        <iron-icon icon="add-circle-outline"></iron-icon>
                        <label><oe-i18n-msg msgid="Add Application">Add Application</oe-i18n-msg></label>
                    </div>
                </div>
                <oe-data-grid
                    hidden=[[!userAccess.orgAdmin]]
                    disable-selection disable-config-editor disable-full-screen-mode disable-add
                    id="appsTable"
                    label="Organization Applications" items={{orgappList}}
                    on-oe-data-table-row-action-view="_viewApplication"
                    on-oe-data-table-row-action-ownership="_changeAppOwnership"
                    columns=[[orgappConfig.columns]] row-actions=[[orgappConfig.rowActions]]></oe-data-grid>
                <oe-data-grid
                    disable-selection disable-config-editor disable-full-screen-mode disable-add
                    id="publishedappsTable" hidden=[[devportalConfig.DISABLE_PUBLISH_APP]]
                    label="Published Applications" items={{publishedAppsList}} 
                    on-oe-data-table-row-action="_handlePublishedAppAction"
                    columns=[[orgappConfig.columns]] row-actions=[[orgappConfig.publishedRowActions]]></oe-data-grid>

                <tpp-dialog id="rejectDialog" modal>
                    <iron-icon slot="header" class="header-icon" icon="assignment"></iron-icon>
                    <label slot="header">
						<oe-i18n-msg msgid="Confirm rejection"></oe-i18n-msg>	
					</label>
                    <div slot="body" class="dialog-content">
                        <tpp-input required label="Comments" value={{rejectionComments}}></tpp-input>
                    </div>
                    <tpp-button slot="footer" mini primary message="Reject" on-tap="__confirmReject"></tpp-button>
                    <tpp-button slot="footer" mini message="Cancel" on-tap="_closeReject"></tpp-button>
                </tpp-dialog>

                <tpp-dialog id="ownershipDialog" modal>
                    <iron-icon slot="header" class="header-icon" icon="face"></iron-icon>
                    <label slot="header">
						<oe-i18n-msg msgid="Re-Assign ownership of application"></oe-i18n-msg>	
					</label>
                    <div slot="body" class="dialog-content">
                        <tpp-input readonly label="Existing Owner" value={{ownership.owner.email}}></tpp-input>
                        <tpp-dropdown label="New Owner" required value={{ownership.newOwner}} list=[[teamMembers]] disp="email"></tpp-dropdown>
                    </div>
                    <tpp-button slot="footer" mini primary message="Re-Assign" on-tap="__updateOwnership"></tpp-button>
                    <tpp-button slot="footer" mini message="Cancel" on-tap="_closeOwnership"></tpp-button>
                </tpp-dialog>

            </div>
        `;
	}

	_configApplication(event) {
		let app = event.model.application;
		this._loadView(false, app, false);
	}

	_viewApplication(event) {
		let app = event.detail.row;
		this._loadView(false, app, true);
	}

	_changeAppOwnership(event) {
		let app = event.detail.row;
		let owner = this.teamMembers.find(u => {
			if (u.application) {
				return u.application.find(a => a.id === app.id);
			}
			return false;
		});
		this.set("ownership", {
			app,
			owner,
			newOwner: owner
		});
		this.$.ownershipDialog.open();
	}

	async __updateOwnership() {
		let {
			app,
			owner,
			newOwner
		} = this.ownership;

		if (!newOwner) {
			this.fire("oe-show-error", "New Owner field is mandatory");
			return;
		}

		if (owner.id === newOwner.id) {
			this.fire("oe-show-error", "New owner is same as existing owner");
			return;
		}

		let payload = {
			newOwnerId: newOwner.id
		};

		let [err] = await this._makeAjaxPromise(`@restApiRoot/Applications/${app.id}/updateOwnership`, "post", payload);
		if (err) {
			this.fire("oe-show-error", window.devportalError(err));
			return;
		}

		this.fire("oe-show-success", "Ownership has been updated");
		this.__getMyOrgApplications();
		this._closeOwnership();
	}

	_closeOwnership() {
		this.$.ownershipDialog.close();
	}

	_handlePublishedAppAction(event) {
		let { row, action } = event.detail;
		let prodApp = structuredClone(row);
		switch (action.action) {
		case "view":
			this.setProperties({
				selectedView: "manage-prod",
				selectedApp: null,
				selectedProdApp: prodApp,
				readOnlyProd: true,
				readOnlyApp: false
			});
			break;
		case "approve":
			// Open confirm dialog and approve
			this.fire("oe-show-confirm", {
				message: "Confirm approval for publishing the application ?",
				ok: () => this.__confirmApprovalForPublish(prodApp._task.id),
				okLabel: "Approve"
			});
			break;
		case "reject":
			this.__initReject(prodApp._task);
			//
			break;
		default:
			break;
		}
	}

	__confirmApprovalForPublish(taskId) {
		let url = `@restApiRoot/Tasks/${taskId}/complete`;
		let payload = {
			"__action__": "approved"
		};

		this.makeAjaxCall(url, "PUT", payload, null, null, null, (err, resp) => {
			if (err) {
				this.fire("oe-show-error", window.devportalError(err));
				return;
			}
			this.fire("oe-show-success", "Application approved for publish successfully.");
			this.__getMyOrgApplications();
		});
	}

	__initReject(task) {
		this.setProperties({
			_selectedTask: task,
			rejectionComments: ""
		});
		this.$.rejectDialog.open();
	}

	_closeReject() {
		this.$.rejectDialog.close();
	}

	__confirmReject() {
		if (this.rejectionComments && this.rejectionComments.length > 4) {
			let task = this._selectedTask;

			let url = `@restApiRoot/Tasks/${task.id}/complete`;
			let payload = {
				"__action__": "rejected",
				"pv": {
					"id": task.application.id,
					"comment": this.rejectionComments
				}
			};

			this.makeAjaxCall(url, "PUT", payload, null, null, null, (err, resp) => {
				if (err) {
					this.fire("oe-show-error", window.devportalError(err));
					return;
				}
				this.fire("oe-show-success", "Application rejected successfully.");
				this.__getMyOrgApplications();
				this.$.rejectDialog.close();
			});
		} else {
			this.fire("oe-show-error", "Rejection comments must be minimum 4 letters.");
		}
	}

	/* MANAGE APPLICATION */

	static get manageAppTemplate() {
		return html`
        <manage-application data-test-id="Sbmit" view="manage-app" 
                on-update-app-list="__getMyApplications" disable-publish=[[devportalConfig.DISABLE_PUBLISH_APP]]
                read-only-mode=[[readOnlyApp]] application-id=[[selectedApp.id]]></manage-application>
        `;
	}

	_showListView() {
		this.scrollIntoView();
		this._loadView(true, null, false);
	}

	/* MANAGE PROD APPLICATION */
	static get prodAppTemplate() {
		return html`
        <manage-published-app view="manage-prod" application=[[selectedProdApp]] read-only-mode=[[readOnlyProd]]></manage-published-app>
        `;
	}

	async _showProdApp(event) {
		let app = event.model.application;
		let existingProd = app.productionApplication;
		let prodApp;
		let [err, resp] = await this._makeAjaxPromise(`@restApiRoot/Applications/${app.id}/getProductionData`, "GET", null);
		if (err) {
			return this.fire("oe-show-error", this.resolveError(err));
		}
		let pendingProd = (resp.result && resp.result.productionApp) ? resp.result.productionApp : resp.result;

		if (!pendingProd && !existingProd) {
			this.fire("oe-show-error", "No production data found for this application.");
			return;
		}
		let readonlyprod = false;
		if (pendingProd) {
			if (existingProd) {
				// Update pending flow
				prodApp = {
					...existingProd,
					...pendingProd
				};
			} else {
				// Create pending flow
				prodApp = pendingProd;
			}
			readonlyprod = true;
		} else {
			prodApp = JSON.parse(JSON.stringify(app.productionApplication));
		}
		prodApp.name = app.name;
		this.setProperties({
			selectedView: "manage-prod",
			selectedApp: null,
			selectedProdApp: prodApp,
			readOnlyProd: readonlyprod,
			readOnlyApp: false
		});
		// document.scrollingElement.scrollIntoView({ behavior: "smooth" });
	}

	_viewProdApplication(event) {
		let prodApp = event.detail.row;
		this.setProperties({
			selectedView: "manage-prod",
			selectedApp: null,
			selectedProdApp: prodApp,
			readOnlyProd: true,
			readOnlyApp: false
		});
	}

	/* ADD APPLICATION LOGIC */

	_lanchAddDialog() {
		this.set("newApp", {
			callbackUrl: "https://",
			loginMethod: "client_credentials"
		});
		this.$.add.open();
	}

	_closeAddDialog() {
		this.$.add.close();
	}

	static get addApplicationTemplate() {
		return html`
            <tpp-dialog id="add" modal>
                <label class="group-name" slot="header">
					<oe-i18n-msg msgid="Add new Application">Add new Application</oe-i18n-msg>
				</label>
                <table slot="body" class="add-table body-container">
                    <tbody>
                        <tr>
                            <td class="label-cell required-cell">
								<oe-i18n-msg msgid="Name">Name</oe-i18n-msg>
							</td>
                            <td class="value-cell">
                                <tpp-input no-label-float label="Name" value={{newApp.name}}></tpp-input>
                            </td>
                        </tr>
                        <tr>
                            <td class="label-cell">
								<oe-i18n-msg msgid="Description">Description</oe-i18n-msg></td>
                            <td class="value-cell">
                                <tpp-input no-label-float label="Description" value={{newApp.description}}></tpp-input>
                            </td>
                        </tr>
                        <tr>
                            <td class="label-cell required-cell">
								<oe-i18n-msg msgid="Callback URL">Callback URL</oe-i18n-msg>	
							</td>
                            <td class="value-cell">
                                <tpp-input no-label-float type="url" label="Callback URL" pattern="https://.*" user-error-message='{"patternMismatch":"URL should start with https://"}' maxlength=250 value={{newApp.callbackUrl}} title="URL must begin with https://"></tpp-input>
                            </td>
                        </tr>
                        <tr>
                            <td class="label-cell required-cell">
								<oe-i18n-msg msgid="Login Method">Login Method</oe-i18n-msg>	
							</td>
                            <td class="value-cell">
                                <oe-radio-group no-label-float value={{newApp.loginMethod}} displayproperty="d" valueproperty="v" listdata=[[_loginMethodsList]]></oe-radio-group>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <tpp-button primary mini slot="footer" on-tap="_addApplication" message="Save"></tpp-button>
                <tpp-button mini slot="footer" message="Close" on-tap="_closeAddDialog"></tpp-button>
            </tpp-dialog>
        `;
	}

	_addApplication() {
		let {
			callbackUrl,
			name,
			loginMethod
		} = this.newApp;

		if (!callbackUrl || !loginMethod || !name) {
			this.fire("oe-show-error", "Please fill all the mandatory fields.");
			return;
		}

		if (callbackUrl.toLowerCase().indexOf("https://") !== 0) {
			this.fire("oe-show-error", "Callback URL should start with https://");
			return;
		}

		this.makeAjaxCall("@restApiRoot/Applications", "POST", this.newApp, null, null, null, async (err, resp) => {
			if (err) {
				this.fire("oe-show-error", window.devportalError(err));
				return;
			}
			this.fire("oe-show-success", "Application added successfully.");
			await this.__getMyApplications();
			let findApp = this.applicationsList.find(app => app.id === resp.id);
			if (findApp) {
				this._loadView(false, findApp, false);
			}
			this._closeAddDialog();
		});
	}
}

window.customElements.define(ManageAppView.is, ManageAppView);
