/* beautify preserve:start */
import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import "@polymer/iron-flex-layout/iron-flex-layout.js";
import "@polymer/iron-flex-layout/iron-flex-layout-classes.js";
import "oe-i18n-msg/oe-i18n-msg.js";
import "@polymer/paper-dialog/paper-dialog.js";
import "@polymer/paper-dialog-scrollable/paper-dialog-scrollable.js";
import "@polymer/paper-button/paper-button.js";

import "@polymer/neon-animation/neon-animations.js";
import "./tpp-dialog.js";
// import "@polymer/neon-animation/animations/scale-down-animation.js";

class TppMessageHandler extends PolymerElement {
	static get is() {
		return "tpp-message-handler";
	}

	static get template() {
		return html`
        <style include="iron-flex iron-flex-alignment">

            .message-success{ --type-color : #4C9D21; }
            .message-error{ --type-color : #DF2D2D; }
            .message-warning{ --type-color : #F5BC5C; }
            .message-confirm{ --type-color : lightgreen; }
            .message-message{ --type-color : #FF9800; }
            
            .header-icon{
                color : var(--type-color);
                margin-right:8px;
            }

            .dialog-content{
                margin: 0px;
                padding-bottom:16px;
                font-family: inherit;
            }

            .close-icon{
                position: absolute;
                right: 16px;
                cursor:pointer;
                color: var(--primary-text-color);
            }

            tpp-button + tpp-button{
                margin-left:8px;
            }

        </style>
        <tpp-dialog id="dialog" modal=[[config.hasModal]] class$="message-[[config.type]]" entry-animation="slide-from-top-animation">
            <iron-icon slot="header" class="header-icon" icon=[[_getIcon(config.type)]]></iron-icon>
            <label slot="header">
				<oe-i18n-msg msgid=[[config.type]]></oe-i18n-msg>
			</label>
            <iron-icon slot="header" class="close-icon" icon="clear" on-tap="_closeDialog"></iron-icon>
            <pre slot="body" class="dialog-content"><oe-i18n-msg msgid=[[config.message]]></oe-i18n-msg></pre>
            <tpp-button slot="footer" mini primary message=[[config.okLabel]] on-tap="_closeOk"></tpp-button>
            <dom-if if=[[config.hasCancel]]>
                <template>
                    <tpp-button slot="footer" mini message=[[config.cancelLabel]] on-tap="_closeCancel"></tpp-button>
                </template>
            </dom-if>
        </tpp-dialog>
      `;
	}

	ready() {
		super.ready();
		var self = this;
		window.addEventListener("oe-show-success", function (e) {
			self.showToast("success", e.detail);
		});
		window.addEventListener("oe-show-message", function (e) {
			self.showToast(e.detail.type || "message", e.detail);
		});
		window.addEventListener("oe-show-error", function (e) {
			self.showToast("error", e.detail);
		});
		window.addEventListener("oe-show-warning", function (e) {
			self.showToast("warning", e.detail);
		});
		window.addEventListener("oe-show-confirm", function (e) {
			self.showToast("confirm", e.detail);
		});
		window.addEventListener("oe-hide-message", e => {
			this._closeDialog();
		});
	}

	showToast(type, data) {
		let config = {
			type,
			message: data.code || data.message || data,
			okLabel: data.okLabel || "Ok",
			cancelLabel: data.cancelLabel || "Cancel",
			hasCancel: false,
			autoClose: typeof data.autoClose !== "undefined"
		};

		config.hasModal = !config.autoClose;

		if (typeof data.ok === "function") {
			config.okCb = data.ok;
			config.hasCancel = true;
		}

		if (typeof data.cancel === "function") {
			config.cancelCb = data.cancel;
		}
		this.set("config", config);
		this.$.dialog.open();
		if (config.autoClose && !isNaN(data.autoClose)) {
			setTimeout(()=>{
				this._closeOk();
			}, data.autoClose);
		}
	}

	_closeOk() {
		if (typeof this.config.okCb === "function") {
			this.config.okCb();
		}
		this._closeDialog();
	}

	_closeCancel() {
		if (typeof this.config.cancelCb === "function") {
			this.config.cancelCb();
		}
		this._closeDialog();
	}

	_closeDialog() {
		this.$.dialog.close();
	}

	_getIcon(type) {
		let icons = {
			success: "check-circle",
			message: "report",
			error: "cancel",
			warning: "report-problem",
			confirm: "assignment"
		};

		return icons[type] || icons.message;
	}
}

window.customElements.define(TppMessageHandler.is, TppMessageHandler);
