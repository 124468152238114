import { html, PolymerElement} from "@polymer/polymer/polymer-element.js";
import ProfileBaseView from "./profile-base-view.js";
import "oe-input/oe-textarea.js";
import "oe-input/oe-file-input.js";
import "../../../styles/input-styles.js";
import "oe-data-grid/oe-data-grid.js";
import { OECommonMixin } from "oe-mixins/oe-common-mixin";

class ManageCertificateView extends ProfileBaseView {
	static get is() {
		return "manage-certificate-view";
	}

	static get contentTemplate() {
		return html`
			<dom-if if=[[_hasOrganization(organization)]]>
                <template>
					<oe-data-grid
						disable-selection disable-config-editor disable-full-screen-mode
						editor-form-element="add-certificate-form" record-handling="local"
						id="certificateTable"
						label="Organization Certificates" items={{certificates}} 
						on-add-certificate="__addCertificate"
						on-oe-data-table-row-action-remove="__removeCertificate"
						on-oe-data-table-action-refresh="__getMyOrgCertificates"
						columns=[[certConfig.columns]] default-record=[[certConfig.default]]
						row-actions=[[certConfig.rowActions]] toolbar-actions=[[certConfig.toolbarActions]]></oe-data-grid>
                </template>
            </dom-if>
		`;
	}

	constructor() {
		super();
		this.set("certificates", []);
		this.set("viewTitle", "Manage CA Certificate");
		this.set("certConfig", {
			columns: [
				{
					key: "expiry",
					label: "Expiry"
				},
				{
					key: "cn",
					label: "cn"
				}
			],
			rowActions: [
				{
					action: "remove",
					title: "remove",
					icon: "clear"
				}
			],
			toolbarActions: [
				{
					action: "refresh",
					title: "Refresh Certificates",
					icon: "refresh",
					isButton: true
				}
			],
			default: {
				cert: ""
			}
		});
	}

	static get properties() {
		return {
			userRole: {
				type: Object,
				statePath: "userRole"
			}
		};
	}

	static get observers() {
		return [
			"__getMyOrgCertificates(userRole,organization)"
		];
	}

	__getMyOrgCertificates() {
		if (this.userRole !== "organization-admin") {
			return;
		}
		if (!this._hasOrganization(this.organization)) {
			return;
		}
		let url = `@restApiRoot/Organizations/${this.organization.id}/certificate`;
		this.makeAjaxCall(url, "GET", null, null, null, null, (err, resp)=>{
			if (err) {
				this.fire("oe-show-error", window.devportalError(err));
				return;
			}
			this.set("certificates", resp);
		});
	}

	__addCertificate(event) {
		let content = event.detail;
		let payload = {
			caCert: encodeURI(content)
		};
		let url = `@restApiRoot/Organizations/${this.organization.id}/certificate`;
		this.makeAjaxCall(url, "POST", payload, null, null, null, (err, resp)=>{
			if (err) {
				this.fire("oe-show-error", window.devportalError(err));
				return;
			}
			this.fire("oe-show-success", "Certificate added successfully.");
			let table = this.shadowRoot.querySelector("#certificateTable");
			this.__getMyOrgCertificates();
			table._showGridView();
		});
	}

	_hasOrganization(organization) {
		return organization && organization.id;
	}

	__removeCertificate(event) {
		let {
			row
		} = event.detail;

		let url = `@restApiRoot/Organizations/${this.organization.id}/certificate/${row.id}`;
		this.makeAjaxCall(url, "DELETE", null, null, null, null, (err)=>{
			if (err) {
				this.fire("oe-show-error", window.devportalError(err));
				return;
			}
			this.fire("oe-show-success", "Certificate deleted successfully.");
			this.__getMyOrgCertificates();
		});
	}
}

window.customElements.define(ManageCertificateView.is, ManageCertificateView);

window.customElements.define("add-certificate-form", class extends OECommonMixin(PolymerElement) {
	static get template() {
		return html`
        <style include="input-styles iron-flex iron-flex-alignment">
            :host{
                padding:16px;
                display:block;
            }
            fieldset{
                padding:16px;
            }

            .field-label{
                display:block;
                margin:8px 0px;
            }

            tpp-button{
                margin-top:16px;
            }
        </style>
        <fieldset>
            <legend>
				<oe-i18n-msg msgid="Add New Certificate">Add New Certificate</oe-i18n-msg>
			</legend>
            <label class="field-label"><oe-i18n-msg msgid="Certificate">Certificate</oe-i18n-msg></label>
            <div class="layout horizontal">
                <oe-textarea id="emailInput" rows="6" max-rows="6" maxlength="100" required class="rounded-input flex" label="Certificate content" always-float-label value="{{vm.cert}}"></oe-textarea>
                <oe-file-input value-type="binary" value={{vm.cert}} >
                    <template custom-upload>
                        <style>
                            .upload-icon{
                                padding:8px;
                                border:1px solid;
                                border-radius:4px;
                                margin:0 8px;
                                cursor:pointer;
                            }
                        </style>
                        <iron-icon class="upload-icon" icon="icons:file-upload" on-tap="_selectFile"></iron-icon>
                    </template>
                </oe-file-input>
            </div>
            <tpp-button message="Add Certificate" mini primary on-tap="_addCertificate"></tpp-button>
        </fieldset>
        `;
	}

	_addCertificate() {
		if (!this.vm.cert) {
			return;
		}

		this.fire("add-certificate", this.vm.cert);
	}
});
