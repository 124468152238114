import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import "@polymer/iron-flex-layout/iron-flex-layout.js";
import "@polymer/iron-flex-layout/iron-flex-layout-classes.js";
import { OECommonMixin } from "oe-mixins/oe-common-mixin.js";

import "@polymer/paper-button/paper-button.js";
import "oe-i18n-msg/oe-i18n-msg.js";
import "@polymer/iron-icon/iron-icon.js";

class TppButton extends OECommonMixin(PolymerElement) {
	static get is() {
		return "tpp-button";
	}

	static get template() {
		return html`
        <style>
            :host{
                display:inline-block;
                --button-height:48px;
                --button-padding: 0 40px;
                --border-color : transparent;
                --button-text-color:var(--primary-text-color);
            }

            :host([primary]){
                --button-text-color : var(--tpp-btn-primary-text);
                --button-bg-color : var(--tpp-btn-primary-bg);
                --border-color : var(--tpp-btn-primary-border);
            }

            :host([secondary]){
                --button-text-color : var(--tpp-btn-secondary-text);
                --border-color : var(--tpp-btn-secondary-border);
                --button-bg-color : var(--tpp-btn-secondary-bg);
            }

            :host([mini]){
                --button-height:38px;
                --button-padding: 0 30px;
            }

            :host([animate-to-primary]) #button:hover{
                --button-bg-color : var(--tpp-btn-animate-to-primary);
            }

            :host(:not([primary]):not([animate-to-primary])) #button:hover{
                --button-bg-color : #E4E4E4;
            }

            :host([disabled]){
                pointer-events:none;
            }

            #button{
                color : var(--button-text-color);
                background:var(--button-bg-color);
                width: fit-content;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                text-transform:none;
                line-height: 0;
                padding: var(--button-padding);
                min-height:var(--button-height);
                border-radius: 4px;
                border: 1px solid var(--border-color);
                transition: 0.5s;
                opacity:1;
                margin:0px;
                font-weight: normal;
                font-family:var(--base-font);
            }  

            #button[disabled]{
                opacity:0.4;
            }

            #translator{
                line-height:initial;
            }

            #icon{
                transition: all 300ms ease-in;
                --iron-icon-width: 20px;
                --iron-icon-height: 20px;
                margin-left: 6px;
            }

            :host([animate-on-hover]) #button:hover  #icon{
                transform: translateX(15px);
            }


        </style>
        <paper-button id="button" disabled=[[disabled]]>
            <oe-i18n-msg id="translator" msgid=[[message]]></oe-i18n-msg>
            <dom-if if=[[__hasIcon(icon)]]>
                <template>
                    <iron-icon id="icon" icon="[[icon]]"></iron-icon>
                </template>
            </dom-if>
        </paper-button>
        `;
	}


	static get properties() {
		return {
			message: {
				type: String
			},
			icon: {
				type: String,
				value: ""
			},
			animateOnHover: {
				type: Boolean,
				value: false,
				reflectToAttribute: true
			},
			animateToPrimary: {
				type: Boolean,
				value: false,
				reflectToAttribute: true
			},
			disabled: {
				type: Boolean,
				value: false,
				reflectToAttribute: true
			}
		};
	}

	__hasIcon(icon) {
		return !!icon;
	}
}
window.customElements.define(TppButton.is, TppButton);
