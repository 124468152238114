import {
	createStore,
	applyMiddleware
} from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger/src";

import reducer from "./reducer.js";

const loggerMiddleware = createLogger();

export const store = createStore(reducer, applyMiddleware(thunkMiddleware, loggerMiddleware));
