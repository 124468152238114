export const groupAPIBundle = (list) => {
	let bundleMap = {};
	list.forEach(bundle => {
		let bundleName = bundle.group || bundle.name;
		let groupBy = bundleName + "__" + bundle.category;
		if (!bundleMap[groupBy]) {
			bundleMap[groupBy] = {};
		}
		bundleMap[groupBy].bundleName = bundleName;
		bundleMap[groupBy].category = bundle.category;
		bundleMap[groupBy][bundle.type] = bundle;
	});

	let bundleCollections = Object.keys(bundleMap).map(key => {
		let bundleObj = bundleMap[key];

		let {
			api, event
		} = bundleObj;

		let collectionObj = {
			groupName: bundleObj.bundleName,
			category: bundleObj.category,
			country: [],
			hasApi: false,
			hasEvent: false,
			rating: 0,
			tags: []
		};

		if (api) {
			collectionObj.hasApi = true;
			collectionObj.api = api;
			collectionObj.country.push(...api.country);
			collectionObj.rating += api.rating.rating;
			collectionObj.tags.push(api.tag);
		}

		if (event) {
			collectionObj.hasEvent = true;
			collectionObj.event = event;
			collectionObj.country.push(...event.country);
			collectionObj.rating += event.rating.rating;
			collectionObj.tags.push(event.tag);
		}

		if (api && event) {
			// Get avg rating
			collectionObj.rating = Math.floor(collectionObj.rating / 2);
		}

		return collectionObj;
	});

	return bundleCollections;
};

const getSchemaFromRef = str => str.replace("#/components/schemas/", "");
const getParamFromRef = str => str.replace("#/components/parameters/", "");


let createSchemaPayload = (name, allConfig, cache) => {
	if (cache[name]) {
		return JSON.parse(JSON.stringify(cache[name]));
	}

	let payload = {};
	let config = allConfig[name];
	let properties = config.type === "object" ? config.properties : config.items.properties;

	Object.keys(properties).forEach(key => {
		let propInfo = properties[key];
		if (propInfo.type === "number" || propInfo.type === "integer") {
			payload[key] = propInfo.minimum || 0;
		} else if (propInfo.type === "string") {
			if (propInfo.format === "date") {
				payload[key] = new Date().toISOString();
			} else {
				payload[key] = new Array(propInfo.minLength || 1).fill("-").join("");
			}
		} else if (propInfo.type === "array") {
			payload[key] = [];
			if (propInfo.items.$ref) {
				let itemType = getSchemaFromRef(propInfo.items.$ref);
				let item = createSchemaPayload(itemType, allConfig, cache);
				payload[key].push(item);
			}
		} else if (propInfo.$ref) {
			let itemType = getSchemaFromRef(propInfo.$ref);
			payload[key] = createSchemaPayload(itemType, allConfig, cache);
		}
	});

	cache[name] = JSON.parse(JSON.stringify(payload));
	return payload;
};

export const _deepValue = (obj, path) => {
	path = path.split(".");
	for (var i = 0, len = path.length; obj && i < len; i++) {
		obj = obj[path[i]];
	}
	return obj;
};

export const _deepSet = (obj, path, value) => {
	if (!obj || !path) return;
	if (typeof path === "string") {
		path = path.split(".");
	}
	var field = path.shift();
	if (path.length === 0) {
		obj[field] = value;
	} else {
		var subobj = obj[field];
		if (typeof subobj !== "object") {
			subobj = {};
			obj[field] = subobj;
		}
		_deepSet(subobj, path, value);
	}
	return obj;
};

export const getYamlTestInfo = (yamlStr) => {
	let parsed = window.YAML.parse(yamlStr);

	let schemaPayloads = {};
	let allSchema = parsed.components.schemas;
	Object.keys(allSchema).forEach(name => {
		createSchemaPayload(name, allSchema, schemaPayloads);
	});


	let testCases = {};
	Object.keys(parsed.paths).map(k => parsed.paths[k]).forEach(path => {
		Object.keys(path).forEach(method => {
			let operation = path[method];
			let test = {
				"name": "Default test case",
				"headers": {},
				"queries": {}
			};

			if (operation.requestBody && operation.requestBody.content && operation.requestBody.content["application/json"] && operation.requestBody.content["application/json"].schema) {
				let ref = operation.requestBody.content["application/json"].schema.$ref;
				let schemaName = getSchemaFromRef(ref);
				test.body = JSON.parse(JSON.stringify(schemaPayloads[schemaName]));
			}

			if (operation.parameters) {
				operation.parameters.forEach(param => {
					let paramConf = param;
					if (param.$ref) {
						let refName = getParamFromRef(param.$ref);
						paramConf = parsed.components.parameters[refName];
					}
					let paramName = paramConf.name;
					let paramVal;

					if (paramConf.schema.type === "string") {
						let minVal = new Array(paramConf.schema.minLength).fill("-").join("");
						paramVal = paramConf.required ? minVal : "";
					}

					if (paramConf.in === "header") {
						test.headers[paramName] = paramVal;
					} else if (paramConf.in === "path") {
						test.queries[paramName] = paramVal;
					}
				});
			}

			testCases[operation.operationId] = [test];
		});
	});

	return {
		testCases
	};
};
