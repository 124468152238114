import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import "@polymer/iron-flex-layout/iron-flex-layout.js";
import "@polymer/iron-flex-layout/iron-flex-layout-classes.js";
import { OEAjaxMixin } from "oe-mixins/oe-ajax-mixin.js";
import "../../styles/input-styles.js";

import "oe-input/oe-file-input.js";
import "./logo-selector.js";
import "oe-input/oe-textarea.js";
import "oe-ui-misc/oe-stepper.js";
import "oe-radio-group/oe-radio-group.js";
import "oe-paper-chip/oe-paper-chip.js";

import "./tpp-dialog.js";
import "./tpp-button.js";
import "./tpp-input.js";
import "./tpp-dropdown.js";
import "./swagger-client-wrapper.js";

let recurNodes = (node, prefix = [], target) => {
	let _path = [...prefix, node.key];
	if (Array.isArray(node.children)) {
		node.children.forEach(n => {
			recurNodes(n, _path, target);
		});
	} else {
		target.push(_path.join("/"));
	}
};


class ApiBundleAddDialog extends OEAjaxMixin(PolymerElement) {
	static get is() {
		return "api-bundle-add-dialog";
	}

	static get template() {
		return html`
        <style include="iron-flex iron-flex-alignment iron-flex-factors input-styles">

        #dialog{
            height:95vh;
            width:95vw;
            border-radius:4px;
            display: flex;
            flex-direction: column;
        }

        .dialog-header{
            margin: 0px;
            padding: 12px 24px;
            font-size:20px;
            text-transform:capitalize;
        }

        #close-icon{
            align-self:end;
        }

        .dialog-body{
            margin:0px;
        }

        .dialog-footer{
            border-top:1px solid var(--secondary-grey-color);
            padding: 16px 24px;
            margin: 0px;
        }

        #stepper{
            --oe-stepper-label-width: 160px;
            --oe-stepper-circle-size: 28px;
            --oe-stepper-step-font-size: 16px;
            pointer-events: none;
            --oe-stepper-data:{
                padding:0 8px;
            }
        }

        .allow-overflow{
            overflow:auto;
        }

        .apihub-fetch-error{
            color:red;
            font-size: 12px;
            margin-left: 8px;
            display: block;
        }

        .step-label{
            font-size: 20px;
            margin: 8px 0px;
            color: var(--dark-primary-color);
            border-bottom: 1px solid;
            padding-bottom: 8px;
        }

        .step-sub-label{
            font-size: 16px;
            margin: 8px 0px;
            color: var(--default-color);
            border-bottom: 1px solid;
            padding-bottom: 8px;
            text-transform: uppercase;
        }

        .step-content{
            height: calc(100% - 40px);
        }

        .selected-file-name{
            margin: 8px;
            font-size: 12px;
        }

        #yaml-editor{
            min-height: calc(100vh - 300px);
        }

        .step-content.detail-page{
            padding: 0 20px 0px 2px;
        }

        .step-content.detail-page > oe-radio-group{
            padding:1%;
        }

        .step-pages-selector{
            height: calc(100vh - 220px);
        }

        .step-pages-selector > .step-container.iron-selected{
            height:100%;
        }

        .margin-tb-16{
            margin-top:16px;
            margin-bottom:16px;
        }

        .step-1-content{
            display: flex;
            flex-direction: column;
        }

        .semi-width{
            width: 48%;
            display:inline-block;
        }

	


        .col-2{
            display:flex;
            flex-wrap:wrap;
        }


        .col-2 > *:not(.full-width){
            width: 48%;
            padding:1%;
            display:inline-block;
        }

        .col-2 > .full-width{
            padding:1%;
            width:98%;
        }

        .base-server-url{
            display: flex;
            padding: 0 8px;
            align-items: center;
            border-radius: 2px 0px 0px 2px;
            margin-left: 2px;
            letter-spacing: 0.5px;
            background: var(--secondary-grey-color);
            height: 38px;
            border: 1px solid var(--border-color);
            border-right-width:0px;
        }

        .group-image-preview{
            height: 200px;
            max-width: 200px;
            margin-left: 18px;
            margin-top: 18px;
            outline: 2px dotted;
            border-radius: 4px;
            outline-offset: 4px;
        }

        .validation-display{
            padding-right:40px;
        }

        .validation-display .display-label{
            width : 30%;
            color: var(--dark-theme-disabled-color);
            font-size: 12px;
        }

        .validation-display .display-value{
            width : 70%;
            margin-bottom: 8px;
            font-size: 12px;
        }

        .validation-display .display-value .display-value-chip{
            padding: 4px 8px;
            border-radius: 4px;
            color: white;
            background: var(--dark-primary-color);
            margin-right: 4px;
            margin-bottom: 4px;
        }       


        .rounded-chip-input::part(iron-input){
            border-width:0px;
        }

        .rounded-chip-input::part(input-chip-container){
            border:1px solid var(--border-color);
        }

        [hidden]{
            display:none !important;
        }

        </style>
        <paper-dialog id="dialog" modal>
            <div class="dialog-header layout horizontal center justified">
                <label class="dialog-name">
					<oe-i18n-msg msgid=[[ui.dialogLabel]]></oe-i18n-msg>
				</label>
                <paper-icon-button id="close-icon" icon="clear" on-tap="_closeDialog"></paper-icon-button>
            </div>
            <div class="dialog-body layout vertical flex">
                <oe-stepper id="stepper" steps=[[_getStepperSteps(ui.steps,ui.currentStep)]] value=[[ui.currentStep]]></oe-stepper>
                <dom-if if=[[dialogOpen]]>
                    <template>
                        <iron-pages selected=[[ui.currentStep]] class="step-pages-selector">
                            ${this.step1Template}
                            ${this.step2Template}
                            ${this.step3Template}
                            ${this.step4Template}
                            ${this.step5Template}
                        </iron-pages>
                    </template>
                </dom-if>
            </div>
            <div class="dialog-footer layout horizontal center justified">
                <tpp-button mini disabled=[[__isEqual(ui.currentStep,0)]] on-tap="_handleBackAction" message="Back"></tpp-button>
                <tpp-button primary mini on-tap="_handleAction" message="[[_getActionLabel(ui.currentStep)]]"></tpp-button>
            </div>
        </paper-dialog>
        `;
	}

	static get step1Template() {
		return html`
        <div class="step-container">
            <div class="step-label">
				<oe-i18n-msg msgid="API Overview">API Overview</oe-i18n-msg>	
			</div>
            <div class="step-content step-1-content">
                <tpp-input class="semi-width" readonly=[[ui.readonly]] required label="Name" field-id="bundleName" value={{pageData.overview.name}}></tpp-input>
                <oe-radio-group disabled=[[ui.readonly]] label="Consumer" listdata=[[ui.listdata.consumer]] valueproperty="c" displayproperty="d"
                    value={{pageData.overview.consumer}}></oe-radio-group>
        
                <oe-radio-group disabled=[[ui.readonly]] label="YAML Source" class="margin-tb-16" listdata=[[ui.listdata.yamlSource]] valueproperty="c"
                    displayproperty="d" value={{pageData.overview.yamlSource}}></oe-radio-group>
                <dom-if if=[[__isEqual(pageData.overview.yamlSource,'independent')]]>
                    <template>
                        <oe-file-input hidden=[[ui.readonly]] label="Upload YAML" value-type="text" file-name={{pageData.overview.fileName}}
                            on-value-changed="_fileUploaded">
                            <template custom-upload>
                                <tpp-button mini secondary message="Upload YAML" on-tap="_selectFile"></tpp-button>
                            </template>
                        </oe-file-input>
                        <div class="selected-file-name">[[pageData.overview.fileName]]</div>
                    </template>
                </dom-if>
                <dom-if if=[[__isEqual(pageData.overview.yamlSource,'apihub')]]>
                    <template>
                        <tpp-dropdown disabled=[[ui.readonly]] required class="semi-width" label="APIHub YAML" value={{pageData.overview.apihubBundle}} disp="d" list=[[ui.listdata.apihubYamls]]>
                        </tpp-dropdown>
                        <label hidden=[[!ui.errorFetchingApiHub]] class="apihub-fetch-error"><oe-i18n-msg msgid="Error fetching list from APIHub.">Error fetching list from APIHub.</oe-i18n-msg></label>
                    </template>
                </dom-if>
            </div>
        </div>
        `;
	}

	static get step2Template() {
		return html`
        <div class="step-container">
            <div class="step-label"><oe-i18n-msg msgid="API Specification">API Specification</oe-i18n-msg></div>
            <div class="step-content layout horizontal">
                <div class="flex-2 layout vertical" hidden=[[__isEqual(pageData.overview.yamlSource,'apihub')]]>
                    <div id="yaml-editor" class=" allow-overflow flex">
                        <slot></slot>
                    </div>
                </div>
                <div class="flex-3 allow-overflow" id="swagger-ui">
                    <swagger-client-wrapper class="flex" id="swagger" no-try-out yaml=[[pageData.specification.validYaml]]>
                    </swagger-client-wrapper>
                </div>
            </div>
        </div>
        `;
	}

	static get step3Template() {
		return html`
        <div class="step-container">
            <div class="step-label"><oe-i18n-msg msgid="API Details">API Details</oe-i18n-msg></div>
            <div class="step-content allow-overflow detail-page">
                <div class="col-2">
                    <tpp-input class="has-prefix" field-id="baseUrl" readonly=[[ui.readonly]] label="Path" always-float-label pattern=".*" value={{pageData.detail.basePath}}>
                        <slot name="prefix" slot="prefix"><label class="base-server-url">[[ui.baseServerURL]]</label></slot>
                    </tpp-input>
                    
                    <tpp-input field-id="version" readonly=[[ui.readonly]] required label="Version" value={{pageData.detail.version}}></tpp-input>
                    <tpp-dropdown readonly=[[ui.readonly]] label="Type" value={{pageData.detail.type}} list=[[ui.listdata.type]] val="c" disp="d"></tpp-dropdown>
                    
                    <tpp-dropdown readonly=[[ui.readonly]] label="Category" value={{pageData.detail.category}} list=[[ui.listdata.category]] val="c" disp="d" required=[[ui.isCategoryRequired]] hidden=[[!ui.isCategoryRequired]]></tpp-dropdown>
                    <tpp-dropdown readonly=[[ui.readonly]] required label="Gateway" value={{pageData.detail.gateway}} val="c" disp="d" list=[[ui.listdata.gateway]]></tpp-dropdown>
                    
                    <tpp-input field-id="upstream" type="url"  pattern="(https|http)://.*" user-error-message='{"patternMismatch":"URL should start with http:// or https://"}' readonly=[[ui.readonly]] label="Upstream URL" placeholder="Default URL will be used if left blank" value={{pageData.detail.upstream}}></tpp-input>
                    <tpp-input field-id="upstreamPath" readonly=[[ui.readonly]] pattern=".*" label="Upstream Path" value={{pageData.detail.upstreamPath}}></tpp-input>
                    
                    <tpp-input field-id="servicePath" readonly=[[ui.readonly]] pattern=".*" label="Service Base Path" value={{pageData.detail.servicePath}}></tpp-input>
                    
                    <oe-textarea readonly=[[ui.readonly]] rows="1" max-rows="4" class="rounded-input" always-float-label label="Comments" value="{{pageData.detail.comments}}"></oe-textarea>
                    <oe-paper-chip readonly=[[ui.readonly]] label="Tags" always-float-label class="rounded-input rounded-chip-input" value={{pageData.detail.tags}}></oe-paper-chip>
                    
                    <tpp-dropdown multi disabled=[[ui.readonly]] required label="Hierarchy" value={{pageData.detail.hierarchy}} list=[[ui.listdata.hierarchy]]></tpp-dropdown>
                    <oe-textarea readonly=[[ui.readonly]] required always-float-label rows="4" max-rows="6" class="rounded-input flex full-width" label="Summary"
                        value="{{pageData.detail.summary}}"></oe-textarea>
                </div>
                <!-- <h3 class="step-sub-label">Limits</h3>
                <div class="layout horizontal wrap justified col-2">
                    <oe-decimal  readonly=[[ui.readonly]] label="API Quota Limit" class="rounded-input" value={{pageData.detail.limit.apiQuotaLimit}}
                        precision="0" no-grouping></oe-decimal>
                    <tpp-dropdown disabled=[[ui.readonly]] label="API Quota Interval"
                        value={{pageData.detail.limit.apiQuotaInterval}} list=[[ui.listdata.timeLimit]] val="c" disp="d"></tpp-dropdown>
                    <oe-decimal  readonly=[[ui.readonly]] label="App Quota Limit" class="rounded-input" value={{pageData.detail.limit.appQuotaLimit}}
                        precision="0" no-grouping></oe-decimal>
                    <tpp-dropdown disabled=[[ui.readonly]] label="App Quota Interval"
                        value={{pageData.detail.limit.appQuotaInterval}} list=[[ui.listdata.timeLimit]] val="c" disp="d"></tpp-dropdown>
                    <oe-decimal readonly=[[ui.readonly]] label="Request Payload Size Limit" class="rounded-input"
                        value={{pageData.detail.limit.payloadSizeLimit}} precision="0" no-grouping></oe-decimal>
                    <tpp-dropdown disabled=[[ui.readonly]] label="Request Payload Size Unit"
                        value={{pageData.detail.limit.payloadSizeUnit}} list=[[ui.listdata.sizeLimit]] val="c" disp="d"></tpp-dropdown>
                </div> 
                <oe-radio-group disabled=[[ui.readonly]] class="rounded-input margin-tb-16" label="Response Cache" value={{pageData.detail.limit.responseCache}}
                    listdata=[[ui.listdata.responseCache]] valueproperty="c" displayproperty="d"></oe-radio-group>-->
                <h3 class="step-sub-label"><oe-i18n-msg msgid="Northbound (Consumer side)">Northbound (Consumer side)</oe-i18n-msg></h3>
                <oe-radio-group disabled=[[ui.readonly]] class="rounded-input" label="Security" value={{pageData.detail.northSecurity.type}}
                    listdata=[[ui.listdata.northSecurity]] valueproperty="c" displayproperty="d"></oe-radio-group>
                <h3 class="step-sub-label"><oe-i18n-msg msgid="Southbound (Provider side)">Southbound (Provider side)</oe-i18n-msg></h3>
                <oe-radio-group disabled=[[ui.readonly]] class="rounded-input" label="Security" value={{pageData.detail.southSecurity.type}}
                    listdata=[[ui.listdata.southSecurity]] valueproperty="c" displayproperty="d"></oe-radio-group>
                <dom-if if=[[__isEqual(pageData.detail.southSecurity.type,'apikey')]]>
                    <template>
                        <tpp-input class="semi-width" readonly=[[ui.readonly]] label="API Key" pattern=".*" value={{pageData.detail.southSecurity.apikey}}></tpp-input>
                    </template>
                </dom-if>
                <dom-if if=[[__isEqual(pageData.detail.southSecurity.type,'mtls')]]>
                    <template>
                        <oe-textarea readonly=[[ui.readonly]] rows="4" max-rows="6" class="rounded-input flex" label="CA Certificate" always-float-label
                            value="{{pageData.detail.southSecurity.mtls}}"></oe-textarea>
                    </template>
                </dom-if>
            </div>
        </div>
        `;
	}

	static get step4Template() {
		return html`
        <div class="step-container">
            <div class="step-label">
				<oe-i18n-msg msgid="Api Group">Api Group</oe-i18n-msg>	
			</div>
            <div class="step-content">
                <div class="step-sub-label">
					<oe-i18n-msg msgid="Selected Hierarchy">Selected Hierarchy</oe-i18n-msg>	
				</div>
                <ul>
                    <dom-repeat items=[[pageData.detail.hierarchy]]>
                        <template>
                            <li class="hierarchy-items">[[item]]</li>
                        </template>
                    </dom-repeat>
                </ul>
                <!-- <tpp-input label="Hierarchy" value=[[pageData.detail.hierarchy]] readonly></tpp-input> -->
                <oe-radio-group hidden=[[ui.readonly]] label="Api Group link" listdata=[[ui.listdata.groupSource]]
                    valueproperty="c" displayproperty="d" value={{pageData.group.source}}></oe-radio-group>
                <label> <oe-i18n-msg msgid="Matching existing groups">Matching existing groups</oe-i18n-msg> : [[ui.listdata.groupsList.length]]</label>
                <dom-if if=[[__isEqual(pageData.group.source,'new')]]>
                    <template>
                        <div class="step-sub-label"><oe-i18n-msg msgid="Create New API Group">Create New API Group</oe-i18n-msg></div>
                        <div class="layout horizontal">
                            <div class="flex flex-4 layout vertical">
                                <tpp-input  class="semi-width" pattern=".*" label="Group Name" field-id="groupName" value={{pageData.group.newGroup.name}}></tpp-input>
							   	<tpp-input  class="semi-width" pattern=".*" label="Group key" field-id="groupKey" value={{pageData.group.newGroup.key}}></tpp-input>
                            </div>
							<logo-selector class="logoSelector" value={{pageData.group.newGroup.image}}></logo-selector>
                            
                        </div>
                    </template>
                </dom-if>
                <dom-if if=[[__isEqual(pageData.group.source,'join')]]>
                    <template>
                        <div class="step-sub-label"><oe-i18n-msg msgid="Select Existing API Group">Select Existing API Group</oe-i18n-msg></div>
                        <tpp-dropdown disabled=[[ui.readonly]] label="API Group" value={{pageData.group.apiGroup}} list=[[ui.listdata.groupsList]] disp="name"></tpp-dropdown>
                    </template>
                </dom-if>
            </div>
        </div>
        `;
	}

	static get step5Template() {
		return html`
        <div class="step-container">
            <div class="step-label"><oe-i18n-msg msgid="Validate and Submit">Validate and Submit</oe-i18n-msg></div>
            <div class="step-content validation-display allow-overflow">
                <dom-repeat items=[[pageData.validation.display]] as="group">
                    <template>
                        <h3 class="step-sub-label group-name"><oe-i18n-msg msgid=[[group.name]]></oe-i18n-msg></h3>
                        <div class="layout horizontal wrap group-items">
                            <dom-repeat items=[[group.children]]>
                                <template>
                                    <label class="display-label" hidden=[[item.hidden]]><oe-i18n-msg msgid=[[item.label]]></oe-i18n-msg></label>
                                    <dom-if if=[[!item.showChips]]>
                                        <template>
                                            <div class="display-value" hidden=[[item.hidden]]>[[item.value]]</div>
                                        </template>
                                    </dom-if>
                                    <dom-if if=[[item.showChips]]>
                                        <template>
                                            <div class="display-value layout horizontal wrap center" hidden=[[item.hidden]]>
                                                <dom-repeat items=[[item.value]] as="chip">
                                                    <template>
                                                        <label class="display-value-chip">[[chip]]</label>
                                                    </template>
                                                </dom-repeat>
                                            </div>
                                        </template>
                                    </dom-if>
                                </template>
                            </dom-repeat>
                        </div>
                    </template>
                </dom-repeat>
            </div>
        </div>
        `;
	}

	constructor() {
		super();
		this.set("dialogOpen", false);
	}

	connectedCallback() {
		super.connectedCallback();
		window.addEventListener("launch-add-api-bundle", (event) => {
			this.context = event.detail;
			this._launch(this.context.id);
			this.set("dialogOpen", true);
		});
		this.fire("load-monaco-editor", {
			cb: (editor) => {
				this.__editor = editor;
				this.__editorModel = this.__editor.getModel();
				this.__editorModel.onDidChangeContent(() => {
					this.set("pageData.specification.yamlContent", this.__editor.getValue());
					this.debounce("parse-yaml", this._parseYaml, 500);
				});
			}
		});

		this.set("ui", {
			steps: [
				"Overview",
				"API Specification",
				"API Detail",
				"Api Group & Hierarchy",
				"Validate & Submit"
			],
			currentStep: 0,
			errorFetchingApiHub: false,
			readonly: false,
			isCategoryRequired: false,
			listdata: {
				consumer: [
					{ c: "internal", d: "Internal" },
					{ c: "external", d: "External" },
					{ c: "both", d: "Both" }
				],
				yamlSource: [
					{ c: "independent", d: "Independent" },
					{ c: "apihub", d: "API Hub management" }
				],
				specFormat: [
					{ c: "openapi", d: "OpenAPI 3" },
					{ c: "swagger", d: "Swagger 2" }
				],
				category: [],
				gateway: [
					{ c: "kong", d: "Kong" }
				],
				hierarchy: [],
				groupSource: [
					{ c: "new", d: "Add New Group" },
					{ c: "join", d: "Join Existing Group" }
				],
				responseCache: [
					{ c: true, d: "Yes" },
					{ c: false, d: "No" }
				],
				groupsList: [],
				apihubYamls: [],
				type: [
					{ c: "api", d: "API" },
					{ c: "event", d: "Event" }
				],
				northSecurity: [
					{ c: "none", d: "None" },
					{ c: "oauth2", d: "OAuth 2.0" }
				],
				southSecurity: [
					{ c: "none", d: "None" },
					{ c: "apikey", d: "Api Key" },
					{ c: "mtls", d: "MTLS" }
				],
				timeLimit: [
					{ c: "min", d: "Minute" },
					{ c: "hour", d: "Hour" },
					{ c: "day", d: "Days" }
				],
				sizeLimit: [
					{ c: "KB", d: "KB" },
					{ c: "MB", d: "MB" }
				]
			}
		});
	}

	__resetDialog() {
		this.set("ui.baseServerURL", "");
		this.set("ui.currentStep", 0);
		this.set("ui.errorFetchingApiHub", false);
		this.set("pageData", {
			overview: {
				specFormat: "openapi",
				consumer: "both",
				yamlSource: "independent",
				fileChanged: false
			},
			specification: {},
			detail: {
				basePath: "",
				version: "",
				type: "",
				category: "",
				gateway: "kong",
				comments: "",
				summary: "",
				hierarchy: [],
				limit: {
					responseCache: true
				},
				northSecurity: {
					type: "oauth2"
				},
				southSecurity: {
					type: "none"
				}
			},
			group: {
				source: "new",
				newGroup: {"image": "default"}

			},
			validation: {
				display: []
			}
		});

		let fileInputs = this.shadowRoot.querySelectorAll("oe-file-input");
		fileInputs.forEach(f => {
			f.value = null;
		});
	}

	__fetchAppConfig() {
		return new Promise((resolve) => {
			this.makeAjaxCall("@restApiRoot/AppConfig", "get", null, null, null, null, (err, resp) => {
				if (err || !resp) {
					this.__showError(window.devportalError(err));
					return;
				}
				this.__appConfig = resp[0];
				let hierarchyList = [];
				this.__appConfig.hierarchy.forEach(h => {
					recurNodes(h, [], hierarchyList);
				});
				this.set("ui.baseServerURL", this.__appConfig.serverUrl);
				let allConfig = [];
				(this.__appConfig.ui.categoryList || []).forEach(c => {
					allConfig.push({
						d: c,
						c
					});
				});
				this.set("ui.listdata.hierarchy", hierarchyList);
				this.set("ui.listdata.category", allConfig);
				this.set("ui.isCategoryRequired", allConfig.length > 0);
				resolve();
			});
		});
	}

	__fetchApiGroups() {
		return new Promise((resolve) => {
			this.makeAjaxCall("@restApiRoot/ApiGroups", "get", null, null, null, null, (err, resp) => {
				if (err || !resp) {
					this.__showError(window.devportalError(err));
					return;
				}
				this.__allApiGroups = resp;
				resolve();
			});
		});
	}

	_getStepperSteps(allSteps, currentStep) {
		return allSteps.map((step, idx) => {
			return {
				label: step,
				isCompleted: idx < currentStep
			};
		});
	}

	_getActionLabel(currentStep) {
		return currentStep === 4 ? this.ui.primaryLabel : "Next";
	}

	async _launch(id) {
		this.__resetDialog();
		await this.__fetchAPIHubYamlList();
		await this.__fetchApiGroups();
		await this.__fetchAppConfig();
		if (id) {
			let existingRecord = await this.__fetchRecordDetails(id);
			let {
				description,
				server,
				security,
				group,
				apiHubYamlId,
				yamlContent
			} = existingRecord;

			let pageData = window.OEUtils.deepCloneJSON(this.pageData);

			["name", "consumer"].forEach(k => {
				if (typeof existingRecord[k] !== "undefined") {
					pageData.overview[k] = existingRecord[k];
				}
			});

			if (apiHubYamlId) {
				pageData.overview.yamlSource = "apihub";
				let matchedBundle = this.ui.listdata.apihubYamls.find(a => "" + a.id === "" + apiHubYamlId);
				if (matchedBundle) {
					pageData.overview.apihubBundle = matchedBundle;
				}
			} else {
				pageData.overview.yamlSource = "independent";
				pageData.overview.fileContent = yamlContent;
				pageData.overview.fileChanged = true;
			}

			pageData.detail.basePath = server;
			pageData.detail.summary = description;

			["version", "type", "category", "gateway", "comments", "tags","upstream","upstreamPath","servicePath"].forEach(k => {
				if (typeof existingRecord[k] !== "undefined") {
					pageData.detail[k] = existingRecord[k];
				}
			});

			let existingHierarchy = [];
			if (existingRecord.hierarchy) {
				if (Array.isArray(existingRecord.hierarchy)) {
					existingHierarchy = existingRecord.hierarchy;
				} else if (typeof existingRecord.hierarchy === "string") {
					existingHierarchy = [
						existingRecord.hierarchy
					];
				}
			}
			pageData.detail.hierarchy = existingHierarchy;


			if (security) {
				if (security.north) {
					pageData.detail.northSecurity = security.north;
				}
				if (security.south) {
					pageData.detail.southSecurity = security.south;
				}
			}


			let matchedGroup = this.__allApiGroups.find(g => g.key === group);
			if (matchedGroup) {
				pageData.group.source = "join";
				pageData.group.apiGroup = matchedGroup;
			}

			this.set("pageData", pageData);
			if (this.context.mode === "edit") {
				this.set("ui.dialogLabel", "Edit API Bundle");
				this.set("ui.primaryLabel", "Update");
				this.set("ui.readonly", false);
				this.ui.mode = "edit";
			} else {
				this.set("ui.dialogLabel", "View API Bundle");
				this.set("ui.primaryLabel", "Exit");
				this.set("ui.readonly", true);
				this.ui.mode = "view";
			}
		} else {
			this.ui.mode = "add";
			this.set("ui.dialogLabel", "Add API Bundle");
			this.set("ui.primaryLabel", "Save");
			this.set("ui.readonly", false);
		}
		this.$.dialog.open();
	}

	/* Step 1 Functions */

	/* Fetch all APIHub Yaml bundle details */
	__fetchAPIHubYamlList() {
		return new Promise(resolve => {
			this.makeAjaxCall("@restApiRoot/ApiBundles/getApiHubYamlList", "GET", null, null, null, null, (err, resp) => {
				if (err) {
					this.set("ui.errorFetchingApiHub", true);
					this.set("ui.listdata.apihubYamls", []);
					resolve();
					return;
				}
				if (resp.result && (!Array.isArray(resp.result) || resp.result.length < 1)) {
					this.set("ui.errorFetchingApiHub", true);
					resolve();
					return;
				}
				this.set("ui.listdata.apihubYamls", resp.result.map(c => {
					return {
						...c,
						d: `${c.name}-${c.version}`
					};
				}));
				this.set("ui.errorFetchingApiHub", false);
				resolve();
			});
		});
	}

	/* Fetch all APIHub Yaml content of given id */
	__fetchAPIHubYaml(id, cb) {
		this.makeAjaxCall("@restApiRoot/ApiBundles/getApiHubYaml/" + id, "GET", null, null, null, null, (err, resp) => {
			if (err) {
				this.__showError(window.devportalError(err));
				return;
			}
			cb(resp.result.data);
		});
	}

	__fetchRecordDetails(id) {
		return new Promise(resolve => {
			this.makeAjaxCall("@restApiRoot/ApiBundles/" + id, "GET", null, null, null, null, (err, resp)=>{
				if (err) {
					this.__showError(window.devportalError(err));
					return;
				}
				resolve(resp);
			});
		});
	}

	__validateStep1() {
		let {
			name,
			yamlSource,
			fileContent,
			apihubBundle
		} = this.pageData.overview;

		if (!name) {
			this.__showError("Name is mandatory");
			return false;
		}

		if (name.length < 4) {
			this.__showError("Name should be minimum 4 letters long");
			return false;
		}

		if (yamlSource === "independent") {
			if (!fileContent) {
				this.__showError("YAML file is mandatory");
				return false;
			}

			// Validate uploaded YAML file
			try {
				window.YAML.parse(fileContent);
			} catch (e) {
				this.__showError(e);
				return false;
			}
		} else if (!(apihubBundle && apihubBundle.id)) {
			this.__showError("API Hub Bundle is mandatory");
			return false;
		}

		return true;
	}

	__stepTransform1to2() {
		let {
			overview,
			specification
		} = this.pageData;

		let {
			yamlSource,
			fileContent,
			fileChanged,
			apihubBundle
		} = overview;

		this.__trim("pageData.overview.name");

		if (!this.__validateStep1()) {
			return;
		}

		let {
			yamlContent,
			bundleId
		} = specification;

		this.set("pageData.specification.validYaml", null);

		if (yamlSource === "independent") {
			let _updateFile = () => {
				this.set("pageData.overview.fileChanged", false);
				this.set("ui.currentStep", 1);
				this.set("pageData.specification.yamlContent", fileContent);
				this.__editor.updateOptions({readOnly: this.ui.readonly});
				this.__editor.setValue(fileContent);
				this.__editor.layout();
				this._parseYaml();
				this.__scrollSwaggerTop();
			};

			// Check if content is changed in 2nd step
			if (fileChanged && typeof yamlContent !== "undefined" && fileContent !== yamlContent) {
				this.fire("oe-show-confirm", {
					message: "Override changes made in 2nd step ?",
					ok: _updateFile
				});
			} else {
				_updateFile();
			}
		} else {
			let _fetchAndMap = () => {
				this.__fetchAPIHubYaml(apihubBundle.id, (yamlString) => {
					this.set("ui.currentStep", 1);
					this.set("pageData.specification.yamlContent", yamlString);
					this.set("pageData.specification.bundleId", apihubBundle.id);
					this._parseYaml();
					this.__scrollSwaggerTop();
				});
			};

			if (!bundleId) {
				// First time fetching YAML
				_fetchAndMap();
			} else if (apihubBundle.id !== bundleId) {
				// Different bundle selected
				this.fire("oe-show-confirm", {
					message: "You will lose changes progress ?",
					ok: _fetchAndMap
				});
			} else {
				// No changes in YAML proceed to next step
				this.set("ui.currentStep", 1);
				this.__scrollSwaggerTop();
			}
		}
	}

	__scrollSwaggerTop() {
		let swaggerContainer = this.$["swagger-ui"];
		if (swaggerContainer) {
			this.async(() => {
				swaggerContainer.scrollTop = 0;
			});
		}
	}

	/* Step 2 Functions */

	__stepTransform2to3() {
		let valid = this._parseYaml(true);
		if (!valid) {
			return;
		}

		let {
			parsedYaml
		} = this.pageData.specification;

		let parsedInfo = parsedYaml.info || {};

		let parsedDetail = {
			summary: parsedInfo.description,
			version: parsedInfo.version
		};

		if (Array.isArray(parsedYaml.tags)) {
			parsedDetail.tags = parsedYaml.tags.map(s => s.name);
		}

		if (parsedInfo["x-fac-type"]) {
			parsedDetail.type = parsedInfo["x-fac-type"];
		}

		if (parsedInfo["x-fac-group"]) {
			parsedDetail.groupKey = parsedInfo["x-fac-group"].key;
		}

		if (parsedInfo["x-fac-category"]) {
			parsedDetail.category = parsedInfo["x-fac-category"];
		}

		if (Array.isArray(parsedInfo["x-fac-hierarchy"])) {
			parsedDetail.hierarchy = parsedInfo["x-fac-hierarchy"];
		}

		let newDetail = {
			...this.pageData.detail,
			...parsedDetail
		};

		let overrideKeys = ["version", "tags", "category", "hierarchy"];

		overrideKeys.forEach(k => {
			let existingV = this.pageData.detail[k];
			if (existingV && existingV.length > 0) {
				newDetail[k] = existingV;
			}
		});

		this.set("pageData.detail", newDetail);
		this.set("ui.currentStep", 2);
	}

	/* Step 3 Functions() */

	__validateStep3() {
		let {
			version,
			summary,
			hierarchy,
			category
		} = this.pageData.detail;

		if (!version) {
			this.__showError("Version is mandatory");
			return false;
		}
		if (!summary) {
			this.__showError("Summary is mandatory");
			return false;
		}
		if (!(Array.isArray(hierarchy) && hierarchy.length > 0)) {
			this.__showError("Hierarchy is mandatory");
			return false;
		}

		if (this.ui.isCategoryRequired && !category) {
			this.__showError("Category is mandatory");
			return false;
		}

		return true;
	}

	__stepTransform3to4() {
		let {
			detail
		} = this.pageData;

		let {
			type,
			category,
			hierarchy,
			southSecurity
		} = detail;

		this.__trim("pageData.detail.basePath");
		this.__trim("pageData.detail.version");
		this.__trim("pageData.detail.summary");
		this.__trim("pageData.detail.comments");
		this.__trim("pageData.detail.upstream");
		this.__trim("pageData.detail.upstreamPath");
		this.__trim("pageData.detail.servicePath");

		if (southSecurity.type === "apikey" && southSecurity.apiKey) {
			this.__trim("pageData.detail.southSecurity.apiKey");
		}

		if (southSecurity.type === "mtls" && southSecurity.mtls) {
			this.__trim("pageData.detail.southSecurity.mtls");
		}

		if (!this.__validateStep3()) {
			return;
		}

		let filteredGroup = this.__allApiGroups
			.filter(group => {
				if (!this.ui.isCategoryRequired) {
					return true;
				}
				return group.category === category;
			})
			.filter(group => {
				return group.tag.every(tag => {
					return hierarchy.indexOf(tag) !== -1;
				});
			})
			.filter(group => {
				if (type === "api") {
					return !(group.api && group.api.name);
				}
				return !(group.event && group.event.name);
			});

		this.set("ui.listdata.groupsList", filteredGroup);
		this.set("ui.currentStep", 3);
	}
	/* Step 4 Functions */

	__validateStep4() {
		let {
			source,
			newGroup,
			apiGroup
		} = this.pageData.group;

		if (source === "new") {
			let {
				name,
				key
			} = newGroup;

			if (!name) {
				this.__showError("Api group name is mandatory.");
				return false;
			}

			if (!key) {
				this.__showError("Api group key is mandatory.");
				return false;
			}
		} else if (!(apiGroup && apiGroup.id)) {
			this.__showError("Api Group is mandatory.");
			return false;
		}

		return true;
	}

	__stepTransform4to5() {
		let {
			overview,
			group,
			detail
		} = this.pageData;

		this.__trim("pageData.group.newGroup.key");
		this.__trim("pageData.group.newGroup.name");

		if (!this.__validateStep4()) {
			return;
		}

		let displayGroups = [];

		let allList = this.ui.listdata;

		let __getDisplayValue = (listdata, value) => {
			let matched = listdata.find(i => i.c === value);
			if (matched) {
				return matched.d;
			}
		};

		// Info from step 1
		let basicInfo = {
			name: "Basic Details",
			children: [
				{ label: "Name", value: overview.name },
				{ label: "Consumer", value: __getDisplayValue(allList.consumer, overview.consumer) },
				{ label: "YAML Source", value: __getDisplayValue(allList.yamlSource, overview.yamlSource) }
			]
		};

		if (overview.yamlSource === "independent") {
			if (this.ui.mode === "add") {
				basicInfo.children.push({
					label: "File name",
					value: overview.fileName
				});
			}
		} else {
			basicInfo.children.push({
				label: "APIHub Bundle",
				value: overview.apihubBundle.name
			});
		}

		displayGroups.push(basicInfo);

		// Info from step 3
		let summaryInfo = {
			name: "Summary",
			children: [
				{ label: "Base Path", value: detail.basePath },
				{ label: "Full Path", value: this.__appConfig.serverUrl + detail.basePath },
				{ label: "Version", value: detail.version },
				{ label: "Summary", value: detail.summary },
				{ label: "Category", value: __getDisplayValue(allList.category, detail.category), hidden: !this.ui.isCategoryRequired},
				{ label: "Type", value: __getDisplayValue(allList.type, detail.type) },
				{ label: "Gateway", value: __getDisplayValue(allList.gateway, detail.gateway) }
			]
		};

        if(detail.upstream){
            summaryInfo.children.push({
				label: "Upstream URL",
				value: detail.upstream
			});
        }

        if(detail.upstreamPath){
            summaryInfo.children.push({
				label: "Upstream Path",
				value: detail.upstreamPath
			});
        }

		if(detail.servicePath){
            summaryInfo.children.push({
				label: "Service Path",
				value: detail.servicePath
			});
        }

		if (detail.comments) {
			summaryInfo.children.push({
				label: "Comments",
				value: detail.comments
			});
		}

		if (Array.isArray(detail.tags) && detail.tags.length !== 0) {
			summaryInfo.children.push({
				label: "Tags",
				value: detail.tags,
				showChips: true
			});
		}

		displayGroups.push(summaryInfo);

		let {
			northSecurity,
			southSecurity
		} = detail;

		let northBoundInfo = {
			name: "North Bound",
			children: [
				{ label: "Security", value: __getDisplayValue(allList.northSecurity, northSecurity.type) }
			]
		};
		displayGroups.push(northBoundInfo);

		let southBoundInfo = {
			name: "South Bound",
			children: [
				{ label: "Security", value: __getDisplayValue(allList.southSecurity, southSecurity.type) }
			]
		};

		switch (southSecurity.type) {
		case "none": break;
		case "apikey": southBoundInfo.children.push({ label: "API Key", value: southSecurity.apikey }); break;
		case "mtls": southBoundInfo.children.push({ label: "CA Certificate", value: southSecurity.mtls }); break;
		default:break;
		}

		displayGroups.push(southBoundInfo);

		// Info from step 4

		let {
			newGroup,
			apiGroup
		} = group;

		let groupName; let groupKey; let groupHierarchy;

		if (group.source === "new") {
			groupName = newGroup.name;
			groupKey = newGroup.key;
			groupHierarchy = detail.hierarchy;
		} else {
			groupName = apiGroup.name;
			groupKey = apiGroup.key;
			groupHierarchy = apiGroup.tag[0];
		}

		let apiGroupInfo = {
			name: "API Group",
			children: [
				{ label: "Group source", value: __getDisplayValue(allList.groupSource, group.source) },
				{ label: "Group name", value: groupName },
				{ label: "Group key", value: groupKey },
				{ label: "Group hierarchy", value: groupHierarchy }
			]
		};

		displayGroups.push(apiGroupInfo);

		this.set("pageData.validation.display", displayGroups);
		this.set("ui.currentStep", 4);
	}

	/* Step 5 Function */
	_submitApiBundle() {
		let {
			overview,
			specification,
			detail,
			group
		} = this.pageData;

		let payload = {
			metadata: {
				yamlSource: overview.yamlSource,
				fileName: overview.fileName,
				apihubBundle: overview.apihubBundle,
				group
			},
			apibundle: {
				name: overview.name,
				consumer: overview.consumer,
				description: detail.summary,
				server: detail.basePath,
				version: detail.version,
				servicePath: detail.servicePath,
				hierarchy: detail.hierarchy,
				type: detail.type,
				// limit : detail.limit,
				security: {
					north: detail.northSecurity,
					south: detail.southSecurity
				},
				gateway: detail.gateway,
				comments: detail.comments,
				category: detail.category,
				yamlContent: encodeURIComponent(specification.validYaml)
			}
		};

        if(detail.upstream){
            payload.apibundle.upstream = detail.upstream;
        }

        let upstreamPath = detail.upstreamPath;
        if(upstreamPath){
            if(upstreamPath[0] !== "/"){
                upstreamPath = "/" + upstreamPath;
            }
            payload.apibundle.upstreamPath = upstreamPath;
        }

		if (payload.apibundle.server && payload.apibundle.server[0] !== "/") {
			payload.apibundle.server = "/" + payload.apibundle.server;
		}

		if (this.context.id) {
			payload.apibundle.id = this.context.id;
		}

		this.makeAjaxCall("@restApiRoot/ApiBundles/generate", "POST", payload, null, null, null, (err, resp)=>{
			if (err) {
				this.__showError(window.devportalError(err));
				return;
			}
			this._closeDialog();
			this.context.cb(resp);
		});
	}


	_handleAction() {
		switch (this.ui.currentStep) {
		case 0:
			this.__stepTransform1to2();
			break;
		case 1:
			this.__stepTransform2to3();
			break;
		case 2:
			this.__stepTransform3to4();
			break;
		case 3:
			this.__stepTransform4to5();
			break;
		case 4:
			if (this.ui.mode === "view") {
				this._closeDialog();
			} else {
				this._submitApiBundle();
			}
			break;
		default:break;
		}
		this.__resetStepScroll();
	}

	_handleBackAction() {
		switch (this.ui.currentStep) {
		case 0:
			this.set("ui.currentStep", 4);
			break;
		case 1:
			this.set("ui.currentStep", 0);
			break;
		case 2:
			this.set("ui.currentStep", 1);
			this.__editor.layout();
			break;
		case 3:
			this.set("ui.currentStep", 2);
			break;
		case 4:
			this.set("ui.currentStep", 3);
			break;
		default:break;
		}
		this.__resetStepScroll();
	}

	__resetStepScroll() {
		let selectedPage = this.shadowRoot.querySelector(".step-pages-selector > .iron-selected > .step-content");
		if (selectedPage) {
			selectedPage.scrollTop = 0;
		}
	}

	_parseYaml(showError) {
		let yamlText = this.pageData.specification.yamlContent;
		try {
			if (yamlText) {
				let parsed = window.YAML.parse(yamlText);
				this.set("pageData.specification.validYaml", yamlText);
				this.set("pageData.specification.parsedYaml", parsed);
				return true;
			}
			if (showError) {
				this.__showError("YAML is mandatory.");
			}
			this.set("pageData.specification.validYaml", null);
			this.set("pageData.specification.parsedYaml", null);
			return false;
		} catch (error) {
			this.set("pageData.specification.validYaml", null);
			this.set("pageData.specification.parsedYaml", null);

			let errMsg = error.message;

			if (error.parsedLine) {
				errMsg += ` In line ${error.parsedLine}.`;
			}

			if (error.snippet) {
				errMsg += ` Snippet : ${error.snippet}.`;
			}
			if (showError) {
				this.__showError(errMsg);
			}
			return false;
		}
	}

	_fileUploaded(event) {
		let fileText = event.detail.value;
		if (typeof fileText === "string") {
			this.set("pageData.overview.fileContent", fileText);
			this.set("pageData.overview.fileChanged", true);
		}
	}

	__isEqual(a, b) {
		return a === b;
	}

	_closeDialog() {
		this.$.dialog.close();
		this.set("dialogOpen", false);
	}

	__showError(msg) {
		this.fire("oe-show-error", msg);
	}

	__trim(key) {
		let value = this._deepValue(this, key);
		if (typeof value === "string") {
			this.set(key, value.trim());
		}
	}
}

window.customElements.define(ApiBundleAddDialog.is, ApiBundleAddDialog);
