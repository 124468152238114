import { html } from "@polymer/polymer/polymer-element.js";
import ProfileBaseView from "./profile-base-view.js";
import "oe-ui-accordion/oe-ui-accordion.js";
import "oe-ui-accordion/oe-accordion-item.js";

class FaqView extends ProfileBaseView {
	static get is() {
		return "faq-view";
	}

	constructor() {
		super();
		this.set("viewTitle", "Frequently Asked Questions");
	}

	static get contentTemplate() {
		let cust = window.devportal.customize;
		return cust["faq-view"] ? cust["faq-view"] : html`<label><oe-i18n-msg msgid="NOT LOADED">NOT LOADED</oe-i18n-msg></label>`;
	}
}

window.customElements.define(FaqView.is, FaqView);
