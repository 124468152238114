import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import "@polymer/iron-flex-layout/iron-flex-layout.js";
import "@polymer/iron-flex-layout/iron-flex-layout-classes.js";
import { OECommonMixin } from "oe-mixins/oe-common-mixin.js";
import SwaggerUI from "swagger-ui";
import "../../styles/swagger-styles.js";
import retargetEvents from "react-shadow-dom-retarget-events";
import "yamljs/dist/yaml.js";

/**
 * ### swagger-client-wrapper
 * `swagger-client-wrapper`
 *
 *
 * @customElement
 * @polymer
 */
class SwaggerClientWrapper extends OECommonMixin(PolymerElement) {
	static get is() {
		return "swagger-client-wrapper";
	}

	static get template() {
		return html `
    <style include="iron-flex iron-flex-alignment swagger-styles">

		#container.try-out-false .btn.try-out__btn{
			display:none !important;
		}

		.swagger-ui .checkbox input[type=checkbox]:checked+label>.item{
			background:#e8e8e8 var(--check-path) 50% no-repeat !important;
		}

		.swagger-ui .model-toggle:after{
			background: var(--toggle-path) 50% no-repeat !important;
		}

		.swagger-ui .copy-to-clipboard button{
			background:var(--copy-path) 50% no-repeat !important;		
		}

		.swagger-ui select{
			background:#f7f7f7 var(--select-path) right 10px center no-repeat !important;
		}

		/* 
		.swagger-ui .responses-table tr:not(:last-of-type).response{
			border-bottom: 2px dashed var(--divider-color);
		}

		.swagger-ui .response-col_description__inner p{
			margin-top:0px;
		}

		.swagger-ui .opblock-body{
			display: grid;
			grid-column-gap: 20px;
			grid-template-columns: 1fr 1.5fr;
			grid-auto-rows: min-content;
		}

		.swagger-ui .opblock-body .opblock-section{
    		grid-column-start: 1;
		}

		.swagger-ui .opblock-body .btn-group{
			grid-row-start: 2;
			-ms-grid-row: 3;
			grid-column-start: 1;
			-ms-grid-column: 1;
			padding-top: 16px;
			padding-right: 0;
			padding-left: 13em;
		}

		.swagger-ui .opblock-body .responses-wrapper{
			grid-column-start: 2;
			grid-row-start: 1;
			grid-row-end: 5;
		}
 */
    </style>
    <div id="container" class$="try-out-[[!noTryOut]]"></div>
    `;
	}

	static get properties() {
		return {
			yaml: {
				type: String,
				observer: "_yamlChanged"
			},
			noTryOut: {
				type: Boolean,
				value: false
			}
		};
	}

	connectedCallback() {
		super.connectedCallback();
		let customVars = {};
		let svgs = [
			"check", "toggle", "copy", "select"
		];

		svgs.forEach(name => {
			customVars[`--${name}-path`] = `url("${window.OEUtils.subPath}/img/swagger/${name}.svg")`;
		});

		this.updateStyles(customVars);
	}

	_yamlChanged() {
		if (!this.yaml) {
			this._loadedYaml = null;
			return;
		}
		let data = window.YAML.parse(this.yaml);
		let container = this.$.container;
		if (data && this._loadedYaml !== this.yaml) {
			this._loadedYaml = this.yaml;
			// Generates a React Element
			if (!this._client) {
				this._client = new SwaggerUI({
					domNode: container,
					spec: data,
					defaultModelsExpandDepth: 0,
					requestSnippetsEnabled: true,
					tagsSorter: "alpha",
					operationsSorter: "method",
					// tryItOutEnabled: !this.noTryOut,
					// TODO: this needs to be parameterised build time
					oauth2RedirectUrl: window.devportal.domainUrl + "/swagger/redirect"
				});
			} else {
				this._client.specActions.updateJsonSpec(data);
			}
			if (!this._retargetComplete) {
				// Fix for event navigation when React Element is used inside shadowDOM
				retargetEvents(container);
				this._retargetComplete = true;
			}
		}
	}

	async tryOutApi(api, testCase) {
		let {
			body,
			headers,
			queries
		} = testCase;

		let operationBlock = await this.scrollToApi(api, true);
		await this.awaitAsync();
		Object.keys(headers).forEach(name => {
			let input = operationBlock.querySelector(`.parameters-container tr[data-param-name="${name}"][data-param-in="header"] input`);
			input.value = headers[name];
		});

		Object.keys(queries).forEach(name => {
			let input = operationBlock.querySelector(`.parameters-container tr[data-param-name="${name}"][data-param-in="path"] input`);
			input.value = queries[name];
		});

		let bodyInput = operationBlock.querySelector("textarea.body-param__text");
		if (bodyInput && body) {
			bodyInput.value = JSON.stringify(body, null, 2);
			bodyInput.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
			return;
		}

		let executeBtn = operationBlock.querySelector(".execute-wrapper");
		executeBtn.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
	}

	async scrollToApi(api, enableTryIt) {
		let container = this.$.container;
		let groupName = api.group.replace(/\s/g, "_");

		// Expand the operationId
		this._client.layoutActions.show(["operations-tag", api.group], true);
		this._client.layoutActions.show(["operations", api.group, api.name], true);

		let operationBlock = container.querySelector(`#operations-${groupName}-${api.name}`);
		operationBlock.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });

		if (enableTryIt) {
			await this.awaitAsync();
			let tryItOutBtn = operationBlock.querySelector("button.try-out__btn");
			if (!tryItOutBtn.classList.contains("cancel")) {
				tryItOutBtn.click();
			}
		}
		return operationBlock;
	}

	awaitAsync() {
		return new Promise(resolve => setTimeout(resolve, 100));
	}
}
window.customElements.define(SwaggerClientWrapper.is, SwaggerClientWrapper);
