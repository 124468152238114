import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import "@polymer/iron-flex-layout/iron-flex-layout.js";
import "@polymer/iron-flex-layout/iron-flex-layout-classes.js";
import "@polymer/paper-icon-button/paper-icon-button.js";
import "@polymer/iron-collapse/iron-collapse.js";
import { OECommonMixin } from "oe-mixins/oe-common-mixin.js";
import "../../styles/app-theme.js";
import { ReduxMixin } from "../redux/redux-mixin.js";

/**
 * ### profile-sidebar
 * `profile-sidebar`
 *
 *
 * @customElement
 * @polymer
 */
class ProfileSidebar extends ReduxMixin(OECommonMixin(PolymerElement)) {
	static get is() {
		return "profile-sidebar";
	}

	static get template() {
		return html `
    <style include="iron-flex iron-flex-alignment app-theme">
		.navigation-sections{
			box-sizing: border-box;
			min-height: calc(100vh - 200px);
			padding: 8px 0px;
			border-top:1px solid var(--primary-grey-color);
		}

         .navigation-sections:not([opened]){
			width: 49px;
		}

		.navigation-sections[opened]{
			width: 239px;
		}
        
        .nav-block-item{
			display:flex;
            margin-bottom: 8px;
			position:relative;
        }

		.nav-block-icon{
			height: 32px;
            min-width: 50px;
            max-width: 50px;
            padding: 0px 10px;
            box-sizing: border-box;
            position:relative;
		}
       
		.sidebar-content{
			background: white;
			width: calc(240px - 20px);
		}

		.navigation-sections:not([opened]) .sidebar-content{
			position: absolute;
			top: 0px;
			left: 100%;
			display:none;
			padding:0 8px;
		}

		.navigation-sections:not([opened]) .nav-block-item:hover .nav-block-icon{
			border: 1px solid;
			border-bottom-left-radius: 4px;
			border-top-left-radius: 4px;
			border-right-width: 0px;
		}

		.navigation-sections:not([opened]) .nav-block-item:hover .nav-block-icon::after{
			content:"";
			position:absolute;
			width: 2px;
			height: 100%;
			background: white;
			right: -1px;
			z-index: 102;
		}

		.navigation-sections:not([opened]) .nav-block-item:hover .sidebar-content{
			display:block;
			z-index:100;
			border: 1px solid var(--primary-grey-color);
			border-top-right-radius:4px;
			border-bottom-right-radius:4px;
			border-bottom-left-radius:4px;
			box-shadow: 4px 4px 4px 0px rgb(1 41 112 / 10%);
		}

		.navigation-sections:not([opened]) .nav-block-item:hover .sidebar-content .toggle-icon{
			display:none;
		}

		.navigation-sections:not([opened]) .nav-block-item:hover .sidebar-content .nav-link-list{
			height:auto !important;
		}

        .nav-block-name{
			display: block;
			font-size: 14px;
			height: 30px;
			padding-right:8px;
			cursor: pointer;
        }

		.nav-block-name > label{
			display:block;
			white-space: nowrap;
		}

		.nav-block-name:hover > label{
			font-weight:bold;
		}

		.nav-block-item[open] .nav-block-name > label{
			color:var(--primary-dark-color);
		}


        .nav-link-list{
			height:0px;
			overflow: hidden;
			/* border-top: 1px solid var(--primary-grey-color); */
        }

		.nav-block-item[open] .nav-link-list{
			height:auto;
		}

		.nav-link-item{
			margin-bottom: 8px;
			font-size: 12px;
			cursor : pointer;
		}

		.nav-link-item.selected-view{
			font-weight:bold;
		}

        .nav-link-item > *{
            cursor : pointer;
        }

		#container{
			position:relative;
		}

		#menu-toggle{
			position:absolute;
			bottom:100%;
			padding: 8px;
			margin: 0px;
			border:1px solid var(--primary-grey-color);
			border-bottom: none;
			border-radius: 4px;
			min-width:50px;
			height:40px;
			border-bottom-right-radius: 0px;
    		border-bottom-left-radius: 0px;
		}

		#menu-toggle[opened]{
			border-bottom-right-radius: 0px;
    		border-bottom-left-radius: 0px;
			width: 50px;
		}

    </style>
    <div id="container">
		<paper-button id="menu-toggle" on-tap="_toggleSidebar" opened$=[[sidebarOpened]]>
			<iron-icon icon="[[_getSidebarIcon(sidebarOpened)]]"></iron-icon>
			<!-- <iron-icon hidden=[[!sidebarOpened]] icon="chevron-left"></iron-icon> -->
			<!-- <label hidden=[[!sidebarOpened]]>Close sidebar</label> -->
		</paper-button>
		<ul class="navigation-sections" opened$=[[sidebarOpened]]>
			<dom-repeat items=[[_navigations]] as="navBlock">
				<template>
					<li class="nav-block-item" open$=[[navBlock.showList]]>
						<iron-icon class="nav-block-icon" icon=[[navBlock.icon]]></iron-icon>
						<div class="sidebar-content">
							<div class="layout horizontal center nav-block-name" on-tap="_toggleShowList">
								<label class="flex"><oe-i18n-msg msgid=[[navBlock.groupName]]></oe-i18n-msg></label>
								<iron-icon class="toggle-icon" icon=[[_getCollapseIcon(navBlock.showList)]]></iron-icon>
							</div>
							<ul class="nav-link-list">
								<dom-repeat items=[[navBlock.links]] as="link">
									<template>
										<li class$="nav-link-item [[__isSelectedView(link.path,selectedView)]]" path$="[[link.path]]" on-tap="_selectView">
											<label><oe-i18n-msg msgid=[[link.label]]></oe-i18n-msg></label>
										</li>
									</template>
								</dom-repeat>
							</ul>
						</div>
					</li>
				</template>
			</dom-repeat>
		</ul>
    </div>
    `;
	}

	static get properties() {
		return {
			selectedView: {
				type: String,
				notify: true,
				observer: "_updateUrl"
			},
			sidebarOpened: {
				type: Boolean,
				reflectToAttribute: true,
				value: false
			},
			_navigations: {
				type: Array,
				statePath: "validNavLinks"
			},
			validViews: {
				type: Array,
				statePath: "validPaths"
			}
		};
	}

	__isSelectedView(view, selectedView) {
		return view === selectedView ? "selected-view" : "";
	}

	_selectView(event) {
		let link = event.model.link;
		this.set("selectedView", link.path);
		document.scrollingElement.scrollIntoView({behavior: "smooth"});
	}

	_updateUrl() {
		if (this.selectedView) {
			let currentPath = location.pathname;
			let newPath = window.OEUtils.subPath + `/user-profile/${this.selectedView}`;
			if (currentPath !== newPath) {
				window.history.pushState(this.selectedView, "", newPath);
			}
		}
	}

	_getCollapseIcon(showList) {
		return showList ? "expand-less" : "expand-more";
	}

	_toggleShowList(event) {
		if (!this.sidebarOpened) {
			return;
		}
		let modelIdx = event.model.index;
		this.set(`_navigations.${modelIdx}.showList`, !this._navigations[modelIdx].showList);
	}

	constructor() {
		super();
		window.addEventListener("update-profile-view", (event) => {
			let view = event.detail;
			this.set("selectedView", view);
			document.scrollingElement.scrollIntoView({behavior: "smooth"});
		});
	}

	_getSidebarIcon(sidebarOpened) {
		return sidebarOpened ? "chevron-left" : "menu";
	}

	_toggleSidebar() {
		this.set("sidebarOpened", !this.sidebarOpened);
	}
}
window.customElements.define(ProfileSidebar.is, ProfileSidebar);
