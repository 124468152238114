import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import ProfileBaseView from "./profile-base-view.js";
import "@polymer/iron-image/iron-image.js";
import "oe-ui-forms/validators/oe-block-validator.js";
import "oe-radio-group/oe-radio-group.js";
import "@polymer/paper-checkbox/paper-checkbox.js";
import "oe-data-grid/oe-data-grid.js";
import { OECommonMixin } from "oe-mixins/oe-common-mixin.js";
import "../tpp-dialog.js";
/**
 * ### my-profile-view
 * `my-profile-view`
 *
 *
 * @customElement
 * @polymer
 */
class SetupTeamView extends ProfileBaseView {
	static get is() {
		return "setup-team-view";
	}

	static get stylesTemplate() {
		return html`
			${super.stylesTemplate}
			<style>
				#container{
					padding-right:4px;
				}

				.user-info-banner{
					height : 230px;
					font-size : 16px;
				}

				.user-info-banner > label{
					display : block;
				}

				.user-name{
					font-size : 24px;
				}

				.update-profile-link{
					margin : 32px 0px;
				}

				.col-2{
					display:flex;
					flex-direction:horizontal;
					justify-content:space-between;
				}

				.col-2 > * {
					width : calc(50% - 24px);
				}

				.action-button{
					margin-top:16px;
					margin-bottom:8px;
				}

				.team-row{
					height : 40px;
					border:1px solid var(--primary-grey-color);
					margin-bottom:8px;
					color:#212529;
					font-size: 14px;
					@apply --layout-horizontal;
				}

				.team-row > div{
					padding:4px 8px;
					display: flex;
					align-items: center;
					overflow : hidden;
					text-overflow : ellipsis;
					flex:1;
					border-left:1px solid var(--primary-grey-color);
				}

				.team-row.header > div{
					text-align : center;
				}

				.team-row > div.selection-column{
					max-width: 38px;
					border-left-width: 0px;
					padding: 0px 10px;
					box-sizing: border-box;
				}

				div.action-column{
					justify-content: center;
					max-width: 200px;
				}

				.hover-row.team-row:not(.header):hover{
					background : rgba(0, 0, 0, 0.075);
				}

				.invite-row{
					height : 70px;
				}

				.invite-row oe-radio-group{
					margin-top: -20px;
				}

				.id-column tpp-input{
					width: 100%;
				}

				.team-section{
					padding:2px;
				}

				.important-msg{
					font-weight:bold;
				}

				.add-user-section{
					margin: 8px 0px;
				}

				.handover-body{
					padding: 8px 0px 20px 0px;
				}

				oe-data-grid{
					margin-top:16px;
				}
				
				tpp-button + tpp-button{
					margin-left:16px;
				}
			</style>
		`;
	}

	static get contentTemplate() {
		return html`
			<oe-data-grid 
				on-oe-data-table-row-action="_handleTeamAction" 
				disable-selection disable-config-editor disable-full-screen-mode disable-add
				id="teamTable"
				label="Team members" items={{teamMembers}} 
				on-oe-data-table-action-refresh="__getMyOrgMemebers"
				on-oe-data-table-action-handover="_initHandover"
				columns=[[teamConfig.columns]] default-record=[[teamConfig.default]]
				row-actions=[[teamConfig.rowActions]] toolbar-actions=[[teamConfig.toolbarActions]]></oe-data-grid>

			<oe-data-grid 
				disable-selection disable-config-editor disable-full-screen-mode
				editor-form-element="add-invite-form" record-handling="local"
				id="invitesTable"
				label="Invites sent" items={{invites}} 
				on-send-invite="__inviteUser"
				on-oe-data-table-row-action-revoke="_revokeInvite"
				on-oe-data-table-action-refresh="__getMyOrgInvites"
				columns=[[inviteConfig.columns]] default-record=[[inviteConfig.default]]
				row-actions=[[inviteConfig.rowActions]] toolbar-actions=[[inviteConfig.toolbarActions]]></oe-data-grid>

			<tpp-dialog id="handoverDlg" modal>
                <label class="group-name" slot="header">
					<oe-i18n-msg msgid="Handover organization to a member">Handover organization to a member</oe-i18n-msg>	
				</label>
                <div class="handover-body" slot="body">
                    <tpp-dropdown always-float-label label="Email ID" value="{{handover.email}}" list=[[handoverCandidates]]></tpp-dropdown>
                    <p class="important-msg"><oe-i18n-msg msgid="setup_team_handover_desc_1">By handing over the role, you will be logged out and your role within organization will be updated to viewer.</oe-i18n-msg> </p>
                    <p><oe-i18n-msg msgid="setup_team_handover_desc_2">You will loose any access to application you created, until you are given developer role by admin.</oe-i18n-msg>  </p>
                    <paper-checkbox id="handover-checkbox" checked={{handover.agree}}>
						<oe-i18n-msg msgid="setup_team_handover_checkbox_label">I confirm to handover the organization administrator role to selected member.</oe-i18n-msg> 	
					</paper-checkbox>
                </div>
                <tpp-button animate-to-primary primary mini slot="footer" disabled=[[!handover.agree]] on-tap="_handoverOrganization" message="Handover"></tpp-button>
                <tpp-button mini slot="footer" message="Cancel" on-tap="_closeHandoverDialog"></tpp-button>
            </tpp-dialog>
            <tpp-dialog id="reassignDlg" modal>
                <label class="group-name" slot="header"><oe-i18n-msg msgid="Update role of user">Update role of user</oe-i18n-msg></label>
                <div class="handover-body" slot="body">
                    <tpp-input readonly label="Email ID" value="{{reassign.email}}"></tpp-input>
                    <oe-radio-group displayproperty="d" valueproperty="v" label="Role" value={{reassign.roleId}} listdata=[[roleChoices]]></oe-radio-group>
                </div>
                <tpp-button primary mini slot="footer" on-tap="_reassignRole" message="ReAssign"></tpp-button>
                <tpp-button mini slot="footer" message="Cancel" on-tap="_closeReassignDialog"></tpp-button>
            </tpp-dialog>
		`;
	}

	constructor() {
		super();
		this.set("teamMembers", []);
		this.set("viewTitle", "Manage Your Team");
		this.set("teamConfig", {
			columns: [
				{
					key: "name",
					valueGetter: row => `${row.firstName} ${row.lastName}`,
					label: "Name"
				},
				{
					key: "email",
					label: "Email ID"
				},
				{
					key: "_role",
					label: "Role",
					valueGetter: row => {
						switch (row.rolemapping.role.name) {
						case "viewer": return "Viewer";
						case "developer": return "API Developer";
						case "organization-admin" : return "Organization Admin";
						case "admin" : return "Admin";
						default:
							break;
						}
					}
				}
			],
			rowActions: [
				{
					action: "remove",
					title: "Remove User",
					icon: "clear",
					isHiddenFunction: row => {
						return row.rolemapping.roleId === "organization-admin";
					}
				},
				{
					action: "change",
					title: "Change Role",
					icon: "assignment-ind",
					isHiddenFunction: row => {
						return row.rolemapping.roleId === "organization-admin";
					}
				}
			],
			toolbarActions: [
				{
					action: "handover",
					title: "Handover Organization",
					icon: "supervisor-account",
					isButton: true
				},
				{
					action: "refresh",
					title: "Refresh Team list",
					icon: "refresh",
					isButton: true
				}
			]
		});
		this.set("invites", []);
		this.set("inviteConfig", {
			columns: [
				{
					key: "email",
					label: "Recepient Email"
				},
				{
					key: "roleId",
					label: "Role",
					formatter: val => val === "developer" ? "API Developer" : "Viewer"
				},
				{
					key: "userRegistered",
					label: "Registered",
					type: "boolean",
					formatter: val => val ? "True" : "False"
				},
				{
					key: "status",
					label: "Status"
				}
			],
			rowActions: [
				{
					action: "revoke",
					title: "Revoke",
					icon: "clear",
					isHiddenFunction: row => {
						return row.status !== "pending";
					}
				}
			],
			toolbarActions: [
				{
					action: "refresh",
					title: "Refresh Invites",
					icon: "refresh",
					isButton: true
				}
			],
			default: {
				email: "",
				roleId: "viewer"
			}
		});
		this.set("roleChoices", [
			{  d: "Viewer", v: "viewer"},
			{  d: "API Developer", v: "developer"}
		]);
	}

	static get observers() {
		return [
			"__getMyOrgInvites(organization)",
			"__getMyOrgMemebers(organization)"
		];
	}

	__getMyOrgMemebers() {
		if (!this.organization) {
			return null;
		}
		let filter = {
			include: ["application", {"rolemapping": "role"}]
		};
		this.makeAjaxCall(`@restApiRoot/Organizations/${this.organization.id}/user`, "get", null, null, {
			filter
		}, null, (err, resp)=>{
			if (err) {
				this.fire("oe-show-error", window.devportalError(err));
				return;
			}
			this.set("teamMembers", resp);
		});
	}

	__getMyOrgInvites() {
		if (!this.organization) {
			return null;
		}

		let ajaxfilter = {
			filter: {
				where: {
					organizationId: this.organization.id
				}
			}
		};

		this.makeAjaxCall("@restApiRoot/Invites/", "get", null, null, ajaxfilter, null, (err, resp)=>{
			if (err) {
				this.fire("oe-show-error", window.devportalError(err));
				return;
			}
			this.set("invites", resp);
		});
	}

	_revokeInvite(event) {
		let {
			row
		} = event.detail;

		this.makeAjaxCall(`@restApiRoot/Organizations/${this.organization.id}/invite`, "delete", row.id, null, null, null, (err, resp)=>{
			if (err) {
				this.fire("oe-show-error", window.devportalError(err));
				return;
			}
			this.fire("oe-show-success", "Invite revoked successfully.");
			this.__getMyOrgInvites();
		});
	}

	__inviteUser(event) {
		let newInvites = event.detail;

		this.makeAjaxCall(`@restApiRoot/Organizations/${this.organization.id}/invite`, "post", newInvites, null, null, null, (err, resp)=>{
			if (err) {
				this.fire("oe-show-error", window.devportalError(err));
				return;
			}
			let msg = "User invited successfully.";
			if (resp.result === "resend") {
				msg = "Invite resend successfully.";
			} else if (resp.result === "update") {
				msg = "Invite with updated role sent successfully.";
			} else {
				// do nothing
			}

			this.fire("oe-show-success", msg);
			this.$.invitesTable._showGridView();
			this.__getMyOrgInvites();
		});
	}

	_handleTeamAction(event) {
		// roles and remove user
		let {
			row,
			action
		} = event.detail;

		if (action.action === "change") {
			this._initReassign(row);
		} else {
			let applen = row.application.length;
			if (applen !== 0) {
				this.fire("oe-show-error", `The selected user has ${applen} application(s) and cannot be removed from the orgaization.`);
				return;
			}
			this.fire("oe-show-confirm", {
				message: "Confirm removing member from organization ?",
				okLabel: "confirm",
				ok: () => {
					this._removeOrganizationUser(row);
				}
			});
		}
	}

	_removeOrganizationUser(user) {
		let payload = {
			email: user.email
		};

		this.makeAjaxCall(`@restApiRoot/Organizations/${this.organization.id}/removeUser`, "post", payload, null, null, null, (err, cb)=>{
			if (err) {
				this.fire("oe-show-error", window.devportalError(err));
				return;
			}
			this.fire("oe-show-success", "User removed from organization successfully.");
			this.__getMyOrgMemebers();
		});
	}

	/**
     * Re assign role logics
     */

	/* Start handover dialog */
	_initReassign(user) {
		this.setProperties({
			reassign: {
				email: user.email,
				roleId: user.rolemapping.roleId
			}
		});
		this.$.reassignDlg.open();
	}

	_reassignRole() {
		let payload = {
			email: this.reassign.email,
			roleId: this.reassign.roleId
		};

		this.makeAjaxCall(`@restApiRoot/Organizations/${this.organization.id}/changeUserRole`, "post", payload, null, null, null, (err, cb)=>{
			if (err) {
				this.fire("oe-show-error", window.devportalError(err));
				return;
			}
			this.fire("oe-show-success", "Role Reassigned successfully.");
			this.__getMyOrgMemebers();
			this._closeReassignDialog();
		});
	}

	_closeReassignDialog() {
		this.$.reassignDlg.close();
	}

	/**
     * Hand over organization logics
     */

	/** Start handover dialog */
	_initHandover() {
		this.setProperties({
			handover: {
				email: "",
				agree: false
			},
			handoverCandidates: this.teamMembers.filter(member => {
				return member.email !== this.userInfo.email;
			}).map(member => member.email)
		});
		this.$.handoverDlg.open();
	}

	_handoverOrganization() {
		if (!this.handover.email) {
			this.fire("oe-show-error", "Handover Email is mandatory.");
			return;
		}

		this.makeAjaxCall(`@restApiRoot/Organizations/${this.organization.id}/handover`, "post", {
			email: this.handover.email
		}, null, null, null, (err, cb)=>{
			if (err) {
				this.fire("oe-show-error", window.devportalError(err));
				return;
			}
			// this.fire("oe-show-success", "Organization handover initiated successfully.");
			this.fire("logout-user");
			this._closeHandoverDialog();
		});
	}

	_closeHandoverDialog() {
		this.$.handoverDlg.close();
	}
}
window.customElements.define(SetupTeamView.is, SetupTeamView);


window.customElements.define("add-invite-form", class extends OECommonMixin(PolymerElement) {
	static get template() {
		return html`
        <style include="app-theme">
            :host{
                padding:16px;
                display:block;
            }
            fieldset{
                padding:16px;
            }

            .field-label{
                display:block;
                margin:8px 0px;
            }

            oe-radio-group{
                margin:8px 0px;
                --paper-input-container-label-floating:{
                    transform:translateY(-85%) scale(1);
                    color: initial;
                };
                
            }
        </style>
        <fieldset>
            <legend>
				<oe-i18n-msg msgid="Add New Invite">Add New Invite</oe-i18n-msg>
			</legend>
			<label class="field-label"><oe-i18n-msg msgid="Name">Name</oe-i18n-msg></label>
            <tpp-input id="nameInput" required type="text" label="Name" value="{{vm.name}}"></tpp-input>
            <label class="field-label"><oe-i18n-msg msgid="Email">Email</oe-i18n-msg></label>
            <tpp-input id="emailInput" required type="email" label="Email ID" value="{{vm.email}}"></tpp-input>
            <oe-radio-group label="Role" listdata=[[roleChoices]] value={{vm.roleId}} displayproperty="d" valueproperty="v" ></oe-radio-group>
            <tpp-button message="Send Invite" mini primary on-tap="_sendInvite"></tpp-button>
        </fieldset>
        `;
	}


	constructor() {
		super();
		this.set("roleChoices", [
			{  d: "Viewer", v: "viewer"},
			{  d: "API Developer", v: "developer"}
		]);
	}

	_sendInvite() {
		if (!this.$.emailInput.$.input.validate() || !this.vm.roleId || !this.$.nameInput.$.input.validate()) {
			return;
		}

		this.fire("send-invite", [this.vm]);
	}
});
