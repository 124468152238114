import AdminApproveBase from "./admin-approve-base.js";
import "./manage-app-view/manage-application.js";
import { html } from "@polymer/polymer/polymer-element.js";

class AdminApproveApp extends AdminApproveBase {
	static get is() {
		return "admin-approve-app";
	}

	static get listTemplate() {
		return html`
            <oe-data-grid label="Pending App Subscription requests" id="grid" items=[[tasksGrid.items]] disable-selection
                disable-config-editor disable-full-screen-mode disable-add on-oe-data-table-row-action="__handleTaskAction"
                on-oe-data-table-action-refresh="__fetchList" toolbar-actions=[[_gridActions]]
                columns=[[_gridColumnConverter(tasksGrid.columns)]] row-actions=[[tasksGrid.rowActions]]></oe-data-grid>
            
            <oe-data-grid label="Applications list" id="list-grid" items={{grid.items}} disable-selection disable-config-editor
                disable-full-screen-mode disable-add on-oe-data-table-row-action="__handleRowAction"
                on-oe-data-table-action-refresh="__fetchList" toolbar-actions=[[_gridActions]]
                columns=[[_gridColumnConverter(grid.columns)]] row-actions=[[grid.rowActions]]></oe-data-grid>
        `;
	}

	static get itemTemplate() {
		return html`
        <manage-application on-show-list-view="_showListView" read-only-mode application-id=[[selectedApp.id]]></manage-application>
        `;
	}

	constructor() {
		super();
		this.setProperties({
			ui: {
				heading: "Approve App subscription requests",
				url: "@restApiRoot/Applications",
				filter: {"include": ["appUser", "organization" ]}
			},
			grid: {
				columns: [
					{ key: "name", label: "App Name"},
					{ key: "_orgName", label: "Organization", valueGetter: r => r.organization.name},
					{ key: "_name", label: "Owner Name", valueGetter: r => `${r.appUser.firstName} ${r.appUser.lastName}` },
					{ key: "_owner", label: "Owner Email", valueGetter: r => r.appUser.email }
				],
				rowActions: [
					{
						action: "ownership",
						title: "Assign to orgadmin",
						icon: "face",
						isHiddenFunction: app => app.appUser.id === app.organization.userId
					},
					{ action: "view", title: "View", icon: "visibility" }
				],
				items: []
			},
			tasksGrid: {
				columns: [
					{ key: "app.name", label: "App Name"},
					{ key: "_orgName", label: "Organization", valueGetter: r => r.app.organization.name},
					{ key: "_name", label: "Owner Name", valueGetter: r => `${r.app.appUser.firstName} ${r.app.appUser.lastName}` },
					{ key: "_owner", label: "Owner Email", valueGetter: r => r.app.appUser.email }
				],
				rowActions: [
					{ action: "view", title: "View", icon: "visibility" },
					{ action: "approve", title: "Approve", icon: "done"},
					{ action: "revoke", title: "Revoke", icon: "close"}
				],
				items: []
			}
		});
		this.__fetchList();
	}

	_changeAppOwnership(app) {
		this.__confirm("Confirm re-assigning the application to its organization admin ?", async () => {
			let payload = {
				newOwnerId: app.organization.userId
			};
			let [err] = await this._makeAjaxPromise(`@restApiRoot/Applications/${app.id}/updateOwnership`, "post", payload);
			if (err) {
				this.fire("oe-show-error", window.devportalError(err));
				return;
			}
			this.fire("oe-show-success", "Ownership has been updated");
			await super.__fetchList();
		}, "Re-Assign");
	}

	async __fetchList() {
		await super.__fetchList();
		let subsTaskParams = {
			filter: {
				where: {
					name: "subscription-approval",
					status: "pending"
				}
			}
		};

		let [err, tasks] = await this._makeAjaxPromise("@restApiRoot/Tasks", "GET", null, null, subsTaskParams);

		if (err) {
			this.fire("oe-show-error", window.devportalError(err));
			return;
		}

		let applications = this.grid.items;
		let taskItems = [];
		tasks.forEach(task => {
			let orgId = task.formVariables._modelInstance[0].id;
			taskItems.push({
				taskId: task.id,
				app: applications.find(org => org.id === orgId)
			});
		});
		this.set("tasksGrid.items", taskItems);
	}

	__handleRowAction(event) {
		let {
			action,
			row
		} = event.detail;
		switch (action.action) {
		case "view":
			this.set("selectedApp", row);
			this.set("selectedView", "item");
			break;
		case "ownership" :
			this._changeAppOwnership(row);
			break;
		default: break;
		}
	}

	__handleTaskAction(event) {
		let {
			action,
			row
		} = event.detail;
		switch (action.action) {
		case "view":
			this.set("selectedApp", row.app);
			this.set("selectedView", "item");
			break;
		case "approve":
			this.__confirm("Confirm approval of the application subscription ?", () => {
				this.__initAccept(row);
			}, "Approve");
			break;
		case "revoke":
			this.__initReject(row);
			break;
		default: break;
		}
	}

	__initDelete(row) {
		this.makeAjaxCall(`@restApiRoot/Applications/${row.id}`, "DELETE", null, null, null, null, (err, resp) => {
			if (err) {
				this.fire("oe-show-error", window.devportalError(err));
				return;
			}
			this.fire("oe-show-success", "Organization deleted successfully.");
			this.__fetchList();
		});
	}

	__initAccept(task) {
		let url = `@restApiRoot/Tasks/${task.taskId}/complete`;
		let payload = {
			"__action__": "approved"
		};

		this.makeAjaxCall(url, "PUT", payload, null, null, null, (err, resp) => {
			if (err) {
				this.fire("oe-show-error", window.devportalError(err));
				return;
			}
			this.fire("oe-show-success", "Application subscription approved successfully.");
			this.__fetchList();
		});
	}

	__confirmReject() {
		if (this.rejectionComments && this.rejectionComments.length > 4) {
			let task = this._selectedTask;

			let url = `@restApiRoot/Tasks/${task.taskId}/complete`;
			let payload = {
				"__action__": "rejected",
				"pv": {
					"id": task.app.id,
					"comment": this.rejectionComments
				}
			};

			this.makeAjaxCall(url, "PUT", payload, null, null, null, (err, resp) => {
				if (err) {
					this.fire("oe-show-error", window.devportalError(err));
					return;
				}
				this.fire("oe-show-success", "Application subscriptions rejected successfully.");
				this.__fetchList();
				this.$.rejectDialog.close();
			});
		} else {
			this.fire("oe-show-error", "Rejection comments must be minimum 4 letters.");
		}
	}
}

window.customElements.define(AdminApproveApp.is, AdminApproveApp);
