import { dedupingMixin } from "@polymer/polymer/lib/utils/mixin.js";
import {OECommonMixin} from "oe-mixins/oe-common-mixin";

export const AnimateMixin = dedupingMixin( BaseClass => class extends OECommonMixin(BaseClass) {
	constructor() {
		super();
		this.__scrollListener = this._scrollListener.bind(this);
	}

	_scrollListener() {
		this.debounce("animate-on-scroll", ()=>{
			let remaining = [];
			this.__animatables.forEach(el => {
				if (this.__isElementVisible(el)) {
					el.classList.add("oe-animated");
				} else {
					remaining.push(el);
				}
			});

			this.__animatables = remaining;
			if (this.__animatables.length === 0) {
				window.removeEventListener("scroll", this.__scrollListener);
			}
		}, 100);
	}

	__isElementVisible(el) {
		// https://stackoverflow.com/questions/487073/how-to-check-if-element-is-visible-after-scrolling

		var rect = el.getBoundingClientRect();
		var elemTop = rect.top;
		var isVisible = (elemTop <= window.innerHeight);
		return isVisible;
	}

	connectedCallback() {
		super.connectedCallback();
		this.__animatables = Array.from(this.shadowRoot.querySelectorAll("[oe-animate]"));
		if (this.__animatables.length !== 0) {
			window.addEventListener("scroll", this.__scrollListener);
		}
		this._scrollListener();
	}

	disconnectedCallback() {
		super.disconnectedCallback();
		window.removeEventListener("scroll", this.__scrollListener);
	}
});
