import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import "@polymer/iron-flex-layout/iron-flex-layout.js";
import "@polymer/iron-flex-layout/iron-flex-layout-classes.js";
import { OECommonMixin } from "oe-mixins/oe-common-mixin.js";

/**
 * ### rating-view
 * `rating-view`
 *
 *
 * @customElement
 * @polymer
 */
class RatingView extends OECommonMixin(PolymerElement) {
	static get is() {
		return "rating-view";
	}

	static get template() {
		return html `
			<style include="iron-flex iron-flex-alignment">

					iron-icon{
						--iron-icon-width:var(--rating-icon-size,20px);
						--iron-icon-height:var(--rating-icon-size,20px);
					}

					.empty-star{
						color:var(--secondary-grey-color);
					}

					.filled-star{
						color:var(--secondary-dark-color);
					}
			</style>
			<div id="container">
				<dom-repeat items=[[iconList]]>
					<template>
						<iron-icon icon=[[item.icon]] class$="[[item.class]]"></iron-icon>
					</template>
				</dom-repeat>
			</div>
    	`;
	}

	static get properties() {
		return {
			score: {
				type: Number,
				value: 0,
				observer: "_updateVisual"
			},
			maxScore: {
				type: Number,
				value: 5,
				observer: "_updateVisual"
			}
		};
	}

	_updateVisual() {
		let icons = new Array(this.maxScore).fill(1).map((item, index) => {
			let scoreVal = index + 1;
			let scoreIcon = "star";
			let scoreClass = "empty-star";

			if (scoreVal <= this.score) {
				scoreIcon = "star";
				scoreClass = "filled-star";
			} else if (this.score < scoreVal && this.score > (scoreVal - 1)) {
				scoreIcon = "star-half";
				scoreClass = "half-star";
			}

			return {
				icon: scoreIcon,
				class: scoreClass
			};
		});

		this.set("iconList", icons);
	}
}
window.customElements.define(RatingView.is, RatingView);
