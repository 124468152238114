import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import { OECommonMixin } from "oe-mixins/oe-common-mixin";

class ShuttleList extends OECommonMixin(PolymerElement) {
	static get is() {
		return "shuttle-list";
	}

	static get template() {
		return html`
        <style include="iron-flex iron-flex-alignment">
            :host{
                display:block;
                --content-height : 200px;
            }

            #container{
                display:grid;
                grid-template-columns: 1fr 80px 1fr;
                box-sizing: border-box;
            }

            .section-panel{
                height: var(--content-height);
                position:relative;
                padding-top: 48px;
                padding-bottom: 8px;
            }

            .search-section{
                position : absolute;
                top:0px;
                width: 100%;
            }

            .section-panel > paper-listbox{
                height: 100%;
                overflow: auto;
                padding: 0px;
                display: flex;
                flex-direction: column;
                gap: 2px;
                background: transparent;
                scrollbar-gutter: stable;
                width: 320px;
            }

            .section-panel paper-item {
                --paper-item-min-height : 30px;
                height: 30px;
                background : white;
                width: 250px;
                white-space: nowrap;
                word-break: break-all;
                overflow: hidden;
                text-overflow: ellipsis;
                padding: 0 8px;
                width: 300px;
                box-sizing: border-box;
                outline: 1px solid var(--divider-color);
                outline-offset: -1px;
                --paper-item-focused-before:{
                    display:none;
                }
            }

            .section-panel paper-item.iron-selected{
                font-weight: initial;
                outline: 2px solid var(--tpp-input-focused-border);
                outline-offset: -2px;
            }

            .actions-panel{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap:8px;
            }

            .actions-panel > paper-button{
                min-width: unset;
                margin: 0px;
                height: 40px;
                width: 40px;
                border: 1px solid;
                background: white;
                padding: 0;
            }
        </style>
        <div id="container">
            <div class="section-panel not-selected-panel">
                <div class="search-section">
                    <tpp-input no-label-float skip-defaults label="Search unselected items" value={{searchKeys.unselected}}></tpp-input>
                </div>
                <paper-listbox multi id="unselectedList" attr-for-selected="key">
                    <dom-repeat items=[[unselected]] filter=[[_getFiltered(searchKeys.unselected)]]>
                        <template>
                            <paper-item item=[[item]] key$="[[_getKey(item)]]" data-target="unselectedList" on-tap="_toggleListItem">[[_getDisplay(item)]]</paper-item>
                        </template>
                    </dom-repeat>
                </paper-listbox>
            </div>
            <div class="actions-panel">
                <paper-button on-tap="_add" disabled=[[disableActions.add]]> &gt </paper-button>
                <paper-button on-tap="_addAll" disabled=[[disableActions.addAll]]> &gt;&gt </paper-button>
                <paper-button on-tap="_remove" disabled=[[disableActions.remove]]> &lt </paper-button>
                <paper-button on-tap="_removeAll" disabled=[[disableActions.removeAll]]> &lt;&lt </paper-button>
            </div>
            <div class="section-panel selected-panel">
                <div class="search-section">
                    <tpp-input no-label-float skip-defaults label="Search selected items"  value={{searchKeys.selected}}></tpp-input>
                </div>
                <paper-listbox multi id="selectedList" attr-for-selected="key">
                    <dom-repeat items=[[selected]] filter=[[_getFiltered(searchKeys.selected)]]>
                        <template>
                            <paper-item item=[[item]] key$="[[_getKey(item)]]" data-target="selectedList" on-tap="_toggleListItem">[[_getDisplay(item)]]</paper-item>
                        </template>
                    </dom-repeat>
                </paper-listbox>
            </div>
        </div>
        `;
	}

	static get properties() {
		return {
			listdata: {
				type: Array,
				value: () => []
			},
			selected: {
				type: Array,
				value: () => []
			},
			unselected: {
				type: Array,
				computed: "__getUnselected(listdata.length,selected.length)",
				observer: "_updateUI"
			},
			displayproperty: String,
			keyproperty: String,
			searchKeys: {
				type: Object,
				value: () => ({
					unselected: "",
					selected: ""
				})
			}
		};
	}

	_getDisplay(item) {
		return (typeof item === "string" || !this.displayproperty) ? item : this._deepValue(item, this.displayproperty);
	}

	_getKey(item) {
		return (typeof item === "string" || !this.keyproperty) ? item : this._deepValue(item, this.keyproperty);
	}

	_toggleListItem(event) {
		let listname = event.target.dataset.target;
		let otherList = listname === "unselectedList" ? this.$.selectedList : this.$.unselectedList;
		if (otherList.selectedValues.length !== 0) {
			otherList._selectSelected([]);
			otherList.selectedValues = [];
		}
		this.async(()=>{
			this._updateUI();
		}, 10);
	}

	_updateUI() {
		this.set("disableActions", {
			add: this.$.unselectedList.selectedValues.length === 0,
			addAll: this.unselected.length === 0,
			remove: this.$.selectedList.selectedValues.length === 0,
			removeAll: this.selected.length === 0
		});
	}

	__getUnselected() {
		return this.listdata.filter(i => !this.selected.includes(i));
	}

	_getFiltered(searchKey) {
		if (!searchKey) {
			return null;
		}
		let key = searchKey.trim().toLowerCase();
		return item => {
			let disp = this._getDisplay(item);
			return disp.toLowerCase().indexOf(key) !== -1;
		};
	}

	_add() {
		let items = this.$.unselectedList.selectedValues.map(k => this.unselected.find(i => this._getKey(i) === k));
		this.push("selected", ...items);
		this._resetSelections();
	}

	_addAll() {
		this.push("selected", ...this.unselected);
		// this.set('selected',[...this.listdata]);
		this._resetSelections();
	}

	_remove() {
		this.$.selectedList.selectedValues.forEach(k => {
			let idx = this.selected.findIndex(i => this._getKey(i) === k);
			this.splice("selected", idx, 1);
		});
		this._resetSelections();
	}

	_removeAll() {
		this.splice("selected", 0, this.selected.length);
		this._resetSelections();
	}

	_resetSelections() {
		this.$.unselectedList._selectSelected([]);
		this.$.unselectedList.selectedValues = [];
		this.$.selectedList._selectSelected([]);
		this.$.selectedList.selectedValues = [];
		this._updateUI();
	}
}

window.customElements.define(ShuttleList.is, ShuttleList);
