import { dedupingMixin } from "@polymer/polymer/lib/utils/mixin.js";
import { OEAjaxMixin } from "oe-mixins/oe-ajax-mixin.js";

export const PromiseMixin = dedupingMixin( BaseClass => class extends OEAjaxMixin(BaseClass) {
	/**
	 * Promise version of oe-ajax call
	 * @param {string} url URL to make the call
	 * @param {string} method HTTP method
	 * @param {object} body payload
	 * @param {object} header header
	 * @param {object} params parameters to ajax call
	 * @returns {Promise} Promise that resolves to [error , response ]
	 */
	_makeAjaxPromise(url, method = "get", body, header = null, params = null ) {
		return new Promise((resolve, reject) => {
			this.makeAjaxCall(url, method, body, header, params, null, (err, resp) => {
				if (err) {
					resolve([err, null]);
					return;
				}
				resolve([null, resp]);
			});
		});
	}
});
