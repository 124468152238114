import {IronOverlayManager} from "@polymer/iron-overlay-behavior/iron-overlay-manager";

((manager) => {
	let resetBackdrop = () => {
		let overlayForEl = manager._overlays[manager._overlays.length - 1];
		let backdropEl = manager._backdropElement;
		if (!overlayForEl) {
			return;
		}
		let isDialog = overlayForEl.tagName.toLowerCase() === "paper-dialog";
		if (backdropEl && isDialog) {
			overlayForEl.insertAdjacentElement("afterend", backdropEl );
		}
	};

	window.addEventListener("iron-overlay-opened", resetBackdrop);
	window.addEventListener("iron-overlay-closed", resetBackdrop);
})(IronOverlayManager);
