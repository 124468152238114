import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import "@polymer/iron-flex-layout/iron-flex-layout.js";
import "@polymer/iron-flex-layout/iron-flex-layout-classes.js";
import { OECommonMixin } from "oe-mixins/oe-common-mixin.js";

class LoadingSpinner extends OECommonMixin(PolymerElement) {
	static get is() {
		return "loading-spinner";
	}

	static get template() {
		return html`
    <style include="iron-flex iron-flex-alignment">
        .backdrop{
            display:none;
        }

        .backdrop[show]{
            display:flex;
            position:fixed;
            top:0;
            left:0;
            z-index:999;
            height:100vh;
            width:100vw;
            background:rgba(0,0,0,0.2);
            align-items:center;
            justify-content:center;
        }

        #content{
            display:flex;
            background:var(--primary-background-color);
            padding:52px;
            border-radius:4px;
        }

        label{
            display:inline-block;
            font-size:2em;
            margin-right:8px;
        }

        @keyframes bouncing-loader {
            to {
                opacity: 0.1;
                transform: translate3d(0, -16px, 0);
            }
        }

        .bouncing-loader {
            display: flex;
            justify-content: center;
        }

        .bouncing-loader > div {
            width: 16px;
            height: 16px;
            margin: 1rem 0.2rem;
            background: var(--secondary-grey-color);
            border-radius: 50%;
            animation: bouncing-loader 0.6s infinite alternate;
        }

        .bouncing-loader > div:nth-child(2) {
            animation-delay: 0.2s;
        }

        .bouncing-loader > div:nth-child(3) {
            animation-delay: 0.4s;
        }

    </style>
    <div class="backdrop" show$=[[show]]>
        <div id="content">
            <label>
                <oe-i18n-msg msgid="Loading">Loading</oe-i18n-msg>
            </label>
            <div class="bouncing-loader">
            <div></div>
            <div></div>
            <div></div>
            </div>
        </div>
    </div>
    `;
	}

	connectedCallback() {
		super.connectedCallback();
		this.set("show", false);
		this.set("activeCalls", 0);
		let OEUtils = window.OEUtils || {};
		OEUtils.componentDefaults = OEUtils.componentDefaults || {};
		OEUtils.componentDefaults["oe-ajax"] = {
			presendHook: (detail) => {
				this.set("activeCalls", this.activeCalls + 1);
			},
			postreceiveHook: (detail) => {
				this.set("activeCalls", this.activeCalls > 0 ? (this.activeCalls - 1) : 0);
			}
		};
	}

	static get observers() {
		return [
			"_activeCallChanged(activeCalls)"
		];
	}

	_activeCallChanged() {
		this.set("show", this.activeCalls > 0);
	}
}

window.customElements.define(LoadingSpinner.is, LoadingSpinner);
