import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import "@polymer/iron-flex-layout/iron-flex-layout.js";
import "@polymer/iron-flex-layout/iron-flex-layout-classes.js";
import { OECommonMixin } from "oe-mixins/oe-common-mixin.js";

import "@polymer/paper-dialog/paper-dialog.js";
import "@polymer/paper-dialog-scrollable/paper-dialog-scrollable.js";
import "@polymer/paper-icon-button/paper-icon-button.js";
import "@polymer/neon-animation/neon-animations.js";

class TppDialog extends OECommonMixin(PolymerElement) {
	static get is() {
		return "tpp-dialog";
	}

	static get template() {
		return html`
            <style include="iron-flex iron-flex-alignment">
                #dialog{
                    min-width:40vw;
                    border-radius:4px;
                    @apply --tpp-dialog;
                }

                .dialog-header{
                    margin: 0px;
                    padding: 12px 24px;
                    font-size:20px;
                    text-transform:capitalize;
                    @apply --tpp-dialog-header;
                }


                #close-icon{
                    align-self:end;
                }

                .dialog-body{
                    margin:0px;
                    @apply --tpp-dialog-body;
                }

                .dialog-footer{
                    border-top:1px solid var(--secondary-grey-color);
                    padding: 16px 24px;
                    margin: 0px;
                    @apply --tpp-dialog-footer;
                    gap:16px;
                }
            </style>
            <paper-dialog part="dialog" id="dialog" modal=[[modal]] entry-animation=[[entryAnimation]] exit-animation=[[exitAnimation]]>
                <div part="dialog-header" class="dialog-header layout horizontal center">
                    <slot name="header"></slot>
                    <paper-icon-button id="close-icon" hidden=[[!showCloseIcon]] icon="clear" on-tap="close"></paper-icon-button>
                </div>
                <paper-dialog-scrollable class="dialog-body">
                    <slot name="body"></slot>
                </paper-dialog-scrollable>
                <div part="dialog-footer" class="dialog-footer layout horizontal center end-justified">
                    <slot name="footer"></slot>
                </div>
            </paper-dialog>
        `;
	}

	static get properties() {
		return {
			modal: {
				type: Boolean,
				value: false
			},
			showCloseIcon: {
				type: Boolean,
				value: false
			},
			entryAnimation: {
				type: String,
				value: "scale-up-animation"
			},
			exitAnimation: {
				type: String,
				value: "scale-down-animation"
			}
		};
	}

	open(config) {
		let dialog = this.$.dialog;
		if (config) {
			this._reposition(config);
		}

		dialog.open();
	}

	_reposition(config) {
		let dialog = this.$.dialog;
		let { target, alignment, offset } = config;
		if (target && alignment) {
			let rect = target.getBoundingClientRect();
			let dialogOffsets = {
				vertical: 0,
				horizontal: 0,
				...(offset || {})
			};

			let {
				vertical,
				horizontal
			} = alignment;

			let translate = {
				top: vertical === "top" ? 0 : -100,
				left: horizontal === "left" ? 0 : -100
			};

			let dialogPos = {
				top: rect.top + dialogOffsets.vertical + (vertical === "top" ? rect.height : 0),
				left: rect.left + dialogOffsets.horizontal + (horizontal === "right" ? rect.width : 0)
			};

			dialog.style.top = dialogPos.top + "px";
			dialog.style.left = dialogPos.left + "px";

			if (translate.top || translate.left) {
				dialog.style.translate = `${translate.left}% ${translate.top}%`;
			}

			if (dialog.style.position !== "fixed") {
				dialog.style.position = "fixed";
			}
		}
	}

	close() {
		this.$.dialog.close();
	}
}
window.customElements.define(TppDialog.is, TppDialog);
