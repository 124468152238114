import { PolymerElement } from "@polymer/polymer/polymer-element.js";
import { OECommonMixin } from "oe-mixins/oe-common-mixin";
import { ReduxMixin } from "../redux/redux-mixin";

class SessionHandler extends ReduxMixin(OECommonMixin(PolymerElement)) {
	static get is() {
		return "session-handler";
	}

	static get properties() {
		return {
			userLoggedIn: {
				type: Boolean,
				statePath: "userLoggedIn",
				observer: "_userLogginChanged"
			},
			isPromptOpen: {
				type: Boolean,
				value: false
			}
		};
	}

	_userLogginChanged() {
		if (this.userLoggedIn) {
			// console.log("User logged in");
			if (window.SharedWorker) {
				this.__createSW();
			}
		} else {
			// console.log("User not logged in");
		}
	}

	__sendToWorker(msg) {
		this.myWorker.port.postMessage(msg);
	}

	__createSW() {
		this.myWorker = new SharedWorker("./worker.js", { type: "module" });
		let config = window.devportal.idleTime || {};
		this.__sendToWorker({config});
		this.__sendToWorker("START");
		this.myWorker.port.onmessage =  (e) => {
			switch (e.data) {
			case "LOGOUT":
				this._logout();
				break;
			case "PROMPT":
				this._prompt();
				break;
			case "CLOSE":
				this._closePrompt();
				break;
			default:
				break;
			}
		};

		// Listen to User Activity on the browser
		var activityEvents = [
			"mousedown",
			"mousemove",
			"keydown",
			"scroll"
		];

		var self = this;
		activityEvents.forEach(function (eventName) {
			document.addEventListener(eventName, () => {
				self.debounce("worker-idle-reset", () => {
					if (!self.isPromptOpen) {
						self.__sendToWorker("RESTART");
					}
				}, 1000);
			}, true);
		});
	}

	_logout() {
		this.fire("logout-user");
		this.isPromptOpen = false;
	}

	_prompt() {
		this.fire("oe-show-confirm", {
			message: "Session is about to timeout. Do you want to continue or logout?",
			okLabel: "Continue",
			ok: () => {
				this.__sendToWorker("RESTART");
				this.__sendToWorker("CLOSE");
			},
			cancelLabel: "Logout",
			cancel: () => {
				this.__sendToWorker("LOGOUT");
			}
		});
	}

	_closePrompt() {
		this.isPromptOpen = false;
		this.fire("oe-hide-message");
	}
}

window.customElements.define(SessionHandler.is, SessionHandler);
