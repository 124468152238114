import { html } from "@polymer/polymer/polymer-element.js";
import ProfileBaseView from "./profile-base-view.js";
import { updateInvitesInfo } from "../../redux/actions.js";
import "@polymer/iron-image/iron-image.js";
import "../../components/tpp-button.js";
import "oe-ui-forms/validators/oe-block-validator.js";
import "../tpp-dialog.js";

/**
 * ### my-profile-view
 * `my-profile-view`
 *
 *
 * @customElement
 * @polymer
 */
class MyProfileView extends ProfileBaseView {
	static get is() {
		return "my-profile-view";
	}

	static get stylesTemplate() {
		return html`
			${super.stylesTemplate}
			<style>
				.user-info-banner{
					height : 230px;
					font-size : 16px;
					gap:12px;
				}

				.user-info-banner > label{
					display : block;
				}

				.user-name{
					font-size : 24px;
				}

				.update-profile-link{
					margin : 32px 0px;
				}

				.instructions{
					padding: 32px 0px;
				}

				.init-steps{
					margin-top:16px;
				}

				.step-item{
					width : 33%;
					padding: 8px;
					box-sizing:border-box;
					text-align: justify;
					line-height: 24px;
					font-size: 14px;
					display: flex;
					flex-direction: column;
				}

				.step-item h3{
					text-align : center;
					font-size : 20px;
				}

				.step-item:not(:nth-child(3)){
					border-right:1px solid var(--primary-grey-color)
				}

				.step-item p{
					min-height: 140px;
				}

				.step-item paper-button{
					border : 1px solid;
					border-radius : 4px;
					font-size : 12px;
					text-transform : none;
				}

				.invites-container{
					padding:16px 2px;
				}

				#validator [field-id]{
					margin-bottom:22px;
				}

				tpp-button + tpp-button{
					margin-left:16px;
				}
			</style>
		`;
	}

	static get contentTemplate() {
		return html`
		<div class="layout horizontal justified">
            <div class="user-info-banner layout vertical flex">
                <label class="user-name primary-text">[[userInfo.firstName]] [[userInfo.lastName]]</label>
                <label class="user-company">[[userInfo.orgName]]</label>
                <label class="user-email">[[userInfo.email]]</label>
                <tpp-button class="submit-button" primary mini animate-on-hover message="Update personal details" icon="arrow-forward" on-tap="_changePassword"></tpp-button>
            </div>
            <iron-image class="flex" src="../img/profile-visual.png" sizing="contain"></iron-image>
        </div>
        <div class="invites-container" hidden=[[hideInvites]]>
            <label class="section-title"><oe-i18n-msg msgid="Invites">Invites</oe-i18n-msg></label>
            <oe-data-grid 
            items=[[myInvites]] 
            on-oe-data-table-row-action="_handleInviteAction" 
            on-oe-data-table-action-refresh="_getUserInvites"
            disable-selection disable-config-editor disable-full-screen-mode disable-add
            columns=[[inviteConfig.columns]] row-actions=[[inviteConfig.rowActions]]
            toolbar-actions=[[inviteConfig.toolbarActions]]></oe-data-grid>
        </div>
        <div class="instructions" hidden=[[__hideSteps(userAccess)]]>
            <label class="section-title">
				<oe-i18n-msg msgid="How it works">How it works</oe-i18n-msg></label>
            <div class="layout horizontal justified init-steps">
                <div class="step-item">
                    <h3>
						<oe-i18n-msg msgid="Step 1">Step 1</oe-i18n-msg>	
					</h3>
                    <p>
						<oe-i18n-msg msgid="my_profile_step_1">You've created an account, so go and have a read through our API documentation and use cases to get some inspiration.</oe-i18n-msg>
                    </p>
                    <tpp-button secondary mini on-tap="_gotoApi" message="View API catalogue"></tpp-button>
                </div>
                <div class="step-item">
                    <h3>
						<oe-i18n-msg msgid="Step 2">Step 2</oe-i18n-msg>
					</h3>
                    <p>
                    	<oe-i18n-msg msgid="my_profile_step_2">Ready to try our APIs in your app? First, create a company account with us. This gives you access to API analytics, customer support, and team and app management tools.</oe-i18n-msg>
                    </p>
                    <tpp-button secondary mini on-tap="_createCompany" message="Create a company account"></tpp-button>
                </div>
                <div class="step-item">
                    <h3><oe-i18n-msg msgid="Step 3">Step 3</oe-i18n-msg>
					</h3>
                    <p>
                    <oe-i18n-msg msgid="my_profile_step_3">After your company account is created, register your apps, then request access to non production and production keys.</oe-i18n-msg>
                    </p>
                    <tpp-button secondary mini on-tap="_createApp" message="Available with company Account"></tpp-button>
                </div>
            </div>
        </div>
		`;
	}

	static get observers() {
		return [
			"userInfoChanged(userInfo)"
		];
	}

	constructor() {
		super();
		this.set("viewTitle", "My Profile");
		this.set("hideInvites", true);
		this.set("inviteConfig", {
			columns: [
				{
					key: "sender",
					label: "Organization Name"
				},
				{
					key: "roleId",
					label: "Role",
					valueGetter: r => r.roleId === "viewer" ? "Viewer" : "API Developer"
				}
			],
			rowActions: [
				{
					action: "accepted",
					icon: "done",
					title: "Accept"
				},
				{
					action: "rejected",
					icon: "clear",
					title: "Reject"
				}
			],
			toolbarActions: [
				{
					action: "refresh",
					title: "Refresh Invites",
					icon: "refresh"
				}
			]
		});
	}

	userInfoChanged() {
		if (!this.userInfo) {
			return;
		}

		if (this.userInfo.roles.length !== 0) {
			return;
		}

		if (this._prevData && this._prevData.userId === this.userInfo.userId) {
			return;
		}
		this._prevData = this.userInfo;
		this._getUserInvites();
	}

	_getUserInvites() {
		this.set("hideInvites", false);

		this.makeAjaxCall("@restApiRoot/Invites/", "GET", null, null, {
			filter: {
				where: {
					email: this.userInfo.email,
					status: "pending"
				}
			}
		}, null, (err, resp)=>{
			if (!err) {
				this.set("myInvites", resp);
				this.dispatch(updateInvitesInfo(resp));
				this._showNotification(resp);
			}
		});
	}

	_showNotification(invitesList) {
		if (!window.seenInviteNotification && invitesList.length > 0) {
			window.seenInviteNotification = true;
			if (invitesList.length === 1) {
				let invite = invitesList[0];
				let role = invite.roleId === "viewer" ? "Viewer" : "API Developer";
				let message = `You have received an invite to join ${invite.sender} as ${role}.\n\nWould you like to accept and join the organization now?`;
				this.fire("oe-show-confirm", {
					message,
					okLabel: "Accept",
					ok: () => setTimeout(()=>this._confirmAndAccept(invite), 100),
					cancelLabel: "Later"
				});
			} else {
				let message = `There are ${invitesList.length} invites waiting for you and needs your attention.\n\nPlease review and accept appropriate invite.`;
				this.fire("oe-show-warning", message);
			}
		}
	}

	_handleInviteAction(event) {
		let {
			action,
			row
		} = event.detail;

		if (action.action === "accepted") {
			return this._confirmAndAccept(row);
		}

		this.fire("oe-show-confirm", {
			message: "Confirm rejecting the invite",
			okLabel: "Reject",
			ok: () => this.__completeOrgInvite("rejected", row)
		});
	}

	_confirmAndAccept(invite) {
		this.fire("oe-show-confirm", {
			message: "Your role would be updated and hence you would be logout from API Center now.\n\nPlease re-login to access with updated role.",
			okLabel: "Accept",
			ok: () => {
				this.__completeOrgInvite("accepted", invite);
			}
		});
	}

	async __completeOrgInvite(action, invite) {
		let payload = {
			status: action,
			organizationId: invite.organizationId
		};
		let [err] = await this._makeAjaxPromise(`@restApiRoot/AppUsers/${this.userInfo.userId}/acceptInvite/`, "POST", payload);
		if (err) {
			return this.fire("oe-show-error", window.devportalError(err));
		}
		if (action === "accepted") {
			this.fire("logout-user");
		} else {
			this._getUserInvites();
		}
	}

	_gotoApi() {
		this.fire("goto-route", "/api");
		window.oe_navigate_to("/apis");
	}

	_createCompany() {
		this.fire("goto-view", "my-company");
	}

	_createApp() {
		this.fire("goto-view", "registered-apps");
	}

	_changePassword() {
		this.fire("goto-view", "change-password");
	}

	__hideSteps(userAccess) {
		let {
			admin,
			orgAdmin,
			developer
		} = userAccess;
		return admin || orgAdmin || developer;
	}
}
window.customElements.define(MyProfileView.is, MyProfileView);
