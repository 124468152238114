import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import "@polymer/iron-flex-layout/iron-flex-layout.js";
import "@polymer/iron-flex-layout/iron-flex-layout-classes.js";
import { OEAjaxMixin } from "oe-mixins/oe-ajax-mixin.js";
import "../../styles/app-theme.js";
import "oe-input/oe-input.js";
import "oe-ui-forms/validators/oe-block-validator.js";
import { AnimateMixin } from "../mixins/animate-mixin.js";
import "../../styles/oe-animation.js";
import "@polymer/iron-image/iron-image.js";
import "../components/tpp-button.js";

/**
 * ### login-user-page
 * `login-user-page`
 *
 *
 * @customElement
 * @polymer
 */
class LoginUserPage extends AnimateMixin(OEAjaxMixin(PolymerElement)) {
	static get is() {
		return "login-user-page";
	}

	static get template() {
		return html`
			<style include="iron-flex iron-flex-alignment app-theme oe-animation iron-flex-factors">

				.banner-section{
					padding: 0px 0 60px 0px;
					min-height: calc(100vh - 120px);
					box-sizing: border-box;
				}

				.content{
					@apply --canvas;
				}
				
				.banner-img{
					height:423px;
				}

				.welcome-message{
					padding-right:60px;
				}

				h1 {
					font-weight:300;
					color: var(--primary-dark-color);
				}
				
				h2 {
					color: var(--primary-grey-color);
				}

				h1, h2{
					margin-top: 0;
					margin-bottom: 0.5rem;
					line-height: 1.2;
				}

				.form-field{
					width: 410px;
					padding-top:20px;
				}

				.tooltip-sufix{
					position:relative;
					padding-right:4px;
				}

				.tooltip-content{
					width: 170px;
					line-height: 14px;
					letter-spacing: 0.7px;
				}

				.tooltip-content ul {
					list-style: disc;
					margin-inline-start: 16px;
				}


				.login-btn{
					float: right;
					margin: 12px 0px 0px;
				}

				.other-options{
					margin-top:16px;
					border-radius : 10px;
					display:flex;
					overflow:hidden;
				}

				.option-item{
					padding: 15px;
					box-shadow: 0px 0px 3px 0px #737373bf;
					color:white;
					flex:1;
				}

				.option-item p{
					margin:0px;
				}

				.option-item a{
					color:white;
					text-decoration:underline !important;
				}

				.option-item.forgot-password{
					background-color : #0077be;
				}

				.option-item.register-user{
					background-color : #f89a1c;
				}

			</style>
			<main id="container">
				<section class="banner-section">
					<div class="content layout horizontal justified">
						<div class="welcome-message layout vertical flex" oe-animate="fade-up">
							<h1>Log in to get access to our <strong>API<br>sandboxes</strong> and <strong>documentation</strong></h1>
								<div class="form-field">
									<oe-block-validator id="validator">
										<tpp-input required type="email" label="Email Id"  field-id="email" value={{loginInfo.email}}></tpp-input>
										<tpp-input required type="password" label="Password"  field-id="password" value={{loginInfo.password}} on-keypress="_handleKeyPress"></tpp-input>
                                        <input aria-hidden="true" label="User Id" hidden id="userId"/>
									</oe-block-validator>
									<tpp-button class="login-btn" on-tap="_validateAndLogin" primary animate-on-hover message="Login" icon="arrow-forward"></tpp-button>
								</div>
							<div class="other-options">
								<div class="option-item forgot-password">
									<p>Dont remember the password:<br/><a href$=[[__getPath("/forgot-password")]]>Click here</a> to recover your password</p>
								</div>
								<div class="option-item register-user">
								<p>Dont have account:<br/><a href$=[[__getPath("/register")]]>Click here</a> to create your account</p>
								</div>
							</div>
						</div>
						<iron-image oe-animate="zoom-in" class="banner-img" src="img/login-visual.png"></iron-image>
					</div>
				</section>
			</main>
    	`;
	}

	constructor() {
		super();
		this.set("loginInfo", {});
		this.set("passwordPattern", "(?=.*[\\d])(?=.*[a-z])(?=.*[A-Z])(?=.*[@_#$])[0-9a-zA-Z@_#$]{6,10}");
	}

	_handleKeyPress(event) {
		if (event.code === "Enter") {
			this._validateAndLogin();
		}
	}

	_validateAndLogin() {
		this.$.validator.validateForm().then(result => {
			if (!result.valid) {
				this.fire("oe-show-error", "Error in form validation");
				return;
			}
			this._captcha.validate(this.$.userId);
		});
	}

	_loginUser(response) {
		let payload = this.__preparePayload();
		payload.captchaToken = response;
		payload.userId = this.$.userId.value;
		this.makeAjaxCall(this._loginURL, "POST", payload, null, null, null, (err, resp) => {
			if (err) {
				this._handleLoginError(err);
				this._captcha.reset();
				return;
			}
			this.__handleResponse(resp);
			location.pathname = window.OEUtils.subPath + "/user-profile";
		});
	}

	__handleResponse(resp) {
		if (resp) {
			sessionStorage.userId = resp.userId;
			sessionStorage.auth_token = resp.id;
			localStorage.swagger_accessToken = resp.id;
		}
	}


	_handleLoginError(err) {
		this.fire("oe-show-error", window.devportalError(err));
	}

	__getPath(path) {
		return window.OEUtils.subPath + path;
	}

	connectedCallback() {
		super.connectedCallback();
		this._loginURL = window.OEUtils.subPath + "/auth/local/callback";
		this.addEventListener("oe-route-change", () => {
			this.fire("oe-captcha-init", {
				name: "login",
				onValid: (response) => {
					this._loginUser(response);
				},
				onError: (error) => {
					this.fire("oe-show-error", error);
				},
				onRegister: captcha => {
					this._captcha = captcha;
				}
			});
		});
	}

	__preparePayload() {
		let {
			email,
			password
		} = this.loginInfo;

		let payload = {
			email,
			password
		};

		return payload;
	}
}
window.customElements.define(LoginUserPage.is, LoginUserPage);
