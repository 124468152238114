import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import "@polymer/iron-flex-layout/iron-flex-layout.js";
import "@polymer/iron-flex-layout/iron-flex-layout-classes.js";
import "../../styles/app-theme.js";
import "oe-input/oe-input.js";
import "oe-ui-forms/validators/oe-block-validator.js";
import "../../styles/oe-animation.js";
import "../components/tpp-button.js";
import { PromiseMixin } from "../mixins/promise-mixin.js";

/**
 * ### verify-account-page
 * `verify-account-page`
 *
 *
 * @customElement
 * @polymer
 */
class VerifyAccountPage extends PromiseMixin(PolymerElement) {
	static get is() {
		return "verify-account-page";
	}

	static get template() {
		return html `
			<style include="iron-flex iron-flex-alignment app-theme iron-flex-factors">

					.banner-section{
						margin: 0 100px;
						padding: 0px 16px 60px 16px;
						width: auto;
						min-height: calc(100vh - 120px);
						box-sizing: border-box;
					}

					
					.verification-message{
						padding: 40px 0px;
					}

					.hero h1 {
						font-size: 42px;
						font-weight: 300;
						color: var(--primary-dark-color);
					}
					
					.hero h2 {
						color: var(--primary-grey-color);
					}

					.float-right{
						float:right;
						margin-top:30px;
					}

				</style>
	 <main id="container">
		<section class="hero banner-section layout horizontal justified">
			<div hidden=[[!pendingVerification]]>
				<h1>Your Email is being verified...</h1>
			</div>
            <div class="verification-message layout vertical" hidden=[[pendingVerification]]>
				<dom-if if=[[verificationStatus.verified]]>
					<template>
						<h1>Email verified successfully</h1>
						<p>Your email address has been verified. Your registration will be sent for approval to Adminstrator.</p>
						<p>You will recieve an email, updating the approval status of your request. Once approved, please login with email address and password you provided at the time of registration.</p>
						<tpp-button on-tap="_login" class="float-right" primary animate-on-hover message="Login" icon="arrow-forward"></tpp-button>
					</template>
				</dom-if>
				<dom-if if=[[!verificationStatus.verified]]>
					<template>
						<h1>Email verification failed</h1>
						<p>We could not verify your email address at this moment. Please contact us for further details.</p>
					</template>
				</dom-if>
            </div>
		</section>
	 </main>
    `;
	}

	static get properties() {
		return {
			pendingVerification: {
				type: Boolean,
				value: false
			},
			verificationStatus: {
				type: Object,
				value: () => {return {verified: false};}
			},
			verificationId: {
				type: String,
				observer: "_verificationIdChanged"
			}
		};
	}

	async _verificationIdChanged() {
		let payload = {
			tokenId: (new URLSearchParams(location.search)).get("token")
		};
		let isSelectedInstance = this.classList.contains("iron-selected");
		if (!isSelectedInstance) {
			return;
		}
		if (this.verificationId && payload.tokenId) {
			let [resp] = await this._makeAjaxPromise(`@restApiRoot/AppUsers/${this.verificationId}/verifyEmail`, "POST", payload);
			this.set("pendingVerification", false);
			this.set("verificationStatus", resp);
		}
	}

	_login() {
		window.location.pathname = window.OEUtils.subPath + "/authlogin";
	}
}
window.customElements.define(VerifyAccountPage.is, VerifyAccountPage);
