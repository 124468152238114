import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import "oe-combo/oe-combo.js";
import { OECommonMixin } from "oe-mixins/oe-common-mixin.js";
import "../../styles/input-styles.js";

class TppDropdown extends OECommonMixin(PolymerElement) {
	static get is() {
		return "tpp-dropdown";
	}

	static get template() {
		return html`
            <style include="input-styles">

                .rounded-input{
                    --paper-input-container-underline-disabled:{
                        display:none !important;
                    }
                }

                .rounded-input::part(iron-input){
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px;
                }

                .rounded-input::part(dropdown-icon){
                    padding: 0 8px;
                    border: 1px solid var(--border-color);
                    background:var(--background-color);
                    width: 40px;
                    border-radius: 3px;
                    height: 39px;
                    align-items: center;
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                    border-left-width: 0px;
                }

                .rounded-input[expand]::part(iron-input),
                .rounded-input[expand]::part(dropdown-icon){
                    border-bottom-left-radius: 0px;
                    border-bottom-right-radius:0px;
                }

                .rounded-input::part(listbox){
                    padding:0px;
                    border: 1px solid var(--border-color);
                    border-bottom-left-radius: 4px;
                    border-bottom-right-radius: 4px;
                    border-top-width: 0px;
					max-height: 250px;
					overflow: auto;
                }

				.rounded-input::part(dropdown-content){
					max-height: 300px;
				}

                .rounded-input::part(list-item){
                    min-height: 30px;
                    padding: 0 16px;
                }

                .rounded-input[disabled]::part(paper-input-container){
                    opacity:1;
                }
            </style>
            <oe-combo id="combo" class="rounded-input" listdata=[[list]] displayproperty=[[disp]] valueproperty=[[val]]
            disabled$=[[readonly]] required=[[required]] field-id=[[fieldId]] vertical-offset="-18" show-filtered-only=[[showFilteredOnly]]
            always-float-label=[[_computeFloatLabel(label,alwaysFloatLabel)]] no-label-float=[[noLabelFloat]] 
            value={{value}} label=[[label]] multi=[[multi]] disable-text-input=[[disableTextInput]]></oe-combo>
        `;
	}

	static get properties() {
		return {
			value: {
				type: String,
				notify: true
			},
			label: String,
			multi: {
				type: Boolean,
				value: false
			},
			required: {
				type: Boolean,
				value: false
			},
			readonly: {
				type: Boolean,
				value: false
			},
			alwaysFloatLabel: {
				type: Boolean
			},
			noLabelFloat: {
				type: Boolean
			},
			showFilteredOnly: {
				type: Boolean,
				value: false
			},
			disableTextInput: {
				type: Boolean,
				value: false
			},
			fieldId: String,
			list: {
				type: Array,
				value: () => []
			},
			disp: String,
			val: String
		};
	}

	connectedCallback() {
		super.connectedCallback();
		let combo = this.$.combo;
		if (combo && !this.showFilteredOnly) {
			combo.$.input.addEventListener("click", () => {
				setTimeout(()=>combo.$.dropdownicon.click(), 100);
			});
		}
	}

	_computeFloatLabel(label, alwaysFloatLabel) {
		return alwaysFloatLabel || label;
	}
}

window.customElements.define(TppDropdown.is, TppDropdown);
