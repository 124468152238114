import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import "@polymer/iron-flex-layout/iron-flex-layout.js";
import "@polymer/iron-flex-layout/iron-flex-layout-classes.js";
import "./app-credential-grid.js";
import "./add-consent-dialog.js";
import { OECommonMixin } from "oe-mixins/oe-common-mixin.js";

class ManageAppCredentials extends OECommonMixin(PolymerElement) {
	static get is() {
		return "manage-app-credentials";
	}

	static get properties() {
		return {
			readOnlyMode: {
				type: Boolean,
				value: false
			},
			application: {
				type: Object
			},
			isProduction: {
				type: Boolean,
				value: false
			},
			loginCreds: {
				type: Array,
				value: () => [],
				notify: true
			}
		};
	}

	constructor() {
		super();
		this.setProperties({
			loginMethods: [
				{ d: "Client Credentials", v: "client_credentials" },
				{ d: "Authorization Code", v: "authorization_code" }
			]
		});
	}

	static get template() {
		return html`
            <style include="iron-flex iron-flex-alignment iron-flex-factors">
                :host{
                    display:block;
                }
                
                oe-data-grid{
                    margin-bottom:16px;
                }

                .col-2{
                    gap: 4px 32px;
                }
                .col-2 > * {
                    flex:1;
                }
                
                .tooltip-info{
                    cursor:pointer;
                }

                .tooltip-info pre{
                    font-size: 14px;
                    margin: 0;
                    line-height: 24px;
                }

                .info-grid{
                    display:grid;
                    grid-template-columns: repeat(3, minmax(0, 1fr));
                    gap:16px;
                    margin-top: 16px;
                }

                .info-grid oe-info::part(label){
                    font-weight: bold;
                    font-size: 14px;
                }

                .info-grid oe-info::part(value){
                    overflow-wrap: break-word;
                    padding-right: 16px;
                    letter-spacing: 0.7px;
                    line-height: 1.5em;
                }

                h1, h2, h3, h4{
                    margin-top: 0;
                    margin-bottom: .5rem;
                    font-weight: 500;
                    line-height: 1.2;
                }

                h1{
                    font-size: 2.5rem;
                }
                
                .primary-text{
                    color : var(--primary-dark-color);
                }

                oe-data-grid{
                    padding:2px;
                }

                [hidden]{
                    display:none !important;
                }
            </style>
            <h2 class="full-width primary-text">
                <oe-i18n-msg msgid="Application Authentication">Application Authentication</oe-i18n-msg>    
            </h2>
            <div class="layout horizontal col-2">
                <oe-radio-group disabled value="[[application.loginMethod]]" label="Login Method" class="layout-radio-button" listdata="[[loginMethods]]" valueproperty="v" displayproperty="d"></oe-radio-group>
            </div>

            <app-credential-grid is-production=[[isProduction]] hidden=[[readOnlyMode]] login-creds=[[loginCreds]] application={{application}}></app-credential-grid>

            <div hidden=[[!__showCredential(application.loginMethod)]]>
                <h2 class="full-width primary-text">
                    <oe-i18n-msg msgid="Account authorization *">Account authorization *</oe-i18n-msg>
                </h2>
                <label class="info-text" hidden=[[application.clientConsent]]>
                    <oe-i18n-msg msgid="Please provide account authorization. Account authorization is mandatory.">Please provide account authorization. Account authorization is mandatory.</oe-i18n-msg>    
                </label>
                <label class="info-text" hidden=[[!application.clientConsent]]>
                    <oe-i18n-msg msgid="Account authorization completed.">Account authorization completed.</oe-i18n-msg>    
                </label>
                <tpp-button mini primary message="Authorize" hidden=[[readOnlyMode]] on-tap="_launchAddConsent"></tpp-button>
                <div class="info-grid" hidden=[[!application.clientConsent]]>
                    <oe-info label="Accounts" value=[[application.clientConsent.maskedAccounts]]></oe-info>
                    <oe-info label="Permission" value=[[application.clientConsent.permissions]]></oe-info>
                    <oe-info label="Expires At" type="date" format="MM/DD/YYYY - hh:mm:ss tt" value=[[application.clientConsent.expiresAt]]></oe-info>
                </div>
            </div>
            <add-consent-dialog id="dialog" is-production=[[isProduction]] ></add-consent-dialog>
        `;
	}

	__showCredential(loginMethod) {
		return loginMethod === "client_credentials";
	}

	_launchAddConsent() {
		this.$.dialog.launch(this.application, (consent) => {
			this.set("application.clientConsent", consent);
			this.fire("oe-show-success", "Consent created successfully");
		});
	}
}

window.customElements.define(ManageAppCredentials.is, ManageAppCredentials);
