import { html } from "@polymer/polymer/polymer-element.js";
import ProfileBaseView from "./profile-base-view.js";
import "../tpp-dropdown.js";

class ManageAccountView extends ProfileBaseView {
	static get is() {
		return "manage-account-view";
	}

	static get template() {
		return html `
        ${super.template}
        <style>
        h2{
            font-size : 20px;
        }

        .validator{
            padding: 16px 0;
        }

        .validator .full-width{
            width:100%;
            display : block;
        }

        .validator > *:not(.full-width){
            width : 45%;
        }
        
        </style>
        <div id="container">
            <h2>Setup your charge Account</h2>
            <p>By filing this details you are giving consent to use by the bank to collect charges, basis the API plan and API usage.</p>
            <oe-block-validator class="validator layout horizontal justified wrap">
                <h2 class="full-width">Account details :</h2>
                <tpp-dropdown required label="Select Account"  value={{chargeAccount}}></tpp-dropdown>
            </oe-block-validator>
            <h2>Setup your payment Account</h2>
            <p>This account will be used by the bank to collect charges, basis the API plan and API usage.</p>
            <oe-block-validator class="validator layout horizontal justified wrap">
                <h2 class="full-width">Account details :</h2>
                <tpp-dropdown required label="Select Account"  value={{chargeAccount}}></tpp-dropdown>
                <tpp-dropdown required label="Select Purpose"  value={{chargeAccount}}></tpp-dropdown>
            </oe-block-validator>
            <div class="btn-holder layout horizontal center-center">
                <paper-button class="btn btn-primary">Submit</paper-button>
            </div>
        </div>
        `;
	}

	constructor() {
		super();
		this.set("userProfile", {
			name: "",
			email: "",
			company: ""
		});
	}
}

window.customElements.define(ManageAccountView.is, ManageAccountView);
