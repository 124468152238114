/**
 * @license
 * ©2018-2019 EdgeVerve Systems Limited (a fully owned Infosys subsidiary),
 * Bangalore, India. All Rights Reserved.
 */
import "@polymer/polymer/lib/elements/dom-module.js";
const styleElement = document.createElement("dom-module");
styleElement.innerHTML =
    `<template>
        <style>
            
            .primary-text{
                color: var(--primary-dark-color);
            }

            .hero h1 {
                margin: 0;
                font-size: 48px;
                font-weight: 300;
            }
            
            .hero h2 {
                margin: 15px 0 0 0;
                font-size: 26px;
            }

            h1, h2, h3, h4{
                margin-top: 0;
                margin-bottom: .5rem;
                font-weight: 500;
                line-height: 1.2;
            }

            h1{
                font-size: 2.5rem;
            }

            section {
                padding: 60px 0;
                overflow: hidden;
            }

            a{
                text-decoration: none !important;
                outline: 0;
                cursor: pointer;
            }

            a:hover{
                text-decoration : underline;
            }

            ul {
                list-style : none;
                margin: 0;
                padding: 0px;
            }

            b , strong{
                font-weight: 400;
            }

            [hidden]{
                display:none !important;
            }
    
            tpp-button + tpp-button:not(.no-margin){
                margin-left:8px;
            }
        </style>
    </template>`;
styleElement.register("app-theme");