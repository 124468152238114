import { html } from "@polymer/polymer/polymer-element.js";
import "@polymer/iron-flex-layout/iron-flex-layout.js";
import "@polymer/iron-flex-layout/iron-flex-layout-classes.js";
import "../../../styles/app-theme.js";
import "oe-data-grid/styles/finacle-table-styles.js";
import ProfileBaseView from "./profile-base-view.js";

class AdminApproveBase extends ProfileBaseView {
	static get stylesTemplate() {
		return html`
			${super.stylesTemplate}
			<style>

				oe-data-grid + oe-data-grid{
					margin-top: 16px;
				}

				.task-dialog .header-icon{
					color : lightgreen;
					margin-right:8px;
				}

				.task-dialog .dialog-content{
					margin: 0px;
					padding-bottom:16px;
					font-family: inherit;
				}

				tpp-button + tpp-button{
					margin-left:16px;
				}
			</style>
		`;
	}

	static get contentTemplate() {
		return html`
			<iron-pages selected=[[selectedView]] attr-for-selected="view">
				<div view="list">
					${this.listTemplate}
				</div>
				<div view="item">
					${this.itemTemplate}
				</div>
			</iron-pages>
			<tpp-dialog id="rejectDialog" class="task-dialog" modal>
				<iron-icon slot="header" class="header-icon" icon="assignment"></iron-icon>
				<label slot="header">
					<oe-i18n-msg msgid="Confirm rejection">Confirm rejection</oe-i18n-msg>
				</label>
				<div slot="body" class="dialog-content">
					<tpp-input required label="Comments" value={{rejectionComments}}></tpp-input>
				</div>
				<tpp-button slot="footer" mini primary message="Reject" on-tap="__confirmReject"></tpp-button>
				<tpp-button slot="footer" mini message="Cancel" on-tap="_close"></tpp-button>
			</tpp-dialog>
		`;
	}

	static get listTemplate() {
		return html`
		<oe-data-grid label="[[ui.heading]]" id="grid" items={{grid.items}} disable-selection disable-config-editor
			disable-full-screen-mode disable-add on-oe-data-table-row-action="__handleRowAction"
			on-oe-data-table-action-refresh="__fetchList" toolbar-actions=[[_gridActions]]
			columns=[[_gridColumnConverter(grid.columns)]] row-actions=[[grid.rowActions]]></oe-data-grid>
		`;
	}

	static get itemTemplate() {
		return html``;
	}

	constructor() {
		super();
		this.set("hideViewTitle", true);
		this.setProperties({
			"selectedView": "list",
			"_gridActions": [
				{
					action: "refresh",
					title: "Refresh list",
					icon: "refresh",
					isButton: true
				}
			]
		});
	}

	static get properties() {
		return {
			userInfo: {
				type: Object,
				statePath: "userInfo"
			},
			userAccess: {
				type: Object,
				statePath: "userAccess"
			},
			organization: {
				type: Object,
				statePath: "organizationInfo"
			}
		};
	}

	__switchView(view) {
		this.set("selectedView", view);
	}

	__handleRowAction() {

	}

	async __fetchList() {
		let params = null;

		if (this.ui.filter) {
			params = {
				filter: this.ui.filter
			};
		}

		let [err, resp] = await this._makeAjaxPromise(this.ui.url, "GET", null, null, params);

		if (err) {
			this.fire("oe-show-error", window.devportalError(err));
			return;
		}

		this.set("grid.items", resp);
	}

	_gridColumnConverter(cols) {
		return cols.map(c => {
			return {
				label: window.OEUtils.camelCaseToLabel(c.key),
				...c
			};
		});
	}

	_showListView() {
		this.set("selectedView", "list");
	}

	__initReject(task) {
		this.setProperties({
			_selectedTask: task,
			rejectionComments: ""
		});
		this.$.rejectDialog.open();
	}

	_close() {
		this.$.rejectDialog.close();
	}

	__confirm(msg, callback, okMsg) {
		this.fire("oe-show-confirm", {
			message: msg,
			ok: callback,
			okLabel: okMsg
		});
	}
}

export default AdminApproveBase;
