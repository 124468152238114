import AdminApproveBase from "./admin-approve-base";
import { html } from "@polymer/polymer/polymer-element.js";

class AdminApproveUser extends AdminApproveBase {
	static get is() {
		return "admin-approve-user";
	}

	constructor() {
		super();
		this.setProperties({
			ui: {
				heading: "Approve User requests",
				url: "@restApiRoot/AppUsers",
				filter: {
					include: ["application"]
				},
				approvalActions: [
					{
						"d": "Onboard organization and approve",
						"c": "org-admin-user"
					},
					{
						"d": "Approve and assign organization",
						"c": "org-user"
					},
					{
						"d": "Approve",
						"c": "approve-user"
					}
				]
			},
			grid: {
				columns: [
					{ key: "_name", label: "Name", valueGetter: r => `${r.firstName} ${r.lastName}` },
					{ key: "email" },
					{ key: "orgName", label: "Organization" },
					{
						key: "__orgApproved",
						label: "Org Approved",
						renderer: () => {
							return `
                            <style>
                                #approved{
                                    color : green;
                                }
                            </style>
                            <iron-icon id="approved" title="Organization is registered and approved" hidden=[[!row.organizationId]] icon="done"></iron-icon>
                            `;
						}, disableFilter: true, disableSort: true
					},
					{ key: "emailVerified", disableFilter: true, disableSort: true }
				],
				rowActions: [
					{ action: "approve", title: "Approve", icon: "done", isHiddenFunction: r => r.approved },
					{ action: "revoke", title: "Revoke", icon: "close", isHiddenFunction: r => !r.approved },
					{ action: "delete", title: "Delete", icon: "delete" }
				],
				items: []
			}
		});
		this.__fetchList();
	}

	static get template() {
		return html`
        <style include="input-styles"></style>
        ${super.template}
        ${this.deleteUserDialog}
        ${this.approveUserDialog}
        `;
	}

	__handleRowAction(event) {
		let {
			action,
			row
		} = event.detail;

		if (action.action === "delete") {
			let applen = row.application.length;
			if (applen !== 0) {
				this.fire("oe-show-error", `The selected user has ${applen} application(s) and cannot be deleted.`);
			} else {
				this._launchDeleteDlg(row);
			}
			return;
		}

		let url; let method = "POST"; let msg;
		switch (action.action) {
		case "approve":
			return this._launchApproveDlg(row);
		case "revoke":
			url = `@restApiRoot/AppUsers/${row.id}/revoke`;
			msg = "User approval revoked successfully.";
			break;
		default: break;
		}


		this.makeAjaxCall(url, method, null, null, null, null, (err, resp) => {
			if (err) {
				this.fire("oe-show-error", window.devportalError(err));
				return;
			}
			this.fire("oe-show-success", msg);
			this.__fetchList();
		});
	}

	async __fetchList() {
		await super.__fetchList();

		let [err, organizations] = await this._makeAjaxPromise("@restApiRoot/Organizations");
		let [err2, catalogsList] = await this._makeAjaxPromise("@restApiRoot/Catalogs");

		if (err || err2) {
			this.fire("oe-show-error", window.devportalError(err || err2));
			return;
		}
		this.set("organizationsList", organizations);
		this.set("catalogsList", catalogsList);
	}

	/* Delete User Flow */

	static get deleteUserDialog() {
		return html`
            <tpp-dialog id="deleteDialog" modal>
                <label slot="header">
                    <oe-i18n-msg msgid="Confirm Delete User [[deleteInfo.userInfo.email]]"></oe-i18n-msg>	
                </label>
                <div slot="body" class="dialog-content">
                    <oe-textarea rows="4" max-rows="4" class="rounded-input" always-float-label label="Comments" value="{{deleteInfo.comments}}"></oe-textarea>
                </div>
                <tpp-button slot="footer" mini primary message="Delete" on-tap="_confirmDelete"></tpp-button>
                <tpp-button slot="footer" mini message="Cancel" on-tap="_closeDeleteDlg"></tpp-button>
            </tpp-dialog>
        `;
	}

	_launchDeleteDlg(userInfo) {
		this.set("deleteInfo", {
			userInfo,
			comments: ""
		});
		this.$.deleteDialog.open();
	}

	async _confirmDelete() {
		let {
			userInfo,
			comments
		} = this.deleteInfo;

		let [err] = await this._makeAjaxPromise(`@restApiRoot/AppUsers/${userInfo.id}/reject`, "POST", { reason: comments });
		if (err) {
			return this.fire("oe-show-error", window.devportalError(err));
		}
		this.fire("oe-show-success", "User deleted successfully.");
		this.__fetchList();
		this._closeDeleteDlg();
	}

	_closeDeleteDlg() {
		this.$.deleteDialog.close();
	}

	/* Approve User flow */

	static get approveUserDialog() {
		return html`
            <style>
                #approveDialog::part(dialog){
                    top : 100px;
                }

                .approve-panel{
                    min-height: 200px;
                }

                .action-selector{
                    margin-bottom:16px;
                }

                .col-2-grid{
                    display:grid;
                    grid-template-columns : 1fr 1fr;
                    gap : 8px 16px;
                }
            </style>
            <tpp-dialog id="approveDialog" modal>
                <label slot="header">
                    <oe-i18n-msg msgid="Approve user : [[approveInfo.userInfo.email]]"></oe-i18n-msg>	
                </label>
                <div slot="body" class="dialog-content">
                    <oe-radio-group label="Approval Action" listdata=[[ui.approvalActions]] valueproperty="c" displayproperty="d"
                    value={{approveInfo.action}} class="action-selector"></oe-radio-group>
                    <iron-pages selected=[[approveInfo.action]] attr-for-selected="id">
                        <oe-block-validator id="org-admin-user" class="approve-panel col-2-grid">
                            <tpp-input field-id="name" required label="Company Legal Name" value={{approveInfo.actionInfo.organization.name}}></tpp-input>
                            <tpp-input field-id="url" type="url"  pattern="https://.*" user-error-message='{"patternMismatch":"URL should start with https://"}' required label="Company Website URL"
                                value={{approveInfo.actionInfo.organization.url}}></tpp-input>
                            <tpp-input required label="CIF ID" field-id="cifId" value={{approveInfo.actionInfo.organization.cifId}} title="Only Alphanumeric (a-z A-Z 0-9) values are allowed"
                                pattern="[a-zA-Z0-9]+" user-error-message='{"patternMismatch":"Only Alphanumeric values are allowed"}'></tpp-input>
                            <tpp-dropdown field-id="catalogs" multi label="Catalogs" value={{approveInfo.actionInfo.organization.catalogs}} list=[[catalogsList]] disp="name" val="id"></tpp-dropdown>
                        </oe-block-validator>
                        <oe-block-validator id="org-user" class="approve-panel">
                            <tpp-dropdown field-id="mappedOrganization" label="Organization" value={{approveInfo.actionInfo.mappedOrganization}} list=[[organizationsList]] disp="name"></tpp-dropdown>
                            <div hidden=[[!approveInfo.actionInfo.mappedOrganization]] class="mapped-org-info col-2-grid">
                                <tpp-input readonly label="Company URL" value=[[approveInfo.actionInfo.mappedOrganization.url]]></tpp-input>
                                <tpp-input readonly label="Company Owner email" value=[[approveInfo.actionInfo.mappedOrganization.email]]></tpp-input>
                            </div>
                        </oe-block-validator>
                        <div id="approve-user"></div>
                    </iron-pages>
                </div>
                <tpp-button slot="footer" mini primary message="Appove" on-tap="_confirmApprove"></tpp-button>
                <tpp-button slot="footer" mini message="Cancel" on-tap="_closeApproveDlg"></tpp-button>
            </tpp-dialog>
        `;
	}

	_launchApproveDlg(userInfo) {
		this.set("approveInfo", {
			userInfo,
			action: "approve-user",
			actionInfo: {
				organization: {
					name: userInfo.orgName
				},
				mappedOrganization: this.organizationsList[0]
			}
		});
		this.$.approveDialog.open();
	}

	async _confirmApprove() {
		let {
			userInfo,
			action,
			actionInfo
		} = this.approveInfo;

		let payload = {
			action
		};

		let validator = this.$[action];
		if (validator && typeof validator.validateForm === "function") {
			let result = await validator.validateForm();
			if (!result.valid) {
				this.fire("oe-show-error", "Error in form validation.");
				return;
			}
		}

		let msg = `Confirm approving user ${userInfo.email} ?`;
		if (action === "org-admin-user") {
			payload.organization = actionInfo.organization;
			msg = `Confirm creation of organization ${actionInfo.organization.name} and assiging to user ${userInfo.email} ?`;
		} else if (action === "org-user") {
			payload.mappedOrgId = actionInfo.mappedOrganization.id;
			msg = `Confirm assigning user ${userInfo.email} to organization ${actionInfo.mappedOrganization.name} ?`;
		}

		this.fire("oe-show-confirm", {
			message: msg,
			okLabel: "Confirm",
			ok: async () => {
				let [err] = await this._makeAjaxPromise(`@restApiRoot/AppUsers/${userInfo.id}/approve`, "POST", payload);
				if (err) {
					return this.fire("oe-show-error", window.devportalError(err));
				}
				this.fire("oe-show-success", "User approved successfully.");
				this.__fetchList();
				this._closeApproveDlg();
			}
		});
	}

	_closeApproveDlg() {
		this.$.approveDialog.close();
	}
}

window.customElements.define(AdminApproveUser.is, AdminApproveUser);
