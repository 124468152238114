import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import "@polymer/iron-flex-layout/iron-flex-layout.js";
import "@polymer/iron-flex-layout/iron-flex-layout-classes.js";
import { ReduxMixin } from "../../redux/redux-mixin.js";
import "../../../styles/app-theme.js";
import { PromiseMixin } from "../../mixins/promise-mixin.js";

class ProfileBaseView extends ReduxMixin(PromiseMixin(PolymerElement)) {
	static get stylesTemplate() {
		return html`
			<style include="iron-flex iron-flex-alignment iron-flex-factors">
				:host{
					color:var(--primary-text-color);
				}

				#container{
					padding-bottom:40px;
					padding-left:16px;
				}

				.section-title{
					font-size:40px;
					color:var(--primary-dark-color);
					margin:0px;
					display : block;
				}

				#container > .section-title{
					margin:0px 0px 16px 0px;
				}

				h1, h2, h3, h4{
					margin-top: 0;
					margin-bottom: .5rem;
					font-weight: 500;
					line-height: 1.2;
				}

				.primary-text{
					color:var(--primary-dark-color);
				}

				[hidden]{
					display:none !important;
				}
			</style>
		`;
	}

	static get viewHeaderTemplate() {
		return html`
			<h2 class="section-title" hidden=[[hideViewTitle]]>
				<oe-i18n-msg msgid=[[viewTitle]]></oe-i18n-msg>	
			</h2>
		`;
	}

	static get contentTemplate() {
		return html``;
	}

	static get template() {
		return html `
        ${this.stylesTemplate}
		<div id="container">
			${this.viewHeaderTemplate}
			<section>
				${this.contentTemplate}
			</section>
		</div>
        `;
	}

	constructor() {
		super();
		this.set("defaultValues", {
			booleanList: [
				{ d: "Yes", v: true },
				{ d: "No", v: false }
			]
		});
	}

	static get properties() {
		return {
			userInfo: {
				type: Object,
				statePath: "userInfo"
			},
			userAccess: {
				type: Object,
				statePath: "userAccess"
			},
			organization: {
				type: Object,
				statePath: "organizationInfo"
			},
			devportalConfig: {
				type: Object,
				statePath: "devportalConfig"
			}
		};
	}

	__isEqual(a, b) {
		return a === b;
	}
}

export default ProfileBaseView;

/*

import { html } from "@polymer/polymer/polymer-element.js";
import ProfileBaseView from "./profile-base-view.js";

class ManageAccountView extends ProfileBaseView {
    static get is() {
        return "manage-account-view";
    }

    static get template(){
        return html`
        ${super.template}
        <style></style>

        `;
    }

    constructor() {
		super();
		this.set("userProfile", {
			name: "",
			email: "",
			company: ""
		});
	}
}

window.customElements.define(ManageAccountView.is, ManageAccountView);


*/
