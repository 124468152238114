import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import "@polymer/iron-flex-layout/iron-flex-layout.js";
import "@polymer/iron-flex-layout/iron-flex-layout-classes.js";
import { OECommonMixin } from "oe-mixins/oe-common-mixin.js";

import "oe-i18n-msg/oe-i18n-msg.js";
import "@polymer/iron-icon/iron-icon.js";
import "@polymer/iron-collapse/iron-collapse.js";
import "@polymer/paper-checkbox/paper-checkbox.js";

class TreeBuilder extends OECommonMixin(PolymerElement) {
	static get is() {
		return "tree-builder";
	}

	static get template() {
		return html`
        <tree-node node=[[_tree]] on-root-node-clicked="toggleNodeSelection"></tree-node>
        `;
	}


	static get properties() {
		return {
			selected: {
				type: Array,
				value: () => [],
				notify: true
			},
			tree: {
				type: Object,
				observer: "_treeChanged"
			}
		};
	}

	toggleNodeSelection(event) {
		let nodeEl = event.detail;
		let node = nodeEl.node;
		this._updateNodeChildren(nodeEl, !node._tree.selected);
		this._updateNodeParent(nodeEl);
		let selected = this._populateSelectedNode();
		this.set("selected", selected.map(n => {
			let allPaths = [...n._tree.path];
			allPaths.shift();
			return allPaths.join("/");
		}));
		this.fire("node-selection-changed", this.selected);
	}


	_populateSelectedNode(node = this._tree, result = []) {
		if (node.children.length === 0) {
			if (node._tree.selected) {
				result.push(node);
			}
		} else {
			node.children.forEach(child => {
				this._populateSelectedNode(child, result);
			});
		}
		return result;
	}

	_updateNodeChildren(nodeEl, selected) {
		nodeEl.set("node._tree.selected", selected);
		let children = [...nodeEl.shadowRoot.querySelectorAll("#child-container tree-node")];
		children.forEach(child => {
			this._updateNodeChildren(child, selected);
		});
	}

	_updateNodeParent(nodeEl) {
		let parentNode = nodeEl.getRootNode().host;
		if (parentNode === this) {
			return;
		}
		let node = parentNode.node;
		let isSelected = node.children.every(n => n._tree.selected);
		parentNode.set("node._tree.selected", isSelected);
		this._updateNodeParent(parentNode);
	}


	_treeChanged() {
		if (this.tree) {
			let _tree = this.__createNode(this.tree);
			this.set("_tree", _tree);
		}
	}

	__createNode(node, parentPath = []) {
		if (!node.children) {
			node.children = [];
		}
		node._tree = {
			collapsed: false,
			selected: false,
			isLeafNode: node.children.length === 0,
			path: [...parentPath, node.key]
		};
		node.children.forEach(child => {
			this.__createNode(child, node._tree.path);
		});
		return node;
	}
}
window.customElements.define(TreeBuilder.is, TreeBuilder);


class TreeNode extends OECommonMixin(PolymerElement) {
	static get is() {
		return "tree-node";
	}

	static get template() {
		return html`
        <style>
            .current-level-content{
                cursor:pointer;
                display: inline-flex;
                align-items: center;
                margin-bottom:8px;
                position:relative;
            }

            .current-level-content.root-true:hover .node-icon.root-node{
                opacity:1;
            }

            .current-level-content.selected-true{
                color: green;
                font-weight:bold;
            }

            .node-icon{
                margin-right:2px;
                --iron-icon-width:16px;
                --iron-icon-height:16px;
            }

            .node-icon.root-node:not(.node-selected){
                opacity:0;
            }

            .node-icon.node-opened{
                transform:rotate(45deg);
            }

            .current-level-content label{
                cursor:pointer;
                font-size:14px;
                text-transform : capitalize;
                padding-right:4px;
            }

            #child-container{
                margin-left:8px;
                display:flex;
                flex-direction:column;
                position:relative;
            }

        </style>
        <div class$="current-level-content selected-[[node._tree.selected]] root-[[node._tree.isLeafNode]]" on-tap="_nodeClicked">
            <iron-icon icon="[[_getIcon(node._tree.isLeafNode)]]" class$="node-icon [[_getIconClass(node._tree.collapsed,node._tree.selected,node._tree.isLeafNode)]]"></iron-icon>
            <paper-checkbox checked=[[node._tree.selected]] on-tap="_checkboxChanged"></paper-checkbox>
            <label>[[node.name]]</label>
        </div>
        <dom-if if=[[!node._tree.isLeafNode]]>
            <template>
                <iron-collapse id="child-container" opened=[[!node._tree.collapsed]]>
                    <dom-repeat items=[[node.children]]>
                        <template>
                            <tree-node node=[[item]]></tree-node>
                        </template>
                    </dom-repeat>
                </iron-collapse>
            </template>
        </dom-if>
        `;
	}

	static get properties() {
		return {
			node: {
				type: Object
			}
		};
	}

	_getIconClass(collapsed, selected, rootNode) {
		if (rootNode) {
			return selected ? "node-selected root-node" : "root-node";
		}
		return collapsed ? "" : "node-opened";
	}

	_getIcon(rootNode) {
		return rootNode ? "" : "chevron-right";
	}

	_checkboxChanged(event) {
		event.stopPropagation();
		this.fire("root-node-clicked", this);
	}

	_nodeClicked() {
		if (this.node._tree.isLeafNode) {
			this.fire("root-node-clicked", this);
		} else {
			this.set("node._tree.collapsed", !this.node._tree.collapsed);
		}
	}
}

window.customElements.define(TreeNode.is, TreeNode);
