import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import "@polymer/iron-flex-layout/iron-flex-layout.js";
import "@polymer/iron-flex-layout/iron-flex-layout-classes.js";
import { OEAjaxMixin } from "oe-mixins/oe-ajax-mixin.js";
import { ReduxMixin } from "../redux/redux-mixin.js";
import "oe-input/oe-input.js";
import "@polymer/app-route/app-location.js";
import "@polymer/iron-icon/iron-icon.js";
import "@polymer/iron-icons/iron-icons.js";
import "@polymer/iron-icons/social-icons.js";
import "@polymer/paper-tabs/paper-tabs.js";
import "@polymer/paper-tabs/paper-tab.js";
import "@polymer/paper-button/paper-button.js";
import "@polymer/paper-icon-button/paper-icon-button.js";
import "@polymer/paper-menu-button/paper-menu-button.js";
import "@polymer/paper-listbox/paper-listbox.js";
import "@polymer/paper-item/paper-item.js";

import { ROLEID } from "../redux/reducer.js";

/**
 * ### header-section
 * `header-section`
 *
 *
 * @customElement
 * @polymer
 */
class HeaderSection extends OEAjaxMixin(PolymerElement) {
	static get is() {
		return "header-section";
	}

	static get template() {
		return html`
    <style include="iron-flex iron-flex-alignment">
        :host{
            display:block;
            background-color: var(--primary-background-color);
            transition: all 300ms ease-in;
            --btn-spacing:36px;
        }

        #container{
            height:108px;
            box-sizing: border-box;
            padding:12px 0px;
            @apply --canvas;
            display:flex;
        }

        .logo {
            height: 48px;
            cursor:pointer;
            margin-left: 5px;
        }

        .search-container{
            height:46px;
            padding: 0 8px;
            justify-content: flex-end;
        }

        /* NAV GROUP CSS */

        .nav-group-list{
            list-style:none;
            font-size: 14px;
            margin:0px;
        }

        .nav-group-list a{
            display: flex;
            align-items: center;
            justify-content: space-between;
            letter-spacing: 0.5px;
            padding: 15px 20px;
            text-decoration:none;
            padding: 10px 20px;
            font-size: 16px;
            font-weight: 300;
            box-sizing: border-box;
            color: var(--primary-grey-color);
        }

        .nav-group-list a:focus{
            color:var(--primary-dark-color);
            outline:1px solid var(--primary-dark-color);
            outline-offset:-1px;
        }

        .nav-group-list a:hover, a[active-path]{
            color:var(--primary-dark-color);
        }

        .nav-group-item{
            position:relative;
        }

        .nav-group-item > a{
            height: 44px;
            margin-left:var(--btn-spacing);
        }

        .nav-group-child-list{
            list-style:none;
            position:absolute;
            background:var(--primary-contrast-color);
            z-index:-1;
            overflow:hidden;
            top:44px;
            right:0px;
            height:auto;
            pointer-events:none;
            opacity: 0;
            padding:10px 0px;
            transition: all 300ms ease-in;
            box-shadow: 0px 0px 30px rgb(127 137 161 / 25%);
            border-radius: 4px;
            transform : translateY(40px);
        }

        .nav-group-item:hover .nav-group-child-list,
        .nav-group-child-list.opened{
            z-index:2;
            opacity:1;
            pointer-events:all;
            padding:10px 0px;
            transform : translateY(0px);
        }

        .nav-child-item > a{
            padding:10px 20px;
            height: 42px;
            font-size: 14px;
            text-transform: none;
            font-weight: 300;
        }

        .primary-nav-item > a{
            color : var(--primary-dark-color);
        }

        /* NAV GROUP CSS */

        .top-container paper-button{
            height: 44px;
            border-radius: 0px;
            text-transform: none;
            letter-spacing: 0.7px;
            font-weight:normal;
            padding: 10px 20px;
            font-size: 14px;
            margin:0px;
            margin-left:var(--btn-spacing);
            color: var(--primary-grey-color);
            display:flex;
            justify-content: end;
            min-width: auto;
        }

        .top-container paper-button[focused]{
            outline:1px solid var(--primary-dark-color);
            outline-offset:-1px;
            color:var(--primary-dark-color);
        }

        .top-container paper-button:hover,
        .top-container paper-button[active-path]{
            color:var(--primary-dark-color);
        }

        .top-container .profile-menu{
            padding:0px;
            margin-left:var(--btn-spacing);
            height:36px;

            --paper-menu-button-content:{
                border-radius:8px;
            }
        }

        .top-container .profile-menu paper-icon-button{
            padding: 4px;
            width: 36px;
            height: 36px;
            border: 1px solid;
            border-radius: 50%;
        }

        .top-container .profile-menu paper-listbox{
            background: var(--secondary-grey-color);
            padding: 6px;
            padding-bottom: 8px;
            border-radius: 8px;
        }

        .top-container .profile-menu paper-item{
            display: flex;
            /* justify-content: end; */
            font-size: 14px;
            letter-spacing: 0.7px;
            background: white;

            --paper-item-focused-before:{
                display:none;
            }
        }

        .top-container .profile-menu .profile-menu-item{
            margin-bottom: 4px;
            padding-bottom: 8px;
            padding-top: 8px;
            line-height: 1.2rem;
        }

        .top-container .profile-menu  .profile-name {
            min-width: 180px;
            max-width: 250px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: bold;
            font-size:16px;
        }

        .top-container .profile-menu paper-item:nth-of-type(1){
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }

        .top-container .profile-menu paper-item:last-of-type{
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }

        .top-container .profile-menu .link-items{
            cursor:pointer;
            --paper-item-min-height: 34px;
        }

        .top-container .profile-menu .link-items:hover{
            background:rgba(0,0,0,0.1);
        }

        .header-panel{
            @apply --layout-horizontal;
        }

        [hidden]{
            display:none !important;
        }

        @media (max-width: 1024px){

            .search-container{
                flex:1;
            }

            .logo{
                width:77px;
            }

            .nav-section{
                display:none;
            }

        }


    </style>

    <header id="container" class="layout horizontal justified header-panel">
        <img alt="Finacle Logo" on-tap="_gotoHome" class="logo" src="img/header-logo.png" />
        <div class="layout vertical">
            <div class="search-container top-container layout horizontal end-justified center">
                <paper-button on-tap="_gotoApis" hidden=[[!showApisLink(userLoggedIn,devEnv)]] active-path$=[[__isActivePath('\/apis',route.path)]]>
                    <oe-i18n-msg msgid="API Catalogue">API Catalogue</oe-i18n-msg>
                </paper-button>
                <div class="button-container layout horizontal" hidden=[[userLoggedIn]]>
                    <paper-button on-tap="_gotoRegister" active-path$=[[__isActivePath('\/register',route.path)]]>
                        <oe-i18n-msg msgid="Register">Register</oe-i18n-msg>    
                    </paper-button>
                    <paper-button data-test-id="login btn" on-tap="_gotoLogin" active-path$=[[__isActivePath('\/login',route.path)]]>
                        <oe-i18n-msg msgid="Login">Login</oe-i18n-msg>    
                    </paper-button>
                </div>
                <div class="button-container layout horizontal center" hidden=[[!userLoggedIn]]>
                    <paper-button on-tap="_gotoApps" hidden=[[!showAppsLink(userRole,userInfo)]] active-path$=[[__isActivePath('\/user-profile\/registered-apps',route.path)]]>
                        <oe-i18n-msg msgid="Apps">Apps</oe-i18n-msg> 
                    </paper-button>
                    <paper-menu-button class="profile-menu" horizontal-align="right" vertical-offset="38">
                        <paper-icon-button data-test-id="header-menu-trigger" icon="social:person" slot="dropdown-trigger"></paper-icon-button>
                        <paper-listbox slot="dropdown-content">
                            <paper-item class="profile-menu-item">
                                <div class="layout vertical">
                                    <div class="profile-name">[[userInfo.firstName]] [[userInfo.lastName]]</div>
                                    <div>[[userInfo.email]]</div>
                                </div>
                            </paper-item>
                            <paper-item class="link-items" on-tap="_gotoProfile">
                                <oe-i18n-msg msgid="Profile">Profile</oe-i18n-msg>    
                            </paper-item>
                            <paper-item class="link-items" on-tap="_logOut" data-test-id="header-logout-option">
                                <oe-i18n-msg msgid="Logout">Logout</oe-i18n-msg>    
                            </paper-item>
                        </paper-listbox>
                    </paper-menu-button>
                </div>
            </div>

            <nav class="nav-section flex" hidden=[[devEnv.DISABLE_HOME_APP]]>
                <ul class="nav-group-list layout horizontal justified">
                    <template is="dom-repeat" items=[[routes.simple]]>
                        <li class="nav-group-item">
                            <a href$="[[__getPath(item.path)]]" active-path$=[[__isActivePath(item.path,route.path)]]>
                                <oe-i18n-msg msgid=[[item.label]]></oe-i18n-msg>    
                            </a>
                        </li>
                    </template>
                    <template is="dom-repeat" items=[[routes.grouped]] as="group">
                        <li class="nav-group-item">
                            <a href$="[[__getPath(group.path)]]" active-path$=[[__isActivePath(group.path,route.path)]]>
                                <oe-i18n-msg msgid=[[group.label]]></oe-i18n-msg>
                                <iron-icon icon="add"></iron-icon>
                            </a>
                            <ul class="nav-group-child-list">
                                <template is="dom-repeat" items=[[group.children]]>
                                    <li class="nav-child-item">
                                        <a href$="[[__getPath(item.path)]]" active-path$=[[__isActivePath(item.path,route.path)]]>
                                            <oe-i18n-msg msgid=[[item.label]]></oe-i18n-msg>    
                                        </a>
                                    </li>
                                </template>
                            </ul>
                        </li>
                    </template>
                </ul>
            </nav>
        </div>
    </header>
    <app-location route="{{route}}"></app-location>
    `;
	}

	static get properties() {
		return {
			userRole: {
				type: String,
				statePath: "userRole"
			},
			userInfo: {
				type: Object,
				statePath: "userInfo"
			},
			userLoggedIn: {
				type: Boolean,
				statePath: "userLoggedIn"
			},
			routes: {
				type: Object
			},
			devEnv: {
				type: Object,
				value: () => {
					return window.devportal;
				}
			}
		};
	}

	connectedCallback() {
		super.connectedCallback();
		window.addEventListener("logout-user", () => this._logOut());
		let staticRoutes = {
			simple: [
				{
					label: "Home",
					path: "/"
				},
				{
					label: "Getting Started",
					path: "/getting-started"
				}
			],
			grouped: [
				{
					label: "Resources",
					path: "/resources",
					children: [
						{
							label: "Industrial Usecases",
							path: "/industry-usecase"
						}, {
							label: "Blog",
							path: "/blog"
						}, {
							label: "Forum",
							path: "/forum"
						}
					]
				},
				{
					label: "About us",
					path: "/about-us",
					children: [
						{
							label: "Support",
							path: "/support"
						}
					]
				}
			]
		};
		this.set("routes", staticRoutes);
		this._updateLinkFocus();
	}

	_updateLinkFocus() {
		this.async(() => {
			let allGroups = Array.from(this.shadowRoot.querySelectorAll(".nav-group-item"));
			allGroups.forEach(navGroup => {
				let groupLinks = Array.from(navGroup.querySelectorAll("a"));
				let dropdown = navGroup.querySelector(".nav-group-child-list");
				if (dropdown) {
					groupLinks.forEach(link => {
						link.onfocus = () => dropdown.classList.add("opened");
						link.onblur = () => dropdown.classList.remove("opened");
					});
				}
			});
		}, 1000);
	}

	__getPath(path) {
		return window.OEUtils.subPath + path;
	}

	_gotoRegister() {
		window.oe_navigate_to("/register");
	}

	_gotoLogin() {
		window.location.pathname = window.OEUtils.subPath + "/authlogin";
	}

	_gotoProfile() {
		window.oe_navigate_to("/user-profile/my-profile");
	}

	_logOut() {
		let subPath = window.OEUtils.subPath || "";
		this.makeAjaxCall(subPath + "/logout", "POST", null, null, null, null, () => {
			window.oe_navigate_to("/");
			window.location.reload();
		});
	}

	__isActivePath(currentPath, routePath) {
		let subPath = window.OEUtils.subPath || "";
		let url = routePath.replace(subPath, "");

		if (currentPath === "/login") {
			return url === currentPath || url === "/login-ldap";
		}

		return url === currentPath;
	}

	_gotoHome() {
		if (window.devportal.DISABLE_HOME_APP) {
			if (this.userRole === ROLEID.UNAUTHENTICATED) {
				this._gotoLogin();
			} else {
				this._gotoProfile();
			}
		} else {
			window.oe_navigate_to("/");
		}
	}

	_gotoApis() {
		window.oe_navigate_to("/apis");
	}

	showApisLink(userLoggedIn, devEnv) {
		if (userLoggedIn) {
			return true;
		}
		return !(devEnv.HIDE_CATALOG_PRELOGIN || devEnv.DISABLE_HOME_APP);
	}

	showAppsLink(userRole, userInfo) {
		return userInfo.organizationId && (userRole === ROLEID.DEVELOPER || userRole === ROLEID.ORGADMIN);
	}

	_gotoApps() {
		window.oe_navigate_to("/user-profile/registered-apps");
		this.fire("update-profile-view", "registered-apps");
	}
}
window.customElements.define(HeaderSection.is, ReduxMixin(HeaderSection));
