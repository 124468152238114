import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import "@polymer/iron-flex-layout/iron-flex-layout.js";
import "@polymer/iron-flex-layout/iron-flex-layout-classes.js";
import { PromiseMixin } from "../../../../mixins/promise-mixin.js";
import "../../../api-bundle-filter.js";
import "oe-ui-accordion/oe-ui-accordion.js";
import "oe-ui-accordion/oe-accordion-item.js";

class ManageAppApiMapping extends PromiseMixin(PolymerElement) {
	static get is() {
		return "manage-app-api-mapping";
	}

	static get properties() {
		return {
			readOnlyMode: {
				type: Boolean,
				value: false
			},
			application: {
				type: String,
				observer: "_applicationChanged"
			},
			existingMaps: {
				type: Array,
				value: () => [],
				notify: true
			},
			allApis: {
				type: Array,
				value: () => [],
				notify: true
			}
		};
	}

	static get template() {
		return html`
            <style include="iron-flex iron-flex-alignment">
                .api-publish-status{
                    margin: 0 8px;
                    --iron-icon-width: 20px;
                    --iron-icon-height: 20px;
                }

                .api-publish-status.api-published{
                    color:#4C9D21;
                }

                .api-publish-status.api-not-published{
                    color:#F5BC5C;
                }

                .bundle-legend label.legend-item{
                    padding-left: 36px;
                    display: block;
                    border-right: 1px solid;
                    padding-right: 16px;
                    width: auto;
                }

                .bundle-legend .legend-item{
                    width: 150px;
                    font-size: 14px;
                    box-sizing: border-box;
                    margin-bottom: 4px;
                }

                oe-ui-accordion{
                    height: 300px;
                    font-size: 14px;
                    overflow: auto;
                    display:block;
                    outline-color: var(--primary-dark-color);
                    outline-style: double;
                }

                oe-accordion-item::part(header){
                    flex-direction: row-reverse;
                    min-height: 30px;
                    height: 30px;
                    box-shadow: 1px 1px 1px 1px rgb(0 0 0 / 20%);
                    background: white;
                    padding: 0 8px;
                }

                .group-name{
                    display:block;
                    margin-left:8px;
                    cursor:pointer;
                }

                .group-api-list{
                    padding: 0px;
                    font-size: 12px;
                    margin: 4px 0px;
                }

                .group-api-item{
                    @apply --layout-horizontal;
                    @apply --layout-center;
                    padding:0px 8px;
                    height:30px;
                }

                .group-api-item[disabled],
                paper-radio-group[disabled]{
                    pointer-events:none;
                    opacity: 0.7;
                }

                .group-api-item.selected{
                    background: var(--primary-dark-color);
                    color: var(--primary-contrast-color);
                }

                .group-api-item::before{
                    content:"";
                    width: 26px;
                    height: 26px;
                    margin: 0 4px;
                    border-radius: 50%;
                    color: white;
                    padding: 0px 7px;
                    box-sizing: border-box;
                    font-size: 18px;
                }

                .group-api-item[api-type="api"]::before{
                    content:"A";
                    background:var(--primary-dark-color);
                }

                .group-api-item[api-type="event"]::before{
                    content:"E";
                    background:var(--secondary-dark-color);
                }

                .group-api-item:not(.selected):hover{
                    background:#FAFAFA;
                }

                .api-method{
                    padding: 6px;
                    color: white;
                    line-height: 8px;
                    border-radius: 2px;
                    margin-right:8px;
                    display:block;
                    min-width: 30px;
                    text-align: center;
                }

                .api-selection{
                    margin:0 8px;
                    --paper-checkbox-size: 1rem;
                    --paper-checkbox-ink-size:30px;
                    --paper-checkbox-label-spacing : 0px;
                }

                .api-name,.api-status{
                    display: block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .method-get{ background : #61affe; }
                .method-post{ background : #49cc90; }
                .method-put{ background : #49cc90; }
                .method-delete{ background : #49cc90; }

                
            </style>
            <api-bundle-filter filter={{apiFilter}}>
                <div class="bundle-legend layout horizontal end-justified center">
                    <label class="legend-item">
						<oe-i18n-msg msgid="Legend">Legend</oe-i18n-msg>
					</label>
                    <div class="legend-item layout horizontal">
                        <iron-icon class="api-publish-status api-published" icon="done"></iron-icon>
						<label>
							<oe-i18n-msg msgid="Ready to use">Ready to use</oe-i18n-msg>
						</label>
                    </div>
                    <div class="legend-item layout horizontal">
                        <iron-icon class="api-publish-status api-not-published" icon="warning"></iron-icon>
                        <label>
							<oe-i18n-msg msgid="Awaiting publish">Awaiting publish</oe-i18n-msg>
						</label>
                    </div>
                </div>
                <oe-ui-accordion multiple>
                    <dom-repeat items={{apiGroups}} as="group" filter=[[__groupFilter(apiFilter.trigger,apiFilter.key,apiFilter.category)]]>
                        <template>
                            <oe-accordion-item>
                                <label slot="title" class="group-name">[[group.name]]</label>
                                <div slot="content" class="group-api-list">
                                    <dom-repeat items=[[group.apiList]] as="api">
                                        <template>
                                            <div class="group-api-item" api-type$="[[api.type]]" title$="[[api.description]]" disabled$=[[readOnlyMode]]>
                                                <paper-checkbox disabled=[[readOnlyMode]] class="api-selection" checked={{api._info.selected}}></paper-checkbox>
                                                <span class$="api-method method-[[api.method]]">[[api.method]]</span>
                                                <label class="flex api-name">[[api.name]]</label>
                                                <label class="api-status"><oe-i18n-msg msgid=[[api._info._status]]></oe-i18n-msg></label>
                                                <iron-icon class$="api-publish-status [[api._info._iconClass]]" icon=[[api._info._icon]]></iron-icon>
                                            </div>
                                        </template>
                                    </dom-repeat>
                                </div>
                            </oe-accordion-item>
                        </template>
                    </dom-repeat>
                </oe-ui-accordion>
            </api-bundle-filter>
        `;
	}

	async _applicationChanged() {
		if (this.application && this.application.id) {
			this.appId = this.application.id;

			try {
				let url = `@restApiRoot/Applications/${this.appId}/getAppDetails`;
				let [err, resp] = await this._makeAjaxPromise(url);

				if (err) {
					this.fire("oe-show-error", window.devportalError(err));
					return;
				}

				let {
					apiBundles,
					apiGroups,
					appApiMaps
				} = resp;

				let allApis = [];

				apiBundles.forEach(bundle => {
					if (Array.isArray(bundle.api)) {
						bundle.api.forEach(api => {
							api.apiGroupKey = bundle.group;
							let isMapped = appApiMaps.find(mapped => mapped.apiId === api.id);
							api._info = {
								selected: false
							};
							if (isMapped) {
								api._info = {
									selected: !(isMapped.action === "delete" && !isMapped.approved),
									approved: isMapped.approved,
									published: isMapped.published,
									_icon: "",
									_iconClass: "",
									action: isMapped.action
								};

								if (isMapped.approved) {
									api._info._icon = isMapped.published ? "done" : "warning";
									api._info._iconClass = isMapped.published ? "api-published" : "api-not-published";
								}

								if (typeof isMapped.action === "undefined") {
									api._info._status = "";
								} else {
									api._info._status = isMapped.approved ? "Approved" : `Pending - ${isMapped.action}`;
								}
							}
							allApis.push(api);
						});
					}
				});

				apiGroups.forEach(group => {
					group.apiList = allApis.filter(api => api.apiGroupKey === group.key);
				});

				this.set("allApis", allApis);
				this.set("existingMaps", appApiMaps);
				this.set("apiGroups", apiGroups.filter(group => group.apiList.length > 0));
			} catch (error) {
				this.fire("oe-show-error", error);
			}
		} else {
			this.set("apiGroups", []);
		}
	}

	__groupFilter() {
		let {
			key,
			tags,
			category
		} = this.apiFilter;

		if (!key && !tags.top && tags.tree.length === 0 && category === "all") {
			return null;
		}

		let searchName = key.toLowerCase();
		let matchingTags = [];

		if (tags.tree.length !== 0) {
			tags.tree.forEach(path => {
				matchingTags.push(tags.top + path);
			});
		} else {
			matchingTags.push(tags.top);
		}

		let hasCategory = category === "all" ? null : category;

		return group => {
			if (searchName && group.name.toLowerCase().indexOf(searchName) === -1) {
				return false;
			}

			if (matchingTags.length > 0) {
				let isTagValid = group.tag.some(gtag => {
					return matchingTags.some(ftag => {
						return gtag.startsWith(ftag);
					});
				});
				if (!isTagValid) {
					return false;
				}
			}

			if (hasCategory) {
				return group.category === hasCategory;
			}

			return true;
		};
	}
}

window.customElements.define(ManageAppApiMapping.is, ManageAppApiMapping);
