import { html } from "@polymer/polymer/polymer-element.js";
import ProfileBaseView from "./profile-base-view.js";

class ContactUsView extends ProfileBaseView {
	static get is() {
		return "contact-us-view";
	}

	constructor() {
		super();
		this.set("viewTitle", "Contact Us");
	}

	static get contentTemplate() {
		let cust = window.devportal.customize;
		return cust["contact-text"] ? cust["contact-text"] : html`<label><oe-i18n-msg msgid="NOT LOADED">NOT LOADED</oe-i18n-msg></label>`;
	}
}

window.customElements.define(ContactUsView.is, ContactUsView);
