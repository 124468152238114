import "../pages/login-user-page.js";
const LoginUserPage = window.customElements.get("login-user-page");

/**
 * ### ldap-login-user-page
 * `ldap-login-user-page` extends from 'login-user-page' to support ldap login
 *
 *
 * @customElement
 * @polymer
 */
class LdapLoginUserPage extends LoginUserPage {
	static get is() {
		return "ldap-login-user-page";
	}

	// Overriding loginURL from 'login-user-page'
	connectedCallback() {
		super.connectedCallback();
		this._loginURL = window.OEUtils.subPath + "/auth/ldap";
	}

	// Overriding _handleLoginError from 'login-user-page'
	_handleLoginError(err) {
		this.fire("oe-show-error", typeof err.detail.request.response === "object" ? err.detail.request.response.error.message : ( err.detail.request.response || "Error occured while login. Please try after sometime or contact administrator for further details."));
	}

	// Overriding __preparePayload from 'login-user-page'
	__preparePayload() {
		let {
			email,
			password
		} = this.loginInfo;

		let payload = {
			username: email,
			password
		};

		return payload;
	}
}
window.customElements.define(LdapLoginUserPage.is, LdapLoginUserPage);
