import * as ACTIONS from "./actions.js";

export const ROLEID = {
	/** User not logged in */
	UNAUTHENTICATED: "unauthenticated",
	/** User logged in , doesn't belong to any organization  */
	AUTHENTICATED: "authenticated",
	/** TPP app admin  */
	ADMIN: "admin",
	/** Organization level admin  */
	ORGADMIN: "organization-admin",
	/** Organization API viewer  */
	VIEWER: "viewer",
	/** Organization Application developer  */
	DEVELOPER: "developer",
	/** Organization publisher, incharge of api bundle uploads */
	PUBLISHER: "publisher"
};

let navigations = [{
	groupName: "Manage Your Profile",
	icon: "face",
	links: [{
		label: "My profile",
		path: "my-profile",
		// retainInstance: true,
		type: "elem",
		name: "my-profile-view"
	},
	{
		label: "Change password",
		path: "change-password",
		// retainInstance: true,
		type: "elem",
		name: "change-password-view"
	}
	]
},
{
	groupName: "Your Company Profile",
	icon: "work",
	allow: [ROLEID.AUTHENTICATED, ROLEID.ORGADMIN],
	links: [{
		label: "My company",
		path: "my-company",
		// retainInstance: true,
		type: "elem",
		name: "my-company-view"
	},
	{
		label: "Manage CA cerificate",
		path: "manage-certificate",
		allow: [ROLEID.ORGADMIN],
		// retainInstance: true,
		type: "elem",
		name: "manage-certificate-view"
	}
	]
},
// {
// 	groupName: "Manage you Accounts",
// 	icon: "stars",
// 	allow: [ROLEID.ORGADMIN],
// 	links: [{
// 		label: "Setup charge account",
// 		path: "setup-charge-account",
// retainInstance: true,
// 		type: "elem",
// 		name: "manage-account-view"
// 	},
// 	{
// 		label: "Setup payment account",
// 		path: "setup-payment-account",
// retainInstance: true,
// 		type: "elem",
// 		name: ""
// 	},
// 	{
// 		label: "Setup & Manage beneficiary",
// 		path: "setup-manage-beneficiary",
// retainInstance: true,
// 		type: "elem",
// 		name: ""
// 	}
// 	]
// },
{
	groupName: "Manage Your Apps",
	icon: "next-week",
	allow: [ROLEID.DEVELOPER, ROLEID.ORGADMIN],
	links: [{
		label: "Registered apps",
		path: "registered-apps",
		// retainInstance: true,
		type: "elem",
		name: "manage-app-view"
	}]
},
// {
// 	groupName: "API Dashboard",
// 	icon: "dashboard",
// 	allow: [ROLEID.DEVELOPER, ROLEID.ORGADMIN],
// 	links: [{
// 		label: "Status & analytics",
// 		path: "status-analytics",
// retainInstance: true,
// 		type: "elem",
// 		name: "analytics-report-view"
// 	}]
// },
{
	groupName: "Setup Your Team",
	icon: "supervisor-account",
	allow: [ROLEID.ORGADMIN],
	links: [{
		label: "Manage your developers",
		path: "manage-your-developers",
		// retainInstance: true,
		type: "elem",
		name: "setup-team-view"
	}]
},
// {
// 	groupName: "Test Certificate",
// 	icon: "verified-user",
// 	allow: [ROLEID.DEVELOPER, ROLEID.ORGADMIN],
// 	links: [{
// 		label: "Download certificate for Sandbox",
// 		path: "download-certificate",
// retainInstance: true,
// 		type: "elem",
// 		name: ""
// 	}]
// },
{
	groupName: "API Bundle",
	icon: "build",
	allow: [ROLEID.ADMIN, ROLEID.PUBLISHER],
	links: [{
		label: "Manage API bundles",
		path: "api-bundle",
		// retainInstance: true,
		type: "elem",
		name: "manage-api-bundle"
	},
	{
		label: "Manage Catalogs",
		path: "manage-catalog",
		type: "elem",
		name: "manage-catalog-view"
	}]
},
{
	groupName: "Administration",
	icon: "verified-user",
	allow: [ROLEID.ADMIN],
	links: [{
		label: "User approval",
		path: "approve-user",
		// retainInstance: true,
		type: "elem",
		name: "admin-approve-user"
	}, {
		label: "Organization approval",
		path: "approve-organization",
		// retainInstance: true,
		type: "elem",
		name: "admin-approve-organization"
	}, {
		label: "Subscription approval",
		path: "approve-app",
		// retainInstance: true,
		type: "elem",
		name: "admin-approve-app"
	}, {
		label: "App publish approval",
		path: "approve-publish-app",
		// retainInstance: true,
		type: "elem",
		name: "admin-approve-publish-app"
	}]
},
{
	groupName: "Support",
	icon: "bug-report",
	links: [{
		label: "Contact us",
		path: "contact-us",
		// retainInstance: true,
		type: "elem",
		name: "contact-us-view"
	},
	{
		label: "FAQs",
		path: "faqs",
		// retainInstance: true,
		type: "elem",
		name: "faq-view"
	}
	// {
	// 	label: "Support Tracker",
	// 	path: "support-tracker",
		// retainInstance: true,
	// 	type: "elem",
	// 	name: ""
	// }
	]
}];


let updateValidLinks = (state) => {
	let {
		navLinks,
		userRole,
		devportalConfig
	} = state;

	let validNavLinks = [];
	let validPaths = [];

	let filterAllowByRole = (item) => {
		if (Array.isArray(item.allow)) {
			return item.allow.indexOf(userRole) !== -1;
		}
		return true;
	};

	let _navLinks = JSON.parse(JSON.stringify(navLinks));

	let allowedGroups = _navLinks.filter(filterAllowByRole);
	allowedGroups.forEach(navGroup => {
		navGroup.showList = true;
		navGroup.links = navGroup.links.filter(filterAllowByRole);
		validNavLinks.push(navGroup);
		validPaths.push(...navGroup.links);
	});

	if (devportalConfig.DISABLE_PUBLISH_APP) {
		let approvalGroup = validNavLinks.find(group => group.groupName === "Administration");
		if (approvalGroup) {
			approvalGroup.links = approvalGroup.links.filter(link => link.name !== "admin-approve-publish-app");
		}
		validPaths = validPaths.filter(link => link.name !== "admin-approve-publish-app");
	}

	if (state.pendingInvites > 0) {
		validNavLinks = validNavLinks.filter(group => group.groupName !== "Your Company Profile");
		validPaths = validPaths.filter(link => link.name !== "my-company-view" && link.name !== "manage-certificate-view");
	}

	state.validNavLinks = JSON.parse(JSON.stringify(validNavLinks));
	state.validPaths = JSON.parse(JSON.stringify(validPaths));
};


let handleUpdateUserInfo = (state, detail) => {
	state.userInfo = detail;
	if (detail && detail.roles) {
		if (detail.roles.length > 0) {
			// User part of organization or admin
			state.userRole = detail.roles[0];
		} else {
			// User logged in but not part of organization
			state.userRole = ROLEID.AUTHENTICATED;
		}
	} else {
		state.userRole = ROLEID.UNAUTHENTICATED;
	}

	let update = {};
	switch (state.userRole) {
	case ROLEID.ADMIN: update = { admin: true, orgAdmin: true, developer: true, viewer: true }; break;
	case ROLEID.ORGADMIN: update = { orgAdmin: true, developer: true, viewer: true }; break;
	case ROLEID.DEVELOPER: update = { developer: true, viewer: true }; break;
	case ROLEID.VIEWER: update = { viewer: true }; break;
	default:break;
	}
	state.userAccess = {
		...state.userAccess,
		...update
	};
	state.userLoggedIn = detail.email;
};


const INTITIAL_STATE = {
	userInfo: {},
	userRole: ROLEID.UNAUTHENTICATED,
	devportalConfig: window.window.devportal,
	userAccess: {
		admin: false,
		orgAdmin: false,
		developer: false,
		viewer: false
	},
	userLoggedIn: false,
	organizationInfo: null,
	navLinks: navigations,
	pendingInvites: 0,
	validNavLinks: [],
	validPaths: []
};

const reducer = (state = INTITIAL_STATE, action) => {
	let newState = JSON.parse(JSON.stringify(state));
	// let newState = {...state };

	switch (action.type) {
	case ACTIONS.UPDATE_USER_INFO:
		handleUpdateUserInfo(newState, action.detail);
		break;
	case ACTIONS.UPDATE_ORGANIZATION_INFO:
		newState.organizationInfo = action.detail;
		break;
	case ACTIONS.UPDATE_INVITES_INFO:
		newState.pendingInvites = action.detail.filter(inv => inv.status === "pending").length;
		break;
	default:
		break;
	}

	if (state.userRole !== newState.userRole ||
        state.navLinks !== newState.navLinks ||
        state.pendingInvites !== newState.pendingInvites) {
		updateValidLinks(newState);
	}

	return newState;
};

export default reducer;
