import { html } from "@polymer/polymer/polymer-element.js";
import AdminApproveBase from "./admin-approve-base";
import "../view-organization-details";

class AdminApproveOrganization extends AdminApproveBase {
	static get is() {
		return "admin-approve-organization";
	}

	static get listTemplate() {
		return html`
            <oe-data-grid label="Pending Organization requests" id="grid" items=[[tasksGrid.items]] disable-selection
                disable-config-editor disable-full-screen-mode disable-add on-oe-data-table-row-action="__handleTaskAction"
                on-oe-data-table-action-refresh="__fetchList" toolbar-actions=[[_gridActions]]
                columns=[[_gridColumnConverter(tasksGrid.columns)]] row-actions=[[tasksGrid.rowActions]]></oe-data-grid>
            
            <oe-data-grid label="Organization list" id="list-grid" items={{grid.items}} disable-selection disable-config-editor
                disable-full-screen-mode disable-add on-oe-data-table-row-action="__handleRowAction"
                on-oe-data-table-action-refresh="__fetchList" toolbar-actions=[[_gridActions]]
                columns=[[_gridColumnConverter(grid.columns)]] row-actions=[[grid.rowActions]]></oe-data-grid>
                ${this.approveDlgTemplate}
				${this.catalogMapTemplate}
        `;
	}

	static get itemTemplate() {
		return html`
        <div class="application-heading layout horizontal center justified">
            <h2 class="app-name flex primary-text section-title">
				<oe-i18n-msg msgid="Organization details">Organization details</oe-i18n-msg>
			</h2>
            <tpp-button mini secondary message="Cancel" on-tap="_showListView"></tpp-button>
        </div>
        <view-organization-details id="viewOrg"></view-organization-details>
        `;
	}

	// CATALOG MAPPING LOGIC START

	static get catalogMapTemplate() {
		return html`
			<style>
			#catalogDialog .dialog-content{
				min-height:200px;
			}
			<!--styling for the dialog box-->
			</style>
			<tpp-dialog id="catalogDialog" modal>
				<iron-icon slot="header" class="header-icon" icon="assignment"></iron-icon>
				<label slot="header">
					<oe-i18n-msg msgid="Map Catalogs to Organization">Map Catalogs to Organization</oe-i18n-msg>	
				</label>
				<div slot="body" class="dialog-content">
					<tpp-input readonly label="Organization" value={{catalogMap.organziation.name}}></tpp-input>
					<tpp-dropdown multi label="Catalogs" value={{catalogMap.mappedCatalogs}} list=[[catalogsList]] disp="name" val="id"></tpp-dropdown>
				</div>
				<tpp-button slot="footer" mini primary message="Save" on-tap="_saveCatalogMappings"></tpp-button>
				<tpp-button slot="footer" mini message="Cancel" on-tap="_closeCatalogDlg"></tpp-button>
			</tpp-dialog>
		`;
	}

	_launchCatalogMapping(organziation) {
		// sets the catalogMap property based on the organiztion and open dialog
		let mappings = this.catalogMappings.filter(m => m.organizationId === organziation.id).map(m => m.catalogId);
		this.set("catalogMap", {
			organziation,
			mappedCatalogs: mappings
		});

		this.$.catalogDialog.open();
	}

	_closeCatalogDlg() {
		this.$.catalogDialog.close();
	}

	async _saveCatalogMappings() {
		let payload = {
			catalogIds: this.catalogMap.mappedCatalogs,
			organizationId: this.catalogMap.organziation.id
		};

		if (payload.catalogIds.length === 0) {
			this.fire("oe-show-error", "Atlease one catalog should be selected");
			return;
		}

		let [err] = await this._makeAjaxPromise("@restApiRoot/OrgCatalogMappings/updateMapping", "POST", payload);
		if (err) {
			this.fire("oe-show-error", window.devportalError(err));
			return;
		}
		this.fire("oe-show-success", "Catalog(s) mapped to organization successfully");
		await this.__fetchList();
		this._closeCatalogDlg();
	}

	// CATALOG MAPPING LOGIC END

	static get approveDlgTemplate() {
		return html`
        <style>
            .cif-id{
                font-weight: bold;
                letter-spacing: 0.7px;
                font-size: 16px;
                font-family: monospace;
                background: #EEE;
                padding: 4px 8px;
            }
        </style>
        <tpp-dialog id="approveDialog"  class="task-dialog" modal>
			<iron-icon slot="header" class="header-icon" icon="assignment"></iron-icon>
			<label slot="header">
			<oe-i18n-msg msgid="Confirm Approval">Confirm Approval</oe-i18n-msg>	
			</label>
			<div slot="body" class="dialog-content">
                <p>
					<oe-i18n-msg msgid="Organization will be registered with CIF Id">Organization will be registered with CIF Id</oe-i18n-msg>
					<label class="cif-id">[[approval.oldCIF]]</label>. 
					<oe-i18n-msg msgid="If this is not correct, please enter valid CIF Id.">If this is not correct, please enter valid CIF Id.</oe-i18n-msg>
				</p>
				<tpp-input required label="Change CIF" value={{approval.newCIF}}></tpp-input>
				<tpp-dropdown multi label="Catalogs" value={{approval.catalogs}} list=[[catalogsList]] disp="name" val="id"></tpp-dropdown>
			</div>
			<tpp-button slot="footer" mini primary message="Approve" on-tap="__confirmApprove"></tpp-button>
			<tpp-button slot="footer" mini message="Cancel" on-tap="_closeApproveDlg"></tpp-button>
		</tpp-dialog>
        `;
	}

	constructor() {
		super();
		this.setProperties({
			ui: {
				heading: "Approve Organization requests",
				url: "@restApiRoot/Organizations",
				filter: {
					"include": ["user"]
				}
			},
			grid: {
				columns: [
					{ key: "name" },
					{
						key: "_owner",
						label: "Owner Email",
						valueGetter: r => {
							let orgAdmin = r.user.find(u => u.id === r.userId);
							return orgAdmin ? orgAdmin.email : "NA";
						}
					},
					{ key: "email", label: "CIF Email" }
				],
				rowActions: [
					{
						action: "map-catalogs",
						title: "Map catalogs",
						icon: "settings"
					},
					{
						action: "view",
						title: "View",
						icon: "visibility"
					},
					{
						action: "delete",
						title: "Delete",
						icon: "delete"
					}
				],
				items: []
			},
			tasksGrid: {
				columns: [
					{
						key: "organization.name",
						label: "Name"
					},
					{
						key: "_owner",
						label: "Owner Email",
						valueGetter: r => {
							let orgAdmin = r.organization.user.find(u => u.id === r.organization.userId);
							return orgAdmin ? orgAdmin.email : "NA";
						}
					},
					{ key: "organization.email", label: "CIF Email" }
				],
				rowActions: [
					{
						action: "view",
						title: "View",
						icon: "visibility"
					},
					{
						action: "approve",
						title: "Approve",
						icon: "done"
					},
					{
						action: "revoke",
						title: "Revoke",
						icon: "close"
					}
				],
				items: []
			}
		});
		this.__fetchList();
	}

	async __fetchList() {
		await super.__fetchList();

		let tasksUrl = "@restApiRoot/Tasks";
		let orgTaskParams = {
			filter: {
				where: {
					name: "organization-approval",
					status: "pending"
				}
			}
		};

		let [err, tasks] = await this._makeAjaxPromise(tasksUrl, "GET", null, null, orgTaskParams);

		if (err) {
			this.fire("oe-show-error", window.devportalError(err));
			return;
		}

		let organizations = this.grid.items;
		let taskItems = [];
		tasks.forEach(task => {
			let orgId = task.formVariables.id;
			taskItems.push({
				taskId: task.id,
				organization: organizations.find(org => org.id === orgId)
			});
		});
		this.set("tasksGrid.items", taskItems);
		this.set("grid.items", organizations.filter(org => org.state === "approved"));

		let [err2, catalogs] = await this._makeAjaxPromise("@restApiRoot/Catalogs", "GET", null, null, {filter: {where: {or: [{type: "custom"}, {type: "prelogin"}]}}});
		if (err2) {
			this.fire("oe-show-error", window.devportalError(err2));
			return;
		}

		this.set("catalogsList", catalogs);

		let [err3, mappings] = await this._makeAjaxPromise("@restApiRoot/OrgCatalogMappings", "GET");
		if (err2) {
			this.fire("oe-show-error", window.devportalError(err3));
			return;
		}
		this.set("catalogMappings", mappings);
	}

	// mapping the actions by using actionHandlers
	__handleRowAction(event) {
		let { action, row } = event.detail;

		const actionHandlers = {
			"map-catalogs": () => this._launchCatalogMapping(row),
			"view": () => {
				this.set("selectedView", "item");
				this.$.viewOrg.showOrganization(row.id);
			},
			"delete": () => {
				this.__confirm("Confirm deleting the organization ?", () => {
					this.__initDelete(row);
				}, "Delete");
			}
		};

		if (action.action in actionHandlers) {
			actionHandlers[action.action]();
		}
	}

	__handleTaskAction(event) {
		let {
			action,
			row
		} = event.detail;
		switch (action.action) {
		case "view":
			this.set("selectedView", "item");
			this.$.viewOrg.showOrganization(row.organization.id);
			break;
		case "approve":
			this.__initAccept(row);
			break;
		case "revoke":
			this.__initReject(row);
			break;
		default: break;
		}
	}

	__initDelete(row) {
		this.makeAjaxCall(`@restApiRoot/Organizations/${row.id}`, "DELETE", null, null, null, null, (err, resp) => {
			if (err) {
				this.fire("oe-show-error", window.devportalError(err));
				return;
			}
			this.fire("oe-show-success", "Organization deleted successfully.");
			this.__fetchList();
		});
	}

	__initAccept(task) {
		this.setProperties({
			_selectedTask: task,
			approval: {
				oldCIF: task.organization.cifId,
				newCIF: task.organization.cifId,
				catalogs: []
			}
		});
		this.$.approveDialog.open();
	}

	_closeApproveDlg() {
		this.$.approveDialog.close();
	}

	__confirmApprove() {
		let task = this._selectedTask;
		let newCIF = this.approval.newCIF;
		let catalogs = this.approval.catalogs;
		if (catalogs.length === 0) {
			this.fire("oe-show-error", "Atleast one catalog must be selected.");
			return;
		}


		if (newCIF && newCIF.length > 4) {
			let url = `@restApiRoot/Tasks/${task.taskId}/complete`;
			let payload = {
				"pv": {
					"__action__": "approved",
					"id": task.organization.id,
					"cifId": newCIF,
					"catalogs": catalogs
				}
			};

			this.makeAjaxCall(url, "PUT", payload, null, null, null, (err, resp) => {
				if (err) {
					this.fire("oe-show-error", window.devportalError(err));
					return;
				}
				this.fire("oe-show-success", "Organization approved successfully.");
				this.__fetchList();
				this._closeApproveDlg();
			});
		}
	}

	__confirmReject() {
		if (this.rejectionComments && this.rejectionComments.length > 4) {
			let task = this._selectedTask;

			let url = `@restApiRoot/Tasks/${task.taskId}/complete`;
			let payload = {
				"pv": {
					"__action__": "rejected",
					"id": task.organization.id,
					"comment": this.rejectionComments
				}
			};

			this.makeAjaxCall(url, "PUT", payload, null, null, null, (err, resp) => {
				if (err) {
					this.fire("oe-show-error", window.devportalError(err));
					return;
				}
				this.fire("oe-show-success", "Organization rejected successfully.");
				this.__fetchList();
				this.$.rejectDialog.close();
			});
		} else {
			this.fire("oe-show-error", "Rejection comments must be minimum 4 letters.");
		}
	}
}

window.customElements.define(AdminApproveOrganization.is, AdminApproveOrganization);
