import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import "@polymer/iron-flex-layout/iron-flex-layout.js";
import "@polymer/iron-flex-layout/iron-flex-layout-classes.js";
import { OECommonMixin } from "oe-mixins/oe-common-mixin.js";
import "@polymer/iron-pages/iron-pages.js";
import "oe-app-route/oe-app-route.js";
import "../../styles/app-theme.js";

import "../layout/profile-sidebar.js";
import "../components/views/my-profile-view.js";
import "../components/views/my-company-view.js";
import "../components/views/manage-account-view.js";
import "../components/views/analytics-report-view.js";
import "../components/views/setup-team-view.js";
import "../components/views/manage-app-view/manage-app-view.js";
import "../components/views/change-password-view.js";
import "../components/views/manage-certificate-view.js";

import "../components/views/admin-approve-app.js";
import "../components/views/admin-approve-organization.js";
import "../components/views/admin-approve-user.js";
import "../components/views/admin-approve-publish-app.js";
import "../components/views/manage-api-bundle.js";
import "../components/views/manage-catalog-view.js";
import "../components/views/faq-view.js";
import "../components/views/contact-us-view.js";

import { ReduxMixin } from "../redux/redux-mixin.js";

/**
 * ### user-profile-page
 * `user-profile-page`
 *
 *
 * @customElement
 * @polymer
 */
class UserProfilePage extends ReduxMixin(OECommonMixin(PolymerElement)) {
	static get is() {
		return "user-profile-page";
	}

	static get template() {
		return html `
            <style include="iron-flex iron-flex-alignment app-theme">
                #container{
                    --repeater-height:100vh;
                    padding : 40px 0px;
                    @apply --canvas;
                }

                #view-selector{
                    padding:0px;
                }

                #view-selector .iron-selected{
                    display : block;
                }

                profile-sidebar{
                    border-right:1px solid var(--primary-grey-color);
                    transition:all 300ms ease-in;
                    min-height: calc(100vh - 200px);
                }

                #main-content{
                    overflow:hidden;
                }
            </style>
            <div id="container" class="layout horizontal">
                <profile-sidebar sidebar-opened selected-view={{selectedView}}></profile-sidebar>
                <div id="main-content" class="flex" on-goto-route="__gotoRoute" on-goto-view="__gotoView">
                    <oe-app-route routes-list=[[_copyOf(validPaths)]] route=[[_getRoute(selectedView)]]>
                        <iron-pages id="view-selector" route-target></iron-pages>
                    </oe-app-route>
                </div>
            </div>
        `;
	}

	static get properties() {
		return {
			validPaths: {
				type: Array,
				statePath: "validPaths"
			}
		};
	}

	_copyOf(jsonObj) {
		return JSON.parse(JSON.stringify(jsonObj));
	}

	_getRoute(selectedView) {
		return {
			path: selectedView
		};
	}

	__gotoRoute(event) {
		window.oe_navigate_to(event.detail);
	}

	__gotoView(event) {
		this.set("selectedView", event.detail);
		this.scrollIntoView();
	}
}
window.customElements.define(UserProfilePage.is, UserProfilePage);
