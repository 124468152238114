import { html } from "@polymer/polymer/polymer-element.js";
import ProfileBaseView from "./profile-base-view.js";
import "@polymer/iron-image/iron-image.js";
import "oe-data-grid/oe-data-grid.js";
import "../../components/tpp-button.js";
import "../tpp-dropdown.js";
/**
 * ### my-profile-view
 * `my-profile-view`
 *
 *
 * @customElement
 * @polymer
 */
class AnalyticsReportView extends ProfileBaseView {
	static get is() {
		return "analytics-report-view";
	}

	static get stylesTemplate() {
		return html`
            ${super.stylesTemplate}
            <style>
                #container{
                    padding-right:4px;
                }

                .col-2{
                    display:flex;
                    flex-direction:horizontal;
                    justify-content:space-between;
                }

                .col-2 > * {
                    width : calc(50% - 24px);
                }

                .rounded-input{
                    margin-right:4px;
                    margin-bottom:8px;
                }

                .box{
                    padding: 30px;
                    box-shadow: 3px 4px 5px rgb(4 4 4 / 30%);
                    background-color: var(--primary-dark-color);
                    color: #ffffff;
                    text-align: center;
                    transition: 0.3s;
                    box-sizing: border-box;
                    margin-bottom:16px;
                }

                .box:nth-child(odd){
                    border-radius: 20px 20px 0px 20px;
                }

                .box:nth-child(even){
                    border-radius: 20px 20px 20px 0px;
                }

                .submit-button{
                    margin-top:16px;
                    float:right;
                }

                oe-data-grid{
                    margin: 0px 2px;
                    width: auto;
                }
            </style>
        `;
	}

	static get contentTemplate() {
		return html`
            <div class="col-2">
                <label>API-wise availability (up-time report)<br />For current month</label>
                <div>
                    <tpp-dropdown label="Year"></tpp-dropdown>
                    <tpp-dropdown label="Month"></tpp-dropdown>
                    <tpp-dropdown label="Date"></tpp-dropdown>
                    <tpp-button class="submit-button" primary animate-on-hover message="Submit" icon="arrow-forward"></tpp-button>
                </div>
            </div>
            <h4 class="primary-text">Result</h4>
            <oe-data-grid hide-header></oe-data-grid>

            <div class="col-2">
                <div class="box">
                    <p>Realtime API-wise transaction report (All Subscribed API’s) (success/ Fail/ Pending) Group by APPs</p>
                </div>
                <div class="box">
                    <p>Fee accrued, collecte & GST calculation report Group by APPs</p>
                </div>
            </div>
            <div class="col-2">
                <label>API-wise TPS report (Peak, Avg, Low)<br />For current day</label>
                <div>
                    <tpp-dropdown label="Year" ></tpp-dropdown>
                    <tpp-dropdown label="Month" ></tpp-dropdown>
                    <tpp-dropdown label="Date" ></tpp-dropdown>
                    <tpp-button class="submit-button" primary animate-on-hover message="Submit" icon="arrow-forward"></tpp-button>
                </div>
            </div>
            <h4 class="primary-text">Result</h4>
            <oe-data-grid hide-header></oe-data-grid>

            <h1 class="primary-text">Status and analytics report</h1>
            <div class="col-2">
                <label>API-wise call reject report<br />For current day</label>
                <div>
                    <tpp-dropdown label="Year" ></tpp-dropdown>
                    <tpp-dropdown label="Month" ></tpp-dropdown>
                    <tpp-dropdown label="Date" ></tpp-dropdown>
                    <tpp-button class="submit-button" primary animate-on-hover message="Submit" icon="arrow-forward"></tpp-button>
                </div>
            </div>
            <h4 class="primary-text">Result</h4>
            <oe-data-grid hide-header></oe-data-grid>
        `;
	}

	constructor() {
		super();
		this.set("viewTitle", "Status and analytics report");
	}
}
window.customElements.define(AnalyticsReportView.is, AnalyticsReportView);
