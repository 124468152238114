import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import "@polymer/iron-flex-layout/iron-flex-layout.js";
import "@polymer/iron-flex-layout/iron-flex-layout-classes.js";
import { ReduxMixin } from "../redux/redux-mixin.js";
import "../../styles/app-theme.js";
import "oe-input/oe-file-input.js";
import "../components/apps-list-dialog.js";
import "../components/rating-view.js";
import "../components/swagger-client-wrapper.js";
import "@polymer/paper-button/paper-button.js";
import "@polymer/iron-image/iron-image.js";
import "@polymer/iron-selector/iron-selector.js";
import "@polymer/iron-collapse/iron-collapse.js";
import "../components/tpp-button.js";
import "oe-toggle/oe-toggle.js";
import "oe-ui-misc/oe-control-switcher.js";
import "oe-input/oe-json-input.js";
import "oe-ui-accordion/oe-ui-accordion.js";
import "oe-ui-accordion/oe-accordion-item.js";
import { ROLEID } from "../redux/reducer.js";
import { PromiseMixin } from "../mixins/promise-mixin.js";
import "oe-info/oe-info.js";

/**
 * ### api-detail-page
 * `api-detail-page`
 *
 *
 * @customElement
 * @polymer
 */
class ApiDetailPage extends PromiseMixin(PolymerElement) {
	static get is() {
		return "api-detail-page";
	}

	static get template() {
		return html`
    <style include="iron-flex iron-flex-alignment app-theme iron-flex-factors">
        #container{
            @apply --canvas;
        }

        .api-group-name{
            color:var(--primary-dark-color);
            padding:20px 0px;
            text-align:left;
            text-transform:capitalize;
            gap:16px;
        }

        .api-group-name #switcher{
            --control-switch-border:{
                border-radius: 4px 4px 4px 4px;
            };

            --on-value:{
                padding:8px;
                height:38px;
                min-width:70px;
                box-sizing:border-box;
                text-align: center;
            };

            --off-value:{
                padding:8px;
                height:38px;
                min-width:70px;
                box-sizing:border-box;
                text-align: center;
            };

            --switch-hover:{
                border-radius:4px;
            }

            --on-value-selected:{
                border-radius: 4px 0px 0px 4px;
            };

            --off-value-selected:{
                border-radius: 0px 4px 4px 0px;
            };
        }

        .api-group-desc{
            padding:8px 16px;
            height:150px;
            overflow:auto;
            border:1px solid var(--primary-grey-color);
        }

        .api-group-action{
            padding: 0px 16px;
        }

        .swagger-section{
            padding-top:24px;
        }



    </style>
    <div id="container">
        <div class="api-group-name layout horizontal center">
            <h1 class="flex">[[apiGroup.name]]</h1>
            <oe-control-switcher id="switcher" config=[[UI.header.controlConfig]] disabled=[[disableSwitch]] value={{selectedView}}></oe-control-switcher>
            <tpp-button primary mini class="action-button" animate-on-hover message="Subscribe" on-tap="_handleSubscribe" hidden=[[_hideSubscribe(userRole)]]></tpp-button>
            <tpp-button secondary mini class="no-margin" message="Credentials" on-tap="_launchApps"></tpp-button>
			<apps-list-dialog id="appsDialog" applications-list=[[applicationsList]] on-apply-credential="_applyCredential"></apps-list-dialog>
        </div>
        <div id="swagger-section" class="swagger-section layout horizontal">
            ${this.sidebarTemplate}
            <swagger-client-wrapper class="flex" id="swagger" yaml=[[viewableGroup.yamlContent]]></swagger-client-wrapper>
        </div>
    </div>
    `;
	}

	static get properties() {
		return {
			userInfo: {
				type: Object,
				statePath: "userInfo"
			},
			userRole: {
				type: String,
				statePath: "userRole"
			},
			userLoggedIn: {
				type: Boolean,
				statePath: "userLoggedIn"
			},
			groupId: {
				type: String,
				observer: "_groupIdChanged"
			},
			apiGroup: {
				type: Object
			},
			viewableGroup: {
				type: Object,
				computed: "__getSelectedView(selectedView,apiGroup)"
			},
			apiTags: {
				type: Object,
				computed: "__getSwaggerTags(viewableGroup.api)"
			}
		};
	}

	constructor() {
		super();
		this._resetUI();
		this.set("applicationsList", []);
	}

	async _groupIdChanged() {
		if (this.groupId) {
			let [errG, group] = await this._makeAjaxPromise(`@restApiRoot/ApiGroups/${this.groupId}`);
			if (errG) {
				this.fire("oe-show-error", window.devportalError(errG));
				return;
			}

			if (group) {
				let filterProp = {
					filter: {
						where: {
							group: group.key
						},
						include: ["api", "rating", "tests"]
					}
				};

				let [err, resp] = await this._makeAjaxPromise("@restApiRoot/ApiBundles", "get", null, null, filterProp);

				if (err) {
					return;
				}

				let api = resp.find(b => b.type === "api");
				let event = resp.find(b => b.type === "event");

				if (api) {
					group.hasApi = true;
					group.api = api;
				}

				if (event) {
					group.hasEvent = true;
					group.event = event;
				}

				this.set("apiGroup", group);
				this.set("selectedView", this.apiGroup.hasApi ? "api" : "event");
				this.set("disableSwitch", !(this.apiGroup.hasApi && this.apiGroup.hasEvent));
			}

			await this._getAllApplications();
		}
	}

	async _getAllApplications() {
		let url = `@restApiRoot/AppUsers/${this.userInfo.userId}/application`;
		let [err, resp] = await this._makeAjaxPromise(url, "GET");
		if (err) {
			this.fire("oe-show-error", window.devportalError(err));
			return;
		}
		resp.forEach(app => {
			app.__disp = `${app.name} (${app.loginMethod === "authorization_code" ? "Authorization Code" : "Client Credentials"})`;
		});
		this.set("applicationsList", resp);
	}

	__getSelectedView(view, group) {
		if (!group || !view) {
			return;
		}
		let selected = view === "api" ? group.api : group.event;
		this._handleSelectedChanged(selected);
		return selected;
	}

	__getSwaggerTags(list) {
		if (!list) {
			return [];
		}

		let apiGroupMap = {};
		list.forEach(api => {
			if (!api.group) {
				api.group = "default";
			}
			if (!apiGroupMap[api.group]) {
				apiGroupMap[api.group] = [];
			}
			apiGroupMap[api.group].push(api);
		});

		let tags = Object.keys(apiGroupMap).sort();
		return tags.map(groupName => {
			let apis = apiGroupMap[groupName].sort((a, b) => a.method.localeCompare(b.method));
			return {
				groupName,
				apis
			};
		});
	}

	_scrollToApi(event) {
		let api = event.model.api;
		this.$.swagger.scrollToApi(api);
	}

	_isAdminUser(userRole) {
		return userRole === ROLEID.ADMIN;
	}

	_hideSubscribe(userRole) {
		return userRole === ROLEID.VIEWER || userRole === ROLEID.ADMIN;
	}

	_handleSubscribe() {
		// if(!this.userLoggedIn){
		//     window.location.pathname = window.OEUtils.subPath + "/authlogin";
		//     return;
		// }

		window.oe_navigate_to("/user-profile/registered-apps");
		// if(this.userRole !== ROLEID.VIEWER){
		//     return;
		// }
	}

	_launchApps(event) {
		this.$.appsDialog._launch(event.target);
	}

	_applyCredential(event) {
		let credential = event.detail;
		let swaggerUI = this.$.swagger._client;
		swaggerUI.initOAuth({
			...credential
		});
		this.$.appsDialog._close();
	}

	static get sidebarTemplate() {
		return html`
            <style include="input-styles">
                #sidebar{
                    position: sticky;
                    top: 120px;
                    z-index: 2;
                    height: calc(100vh - 130px);
                    overflow-x: hidden;
                    overflow-y: auto;
                    padding: 0px 16px;
                    scroll-gutter: stable;
                    box-sizing: border-box;
                    border-right: 1px solid var(--primary-grey-color);
                }

                #sidebar .nav-panel{
                    width : 300px;
                    padding-top: 10px;
                    box-sizing: border-box;
                }

                #sidebar .test-panel{
                    width : 300px;
                    padding-top: 10px;
                    box-sizing: border-box;
                }

                #sidebar .collapse-content paper-tabs{
                    height: 30px;
                    --paper-tabs-selection-bar-color: var(--primary-dark-color);
                }

                #sidebar .api-nav-list oe-accordion-item::part(header){
                    height: 30px;
                    padding: 0px;
                    min-height: 30px;
                }



                rating-view{
                    --rating-icon-size : 26px;
                }
        
                .group-list{
                    list-style:none;
                }
        
                .group-name{
                    font-size:14px;
                    padding:0px 8px;
                    cursor:pointer;
                    text-transform:capitalize;
                }

                .api-item{
                    font-size: 12px;
                    margin-bottom: 4px;
                    display: flex;
                    cursor:pointer;
                    @apply --layout-horizontal;
                    @apply --layout-center;
                }

                .api-item > label{
                    cursor:pointer;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    padding: 0 4px;
                }

                .api-method{
                    padding: 6px;
                    color: white;
                    line-height: 8px;
                    border-radius: 2px;
                    margin-right:4px;
                    display:block;
                    min-width: 30px;
                    text-align: center;
                }

                .method-get{ background : #61affe; }
                .method-post{ background : #49cc90; }
                .method-put{ background : #fca130; }
                .method-delete{ background : #49cc90; }

                .test-panel oe-json-input{
                    --iron-autogrow-textarea : {
                        font-size : 12px;
                        overflow:auto;
                        padding: 8px;
                        box-sizing :border-box;
                        line-height: 1rem;
                    }
                }

                .test-info-section iron-selector{
                    display: flex;
                    gap: 8px;
                    font-size: 10px;
                    margin:14px 0px;
                }


                .test-info-section iron-selector > div{
                    padding-right: 8px;
                    cursor:pointer;
                }

                .test-info-section iron-selector .iron-selected{
                    font-weight:bold;
                }

                .test-info-section iron-selector > div:not(:last-of-type) {
                    border-right:1px solid;
                }
            </style>
            <div id="sidebar">
                <iron-collapse opened=[[UI.sidebar.show]] horizontal>
                    <div class="collapse-content">
                        <paper-tabs selected={{UI.sidebar.selectedView}}>
                            <paper-tab>Navigation</paper-tab>
                            <paper-tab disabled=[[!testUI.operations.length]]>Test data</paper-tab>
                        </paper-tabs>
                        <iron-pages selected={{UI.sidebar.selectedView}}>
                            <div class="nav-panel">
                                <rating-view score=[[viewableGroup.rating.rating]]></rating-view>
                                <div class="api-nav-list">
                                    <oe-ui-accordion>
                                        <dom-repeat items=[[apiTags]] as="group">
                                            <template>
                                                <oe-accordion-item>
                                                    <div slot="title" class="group-name">[[group.groupName]]</div>
                                                    <ul class="group-list" slot="content">
                                                        <dom-repeat items=[[group.apis]] as="api">
                                                            <template>
                                                                <li class="api-item list-item" on-tap="_scrollToApi">
                                                                    <span class$="api-method method-[[api.method]]">[[api.method]]</span>
                                                                    <label title$="[[api.description]]">[[api.name]]</label>
                                                                </li>
                                                            </template>
                                                        </dom-repeat>
                                                    </ul>
                                                </oe-accordion-item>
                                            </template>
                                        </dom-repeat>
                                    </oe-ui-accordion>
                                </div>
                            </div>
                            <div class="test-panel">
                                <tpp-dropdown disable-text-input label="API" value={{testUI.selectedOperation}} list=[[testUI.operations]]></tpp-dropdown>
                                <tpp-dropdown disable-text-input label="Test case" value={{testUI.selectedTestCase}} disp="name" list=[[__getTestForOperationId(testUI.selectedOperation)]] on-change="_testCaseChanged"></tpp-dropdown>
                                <div class="layout horizontal center justified">
                                    <tpp-button secondary mini icon="info" on-tap="_toggleTestCaseDetails"></tpp-button>
                                    <tpp-button primary mini on-tap="_gotoTestApi" message="Load"></tpp-button>
                                </div>
                                <iron-collapse opened=[[UI.sidebar.test.showInfo]] class="test-info-section">
                                    <iron-selector selected={{UI.sidebar.test.selectedInfo}}>
                                        <div> Payload </div>
                                        <div> Header </div>
                                        <div> Queries </div>
                                    </iron-selector>
                                    <iron-pages selected=[[UI.sidebar.test.selectedInfo]]>
                                        <div>
                                            <oe-json-input readonly no-label-float value={{testUI.selectedTestCase.body}} class="rounded-input no-error" max-rows="8"></oe-json-input>
                                        </div>
                                        <div>
                                            <dom-repeat items=[[_getArrayFromObj(testUI.selectedTestCase.headers)]]>
                                                <template>
                                                    <oe-info layout="horizontal" separator=":" label=[[item.key]] value=[[item.value]]></oe-info>
                                                </template>
                                            </dom-repeat>
                                        </div>
                                        <div> 
                                            <dom-repeat items=[[_getArrayFromObj(testUI.selectedTestCase.queries)]]>
                                                <template>
                                                    <oe-info layout="horizontal" separator=":" label=[[item.key]] value=[[item.value]]></oe-info>
                                                </template>
                                            </dom-repeat> 
                                        </div>
                                    </iron-pages>
                                </iron-collapse>
                            </div>
                        </iron-pages>
                    </div>
                </iron-collapse>
            </div>
        `;
	}

	_resetUI() {
		this.set("UI", {
			header: {
				controlConfig: {
					onLabel: "Webhook",
					onValue: "event",
					offLabel: "Api",
					offValue: "api"
				}
			},
			sidebar: {
				show: true,
				selectedView: 0,
				test: {
					showInfo: false,
					selectedInfo: 0
				}
			}
		});
	}

	_toggleTestCaseDetails() {
		this.set("UI.sidebar.test", {
			showInfo: !this.UI.sidebar.test.showInfo,
			selectedInfo: 0
		});
	}

	_getArrayFromObj(obj) {
		return obj ? Object.keys(obj).map(key => ({ key, value: obj[key] })) : [];
	}

	_handleSelectedChanged(selected) {
		let operationTestMap = selected.tests ? selected.tests.testCases : {};
		let operations = Object.keys(operationTestMap).sort();
		let selectedOperation = operations[0];
		this.set("testUI", {
			operations,
			operationTestMap,
			selectedOperation,
			selectedTestCase: selectedOperation ? operationTestMap[selectedOperation][0] : null
		});
	}

	__getTestForOperationId(operationName) {
		if (!operationName) {
			return [];
		}
		if (this.viewableGroup) {
			this.async(() => {
				let api = this.viewableGroup.api.find(a => a.name === operationName);
				this.$.swagger.scrollToApi(api, true);
			}, 300);
		}
		let allTests = [...this.testUI.operationTestMap[operationName]].sort((a, b) => a.name.localeCompare(b.name));
		this.set("testUI.selectedTestCase", allTests[0]);
		return allTests;
	}

	async _gotoTestApi() {
		if (this.testUI.selectedTestCase) {
			let {
				selectedOperation,
				selectedTestCase
			} = this.testUI;

			let api = this.viewableGroup.api.find(a => a.name === selectedOperation);
			if (api && selectedTestCase) {
				await this.$.swagger.tryOutApi(api, selectedTestCase);
			} else {
				this.fire("oe-show-error", "Operation and test case are mandatory");
			}
		}
	}
}
window.customElements.define(ApiDetailPage.is, ReduxMixin(ApiDetailPage));
