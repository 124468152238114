import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import "@polymer/iron-flex-layout/iron-flex-layout.js";
import "@polymer/iron-flex-layout/iron-flex-layout-classes.js";
import { OEAjaxMixin } from "oe-mixins/oe-ajax-mixin.js";
import {AnimateMixin} from "../mixins/animate-mixin.js";
import "../../styles/app-theme.js";
import "@polymer/iron-form/iron-form.js";
import "@polymer/paper-tooltip/paper-tooltip.js";
import "@polymer/paper-checkbox/paper-checkbox.js";
import "oe-input/oe-input.js";
import "oe-ui-forms/validators/oe-block-validator.js";
import "oe-ui-forms/validators/oe-eq-validator.js";
import "../../styles/oe-animation.js";
import "../components/tpp-button.js";

const SUCCESS_MSG = email =>  `You should expect a verification email shortly from adminstrator at your email - ${email}.

If it’s been a few minutes and you haven’t received it yet, here’s what you can do:
	- check your spam folder to make sure it did not accidentally get filtered there.
			
If you still need help, please contact us.`;

/**
 * ### register-user-page
 * `register-user-page`
 *
 *
 * @customElement
 * @polymer
 */
class RegisterUserPage extends AnimateMixin(OEAjaxMixin(PolymerElement)) {
	static get is() {
		return "register-user-page";
	}

	static get template() {
		return html `
			<style include="iron-flex iron-flex-alignment oe-animation">
				.banner-section{
					padding: 10px 0px;
					width: auto;
					min-height: calc(100vh - 120px);
					box-sizing: border-box;
				}

				.content{
					@apply --canvas;
					display:flex;
					justify-content: space-between;
				}

				h1 {
					font-size:40px;
					font-weight:300;
					color: var(--primary-dark-color);
				}

				h1, h2{
					margin-top: 0;
					margin-bottom: 0.5rem;
					line-height: 1.2;
				}

				a{
					text-decoration:none;
				}

				a.link{
					font-weight:300;
					color: var(--primary-dark-color);
				}

				h2 {
					font-weight:300;
					color: var(--primary-grey-color);
				}

				.content > *{
					width : 45%;
				}

				.welcome-message{
					padding: 50px 0px;
					width : 30%;
				}

				.register-section{
					padding: 60px 116px;
				}

				.form-field{
					width: 700px;
					gap:16px 0px;
				}

				.form-field > [field-id]{
					width:330px;
				}

				#validator{
					margin-bottom:16px;
					display:block;
				}

				.agree-checker{
					--paper-checkbox-size : 1rem;
				}

				.margin-b{
					margin-bottom:36px !important;
				}

				tpp-button{
					margin-top: 16px;
					margin-bottom: 12px;
				}
				
				@media (max-width: 1024px){
					.content{
						flex-direction:column-reverse;
						padding-left:16px;
					}
				}
			</style>
			<main id="container">
				<section class="banner-section">
					<div class="content">
						<div class="register-form-container" oe-animate="fade-up">
							<h1 class="section-header">
								<oe-i18n-msg msgid="Register">Register</oe-i18n-msg>
							</h1>
							<iron-form id="register-form">
								<oe-block-validator id="validator">
									<div class="layout horizontal wrap justified form-field">
										<tpp-input required label="First Name" field-id="firstName" value={{registerInfo.firstName}} pattern="[a-zA-Z\\.\\s]+" user-error-message='{"patternMismatch":"Numbers and special characters are not allowed"}'></tpp-input>
										<tpp-input required label="Last Name" field-id="lastName" minlength="1" value={{registerInfo.lastName}} pattern="[a-zA-Z\\.\\s]+"  user-error-message='{"patternMismatch":"Numbers and special characters are not allowed"}'></tpp-input>
										<tpp-input required type="email" label="Email Id" field-id="email" value={{registerInfo.email}}></tpp-input>
										<tpp-input required label="Developer Organisation" field-id="orgName" value={{registerInfo.orgName}} user-error-message='{"patternMismatch":"Special characters are not allowed"}'></tpp-input>
										<tpp-input required type="password" label="Password" field-id="password" value={{registerInfo.password}}></tpp-input>
										<tpp-input required type="password" label="Confirm Password" field-id="confirmPassword" value={{registerInfo.confirmPassword}} hide-password-info></tpp-input>
									</div>
                                    <input aria-hidden="true" label="User Id" hidden id="userId"/>
									<oe-eq-validator error="Password should match confirm Password" fields='["password","confirmPassword"]' model={{registerInfo}}></oe-eq-validator>
								</oe-block-validator>
								<div class="layout horizontal center">
									<paper-checkbox class="agree-checker" checked={{registerInfo.agreeTerms}}></paper-checkbox>
									<span>Check to agree to our <a href$="[[__getPath('/terms-conditions')]]"> Terms & Conditions and Privacy Policy</a>.</span>
								</div>
							</iron-form>
							<tpp-button on-tap="_validateAndRegister" disabled=[[!registerInfo.agreeTerms]]
							primary mini animate-on-hover message="Register" icon="arrow-forward"></tpp-button>
						</div>
						<div class="welcome-message layout vertical" oe-animate="fade-up" oe-animate-delay="500">
							${this.registerTextCustomizable}
							<h2 class="margin-b">
								<oe-i18n-msg msgid="Already have an account ?">Already have an account ?</oe-i18n-msg>	
							<br>
								<a class="link" on-click="_reload" href$="[[__getPath('/authlogin')]]">Sign in</a> here.</h2>
						</div>
					</div>
				</section>
			</main>
    	`;
	}

	constructor() {
		super();
		this.set("registerInfo", {});
	}

	_validateAndRegister() {
		[
			"email",
			"password",
			"firstName",
			"lastName",
			"orgName"
		].forEach(k => {
			this.set(`registerInfo.${k}`, this.registerInfo[k].trim());
		});

		this.$.validator.validateForm().then(result => {
			if (!result.valid) {
				this.fire("oe-show-error", "Please fill all the mandatory fields (*) and make sure there are no input errors.");
				return;
			}
			this._captcha.validate(this.$.userId);
		});
	}

	_reload() {
		this.async(() => {
			window.location.reload();
		}, 300);
	}

	_registerUser(captchaToken) {
		let {
			email,
			password,
			firstName,
			lastName,
			orgName
		} = this.registerInfo;

		let payload = {
			email,
			password,
			firstName,
			lastName,
			orgName,
			captchaToken
		};
		payload.userId = this.$.userId.value;
		this.makeAjaxCall("@restApiRoot/AppUsers/register", "POST", payload, null, null, null, (err) => {
			if (err) {
				this.fire("oe-show-error",  window.devportalError(err));
				this._captcha.reset();
				return;
			}
			this.set("registerInfo", {});
			this.fire("oe-show-success", {
				message: SUCCESS_MSG(email),
				ok: ()=>{
					window.location.pathname = window.OEUtils.subPath + "/authlogin";
				}
			});
		});
	}

	__getPath(path) {
		return window.OEUtils.subPath + path;
	}

	connectedCallback() {
		super.connectedCallback();
		this.addEventListener("oe-route-change", ()=>{
			this.fire("oe-captcha-init", {
				name: "register",
				onValid: (response) => {
					this._registerUser(response);
				},
				onError: (error) => {
					this.fire("oe-show-error", error);
				},
				onRegister: captcha => {
					this._captcha = captcha;
				}
			});
		});
	}

	static get registerTextCustomizable() {
		let cust = window.devportal.customize;
		return cust["register-text"] ? cust["register-text"] : html`<label>NOT LOADED</label>`;
	}
}
window.customElements.define(RegisterUserPage.is, RegisterUserPage);
