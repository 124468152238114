import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import { OECommonMixin } from "oe-mixins/oe-common-mixin";
import { PromiseMixin } from "../../../../mixins/promise-mixin";

class AppCredentialGrid extends PromiseMixin(PolymerElement) {
	static get is() {
		return "app-credential-grid";
	}

	static get properties() {
		return {
			application: {
				type: Object
			},
			isProduction: {
				type: Boolean,
				value: false
			},
			loginCreds: {
				type: Array,
				value: () => [],
				notify: true
			}
		};
	}

	static get template() {
		return html`
        <oe-data-grid label="Credentials" page-size=3 disable-selection disable-config-editor disable-full-screen-mode disable-add
                columns=[[loginGrid.columns]] toolbar-actions=[[loginGrid.gridActions]] row-actions=[[loginGrid.rowActions]]
                on-oe-data-table-row-action="_handleCredRowActions"
                on-oe-data-table-action-generate="_generateCred"
                items=[[loginCreds]] ></oe-data-grid>
        `;
	}

	constructor() {
		super();
		this.set("loginGrid", {
			columns: [
				{
					key: "clientId",
					label: "Client Id",
					width: 300,
					renderer: "<copy-text-display text=[[row.clientId]] show-secret></copy-text-display>"
				},
				{
					key: "clientSecret",
					label: "Client Secret",
					renderer: "<copy-text-display text=[[row.clientSecret]] show-secret=[[row.showSecret]]></copy-text-display>"
				}
			],
			rowActions: [{
				action: "show",
				title: "Show Secret",
				icon: "visibility"
			}, {
				action: "remove",
				title: "Revoke",
				icon: "clear"
			}],
			gridActions: [
				{
					title: "Generate Credentials",
					action: "generate",
					isButton: true
				}
			]
		});
	}

	updateCredentialList(credentials) {
		if (Array.isArray(credentials)) {
			this.set("application.credentials.login", credentials);
			this.set("loginCreds", credentials.map(r => {
				return {
					...r,
					showSecret: false
				};
			}));
		}
	}

	get _appURLBase() {
		return `@restApiRoot/${this.isProduction ? "ProductionApplications" : "Applications"}/${this.application.id}`;
	}

	async _handleCredRowActions(event) {
		let {
			action,
			rowIndex
		} = event.detail;

		let temp = this.loginCreds.slice();

		if (action.action === "show") {
			temp[rowIndex].showSecret = !temp[rowIndex].showSecret;
			this.set("loginCreds", temp.map(t => {return {...t};}));
		} else if (action.action === "remove") {
			if (this.application.credentials.login.length === 1) {
				this.fire("oe-show-warning", "At least one credential is required.");
				return;
			}
			let [err, resp] = await this._makeAjaxPromise(this._appURLBase + "/deleteCredentials", "post", temp[rowIndex]);
			if (err) {
				this.fire("oe-show-error", window.devportalError(err));
			} else {
				this.updateCredentialList(resp.result);
			}
		}
	}

	async _generateCred() {
		let payload = {
			"grant_types": [ this.application.loginMethod],
			"redirect_uris": this.application.callbackUrl.split(",")
		};

		let [err, resp] = await this._makeAjaxPromise(this._appURLBase + "/generateCredentials", "post", payload);
		if (err) {
			this.fire("oe-show-error", "Error generating Credentials.");
		} else {
			this.updateCredentialList(resp.result);
		}
	}
}
window.customElements.define(AppCredentialGrid.is, AppCredentialGrid);

window.customElements.define("copy-text-display", class extends OECommonMixin(PolymerElement) {
	static get properties() {
		return {
			text: {
				type: String
			},
			showSecret: {
				type: Boolean,
				value: false
			}
		};
	}

	static get template() {
		return html`
            <style>
                :host{
                    display: block;
                    min-height: 20px;
                }

                .copy-container{
                    position:relative;
                }

                #selectable{
                    user-select:text;
                    word-break:break-all;
                }

                .copy-icon{
                    position:absolute;
                    top:0px;
                    left:100%;
                    cursor:pointer;
                }
            </style>
            <div class="copy-container">
                <span hidden=[[showSecret]]>************************</span>
                <span id="selectable" hidden=[[!showSecret]]>[[text]]</span>
                <iron-icon hidden=[[!showSecret]] icon="content-copy" on-tap="_copyContent" class="copy-icon"></iron-icon>
            </div>
        `;
	}

	_copyContent() {
		var el = this.shadowRoot.querySelector("#selectable");
		var r = document.createRange();
		r.selectNode(el);
		window.getSelection().removeAllRanges();
		window.getSelection().addRange(r);
		document.execCommand("copy");
		window.getSelection().removeAllRanges();

		this.fire("oe-show-success", {
			message: "Content copied to clipboard.",
			autoClose: 2000
		});
	}
});
