import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import "@polymer/iron-flex-layout/iron-flex-layout.js";
import "@polymer/iron-flex-layout/iron-flex-layout-classes.js";
import { OEAjaxMixin } from "oe-mixins/oe-ajax-mixin";
import "oe-input/oe-input.js";
import "@polymer/iron-icon/iron-icon.js";
import "@polymer/iron-selector/iron-selector.js";
import "@polymer/paper-button/paper-button.js";

import "@polymer/paper-dropdown-menu/paper-dropdown-menu.js";
import "@polymer/paper-listbox/paper-listbox.js";
import "@polymer/paper-item/paper-item.js";

import "./tpp-input.js";
import "./tpp-dropdown.js";

import "./tree-builder.js";

/**
 * ### api-bundle-filter
 * `api-bundle-filter`
 *
 *
 * @customElement
 * @polymer
 */
class ApiBundleFilter extends OEAjaxMixin(PolymerElement) {
	static get is() {
		return "api-bundle-filter";
	}

	static get template() {
		return html`
            <style include="iron-flex iron-flex-alignment">
                :host{
                    display:block;
                    position:relative;
                }

				.search-input {
					width: 250px;
				}

				.dropdown-content {
					overflow: hidden !important;
				}

                .top-filter{
                    margin-bottom:8px;
                }

                .level-filter{
                    margin-top: 12px;
					min-height: 90px;
                }

                .filter-level{
                    margin-top:8px;
                }

                .filter-option{
                    outline: 1px solid transparent;
                    outline-offset: 2px;
                    color:var(--primary-dark-color);
                    min-width: 100px;
                    height: 30px;
                    border-radius:4px;
                    margin: 2px 4px;
                    font-size: var(--filter-text-size,14px);
                    letter-spacing: 0.7px;
                    padding: 4px 16px;
                    white-space: nowrap;
                    position:relative;
                    overflow:hidden;
                }

                .filter-option::before{
                    content:"";
                    position:absolute;
                    width:calc(100% + 2px);
                    height:40px;
                    background:var(--primary-dark-color);
                    opacity:0.2;
                }

                .filter-option[selected]{
                    outline-color:var(--primary-dark-color);
                }

                .filter-menu{
                    min-width: 175px;
                    padding:0px;

                    --paper-input-container-input_-_color:var(--primary-dark-color);
                    --paper-input-container-color:var(--primary-dark-color);
                    --primary-text-color:var(--primary-dark-color);

                    --paper-dropdown-menu-button:{
                        position:absolute;
                        height:100%;
                    };

                    --paper-input-container:{
                        padding:0px 8px;
                        box-sizing:border-box;
                        text-align: center;
                        margin-top: -18px;
                    };
                    
                    --paper-input-container-underline: {
                        display: none;
                    };
                    --paper-input-container-underline-focus: {
                        display: none;
                    };
                    --paper-dropdown-menu-icon:{
                        color:var(--primary-dark-color);
                    };

                    --paper-input-container-label-floating:{
                        display:none;
                    };
                }

                .filter-list-item{
                    --paper-item-min-height:40px;
                    min-width:175px;
					box-sizing: border-box;
                }


                .side-filter{
                    position:relative;
                }

                #toggle-control{
                   display:flex;
                }

                .filter-label{
                    color : var(--primary-red-color);
                    font-weight:bold;
                }

                .container{
                    width:200px;
                    height: 400px;
                    overflow:auto;
                }

                .catergory-label{
                    margin: 8px 0px;
                    display:block;
                }

               	.input-containers{
                    gap:16px;
                }

				.hierarchy-combo{
					width:500px;
				}

				[hidden]{
                    display:none !important;
                }
            </style>
            <div id="container" class="layout vertical">
                <div class="top-filter layout horizontal start justified">
                    <div class="layout horizontal center input-containers">
                        <tpp-input class="rounded-input search-input" always-float-label label="Search Name" value={{filter.key}}></tpp-input>
                        <tpp-dropdown label="Category" hidden=[[hideCategory]] value={{filter.category}} list=[[categoryItems]] disp="d" val="v"></tpp-dropdown>
                        <!-- <tpp-dropdown label="Hierarchy" class="hierarchy-combo" show-filtered-only value={{filter.hierarchy}} list=[[hierarchyItems]] disp="disp" val="key"></tpp-dropdown> -->
                    </div>
                    <div class="level-filter">
                        <dom-repeat items=[[config.filter.top]] as="levelfilter">
                            <template>
                                <div class="horizontal layout end-justified filter-level" hidden=[[!levelfilter.show]]>
                                    <paper-button selected$=[[_selectedItem(levelfilter.selected,'*')]] on-tap="_selectFilterItem" class="filter-option" filter="*">All</paper-button>
                                    <dom-repeat items=[[levelfilter.list]]>
                                        <template>
                                            <paper-button selected$=[[_selectedItem(levelfilter.selected,item.key)]] on-tap="_selectFilterItem" class="filter-option" filter="item.key">[[item.name]]</paper-button>
                                        </template>
                                    </dom-repeat>
                                    <paper-dropdown-menu level-more$=[[index]] hidden=[[!levelfilter.showMore]] class="filter-option filter-menu" value={{levelfilter.moreVal}} selected$=[[_selectedMenuItem(levelfilter.selected,levelfilter.moreList)]] title$="[[levelfilter.moreVal]]" label="More" vertical-offset="40" horizontal-align="right">
                                        <paper-listbox slot="dropdown-content" class="dropdown-content filter-list">
                                            <dom-repeat items=[[levelfilter.moreList]]>
                                                <template>
                                                    <paper-item selected$=[[_selectedItem(levelfilter.selected,item.key)]] on-tap="_selectFilterItem" class="filter-list-item">[[item.name]]</paper-item>
                                                </template>
                                            </dom-repeat>
                                        </paper-listbox>
                                    </paper-dropdown-menu>
                                </div>
                            </template>
                        </dom-repeat>
                    </div>
                </div>
                <div class="content flex layout horizontal">
                    <div class="side-filter">
                        <iron-collapse horizontal opened=[[panelVisible]]>
                            <div class="container">
                                <dom-if if=[[treeData]]>
                                    <template>
                                        <label class="catergory-label">
										<oe-i18n-msg msgid="Tags">Tags</oe-i18n-msg>	
										</label>
                                        <tree-builder tree=[[treeData]] on-node-selection-changed="_selectTreeItem" selected={{filter.tags.tree}}></tree-builder>
                                    </template>
                                </dom-if>
                            </div>
                        </iron-collapse>
                    </div>
                    <div class="content flex">
                        <slot></slot>
                    </div>
                </div>
            </div>
            
        `;
	}


	static get properties() {
		return {
			filter: {
				type: Object,
				value: () => {
					return {
						key: "",
						tags: {
							top: "",
							tree: []
						},
						category: "all",
						trigger: false,
						type: "all"
					};
				},
				notify: true
			},
			panelVisible: {
				type: Boolean,
				value: false,
				reflectToAttribute: true
			},
			treeData: {
				type: Object,
				value: null
			}
		};
	}

	connectedCallback() {
		super.connectedCallback();
		this.set("hideCategory", true);
		this.set("hierarchyItems", []);
		this._fetchAppConfig();
		this.set("categoryItems", [
			{ d: "All Category", v: "all" }
		]);
	}

	_fetchAppConfig() {
		this.makeAjaxCall("@restApiRoot/AppConfig", "get", null, null, null, null, (err, resp) => {
			if (err || !resp) {
				this.fire("oe-show-error", window.devportalError(err));
				return;
			}
			let config = {
				ui: {
					apiFilter: {
						topLevel: 0
					},
					categoryList: []
				},
				hierarchy: []
			};
			if (resp && resp.length > 0) {
				config = resp[0];
			}
			this.__createUIConfig(config);
		});
	}

	__createUIConfig(appConfig) {
		let {
			ui,
			hierarchy
		} = appConfig;

		let allCategory = [
			{ d: "All Category", v: "all" }
		];

		(ui.categoryList || []).forEach(c => {
			allCategory.push({
				d: c,
				v: c
			});
		});

		this.set("categoryItems", allCategory);
		this.set("hideCategory", allCategory.length > 1 ? false : true );
		let topDisplayLevels = ui.apiFilter.verticalLimit || 2;
		let allowedOnRow = ui.apiFilter.horizontalLimit || 2;
		let topFilterArr = [];

		for (let i = 0; i < topDisplayLevels; i++) {
			topFilterArr.push({
				level: i + 1,
				selected: "*",
				index: i,
				show: i === 0,
				list: [],
				showMore: false,
				moreList: [],
				isLastLevel: i === (topDisplayLevels - 1)
			});
		}
		topFilterArr[0].list = hierarchy.slice(0, allowedOnRow);
		topFilterArr[0].moreList = hierarchy.slice(allowedOnRow);
		if (topFilterArr[0].moreList.length > 0) {
			topFilterArr[0].showMore = true;
		}

		let config = {
			filter: {
				top: topFilterArr,
				sideBar: [],
				verticalLimit: topDisplayLevels,
				horizontalLimit: allowedOnRow
			},
			hierarchy: hierarchy
		};
		this.set("config", config);
		let flatList = [];
		this._createFlatHierarhcy(flatList, hierarchy, {
			disp: "", key: ""
		});
		this.set("hierarchyItems", flatList);
	}

	_createFlatHierarhcy(allList, hierarhcyList, prefix) {
		hierarhcyList.forEach(({ key, name, children }) => {
			let curName = `${prefix.disp}${name}`;
			let curKey = `${prefix.key}${key}`;
			if (name && key) {
				allList.push({
					disp: curName,
					key: curKey
				});
			}
			if (Array.isArray(children) && children.length > 0) {
				let nextPrefix = {
					disp: curName + "/",
					key: curKey + "/"
				};
				this._createFlatHierarhcy(allList, children, nextPrefix);
			}
		});
	}

	_selectedItem(selected, item) {
		return selected === item;
	}

	_selectedMenuItem(selected, menu) {
		return !!(selected && menu.find(item => item.key === selected));
	}

	_selectFilterItem(event) {
		let {
			item,
			levelfilter
		} = event.model;

		let nextIndex = levelfilter.index + 1;

		let currentSelected = item ? item.key : "*";

		if (levelfilter.selected === currentSelected) {
			return;
		}

		let {
			verticalLimit,
			horizontalLimit
		} = this.config.filter;

		if (!item) {
			// selected "All"
			this.set(`config.filter.top.${levelfilter.index}.selected`, "*");
			for (let i = nextIndex, len = verticalLimit; i < len; i++) {
				this.set(`config.filter.top.${i}.show`, false);
				this.set(`config.filter.top.${i}.list`, []);
				this.set(`config.filter.top.${i}.selected`, "*");
			}
			// Reset tree in sidebar as "all" should not show tree
			this.set("treeData", null);
			this.set("panelVisible", false);
		} else {
			this.set(`config.filter.top.${levelfilter.index}.selected`, item.key);
			if (levelfilter.isLastLevel) {
				if (Array.isArray(item.children) && item.children.length > 1) {
					// Populate tree in sidebar
					this.set("treeData", item);
					this.set("panelVisible", true);
				} else {
					this.set("treeData", null);
					this.set("panelVisible", false);
				}
			} else {
				// Populate child level
				for (let i = nextIndex, len = verticalLimit; i < len; i++) {
					if (i === nextIndex) {
						this.set(`config.filter.top.${i}.show`, true);
						let nextChildren = [...item.children];
						let list = nextChildren.slice(0, horizontalLimit);
						let moreList = nextChildren.slice(horizontalLimit);
						this.set(`config.filter.top.${i}.list`, list);
						this.set(`config.filter.top.${i}.moreList`, moreList);
						this.set(`config.filter.top.${i}.showMore`, moreList.length > 0);
					} else {
						this.set(`config.filter.top.${i}.show`, false);
						this.set(`config.filter.top.${i}.list`, []);
					}
					this.set(`config.filter.top.${i}.selected`, "*");
				}
				// Reset tree in sidebar as non last level is modified
				this.set("treeData", null);
				this.set("panelVisible", false);
			}
		}

		let tagFilter = [];
		this.config.filter.top.forEach(levelItem => {
			if (levelItem.selected !== "*" && levelItem.show) {
				tagFilter.push(levelItem.selected);
			}
		});
		this.set("filter.tags.top", tagFilter.join("/"));
		this.set("filter.tags.tree", []);
		this.set("filter.trigger", !this.filter.trigger);
		// this.fire('api-filter-updated');
	}

	_selectTreeItem(event) {
		this.set("filter.trigger", !this.filter.trigger);
		// this.fire('api-filter-updated');
	}

	_togglePanelVisibility() {
		this.set("panelVisible", !this.panelVisible);
	}

	_togglePanelIcon(isVisible) {
		return isVisible ? "chevron-left" : "chevron-right";
	}
}
window.customElements.define(ApiBundleFilter.is, ApiBundleFilter);
