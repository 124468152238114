import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import "@polymer/iron-flex-layout/iron-flex-layout.js";
import "@polymer/iron-flex-layout/iron-flex-layout-classes.js";
import { OEAjaxMixin } from "oe-mixins/oe-ajax-mixin.js";
import { ReduxMixin } from "../redux/redux-mixin.js";
import { AnimateMixin } from "../mixins/animate-mixin.js";
import "../../styles/app-theme.js";
import "oe-input/oe-file-input.js";
import "@polymer/paper-button/paper-button.js";
import "@polymer/iron-selector/iron-selector.js";
import "@polymer/iron-icon/iron-icon.js";

import "../../styles/oe-animation.js";
import "../components/api-group-card.js";
import "../components/tpp-dialog.js";
import "../components/tpp-button.js";
import "../components/api-bundle-filter.js";

/**
 * ### apis-page
 * `apis-page`
 *
 *
 * @customElement
 * @polymer
 */
class ApisPage extends AnimateMixin(OEAjaxMixin(PolymerElement)) {
	static get is() {
		return "apis-page";
	}

	static get template() {
		return html`
			<style include="iron-flex iron-flex-alignment oe-animation iron-flex-factors">

				.content{
					@apply --canvas;
					height: auto;
				}

				.banner-section{
					padding: 5px 0px;
					height: 275px;
					/* min-height:calc(100vh - 108px); */
				}

				.banner-img{
					height: 250px;
					width: 300px;
					padding: 0 0 0 50px;
				}

				.welcome-message{
					padding:0 150px 0 0;
					width: 50%;
				}

				h1 {
					font-size: 2.5rem;
					color: var(--primary-dark-color);
				}
				
				h2 {
					color: var(--primary-grey-color);
				}

				h1, h2, h3, h4{
					margin-top: 0;
					margin-bottom: .5rem;
					font-weight: 500;
					line-height: 1.2;
				}

				.filter-panel{
					display:block;
					width:100%;
				}

				.api-prelogin-msg {
					padding: 0 0 10px 0;
				}

				.api-bundle-list{
					display: grid;
                    grid-template-columns: repeat(5,1fr);
					padding: 5px 5px;
					gap : 15px;
					grid-auto-rows: 225px 225px 225px;
					overflow-y: auto;
					min-height:40vh;
				}

				
				.body-container{
					margin:16px 0px;
				}

				[hidden]{
					display:none !important;
				}

				tpp-button + tpp-button{
					margin-left:16px;
				}
			</style>
			
			<div id="container">
			
				<section class="hero" hidden=[[userLoggedIn]]>
					<div class="content banner-section layout horizontal justified">
						<div class="welcome-message layout vertical flex">
							<h1 oe-animate="fade-up">API Explorer lets you <br>quickly experience &<br>learn our APIs!</h1>
							<h2 oe-animate="fade-up" oe-animate-delay="500">
								<oe-i18n-msg msgid="Try out our APIs to discoverthe structure and depth of the data. We have covered every aspect of the banking open APIs.">Try out our APIs to discoverthe structure and depth of the data. We have covered every aspect of the banking open APIs.</oe-i18n-msg>
							</h2>
						</div>
						<iron-image oe-animate="zoom-in" class="banner-img" sizing="contains" src="img/api-banner_a.png"></iron-image>
					</div>
					
				</section>
				<div id="main-content" class="content" oe-animate-delay="500">
					<api-bundle-filter class="filter-panel" all-tags=[[allNodes]] filter={{apiFilter}} on-api-filter-updated="_filterUpdated">
					<div class="api-prelogin-msg" hidden=[[userLoggedIn]]> Please register yourself to get access to all available APIs and their documentation.</div>
					<div class="api-bundle-list" >
							<template is="dom-repeat" items=[[apiGroupsList]] as="group" filter=[[__groupFilter(apiFilter.trigger,apiFilter.key,apiFilter.category)]]>
								<api-group-card group=[[group]] allow-edit=[[_isAdminUser(userRole)]]
								on-view-group-details="__viewBundle" 
								on-edit-group-image="__editBundleImage"
								on-show-group-overview="__launchOverview"></api-bundle-card>
							</template>
						</div>
					</api-bundle-filter>
				</div>
			</div>
			${this.editImageTemplate}
			${this.overviewTemplate}
    	`;
	}

	static get properties() {
		return {
			userRole: {
				type: String,
				statePath: "userRole"
			},
			userLoggedIn: {
				type: Boolean,
				statePath: "userLoggedIn"
			},
			selectedRegion: {
				type: String,
				value: "*"
			},
			apiSearchText: {
				type: String
			},
            hideApiCount:{
                type:Boolean,
                value : Boolean(window.devportal.HIDE_API_CARD_COUNT)
            }
		};
	}

	static get observers() {
		return [
			"_computeImgDim(editGroupImage.src)"
		];
	}

	_isAdminUser(userRole) {
		return userRole === "admin";
	}

	connectedCallback() {
		super.connectedCallback();
		this.set("selectedView", "api-plan-list");
		this._fetchApiGroups();
	}

	_fetchApiGroups() {
		this.makeAjaxCall("@restApiRoot/ApiGroups/", "get", null, null, null, null, (err, groups) => {
			if (err) {
				this.fire("oe-show-error", window.devportalError(err));
				return;
			}
			let bundleCollections = groups.map(group => {
				let groupObj = {
					...group,
					hasApi: typeof group.api !== "undefined",
					hasEvent: typeof group.event !== "undefined"
				};
				return groupObj;
			});
			this.set("apiGroupsList", bundleCollections);
			this.set("allNodes", []);
		});
	}

	__viewBundle(event) {
		let group = event.detail;
		if (this.userLoggedIn) {
			window.oe_navigate_to("/api-detail/" + group.id);
		} else {
			this._gotoLogin();
		}
	}

	__groupFilter() {
		let {
			key,
			tags,
			category
		} = this.apiFilter;

		if (!key && !tags.top && tags.tree.length === 0 && category === "all") {
			return null;
		}

		let searchName = key.toLowerCase();
		let matchingTags = [];

		if (tags.tree.length !== 0) {
			tags.tree.forEach(path => {
				matchingTags.push(tags.top + path);
			});
		} else {
			matchingTags.push(tags.top);
		}

		let hasCategory = category === "all" ? null : category;

		return group => {
			if (searchName && group.name.toLowerCase().indexOf(searchName) === -1) {
				return false;
			}

			if (matchingTags.length > 0) {
				let isTagValid = group.tag.some(gtag => {
					return matchingTags.some(ftag => {
						return gtag.startsWith(ftag);
					});
				});
				if (!isTagValid) {
					return false;
				}
			}

			if (hasCategory) {
				return group.category === hasCategory;
			}

			return true;
		};
	}


	/** OVERVIEW CODE */

	static get overviewTemplate() {
		return html`
		<style>
			#overview{
				width:300px;
			}

			#overview .group-name{
				font-size:24px;
                display: block;
                margin-top: 16px;
			}
		</style>
		<tpp-dialog id="overview" modal>
			<label class="group-name" slot="header">[[selectedGroup.name]]</label>
			<div slot="body" class="body-container">
				<div class="desc-section" hidden=[[!selectedGroup.hasApi]]>
					<h2>
                        <oe-i18n-msg msgid="Apis"></oe-i18n-msg>
                        <span hidden=[[hideApiCount]]>( [[selectedGroup.api.apiCount]] )</span>
                    </h2>
					<p>[[selectedGroup.api.description]]</p>
				</div>
				<div class="desc-section" hidden=[[!selectedGroup.hasEvent]]>
					<h2>
                        <oe-i18n-msg msgid="Webhooks"></oe-i18n-msg>
                        <span hidden=[[hideApiCount]]>( [[selectedGroup.event.apiCount]] )</span>
                    </h2>
					<p>[[selectedGroup.event.description]]</p>
				</div>
			</div>
			<tpp-button primary mini slot="footer" hidden=[[userLoggedIn]] on-tap="_gotoLogin" message="Login"></tpp-button>
			<tpp-button mini slot="footer" message="Close" on-tap="_closeOverview"></tpp-button>
		</tpp-dialog>
		`;
	}

	__launchOverview(event) {
		let bundle = event.detail;
		this.set("selectedGroup", bundle);
		this.$.overview.open();
	}

	_gotoLogin() {
		this._closeOverview();
		window.oe_navigate_to("/authlogin");
		window.location.reload();
	}

	_closeOverview() {
		this.$.overview.close();
	}

	/** EDIT GROUP IMAGE CODE */

	static get editImageTemplate() {
		return html`
			<style>
				.image-dialog{
					min-height:250px;
				}

				.image-dialog iron-image{
					width:200px;
					height:200px;
				}

				.image-dialog > div {
					width:200px;
					min-height:200px;
					outline:1px dashed #222;
					outline-offset: 1px;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
				}
			</style>
			<tpp-dialog id="imageDlg" modal>
				<label class="group-name" slot="header">Change image for [[editGroup.name]]</label>
				<div slot="body" class="body-container layout horizontal center justified image-dialog">
					<div>
						<dom-if if=[[editGroup.img]] restamp>
							<template>
								<iron-image class="flex" sizing="contain" src="[[editGroup.img]]"></iron-image>
							</template>
						</dom-if>
					
					</div>
					<oe-file-input accept="image/*" value={{editGroupImage.src}} on-value-change="_computeImgDim">
						<template custom-upload>
							<style>
								.hint-text{
									padding:16px;
									font-size:12px;
								}
							</style>
							<div class="layout vertical center-center">
								<tpp-button secondary mini class="btn" on-tap="_selectFile" message="Browse"></tpp-button>
								<div class="hint-text">Dimension should be 128x128 px.</div>
							</div>
						</template>
					</oe-file-input>
					<div>
						<dom-if if=[[editGroupImage.src]] restamp>
							<template>
								<iron-image sizing="contain" src=[[editGroupImage.src]]></iron-image>
							</template>
						</dom-if>
						<label hidden=[[!editGroupImage.src]]>
							<span>Height : [[editGroupImage.height]] px</span>
							<span>Width : [[editGroupImage.width]] px</span>
						</label>
					</div>
				</div>
				<tpp-button primary mini disabled=[[!editGroupImage.isValid]] slot="footer" on-tap="_updateImage" message="Update Image"></tpp-button>
				<tpp-button mini slot="footer" message="Close" on-tap="_closeEdit"></tpp-button>
			</tpp-dialog>
		`;
	}

	__editBundleImage(event) {
		let group = event.detail;
		this.set("editGroup", group);
		this.set("editGroupImage", {
			src: "",
			height: null,
			width: null,
			isValid: false
		});
		this.$.imageDlg.open();
	}

	_computeImgDim() {
		if (this.editGroupImage.src) {
			var image = new Image();
			var self = this;
			image.onload = function () {
				self.set("editGroupImage.width", this.width);
				self.set("editGroupImage.height", this.height);
			};
			image.src = this.editGroupImage.src;
		}
	}

	_updateImage() {
		this.makeAjaxCall("@restApiRoot/ApiGroups/" + this.editGroup.id, "put", {
			img: this.editGroupImage.src
		}, null, null, null, (err, resp) => {
			if (err) {
				this.fire("oe-show-error", window.devportalError(err));
				return;
			}
			this.fire("oe-show-success", "Image updated succesfully");
			this._fetchApiGroups();
			this._closeEdit();
		});
	}

	_closeEdit() {
		this.$.imageDlg.close();
	}
}
window.customElements.define(ApisPage.is, ReduxMixin(ApisPage));
