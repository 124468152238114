/**
 * @license
 * ©2018-2019 EdgeVerve Systems Limited (a fully owned Infosys subsidiary),
 * Bangalore, India. All Rights Reserved.
 */
import "@polymer/polymer/lib/elements/dom-module.js";
const styleElement = document.createElement("dom-module");
styleElement.innerHTML =
    `<template>
        <style>
        [oe-animate]{
            transition-duration: 300ms;
            transition-timing-function: ease-in-out;
        }

        [oe-animate][oe-animate-delay="500"]{
            transition-delay: 500ms;
        }

        [oe-animate][oe-animate-delay="1000"]{
            transition-delay: 1000ms;
        }

        [oe-animate="fade-up"]{
            transform: translateY(20px);
            opacity:0;
        }

        .oe-animated[oe-animate="fade-up"]{
            transform: translateY(0);
            opacity: 1;
        }

        [oe-animate="fade-down"]{
            transform: translateY(-20px);
            opacity:0;
        }

        .oe-animated[oe-animate="fade-down"]{
            transform: translateY(0);
            opacity: 1;
        }

        [oe-animate="zoom-in"]{
            transform: scale(1.2);
            opacity: 0;
        }

        .oe-animated[oe-animate="zoom-in"]{
            transform: scale(1);
            opacity: 1;
        }
        </style>
    </template>`;
styleElement.register("oe-animation");