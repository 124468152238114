import { html } from "@polymer/polymer/polymer-element.js";
import ProfileBaseView from "./profile-base-view.js";

import "oe-input/oe-textarea.js";
import "oe-input/oe-file-input.js";
import "oe-radio-group/oe-radio-group.js";
import "@polymer/paper-checkbox/paper-checkbox.js";
import "../tpp-button.js";
import "../tpp-input.js";
import "../tpp-dropdown.js";
import "@polymer/neon-animation/animations/scale-up-animation.js";
import "@polymer/neon-animation/animations/scale-down-animation.js";
import "oe-ui-misc/oe-stepper.js";

/**
 * ### my-company-view
 * `my-company-view`
 *
 *
 * @customElement
 * @polymer
 */
class MyCompanyView extends ProfileBaseView {
	static get is() {
		return "my-company-view";
	}

	static get stylesTemplate() {
		return html`
			${super.stylesTemplate}
			<style include="input-styles">

				.flex-form{
					padding: 16px 2px;
				}

				.flex-form .full-width{
					width:100%;
					display : block;
				}

				.flex-form > *:not(.full-width){
					width : 45%;
				}

				.service-list{
					margin-bottom:16px;
				}
				
				.margin-b-16{
					display:block;
					margin-bottom:16px;
				}

				.service-list > paper-checkbox{
					width : calc(25% - 16px);
				}

				.helper-text{
					font-size: 14px;
					line-height: 24px;
				}

				#ca-cert-textbox{
					width:80%;
				}

				oe-file-input{
					display:block;
				}

				.uploaded-file-item{
					font-size: 13px;
					border: 1px solid;
					border-radius: 4px;
					margin: 8px 0px;
					padding: 2px 16px;
					display: flex;
					align-items: center;
				}
				.uploaded-file-item > label{
					flex:1;
				}

				.uploaded-file-item .download-icon{
					cursor:pointer;
				}
				
				.cif-btn{
					margin:0 16px;
				}

				#stepper{
					pointer-events: none;
					--oe-stepper-circle-size:30px;
					--oe-stepper-step-font-size:16px;
					--oe-stepper-label-width:200px;
				}

				.btn-holder{
					border-top: 1px solid #E4E4E4;
					margin-top:16px;
					padding: 16px;
					gap:16px;
				}
			</style>
		`;
	}

	static get contentTemplate() {
		return html`
			<p>
				<oe-i18n-msg msgid="Get access to APIs, analytics, customer support, and management tools. To get started, we need to know more about your company.">Get access to APIs, analytics, customer support, and management tools. To get started, we need to know more about your company.</oe-i18n-msg>	
			</p>
			<div class="layout vertical">
				<oe-stepper id="stepper" value=[[ui.activeStep]] steps=[[ui.steps]]></oe-stepper>
				<div class="flex">
					${this.basicDetails}
					<dom-if if=[[ui.blockBasicDetails]]>
						<template>
							${this.additionalDetails}
						</template>
					</dom-if>
				</div>
			</div>
			<div class="btn-holder layout horizontal center-center">
				<tpp-button hidden=[[!ui.saveBtnAllowed]] data-test-id="company-save-btn" primary mini class="btn btn-primary" on-tap="_saveOrganization" message="[[ui.saveBtnlabel]]"></tpp-button>
				<tpp-button hidden=[[!ui.submitBtnAllowed]] primary mini class="btn btn-primary" on-tap="_submitOrganization" message="Submit"></tpp-button>
				<tpp-button hidden=[[!ui.updateBtnAllowed]] primary mini class="btn btn-primary" on-tap="_updateApprovedOrg" message="Update"></tpp-button>
			</div>
		`;
	}

	constructor() {
		super();
		this.set("viewTitle", "Register Your Company Profile");
	}

	static get basicDetails() {
		return html`
            <oe-block-validator id="validator" class="flex-form layout horizontal justified wrap flex">
                <tpp-input readonly$=[[ui.blockBasicDetails]] field-id="name" required label="Company Legal Name"
                    value={{companyInfo.name}}></tpp-input>
                <tpp-input readonly$=[[ui.blockBasicDetails]] field-id="url" type="url"  pattern="https://.*" user-error-message='{"patternMismatch":"URL should start with https://"}' required label="Company Website URL"
                    value={{companyInfo.url}}></tpp-input>
                <oe-textarea rows="4" class="full-width rounded-input" always-float-label label="Company Address"
                    value={{companyInfo.address.line}}></oe-textarea>
                <tpp-input label="City (Optional)" value={{companyInfo.address.city}}></tpp-input>
                <tpp-input label="State (Optional)" value={{companyInfo.address.state}}></tpp-input>
                <tpp-input label="Postal code (Optional)" value={{companyInfo.address.postalCode}}></tpp-input>
                <tpp-dropdown label="Market" list=[[_availableMarkets]] value={{companyInfo.market}}></tpp-dropdown>
                <tpp-input readonly$=[[ui.blockBasicDetails]] required label="CIF ID" field-id="cifId" value={{companyInfo.cifId}} title="Only Alphanumeric (a-z A-Z 0-9) values are allowed"
				pattern="[a-zA-Z0-9]+" user-error-message='{"patternMismatch":"Only Alphanumeric values are allowed"}'>
                </tpp-input>
                <tpp-input label="Your Full Name" value={{companyInfo.ownerName}}></tpp-input>
                <tpp-input label="Your role (optional)" value={{companyInfo.ownerRole}}>
                </tpp-input>
                <tpp-input readonly$=[[ui.blockBasicDetails]] label="Your corporate email Id" type="email" required
                    value={{companyInfo.email}}></tpp-input>
                <tpp-input label="Phone No" pattern="[0-9\+]+" minlength="8" maxlength="50" value={{companyInfo.phone}}>
                </tpp-input>
                <oe-textarea rows="4" class="full-width rounded-input" minlength="10" maxlength="500" always-float-label field-id="businessCase"
                    required label="Describe your business case" value={{companyInfo.businessCase}}></oe-textarea>
            </oe-block-validator>
        `;
	}

	static get additionalDetails() {
		return html`
            <div class="flex-form layout horizontal justified wrap flex">
                <div class="full-width layout horizontal service-list">
                    <label class="full-width margin-b-16">
						<oe-i18n-msg msgid="Type of services you are authorised for: (optional)">Type of services you are authorised for: (optional)</oe-i18n-msg>	
					</label>
                    <paper-checkbox data-test-id="PISP" checked={{companyInfo.serviceType.pisp}}>
						<oe-i18n-msg msgid="PISP">PISP</oe-i18n-msg>
					</paper-checkbox>
                    <paper-checkbox data-test-id="NEO" checked={{companyInfo.serviceType.neobanks}}>
						<oe-i18n-msg msgid="Neobanks">Neobanks</oe-i18n-msg>
					</paper-checkbox>
                    <paper-checkbox checked={{companyInfo.serviceType.aggregator}}>
						<oe-i18n-msg msgid="Aggregrator">Aggregrator</oe-i18n-msg>
					</paper-checkbox>
                    <paper-checkbox checked={{companyInfo.serviceType.erp}}>
						<oe-i18n-msg msgid="ERP OEMs">ERP OEMs</oe-i18n-msg>
					</paper-checkbox>
                </div>
                <div>
                    <label class="margin-b-16">
						<oe-i18n-msg msgid="Upload Board Resolution">Upload Board Resolution</oe-i18n-msg>	
					</label>
                    <dom-repeat items=[[companyInfo.documents]]>
                        <template>
                            <div class="uploaded-file-item">
                                <label>[[item]]</label>
                                <iron-icon icon="clear" class="download-icon" on-tap="_unlinkDocument" hidden=[[ui.isApproved]]></iron-icon>
                                <iron-icon icon="file-download" class="download-icon" on-tap="_downloadDocument"></iron-icon>
                            </div>
                        </template>
                    </dom-repeat>
                    <oe-file-input value={{_filesToUpload}} accept="application/pdf,image/tiff,image/jpeg" multiple
                        label="Upload Board Resolution">
                        <template custom-upload>
                            ${this.fileUploaderTemplate}
                        </template>
                    </oe-file-input>
                </div>
                <div>

                </div>
            </div>
            <dom-if if=[[organizationInfo.comments]]>
                <template>
                    <label class="margin-b-16">
					<oe-i18n-msg msgid="Comments">Comments</oe-i18n-msg>
					</label> 
                    <pre> 
[[organizationInfo.comments]]
                    </pre>
                </template>
            </dom-if>
        `;
	}

	static get fileUploaderTemplate() {
		return html`
            <style>
                .uploaded-file-item{
                    font-size: 13px;
                    border: 1px solid;
                    border-radius: 4px;
                    margin: 8px 0px;
                    padding: 2px 16px;
                }
            </style>
            <tpp-button secondary mini class="btn" on-tap="_selectFile" message="Browse" disabled=[[ui.isApproved]]></tpp-button>
            <template is="dom-repeat" items="[[listToArray(files)]]">
                <div class="uploaded-file-item">[[item.name]]</div>
            </template>
        `;
	}

	static get properties() {
		return {
			organizationInfo: {
				type: Object,
				statePath: "organizationInfo",
				observer: "_orgDataChanged"
			},
			userAccess: {
				type: Object,
				statePath: "userAccess"
			},
			userRole: {
				type: Object,
				statePath: "userRole"
			},
			_availableMarkets: {
				type: Array,
				value: () => [
					"India",
					"Europe",
					"Middle East",
					"APAC",
					"North America"
				]
			}
			// _availableCatalog: {
			// 	type: Array,
			// 	value: () => [
			// 		"catalog 1",
			// 		"catalog 2",
			// 		"catalog 3",
			// 		"catalog 4",
			// 		"catalog 5"
			// 	]
			// }
		};
	}

	static get observers() {
		return [
			"_updateUI(userRole,userInfo,organizationInfo)"
		];
	}

	_orgDataChanged() {
		if (this.organizationInfo) {
			this.organizationInfo.serviceType = this.organizationInfo.serviceType || {};
			this.set("companyInfo", { ...this.organizationInfo });
		} else {
			this.set("companyInfo", {
				name: this.userInfo.orgName,
				url: "https://",
				email: (this.companyInfo ? this.companyInfo.email : "") || this.userInfo.email,
				webhook: {},
				address: {},
				serviceType: {}
			});
		}

		// Reset file uploaders
		this.shadowRoot.querySelectorAll("oe-file-input").forEach(el => { el.files = null; });
		this.set("_filesToUpload", null);
		this._removedFiles = [];
	}


	/* Save/Update/Submit organization functions */

	async _saveOrganization(cb) {
		let state = this.companyInfo.state;

		if (typeof state === "undefined" || state === "created") {
			// Creation or Updation before verfiying cif
			// Need to validate the basic details
			let result = await this.$.validator.validateForm();
			if (!result.valid) {
				this.fire("oe-show-error", "Error in form validation.");
				return;
			}
			await this.__updateOrganizationPreVerification(cb);
		} else {
			// Updating Additional details after verfication of cif
			// Basic details cannot be edited, skipping validation
			this.__updateOrganizationPostVerification(cb);
		}
	}

	async __updateOrganizationPreVerification(_cb) {
		let payload = this.companyInfo;
		// delete payload.cacert;
		payload.documents = [];
		payload.certificate = [];

		let method = this.organization && this.organization.id ? "PUT" : "POST";
		let url = method === "POST" ? "@restApiRoot/Organizations" : `@restApiRoot/Organizations/${this.organization.id}`;
		// let msg = method === 'POST' ? `Your details have been saved successfully, please validate CIF to complete your registration. Without which you will not be able to access the APIs.` : `Your details have been updated successfully.`;
		let msg = method === "POST" ? "Your details have been saved successfully." : "Your details have been updated successfully.";

		try {
			let [err, resp] = await this._makeAjaxPromise(url, method, payload);

			if (err) {
				this.fire("oe-show-error", window.devportalError(err));
				return;
			}

			if (method === "POST") {
				// Create new Document container for the organization to store documents
				await this._makeAjaxPromise("@restApiRoot/Documents", "POST", { name: resp.id });
			}

			if (typeof _cb === "function") {
				_cb();
			} else {
				this.fire("oe-show-success", msg);
				this.fire("refetch-user-info");
			}
		} catch (err) {
			this.fire("oe-show-error", window.devportalError(err));
		}
	}

	async __updateOrganizationPostVerification(_cb) {
		// After Cif Authenticated Only file upload and other info are editable.
		let payload = this.companyInfo;
		// Handle Documents upload
		payload.documents = payload.documents || [];
		if (this._filesToUpload) {
			Array.from(this._filesToUpload).map(f => f.name).forEach(fName => {
				// Only add new documents
				if (!payload.documents.includes(fName)) {
					payload.documents.push(fName);
				}
			});
		}

		// Delete comments on payload
		delete payload.comments;

		try {
			let updateUrl = `@restApiRoot/Organizations/${this.organization.id}`;
			let [err, resp] = await this._makeAjaxPromise(updateUrl, "PUT", payload);

			if (err) {
				this.fire("oe-show-error", window.devportalError(err));
				return;
			}

			await this._uploadDocuments(resp.id);
			await this.removeUnlinedDocuments();
			if (typeof _cb === "function") {
				_cb();
			} else {
				this.fire("oe-show-success", "The organization has been updated successfully.");
				this.fire("refetch-user-info");
			}
		} catch (err) {
			this.fire("oe-show-error", window.devportalError(err));
		}
	}

	_validateCif() {
		this._saveOrganization(() => {
			window.location.pathname = window.OEUtils.subPath + "/validate-cif";
		});
	}

	_submitOrganization() {
		this.fire("oe-show-confirm", {
			message: "Confirm submitting for approval ?",
			ok: () => {
				this.companyInfo.state = "submitted";
				this._saveOrganization(() => {
					this.fire("oe-show-success", "Your details have been recorded successfully. You will receive an email from Bank Admin with next steps.");
					this.fire("goto-view", "my-profile");
					this.fire("refetch-user-info");
				});
			}
		});
	}

	_updateApprovedOrg() {
		this.__updateOrganizationPostVerification();
	}

	/* Document handling functions */

	_uploadDocuments(containerName, cb) {
		return new Promise((resolve, reject) => {
			if (!this._filesToUpload) {
				resolve();
				return;
			}

			let containerUrl = `@restApiRoot/Documents/${containerName}`;

			// Add all documents into the container
			var formData = new FormData();
			Array.from(this._filesToUpload).forEach(file => {
				formData.append(file.name, file, file.name);
			});

			let data = formData;
			var ajax = document.createElement("oe-ajax");
			// eslint-disable-next-line
			ajax.contentType = undefined;
			ajax.url = `${containerUrl}/upload`;
			ajax.method = "POST";
			ajax.body = data;
			ajax.addEventListener("response", resolve);
			ajax.addEventListener("error", reject);
			ajax.generateRequest();
		});
	}

	_downloadDocument(event) {
		let fileName = event.model.item;
		let url = window.OEUtils.geturl(`@restApiRoot/Documents/${this.companyInfo.id}/download/${fileName}`);
		let a = document.createElement("a");
		a.setAttribute("href", url);
		a.setAttribute("download", fileName);
		a.setAttribute("target", "blank");
		a.click();
	}

	_unlinkDocument(event) {
		let fileName = event.model.item;
		this._removedFiles.push(fileName);
		this.splice("companyInfo.documents", event.model.index, 1);
	}

	async removeUnlinedDocuments() {
		let deletedFiles = [];
		let baseURL = `@restApiRoot/Documents/${this.companyInfo.id}/files/`;
		for (let fileName of this._removedFiles) {
			await this._makeAjaxPromise(baseURL + fileName, "DELETE", null);
			deletedFiles.push(fileName);
		}
		this._removedFiles = [];
	}

	/**
     * Creates flags to manage the UI
     * @param {object} userRole User role based on role mapping
     * @param {object} userInfo User information
     * @param {object} organizationInfo Organization information loaded, not the editable companyInfo
     */
	_updateUI(userRole, userInfo, organizationInfo) {
		if (!userRole || !userInfo) {
			return;
		}
		organizationInfo = organizationInfo || {};
		let orgState = organizationInfo.state;
		let activeStep = 0;
		let saveBtnlabel = "Save";
		let saveBtnAllowed = true;
		let cifBtnAllowed = true;
		let submitBtnAllowed = false;
		let updateBtnAllowed = false;

		switch (orgState) {
		case "created":
			saveBtnlabel = "Update";
			activeStep = 1;
			break;
		case "cifverified":
			saveBtnlabel = "Update";
			activeStep = 2;
			cifBtnAllowed = false;
			submitBtnAllowed = true;
			break;
		case "submitted":
			activeStep = 2;
			saveBtnAllowed = false;
			cifBtnAllowed = false;
			break;
		case "approved":
			activeStep = 2;
			saveBtnAllowed = false;
			cifBtnAllowed = false;
			updateBtnAllowed = true;
			break;
		default:
			break;
		}

		if (organizationInfo && organizationInfo.id && organizationInfo.userId) {
			// Disable all actions if user is not organization creater
			if (userInfo.userId !== organizationInfo.userId) {
				saveBtnAllowed = false;
				cifBtnAllowed = false;
				submitBtnAllowed = false;
				updateBtnAllowed = false;
			}
		}

		this.set("ui", {
			activeStep,
			saveBtnlabel,
			saveBtnAllowed,
			cifBtnAllowed,
			submitBtnAllowed,
			updateBtnAllowed,
			isApproved: orgState === "approved" || orgState === "submitted",
			blockBasicDetails: activeStep > 1,
			steps: [
				{
					"label": "Basic Details",
					"isDisabled": false,
					"hasError": false,
					"isCompleted": activeStep !== 0
				},
				{
					"label": "Verify CIF",
					"hasError": false,
					"isCompleted": activeStep > 1
				},
				{
					"label": "Additional Details",
					"hasError": false,
					"isCompleted": false
				}
			]
		});
	}
}
window.customElements.define(MyCompanyView.is, MyCompanyView);
