const LOGOLIST = [
	"default.png",
	"collaterals.png",
	"consent-APIs.png",
	"corporate-current-accounts.png",
	"corporate-deposit-accounts.png",
	"corporate-overdraft-accounts.png",
	"credit-limit.png",
	"crm-customer-data-hub.png",
	"current-accounts.png",
	"data-privacy.png",
	"deposit-accounts.png",
	"liquidity-management.png",
	"loan-accounts-secured.png",
	"loan-accounts-unsecured.png",
	"loan.png",
	"open-banking-APIs.png",
	"overdraft-accounts.png",
	"payments.png",
	"savings-accounts.png",
	"trade-finance.png",
	"transactions.png",
	"treasury.png",
	"virtual-accounts.png",
	"wealth-management.png"
];

import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import "@polymer/iron-flex-layout/iron-flex-layout.js";
import "@polymer/iron-flex-layout/iron-flex-layout-classes.js";
import { OECommonMixin } from "oe-mixins/oe-common-mixin.js";
import "@polymer/paper-menu-button/paper-menu-button.js";
import "@polymer/paper-listbox/paper-listbox.js";


class LogoSelector extends OECommonMixin(PolymerElement) {
	static get is() {
		return "logo-selector";
	}

	static get template() {
		return html`
          <style>
              :host{
                  display:inline-block;
              }
  
              .container{
                  display:flex;
                  flex-direction:column;
              }
  
              paper-menu-button{
                  padding:0px;
              }
  
              iron-image{
                  width:60px;
                  height:60px;
              }
  
              .list-box{
                  display: grid;
                  grid-template-columns: repeat(3,200px);
                  grid-template-rows: 100px;
                  gap: 16px 8px;
                  max-height:300px;
              }
  
              .logo-item{
                  display:flex;
                  text-align: center;
                  align-items: center;
                  justify-content: center;
                  flex-direction: column;
                  transition:all 300ms ease;
              }
  
              .logo-item > label{
                  height: 24px;
                  font-size: 12px;
                  text-transform: capitalize;
              }
  
              .list-box .logo-item:hover{
                  transform:translateY(-10px);
              }
  
          </style>
          <div class="container">
              <label class="selector-label">Select Proxy Logo</label>
              <paper-menu-button vertical-align="bottom" horizontal-align="right">
                  <div slot="dropdown-trigger" class="logo-item">
                      <iron-image src=[[__getImg(value)]] sizing="contain"></iron-image>
                      <!-- <label>[[value]]</label> -->
                  </div>
                  <paper-listbox slot="dropdown-content" class="list-box">
                      <dom-repeat items=[[iconsList]]>
                          <template>
                              <paper-item class="logo-item" on-tap="_selectLogo">
                                  <iron-image src=[[__getImg(item)]] sizing="contain"></iron-image>
                                  <label>[[item]]</label>
                              </paper-item>
                          </template>
                      </dom-repeat>
                  </paper-listbox>
              </paper-menu-button>
          </div>
          `;
	}

	static get properties() {
		return {
			value: {
				type: String,
				value: "default",
				observer: "_valueChanged",
				notify: true
			},
			iconsList: {
				type: Array,
				value: () => [...LOGOLIST.map(n => n.replace(".png", ""))]
			}
		};
	}

	_valueChanged() {
		if (typeof this.value === "undefined") {
			this.set("value", "default");
		}
	}

	__getImg(selected) {
		selected = selected || "default";
		return `${this.rootPath}./img/proxy-logo/${selected}.png`;
	}

	_selectLogo(event) {
		let item = event.model.item;
		this.set("value", item);
	}
}
window.customElements.define(LogoSelector.is, LogoSelector);
