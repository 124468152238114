import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import "@polymer/iron-flex-layout/iron-flex-layout.js";
import "@polymer/iron-flex-layout/iron-flex-layout-classes.js";
import {AnimateMixin} from "../mixins/animate-mixin.js";
import "oe-input/oe-input.js";
import "oe-input/oe-textarea.js";
import "@polymer/iron-image/iron-image.js";
import "../components/tpp-button.js";
import "../../styles/app-theme.js";
import "../../styles/oe-animation.js";

/**
 * ### home-page
 * `home-page`
 *
 *
 * @customElement
 * @polymer
 */
class HomePage extends AnimateMixin(PolymerElement) {
	static get is() {
		return "home-page";
	}

	static get template() {
		return html `
    <style include="iron-flex iron-flex-alignment oe-animation">

        h1, h2, h3, h4{
            margin-top: 0;
            margin-bottom: 0.5rem;
            font-weight: 500;
            line-height: 1.2;
        }

        ul{
            list-style:none;
            margin:0px;
            padding:0px;
        }

        #container > section{
            margin:0px;
            /* width : 100vw; */
            min-height: calc(100vh - 108px);
            padding: 60px 0px;
            box-sizing: border-box;
            padding-bottom:60px;
            --section-heading-color : var(--primary-dark-color);
            --section-subheading-color : var(--primary-grey-color);
        }

        #container > section.accent-container{
            background-color:var(--primary-dark-color);
            --section-heading-color : var(--primary-contrast-color);
            --section-subheading-color : var(--primary-contrast-color);
        }

        #container > section > div{
            @apply --canvas;
        }

        .section-heading{
            font-size: 2.5rem;
            text-align:center;
            color : var(--section-heading-color);
        }

        .section-subheading{
            font-size: 28px;
            font-weight: 300;
            letter-spacing: 0px;
            text-transform: none;
            text-align: center;
            color : var(--section-subheading-color);
        }

        /* ============================== */
        .welcome-message{
            padding: 40px 60px 40px 0px;
        }

        .welcome-message .section-heading{
            font-size: 48px;
            font-weight:300;
            text-align:left;
        }

        .welcome-message .section-subheading{
            font-size: 26px;
            text-align:left;
        }

        /* ============================== */

        .service-list{
            margin: 64px 200px;
        }

        .item-lb{
            border-radius: 20px 20px 0px 20px;
        }

        .item-rb{
            border-radius: 20px 20px 20px 0px;
        }

        .service-item{
            padding: 30px;
            box-shadow: 3px 4px 5px rgb(4 4 4 / 30%);
            background-color: var(--primary-contrast-color);
            color: var(--primary-grey-color);
            text-align: center;
            transition: 0.3s;
            width: 41%;
        }

        .service-item img{
            padding: 30px 50px;
            transition: 0.5s;
            transform: scale(1);
        }

        .service-item:hover img{
            transform: scale(1.2);
        }

        .service-item h3{
            font-size: 24px;
            color: var(--primary-dark-color);
            font-weight: 300;
            margin-bottom: 18px;
        }

        .service-footer{
            padding:0 200px;
        }

        /* ============================== */

        .explore-list{
            margin: 64px 0px;
        }

        .explore-item{
            padding: 5px;
            background-color: var(--primary-contrast-color);
            color: var(--primary-grey-color);
            text-align: center;
            transition: 0.3s;
            height: 255px;
            width: 30%;
            margin-bottom: 12px;
        }

        .explore-item:hover{
            box-shadow: 0px 0 20px rgb(1 41 112 / 8%);
        }

        .explore-item:hover img{
            transform: scale(0.8);
        }

        .explore-item h3{
            font-size: 24px;
            color: var(--primary-dark-color);
            font-weight: 300;
            margin-bottom: 18px;
        }

        .explore-item img{
            font-size: 24px;
            color: var(--primary-dark-color);
            font-weight: 300;
            margin-bottom: 18px;
            transition: 0.3s;
        }

        /* ============================== */

        .steps-list{
            margin: 64px auto;
            background-image: url("img/info-graphic.png");
            background-size: contain;
            background-repeat: no-repeat;
            padding: 0px 20px;
            box-sizing: border-box;
            width: 920px;
        }

        .steps-item{
            padding: 9px;
            text-align: center;
            transition: 0.3s;
            height: 255px;
            width: 20%;
            margin-bottom: 12px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
        }

        .steps-item h2{
            color: var(--primary-contrast-color);
            font-size: 20px;
            font-weight: 700;
        }

        .steps-item p {
            color: var(--primary-contrast-color);
            font-size: 14px;
            font-weight: 300;
            margin-top: 0;
            margin-bottom: 1rem;
        }

        /* ============================== */


        .build-list{
            margin:16px 0px;
        }

        .build-item{
            width: 33%;
            padding: 16px;
            text-align: center;
        }

        .build-item:not(:last-of-type){
            border-right : 3px solid var(--primary-grey-color);
        }

        .build-item h3{
            font-size: 24px;
            color: var(--primary-dark-color);
            font-weight: 300;
            margin-bottom: 18px;
        }

        /* ============================== */

        .partner-section{
            background: #f1f1f1;
        }

        .partner-form{
            width:780px;
            margin:16px auto;
            background: #f9f8f8;
            padding: 30px;
        }

        .block-input{
            margin-top:24px;
            width: 48%;
            background:var(--primary-contrast-color);
            border:1px solid var(--primary-grey-color);

            --paper-input-container-underline: {
                display: none;
            };

            --paper-input-container-underline-focus: {
                display: none;
            };

            --iron-autogrow-textarea:{
                padding: 10px 15px 8px;
                box-shadow: none;
                font-size: 14px;
                border-radius: 0;
                box-sizing: border-box;
                line-height: 21px;
            }
        }

        .block-input[focused]{
            border:1px solid var(--primary-dark-color);
        }

        .block-input::part(paper-input-container){
            padding: 0px;
            border-width:0px;
            box-sizing: border-box;
        }

        .block-input::part(iron-input){
            padding: 10px 15px 8px;
            box-shadow: none;
            font-size: 14px;
            border-radius: 0;
            box-sizing: border-box;
            line-height: 21px;
        }

        .margin-top{
            margin-top:30px;
        }




    </style>
    <main id="container">

        <section>
            <div class="layout horizontal justified banner-section">
                <div class="welcome-message layout vertical flex">
                    <h1 class="section-heading" oe-animate="fade-up">
                        <oe-i18n-msg msgid="Welcome to">Welcome to</oe-i18n-msg>    
                    <br>Finacle <oe-i18n-msg msgid="API Center!">API Center!</oe-i18n-msg></h1>
                    <h2 class="section-subheading" oe-animate="fade-up" oe-animate-delay="500">
                        <oe-i18n-msg msgid="home_page_welcome_desc">Enabling FinTechs and Corporate entities to avail a wide range of banking services via APIs.</oe-i18n-msg>    
                    </h2>
                    <tpp-button on-tap="_gotoLink" link="/apis" class="margin-top" oe-animate="fade-up" oe-animate-delay="1000" primary animate-on-hover message="Explore API Catalog" icon="arrow-forward"></tpp-button>
                </div>
                <iron-image oe-animate="zoom-in" class="banner-img" src="img/visual-1.png"></iron-image>
            </div>
        </section>

        <section class="accent-container">
            <div>
                <h1 class="section-heading" oe-animate="fade-up">
                    <oe-i18n-msg msgid="Access to banking services - Easy,">Access to banking services - Easy,</oe-i18n-msg>    
                <br/> 
                    <oe-i18n-msg msgid="Efficient and Secure">Efficient and Secure</oe-i18n-msg>
                </h1>
                <ul class="service-list layout horizontal justified" >
                    <li class="service-item item-lb" oe-animate="fade-up" oe-animate-delay="500">
                        <img src="img/FinTech-innovations_Icon.png" alt="">
                        <h3>
                            <oe-i18n-msg msgid="FinTech innovations">FinTech innovations</oe-i18n-msg>    
                        </h3>
                        <p>
                            <oe-i18n-msg msgid="home_page_innovation_desc">Build with Finacle API Center APIs and extend your innovation to millions of banking customers.</oe-i18n-msg>    
                        </p>
                    </li>
                    <li class="service-item item-rb" oe-animate="fade-up" oe-animate-delay="1000">
                        <img src="img/ERP-integrations_Icon.png" alt="">
                        <h3> 
                            <oe-i18n-msg msgid="ERP integrations">ERP integrations</oe-i18n-msg>
                        </h3>
                        <p>
                            <oe-i18n-msg msgid="home_page_erp_desc">Enabling corporate users to access a wide range of banking services within their ERP applications.</oe-i18n-msg>    
                        </p>
                    </li>    
                </ul>
                <div class="service-footer">
                    <tpp-button class="margin-top" on-tap="_gotoLink" link="/register" primary animate-on-hover message="Register Now" icon="arrow-forward"></tpp-button>
                </div>
            </div>
        </section>

        <section oe-animate="fade-up">
            <div>
                <h1 class="section-heading">
                    <oe-i18n-msg msgid="Explore Finacle API Center APIs">Explore Finacle API Center APIs</oe-i18n-msg>    
                </h1>
                <h2 class="section-subheading">
                    <oe-i18n-msg msgid="Business APIs across various banking applications and use-cases">Business APIs across various banking applications and use-cases</oe-i18n-msg>    
                </h2>
                <ul class="explore-list layout horizontal justified wrap" >
                    <template is="dom-repeat" items=[[__exploreList]]>
                        <li class="explore-item">
                            <template is="dom-if" if=[[item.img]]>
                                <img src$="img/icon/[[item.img]]" alt="">
                            </template>
                            <h3>
                                <oe-i18n-msg msgid=[[item.title]]></oe-i18n-msg>    
                            </h3>
                            <p>
                            <oe-i18n-msg msgid=[[item.descId]]>[[item.desc]]</oe-i18n-msg>    
                            </p>
                        </li>
                    </template>
                </ul>
                <div>
                    <tpp-button on-tap="_gotoLink" link="/apis" primary animate-on-hover message="Browse all APIs" icon="arrow-forward"></tpp-button>
                </div>
            </div>
        </section>

        <section class="accent-container steps-section">
            <div>
                <h1 class="section-heading" oe-animate="fade-up">
                <oe-i18n-msg msgid="How it works">How it works</oe-i18n-msg>    
                </h1>
                <h2 class="section-subheading" oe-animate="fade-up">
                    <oe-i18n-msg msgid="Incorporate Finacle API Center APIs in your innovative ">Incorporate Finacle API Center APIs in your innovative </oe-i18n-msg>
                    <br>
                    <oe-i18n-msg msgid="solutions in 4 easy steps">solutions in 4 easy steps</oe-i18n-msg>    
                </h2>
                <ul class="steps-list layout horizontal justified wrap" oe-animate="fade-up" oe-animate-delay="500">
                    <template is="dom-repeat" items=[[__stepsList]]>
                        <li class="steps-item">
                            <h2>
                                <oe-i18n-msg msgid=[[item.title]]></oe-i18n-msg>    
                            </h2>
                            <p>
                                <oe-i18n-msg msgid=[[item.desc]]></oe-i18n-msg>    
                            </p>
                        </li>
                    </template>
                </ul>
                <div class="steps-footer">
                    <tpp-button class="margin-top" on-tap="_gotoLink" link="/register" primary animate-on-hover message="Register Now" icon="arrow-forward"></tpp-button>
                </div>
            </div>
        </section>

        <section class="build-section">
            <div>
                <h1 class="section-heading" oe-animate="fade-up">
                <oe-i18n-msg msgid="Explore the ways you can build">Explore the ways you can build</oe-i18n-msg>    
                <br/>
                <oe-i18n-msg msgid="with Finacle API Center APIs">with Finacle API Center APIs</oe-i18n-msg>
                </h1>
                <ul class="build-list layout horizontal justified" oe-animate="fade-up" oe-animate-delay="500">
                    <template is="dom-repeat" items=[[__buildList]]>
                        <li class="build-item">
                            <h3>
                                <oe-i18n-msg msgid=[[item.title]]></oe-i18n-msg>    
                            </h3>
                            <p>
                                <oe-i18n-msg msgid=[[item.descId]]>[[item.desc]]</oe-i18n-msg>    
                            </p>
                        </li>
                    </template>
                </ul>
            </div>
        </section>

        <section class="partner-section" oe-animate="fade-up">
            <div>
                <h1 class="section-heading">
                    <oe-i18n-msg msgid="Partner with us">Partner with us</oe-i18n-msg>    
                </h1>
                <h2 class="section-subheading">
                    <oe-i18n-msg msgid="Write to us and we will help you innovate">Write to us and we will help you innovate</oe-i18n-msg>    
                    <br/>
                    <oe-i18n-msg msgid="faster with Finacle API Center APIs">faster with Finacle API Center APIs</oe-i18n-msg>
                </h2>
                <div class="partner-form layout horizontal wrap justified" >
                    <oe-input placeholder="Full Name" no-label-float class="block-input"></oe-input>
                    <oe-input placeholder="Email ID" no-label-float class="block-input"></oe-input>
                    <oe-input placeholder="Phone No" no-label-float class="block-input"></oe-input>
                    <oe-input placeholder="Organization" no-label-float class="block-input"></oe-input>
                    <oe-input placeholder="Country" no-label-float class="block-input"></oe-input>
                    <oe-textarea max-rows="5" placeholder="Message" no-label-float class="block-input"></oe-textarea>
                    <div class="partner-form-footer flex layout horizontal end-justified">
                        <tpp-button class="margin-top" 
                        primary animate-on-hover 
                        mini animate-to-primary
                        message="Submit" icon="arrow-forward"></tpp-button>
                    </div>
                </div>
            </div>
        </section>
    </main>
    `;
	}

	constructor() {
		super();
		this.set("__exploreList", [
			{
				"img": "customer.png",
				"title": "Customers",
				"descId": "Customers-desc",
				"desc": "Leverage these APIs to onboard and service customers across the retail, SME and corporate segments"
			},
			{
				"img": "deposit.png",
				"title": "Deposits",
				"descId": "Deposits-desc",
				"desc": "Open, maintain, inquire, and close savings accounts, current accounts, overdraft accounts and term deposits"
			},
			{
				"img": "loan.png",
				"title": "Loans",
				"descId": "Loans-desc",
				"desc": "Carry out transactions across the lending cycle for unsecured and secured loans"
			},
			{
				"img": "payment.png",
				"title": "Payments",
				"descId": "Payments-desc",
				"desc": "Use these APIs to initiate domestic payments, cross-border payments, and payment related enquiries"
			},
			{
				"img": "trade.png",
				"title": "Trade Finance",
				"descId": "Trade Finance-desc",
				"desc": "These APIs help you inquire, create, and amend trade finance transactions"
			},
			{
				"img": "data-privacy.png",
				"title": "Data Privacy",
				"descId": "Data Privacy-desc",
				"desc": "These APIs assist in managing customers' Consent Mandates including their opt-in, opt-outs, and consent history"
			}
		]);
		this.set("__stepsList", [
			{
				title: "Register",
				desc: "Sign-up for a Finacle API Center Developer Account"
			},
			{
				title: "Explore",
				desc: "Browse the vast catalog of Open APIs"
			},
			{
				title: "Develop",
				desc: "Build and test in the sandbox environment"
			},
			{
				title: "Go-Live",
				desc: "Get approvals and from Finacle API Center"
			}
		]);
		this.set("__buildList", [
			{
				"title": "ReconTech",
				"descId": "recon_desc",
				"desc": "Real-time financial book-keeping by accessing account summary, balance, and transaction history"
			},
			{
				"title": "Rewards Co",
				"descId": "rewards_desc",
				"desc": "Manages rewards, cashbacks, and loyalty program by using the Credit & Debit Card transactions data"
			},
			{
				"title": "AutoInvest",
				"descId": "autoinvest_desc",
				"desc": "Manages investment portfolio based on the account statements, deposits and other products held by customer"
			}
		]);
	}

	_gotoLink(e) {
		let target = e.target;
		let url = target.getAttribute("link");
		window.oe_navigate_to(url);
	}
}
window.customElements.define(HomePage.is, HomePage);
